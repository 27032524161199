import React from "react";
import styled, { keyframes } from "styled-components";

const SkeletonLoad = () => {
    const renderCard = () => (
        <Card>
            <PrimeContent>
                <ServiceIcon />
                <TextArea>
                    <ServiceName />
                    <UserAccount />
                </TextArea>
                <Button />
            </PrimeContent>
            <SubContent />
        </Card>
    );
    return (
        <Wrapper>
            <Category>
                <Title />
                <ItemsContainer>
                    {renderCard()}
                    {renderCard()}
                    {renderCard()}
                </ItemsContainer>
            </Category>
            <Category>
                <Title />
                <ItemsContainer>
                    {renderCard()}
                    {renderCard()}
                </ItemsContainer>
            </Category>
            <Category>
                <Title />
                <ItemsContainer>{renderCard()}</ItemsContainer>
            </Category>
        </Wrapper>
    );
};

export default SkeletonLoad;

const skeletonLoading = keyframes`
    0% {
        background-color: hsla(210, 7%, 94%, .5);
    }
    100% {
        background-color: hsla(210, 7%, 94%, 1);
    }
`;
const Wrapper = styled.div``;
const Category = styled.div`
    width: 100%;
    margin-bottom: 32px;
`;
const Title = styled.div`
    width: 64px;
    height: 14px;
    border-radius: 4px;
    background-color: var(--surface-skeleton);
    animation: ${skeletonLoading} 1s linear infinite alternate;
`;
const ItemsContainer = styled.div`
    margin-top: 8px;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-flow: row wrap;
    gap: 24px;
`;
const Card = styled.div`
    width: 425px;
    min-width: 300px;
    border-radius: var(--radius-rounded);
    background: var(--surface-primary);
    position: relative;
    box-sizing: border-box;
    padding: var(--spacing-xl) 0 var(--spacing-lg);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: 152px;

    @media (max-width: 800px) {
        width: 100%;
        min-width: auto;
        height: 96px;
    }
`;

const PrimeContent = styled.div`
    display: flex;
    align-items: start;
    justify-content: start;
    gap: 16px;
    padding-left: var(--spacing-lg);
    padding-right: var(--spacing-lg);
`;
const SubContent = styled.div`
    width: 250px;
    height: 12px;
    margin-left: 72px;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: var(--surface-skeleton);
    animation: ${skeletonLoading} 1s linear infinite alternate;
`;
const ServiceIcon = styled.div`
    display: inline-block;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: var(--surface-skeleton);
    animation: ${skeletonLoading} 1s linear infinite alternate;
`;
const TextArea = styled.div``;
const ServiceName = styled.div`
    width: 110px;
    height: 20px;
    border-radius: 4px;
    background-color: var(--surface-skeleton);
    animation: ${skeletonLoading} 1s linear infinite alternate;
`;
const UserAccount = styled.div`
    width: 160px;
    height: 12px;
    margin-top: 8px;
    border-radius: 4px;
    background-color: var(--surface-skeleton);
    animation: ${skeletonLoading} 1s linear infinite alternate;
`;
const Button = styled.div`
    width: 81px;
    height: 40px;
    margin-left: auto;
    border-radius: var(--radius-full);
    background: var(--surface-skeleton);
    animation: ${skeletonLoading} 1s linear infinite alternate;
`;
