// @flow

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import * as SETTINGS from "../settingsConstants";
import * as Style from "../preferences.style";
import EncryptionSetupSetting from "../EncryptionSetupSetting";
import ChangeCryptoPassSetting from "../ChangeCryptoPassSetting";
import ResetCryptoFolderSetting from "../ResetCryptoFolderSetting";

const Encryption = ({ settings }) => {
  const renderSetupEncryption = () => <EncryptionSetupSetting/>;

  const renderChangeCryptoPassSetting = () => <ChangeCryptoPassSetting/>;

  const renderResetCrypto = () => <ResetCryptoFolderSetting/>

  const renderFunctionsPerSetting = {
    [SETTINGS.ENCRYPTION_SETUP_SETTING]: renderSetupEncryption,
    [SETTINGS.CHANGE_CRYPTO_PASS_SETTING]: renderChangeCryptoPassSetting,
    [SETTINGS.RESET_CRYPTO_PASS_SETTING]: renderResetCrypto
  };

  return (
    <Style.Container className="account-container lang-wrapper">
      {settings.map((setting) => renderFunctionsPerSetting[setting] && renderFunctionsPerSetting[setting]())}
    </Style.Container>
  );
};

export default Encryption;
