import styled, { keyframes, css } from "styled-components";

const ring = keyframes`
    0% { transform: rotate(0); }
    1% { transform: rotate(30deg); }
    3% { transform: rotate(-28deg); }
    5% { transform: rotate(34deg); }
    7% { transform: rotate(-32deg); }
    9% { transform: rotate(30deg); }
    11% { transform: rotate(-28deg); }
    13% { transform: rotate(26deg); }
    15% { transform: rotate(-24deg); }
    17% { transform: rotate(22deg); }
    19% { transform: rotate(-20deg); }
    21% { transform: rotate(18deg); }
    23% { transform: rotate(-16deg); }
    25% { transform: rotate(14deg); }
    27% { transform: rotate(-12deg); }
    29% { transform: rotate(10deg); }
    31% { transform: rotate(-8deg); }
    33% { transform: rotate(6deg); }
    35% { transform: rotate(-4deg); }
    37% { transform: rotate(2deg); }
    39% { transform: rotate(-1deg); }
    41% { transform: rotate(1deg); }

    43% { transform: rotate(0); }
    100% { transform: rotate(0); }
`;

const NotificationsBell = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    float: right;
    height: 40px;
    padding: 2px 4px;
    width: 40px;
    transition: width 0.6s, height 0.6s ease-in-out,
        background-color 0.3s ease-in-out;
    box-sizing: border-box;
    border-radius: var(--radius-full);
    background: #f0f4f5;
    cursor: pointer;
    position: relative;

    @media (max-width: 1023px) {
        background: ${({ $isOpen }) => ($isOpen ? "#f0f4f5" : "inherit")};
    }

    .notification-bell {
        transition: transform 0.3s;
        transform: rotate(${({ $isOpen }) => ($isOpen ? "20deg" : "0")});
        ${({ $isOpen, $hasNew }) =>
            !$isOpen &&
            $hasNew &&
            css`
                animation: ${ring} 4s 0.7s ease-in-out infinite;
            `}
    }
`;

export const NotificationContainer = styled.div`
    position: relative;
    width: 40px;
    height: 48px;
`;

export default NotificationsBell;
