//@flow

import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";

import apiMethod from "../../api/apiMethod";
import { pingTest, dlTest, ulTest } from "../../lib/speedTest";
import { __ } from "../../lib/translate";
import { isBetween } from "../../lib/utils";
import { apiMethodUrl } from "../../api/utils";
import { SPEED_STATUS, MAX_REPORT_MESSAGE } from "@pcloud/web-utilities/dist/config/constants";

import ProgressBox from "./ProgressBox";
import CloseButton from "../../../root/img/speed-test/close-btn.svg";
import PingIcon from "../../../root/img/speed-test/ping-icon.svg";
import DownloadIcon from "../../../root/img/speed-test/download-icon.svg";
import UploadIcon from "../../../root/img/speed-test/upload-icon.svg";

const SpeedTestContainer = () => {
  const [downloadStatus, setDownloadStatus] = useState(0);
  const [uploadStatus, setUploadStatus] = useState(0);
  const [status, setStatus] = useState(0);

  const [apiList, setApiList] = useState([]);
  const [code, setCode] = useState("");
  const [showMessage, setShowMessage] = useState("");

  const [pingSpeedArr, setPingSpeedArr] = useState([]);
  const [dlSpeedArr, setDlSpeedArr] = useState([]);
  const [ulSpeedArr, setUlSpeedArr] = useState([]);

  const [dlSpeedProgress, setDlSpeedPorgress] = useState(0);
  const [ulSpeedProgress, setUlSpeedPorgress] = useState(0);

  const [reportMessage, setReportMessage] = useState("");
  const [sendLoding, setSendLoading] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    if (status === SPEED_STATUS["DONE"]) {
      onTestDone();
    }
  }, [onTestDone, status]);

  useEffect(() => {
    console.log("code", code);
    if (code.length !== 0 && apiList.length !== 0) {
      getPing([...apiList]);
    }
  }, [code, apiList]);

  const init = () => {
    apiMethod(
      "speedtestinit",
      {},
      ({ code, api }) => {
        setCode(code);
        setApiList(api);
        setShowLoader(false);
      },
      {
        errorCallback: res => {
          setShowLoader(false);
          setShowMessage("error");
          throw Error("speedtestinit", res);
        }
      }
    );
  };

  const clearState = () => {
    setApiList([]);
    setCode("");
    setPingSpeedArr([]);
    setDlSpeedArr([]);
    setUlSpeedArr([]);
    setDlSpeedPorgress(0);
    setUlSpeedPorgress(0);
    setStatus(SPEED_STATUS["DEFAULT"]);
    setDownloadStatus(SPEED_STATUS["DEFAULT"]);
    setUploadStatus(SPEED_STATUS["DEFAULT"]);
    setReportMessage("");
    setSendLoading(false);
  };

  const onTestDone = () => {
    const setPingParam = (paramName, list) => {
      return list.reduce((acc, item, index) => {
        acc[paramName + (index + 1)] = Math.round(item);
        return acc;
      }, {});
    };
    const setSpeedParam = (paramName, list) => {
      return list.reduce((acc, item, index) => {
        acc[paramName + (index + 1)] = Math.round(item * 1000 * 1000);
        return acc;
      }, {});
    };
    const pingParams = setPingParam("pingsrv", pingSpeedArr);
    const downloadParams = setSpeedParam("dlspeedsrv", dlSpeedArr);
    const uploadParams = setSpeedParam("ulspeedsrv", ulSpeedArr);

    const params = Object.assign(
      { code: code, servers: apiList.join(",") },
      pingParams,
      downloadParams,
      uploadParams
    );
    console.log("PARAMS", params);
    setTimeout(() => {
      apiMethod(
        "speedtestfinish",
        params,
        () => {
          console.log("speedtestfinish success", params);
        },
        {
          errorCallback: res => {
            throw Error("speedtestfinish", res);
          }
        }
      );
    }, 1000);
  };

  const onError = () => {
    setTimeout(() => {
      setShowMessage("error");
      clearState();
    }, 500);
  };

  const getUploadSpeed = list => {
    if (list.length === 0) {
      return;
    }
    const server = list[0];
    const url = apiMethodUrl(server, "speedtestupload", { code, server });

    ulTest(url, {
      onProgressCallback: speed => {
        setUlSpeedPorgress(speed);
      },
      doneCallback: speed => {
        if (speed === "Fail") {
          onError();
          return;
        }
        setUlSpeedArr(oldArr => [...oldArr, speed]);
        if (list.length === 1) {
          setShowMessage("report");
          setStatus(SPEED_STATUS["DONE"]);
          setUploadStatus(SPEED_STATUS["DONE"]);
        } else {
          getUploadSpeed(list.slice(1));
        }
      },
      onErrorCallback: onError
    });
  };

  const getDownloadSpeed = list => {
    if (list.length === 0) {
      return;
    }
    const server = list[0];
    const url = apiMethodUrl(server, "speedtestdownload", { code, server });

    dlTest(url, {
      onProgressCallback: speed => {
        setDlSpeedPorgress(speed);
      },
      doneCallback: speed => {
        if (speed === "Fail") {
          onError();
          return;
        }
        setDlSpeedArr(oldArr => [...oldArr, speed]);
        if (list.length === 1) {
          getUploadSpeed([...apiList]);
          setDownloadStatus(SPEED_STATUS["DONE"]);
          setUploadStatus(SPEED_STATUS["RUNNING"]);
        } else {
          getDownloadSpeed(list.slice(1));
        }
      },
      onErrorCallback: onError
    });
  };

  const getPing = list => {
    if (list.length === 0) {
      return;
    }
    const server = list[0];
    const url = apiMethodUrl(server, "speedtestping", { code });

    pingTest(url, {
      doneCallback: speed => {
        if (speed === "Fail") {
          onError();
          return;
        }
        setPingSpeedArr(oldArr => [...oldArr, speed]);
        if (list.length === 1) {
          getDownloadSpeed([...apiList]);
          setDownloadStatus(SPEED_STATUS["RUNNING"]);
        } else {
          getPing(list.slice(1));
        }
      },
      onErrorCallback: onError
    });
  };

  const onStartClick = () => {
    if (status === SPEED_STATUS["RUNNING"]) {
      return;
    }

    clearState();
    setShowMessage("");
    setStatus(SPEED_STATUS["RUNNING"]);
    init();
  };

  const onSendReportClick = () => {
    if (sendLoding) {
      return;
    } else if (!reportMessage.length) {
      HFN.message(__("", "Message is empty!"), "error");
      return;
    } else if (reportMessage.length > MAX_REPORT_MESSAGE) {
      HFN.message(__("error_2245", "Maximum message length is 500 characters."), "error");
      return;
    }
    setSendLoading(true);
    apiMethod(
      "speedtestfeedback",
      {
        code,
        message: reportMessage
      },
      () => {
        setTimeout(() => {
          setShowMessage("success");
          setSendLoading(true);
        }, 300);
      },
      {
        errorCallback: ({ error }) => {
          setSendLoading(true);
          setShowMessage("error");
          throw new Error(error);
        }
      }
    );
  };

  const renderReportForm = () => {
    return (
      <FormWrapper>
        <CloseButton
          className="close-btn msg-form"
          onClick={() => {
            setShowMessage("");
          }}
        />
        <FormMessage
          value={reportMessage}
          onChange={e => setReportMessage(e.target.value)}
          placeholder={__("your_message")}
        />
        <FormButton onClick={onSendReportClick}>{sendLoding ? <Loading /> : __("Send")}</FormButton>
      </FormWrapper>
    );
  };

  const renderReportMsg = () => {
    let title = __("speed_test_results_case3", "Your internet speed is excellent.");
    let subTitle = '';
    let showSendReportLink = false;
    let colorBorder = "#eeeeee";
    let colorBackground = "#ffffff";
    let colorText = '#4bb85c';

    if (
      Math.floor(ulSpeedProgress / dlSpeedProgress) >= 4 ||
      Math.floor(dlSpeedProgress / ulSpeedProgress) >= 4
    ) {
      title = __("speed_test_results_case4_title", "It seems that you\'re having bandwidth limitations.");
      subTitle = __("speed_test_results_case4_subtitle", "Please contact your ISP (Internet Service Provider).");
      colorBorder = "#f7f1dc";
      colorBackground = "#fff9e3";
      colorText = '#fe970c';
    } else if (isBetween(dlSpeedProgress, 0, 7.99) || isBetween(ulSpeedProgress, 0, 7.99)) {
      // less than 7.99 mbps
      title = __("speed_test_results_case1_title", "Your internet speed seems slow");
      subTitle = __("speed_test_results_case1_subtitle", "Please contact your ISP (Internet Service Provider) and send us a report.");
      showSendReportLink = true;
      colorBorder = "#f7ebea";
      colorBackground = "#fff3f2";
      colorText = '#fe0c0c';
    } else if (isBetween(dlSpeedProgress, 8, 79.99) || isBetween(ulSpeedProgress, 8, 79.99)) {
      // 	8 mbps - 79.99 mbps
      title = __("speed_test_results_case2", "Your internet speed is good.");
      colorBorder = "#eeeeee";
      colorBackground = "#ffffff";
      colorText = '#4bb85c';
    }

    return (
      <MessageWrapper colorBorder={colorBorder} colorBackground={colorBackground}>
        <CloseButton
          className="close-btn"
          onClick={() => {
            setShowMessage("");
          }}
        />
        <MessageHeader>
          <Title colorText={colorText}>{title}</Title>
          <SubTitle>{subTitle}</SubTitle>
          {showSendReportLink ? (
            <SendReport
              onClick={() => {
                setShowMessage("form");
              }}
            >
              {__("speed_test_results_report")}
            </SendReport>
          ) : null}
        </MessageHeader>
        <MessageText>
          <SummaryWrapper>
            <OuterContainer>
              <OuterCircle>
                <InnerCircle>
                  <PingIcon />
                </InnerCircle>
              </OuterCircle>
              <Container>
                <SummaryLabel>{__("speed_test_ping")}</SummaryLabel>
                <SummaryValue>
                  {pingSpeedArr.slice(-1).pop() || 0}
                  <SummaryUnit> ms</SummaryUnit>
                </SummaryValue>
              </Container>
            </OuterContainer>
            <OuterContainer>
              <OuterCircle>
                <InnerCircle>
                  <DownloadIcon />
                </InnerCircle>
              </OuterCircle>
              <Container>
                <SummaryLabel>{__("speed_test_download", "Download")}</SummaryLabel>
                <SummaryValue>
                  {dlSpeedProgress}
                  <SummaryUnit> Mbps</SummaryUnit>
                </SummaryValue>
                <SummaryConverted>
                  ({(dlSpeedProgress * 0.125).toFixed(2) || 0} MB/s)
                </SummaryConverted>
              </Container>
            </OuterContainer>
            <OuterContainer>
              <OuterCircle>
                <InnerCircle>
                  <UploadIcon />
                </InnerCircle>
              </OuterCircle>
              <Container>
                <SummaryLabel>{__("speed_test_upload", "Upload")}</SummaryLabel>
                <SummaryValue>
                  {ulSpeedProgress}
                  <SummaryUnit> Mbps</SummaryUnit>
                </SummaryValue>
                <SummaryConverted>
                  ({(ulSpeedProgress * 0.125).toFixed(2) || 0} MB/s)
                </SummaryConverted>
              </Container>
            </OuterContainer>
          </SummaryWrapper>
        </MessageText>
      </MessageWrapper>
    );
  };

  const rendeSendMsg = () => {
    return (
      <MessageWrapper colorBorder="#ecf7e5" colorBackground="#f4ffec">
        <CloseButton
          className="close-btn"
          onClick={() => {
            setShowMessage("");
          }}
        />
        <MessageHeader>{__("speed_test_results_reported")}</MessageHeader>
        <MessageText>{__("speed_test_results_problem")}</MessageText>
      </MessageWrapper>
    );
  };

  const renderErrorMessage = () => {
    return (
      <MessageWrapper colorBorder="#FFE8E8" colorBackground="#FFF6F6">
        <CloseButton
          className="close-btn"
          onClick={() => {
            setShowMessage("");
          }}
        />
        <MessageHeader>{__("speed_test_error_title")}</MessageHeader>
        <MessageText>{__("speed_test_error_message")}</MessageText>
      </MessageWrapper>
    );
  };

  const renderMessage = () => {
    if (showMessage === "error") {
      return renderErrorMessage();
    } else if (showMessage === "success") {
      return rendeSendMsg();
    } else if (showMessage === "report") {
      return renderReportMsg();
    } else if (showMessage === "form") {
      return renderReportForm();
    }
  };

  const renderHeader = () => {
    if (showMessage) {
      return (
        <React.Fragment>
          {renderMessage()}
          {showMessage !== "form" ? renderButton() : null}
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <IconWrapper>
          <i className="baseIcon fa-light fa-gauge-simple-high"/>
        </IconWrapper>
        <Header>{__("speed_test_headline", "pCloud Speed Test")}</Header>
        <Subheader>
          <div>{__("speed_test_description")}</div>
          <div>{__("speed_test_description_note")}</div>
        </Subheader>
        {renderButton()}
      </React.Fragment>
    );
  };

  const renderButton = () => {
    let text = __("speed_test_start", "START TEST");

    if (status === SPEED_STATUS["RUNNING"]) {
      text = __("Running", "Running") + "...";
    } else if (status === SPEED_STATUS["DONE"]) {
      text = __("speed_test_restart", "START AGAIN");
    }

    return (
      <StartButton status={status} onClick={onStartClick}>
        {text.toUpperCase()}
      </StartButton>
    );
  };

  const renderPingText = () => {
    const ping = pingSpeedArr.slice(-1).pop() || 0;
    let message = "";

    if (isBetween(ping, 1, 59)) {
      message = ` | ${__("ping_case1")}`;
    } else if (isBetween(ping, 60, 129)) {
      message = ` | ${__("ping_case2")}`;
    } else if (isBetween(ping, 130, 199)) {
      message = ` | ${__("ping_case3")}`;
    } else if (ping >= 200) {
      message = ` | ${__("ping_case4")}`;
    }

    return `${__("speed_test_ping", "Ping")} ${ping} ms${message}`;
  };

  if (showLoader) {
    return (
      <Wrapper>
        <Loader />
      </Wrapper>
    );
  }

  const renderProgress = () => (
    <Body opacity={showMessage === "form" ? 0.5 : 1}>
      <Ping opacity={showMessage === "form" ? 0 : 1}>{renderPingText()}</Ping>
      <BoxWrapper>
        <ProgressBox
          key="download"
          progressStatus={downloadStatus}
          speed={dlSpeedProgress}
          scaleDirection="left"
          type="download"
        />
        <ProgressBox
          key="upload"
          progressStatus={uploadStatus}
          speed={ulSpeedProgress}
          scaleDirection="right"
          type="upload"
        />
      </BoxWrapper>
    </Body>
  );

  const renderCreative = () => (
    <Creative
      srcset="/ZCDNURL/img/speed-test-start.png, /ZCDNURL/img/speed-test-start@2x.png 1.5x"
      src="/ZCDNURL/img/speed-test-start.png"
      width="740px"
    />
  )

  return (
    <Wrapper>
      {renderHeader()}
      {status ? renderProgress() : renderCreative()}
    </Wrapper>
  );
};

export default SpeedTestContainer;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 30px auto 0 auto;
  padding: 20px;
  box-sizing: border-box;
  max-width: 760px;

  @media (max-width: 768px) {
    margin-top: 0;
  }

  .close-btn {
    position: absolute;
    top: 7px;
    right: 7px;
    cursor: pointer;
  }

  .close-btn.msg-form {
    top: 12px;
    right: 12px;
  }

  .close-btn:hover {
    opacity: 0.8;
  }
`;

const Header = styled.div`
  color: var(--color-base-black);
  text-align: center;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  margin-bottom: var(--spacing-sm);

  @media (max-width: 768px) {
    font-size: 22px;
  }
`;

const Subheader = styled.div`
  color: var(--color-base-black);
  text-align: center;
  font-size: var(--font-size-16);
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: var(--spacing-xl);

  @media (max-width: 768px) {
    line-height: 20px;
    font-size: 15px;
    margin-bottom: 24px;
  }
`;

const Creative = styled.img`
  width: 100%;
  max-width: 740px;
`;

const StartButton = styled.div`
  width: 200px;
  height: 44px;
  padding: 14px 15px;
  text-align: center;
  border-radius: 22px;
  box-shadow: 0 0.5px 1px 0 rgba(0, 0, 0, 0.1);
  background-color: ${props => (props.status === 1 ? "#eee" : "#17bed0")};
  font-size: 14px;
  font-weight: bold;
  color: ${props => (props.status === 1 ? "#a4a4a4" : "#ffffff")};
  box-sizing: border-box;
  cursor: ${props => (props.status === 1 ? "initial" : "pointer")};
  margin-bottom: 20px;
`;

const Ping = styled.div`
  margin-bottom: 13px;
  font-size: 13px;
  opacity: ${props => props.opacity};
`;

const BoxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  padding: 0 80px;
  box-sizing: border-box;
  gap: 10px;

  @media (max-width: 768px) {
    padding: 0;
    justify-content: space-between;
    gap: 12px;
  }
`;

const MessageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 50px;
  border-radius: 17px;
  border: ${props => "solid 1px" + props.colorBorder};
  background-color: ${props => props.colorBackground};
  margin-bottom: 20px;
  min-height: 76px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  text-align: center;
  font-weight: bold;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const MessageHeader = styled.div`
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const IconWrapper = styled.div`
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  background: linear-gradient(180deg, #17BED0 0%, #1AA4B3 100%);
  margin-bottom: var(--spacing-xl);

  i {
    color: var(--color-base-white);
    font-size: 42px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }

  @media (max-width: 768px) {
    margin-bottom: 24px;
  }
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: ${props => props.colorText};
`;

const SubTitle = styled.span`
  font-size: 15px;
  text-align: center;
  color: #000;
  line-height: 1.5;
`;

const MessageText = styled.div`
  font-size: 14px;
  width: 100%;
`;

const SendReport = styled.span`
  margin-left: 5px;
  color: #17bed0;
  cursor: pointer;
  font-size: 15px;
  text-decoration: underline;
`;

const FormWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 30px;
  width: 460px;
  height: 220px;
  padding: 20px;
  border-radius: 22px;
  padding: 40px 20px 0 20px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px #eee;
  background-color: #fafafa;
  box-sizing: border-box;
  position: relative;
`;

const FormMessage = styled.textarea`
  width: 100%;
  height: 100px;
  padding: 11px;
  border-radius: 3px;
  font-family: "Roboto", sans-serif;
  border: solid 1px #d4d4d4;
  background-color: var(--color-base-white);
  box-sizing: border-box;
  resize: none;
`;

const FormButton = styled.div`
  width: 120px;
  padding: 10px 0;
  margin-top: 20px;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  color: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.18);
  background-color: var(--color-primary-500);
  border-radius: 20px;
  box-sizing: border-box;
  position: relative;
  min-height: 37px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  opacity: ${props => props.opacity};
`;

const spin = keyframes`
  100% { transform: rotate(360deg); }
`;

const Loading = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin: -10px 0 0 -10px;
  -webkit-animation: ${spin} 0.5s linear infinite;
  animation: ${spin} 0.5s linear infinite;
  background: url(${require("../../../root/img/loading_button.png")}) no-repeat 50%;
  background-size: 20px 20px;
`;

const Pulsate = keyframes`
  0% {transform: scale(0.1, 0.1); opacity: 0;}
  50% {opacity: 1;}
  100% {
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0) scale(1.2, 1.2);
    transform: translateZ(0);
    transform: scale(1.0, 1.0);
    opacity: 0;
    }
`;

const Loader = styled.div`
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  border: 4px solid #20bed6;
  border-radius: 30px;
  animation: ${Pulsate} 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
`;

const SummaryWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-evenly;
  font-weight: bold;
  width: 100%;

  @media (max-width: 768px) {
    justify-content: space-between;
  }
`;

const SummaryLabel = styled.div`
  font-size: 13px;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 12px;
    font-weight: 400;
    text-align: center;
  }
`;

const SummaryValue = styled.div`
  position: relative;
  font-size: 26px;
  font-weight: bold;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 20px;
    text-emphasis-color: center;
  }
`;

const SummaryConverted = styled.div`
  font-size: 13px;
  font-weight: bold;
  text-align: left;
  color: #000;

  @media (max-width: 768px) {
    font-size: 12px;
    font-weight: 400;
    text-align: center;
  }
`;

const SummaryUnit = styled.span`
  position: absolute;
  bottom: 0;
  margin-left: 4px;
  font-size: 12px;
  font-family: "Roboto", sans-serif;

  @media (max-width: 768px) {
    margin-left: 0;
    position: static;
  }
`;

const OuterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
    gap: 8px;
  }
`;

const OuterCircle = styled.div`
  width: 64px;
  height: 64px;
  box-sizing: border-box;
  margin-right: 10px;
  padding-top: 8px;
  line-height: 64px;
  vertical-align: middle;
  border-radius: 50%;
  background-color: rgba(0,0,0,0.05);
  flex-shrink: 0;
`;

const InnerCircle = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 2%);
  margin: 0 auto;
  vertical-align: middle;
  line-height: 64px;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (max-width: 768px) {
    width: 100%;
    gap: 4px;
  }
`;

const Note = styled.div`
  font-size: 13px;
  color: #8e8e95;
`;
