// @flow

import React, { PureComponent } from "react";
import styled from "styled-components";
import { connect } from "react-redux";

import { __ } from "../../lib/translate";
import { formatSizeInGb } from "../../lib/utils";
import { QUOTA_STEP } from "@pcloud/web-utilities/dist/config/constants";

import Slider from "../../components/Slider";

type Props = {
  quota: number,
  usedQuota: number,
  minStartValue: number,
  startValue: number,
  maxValue: number,
  valueBoxColor?: string,
  shouldDisableSlider?: boolean,
  getQuota: number => void,
  isModal?: boolean
};

type State = { storage: number };

class StorageManagment extends PureComponent<Props, State> {
  static defaultProps = {
    shouldDisableSlider: false,
    isModal: false
  };

  constructor(props: Props) {
    super(props);

    this.state = { storage: 0 };

    (this: any).getStorageValue = this.getStorageValue.bind(this);
  }

  getStorageValue(storage: number) {
    const { getQuota } = this.props;

    this.setState({ storage });
    getQuota(storage);
  }

  render() {
    const {
      valueBoxColor,
      shouldDisableSlider,
      startValue,
      maxValue,
      minStartValue,
      isModal
    } = this.props;
    const { storage } = this.state;
    const formatedStorage = formatSizeInGb(storage, 0);

    console.log("startValue", formatSizeInGb(startValue));
    console.log("maxValue", formatSizeInGb(maxValue));
    console.log("minStartValue", formatSizeInGb(minStartValue));
    return (
      <Container>
        <StorageTitle className="left" disabled={shouldDisableSlider}>
          {__("family_manage_quota_modal_select", "Select quota:")}
        </StorageTitle>
        <SliderContainder isModal={isModal}>
          <Slider
            minValue={0}
            startValue={startValue}
            minStartValue={minStartValue}
            maxValue={maxValue}
            step={QUOTA_STEP}
            disabled={shouldDisableSlider}
            getSliderValue={this.getStorageValue}
          />
          <StorageContainer>
            <StorageWrapper className="left">
              <StorageTitle disabled={shouldDisableSlider}>
                {__("family_manage_quota_modal_storage", "Storage")}
              </StorageTitle>
              <StorageValue disabled={shouldDisableSlider} color={valueBoxColor}>
                {formatedStorage}
              </StorageValue>
            </StorageWrapper>
            <StorageWrapper className="right">
              <StorageTitle disabled={shouldDisableSlider}>
                {__("family_manage_quota_modal_traffic", "Download link traffic")}
              </StorageTitle>
              <StorageValue disabled={shouldDisableSlider} color={valueBoxColor}>
                {formatedStorage}
              </StorageValue>
            </StorageWrapper>
          </StorageContainer>
        </SliderContainder>
      </Container>
    );
  }
}

export default connect(({ user }) => {
  const { userinfo: { usedquota, quota } = {} } = user;

  return { usedQuota: usedquota, quota };
})(StorageManagment);

const Container = styled.div`
  margin-bottom: 40px;
  width: 100%;
`;

const StorageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  border-top: 1px solid #eee;
`;

const StorageWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-around;
  align-items: center !important;
  flex-direction: column;
  padding: 15px;
  box-sizing: border-box;

  &.left {
    border-right: 1px solid #eeeeee;
  }

  &.right {
    /* float: right; */
  }
`;

const StorageTitle = styled.div`
  opacity: ${props => (props.disabled ? 0.4 : 1)};
  font-size: 13px;
  margin-bottom: 10px;
  text-align: center;
  font-weight: normal;
  
  &.left {
    text-align: left;
  }
`;

const StorageValue = styled.div`
  font-size: 23px;
  font-weight: bold;
  opacity: ${props => (props.disabled ? 0.4 : 1)};

  @media (max-width: 500px) {
    font-size: 16px;
  }
`;

const SliderContainder = styled.div`
  border-radius: 6px;
  border: solid 1px #eee;
  background-color: ${props => (props.isModal ? "#fafafa" : "#ffffff")};
`;
