import React from "react";
import CardFolderBackup from "./Card/CardFolderBackup";
import CardControlledBackup from "./Card/CardControlledBackup";

const Card = (props) => {

    if (["mobile", "desktop"].includes(props.category)) {
        return <CardFolderBackup {...props} />;
    }

    return <CardControlledBackup {...props} />;
};

export default Card;