import { useState, useEffect } from "react";
import { getFileIds, getThumbIdenticator } from "../helpers";
import {
    BOX_THUMB_SIZE,
    PAGE_PLAYER_THUMB_SIZE,
    QUEUE_THUMB_SIZE
} from "../constants";
import apiMethod from "../../../api/apiMethod";

type Props = {
    queue: Array<any>
};

const usePlayerThumbs = ({ queue = [], isPublic = false }: Props) => {
    const [loadedThumbs, setLoadedThumbs] = useState(new Map());

    useEffect(() => {
        if (!queue.length) {
            return;
        }

        const songsForLoadThumbs = queue.filter((item) => {
            return item.thumb && !loadedThumbs[item.fileid];
        });

        if (!songsForLoadThumbs.length) {
            return;
        }

        _loadThumbs(
            getFileIds(songsForLoadThumbs),
            QUEUE_THUMB_SIZE + "x" + QUEUE_THUMB_SIZE
        );
        _loadThumbs(
            getFileIds(songsForLoadThumbs),
            BOX_THUMB_SIZE + "x" + BOX_THUMB_SIZE
        );
        _loadThumbs(getFileIds(songsForLoadThumbs), "512x512");
        if (isPublic) {
            _loadThumbs(
                getFileIds(songsForLoadThumbs),
                PAGE_PLAYER_THUMB_SIZE + "x" + PAGE_PLAYER_THUMB_SIZE
            );
        }
    }, [queue]);

    const _loadThumbs = (fileIds, size) => {
        let method = "getthumbslinks";
        const params = {
            size: size,
            type: "auto",
            fileids: fileIds
        };

        if (queue[0].code) {
            method = "getpubthumbslinks";
            params.code = queue[0].code;

            if (queue[0].linkpassword) {
                params.linkpassword = queue[0].linkpassword;
            }
        }

        apiMethod(method, params, function(ret) {
            if (typeof ret.thumbs !== "undefined") {
                ret.thumbs.forEach((item) => {
                    if (item.error) {
                        return;
                    }
                    setLoadedThumbs(
                        new Map(
                            loadedThumbs.set(
                                getThumbIdenticator(item.fileid, params.size),
                                HFN.prepUrl(item)
                            )
                        )
                    );
                });
            }
        });
    };

    const loadPrimeThumb = (fileId, size) => {
        const thumb = loadedThumbs.get(getThumbIdenticator(fileId, size));

        if (thumb) {
            return;
        }

        const targetItem = queue.find((item) => {
            return item.fileid === fileId;
        });

        if (!targetItem || !targetItem.thumb) {
            return;
        }
        _loadThumbs(fileId, size);
    };

    return {
        loadedThumbs,
        loadPrimeThumb
    };
};

export default usePlayerThumbs;
