import styled from "styled-components";

export const ContentHeader = styled.div`
    color: #ffff;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 8px;
`;
export const ContentText = styled.div`
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
`;
