import styled from "styled-components";

export const CommentItemWrapper = styled.div`
  --avatar-width: 32px;
  --avatar-height: 32px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const CommentItem = styled.div`
  display: flex;
  padding-bottom: var(--spacing-md, 16px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-md, 16px);
  align-self: stretch;
  border-bottom: 1px solid var(--divider-secondary, #EFF0F1);
  border-color: ${({$borderColor}) => $borderColor ? $borderColor : "var(--divider-secondary, #EFF0F1)"};
  box-sizing: border-box;
  animation: fadeIn 0.1s normal forwards ease-in;

  &:hover {
    .delete-comment {
      visibility: visible;
      opacity: 1;
    }
  }
`;