import React from "react";
import ActionButton from "../../../userinterface/ActionButton";
import { FlexWrapper } from "../../../userinterface/styles";

const MinimizedBoxControls = ({
    onPlayerClose = () => {},
    onPlayerMaximize = () => {}
}) => {
    return (
        <FlexWrapper $columnGap={0} className={"animate"}>
            <ActionButton
                onClick={onPlayerMaximize}
                className={"svg-color-default-light"}
                label={
                    <i
                        className="fa-solid fa-chevron-up"
                        style={{ fontSize: 16 }}
                    />
                }
            />
            <ActionButton
                onClick={onPlayerClose}
                className={"svg-color-default-light"}
                label={
                    <i className="fa-solid fa-close" style={{ fontSize: 16 }} />
                }
            />
        </FlexWrapper>
    );
};

export default MinimizedBoxControls;
