import React, { useState } from "react";
import styled from "styled-components";
import { Tooltip } from "react-tooltip";
import useKeepScrollPosition from "./hooks/useKeepScrollPosition";

import CommentListItem from "./CommentListItem";
import SkeletonCommentListItem from "./SkeletonCommentListItem";

type Props = {
  comments: [],
  commentsTotalCount: number,
  contentItemData: {
    isfolder: boolean,
    folderid: number,
    fileid: number,
    comments: number
  },
  onLoadMoreComments: () => void,
  loadMorePortionLimit: number,
  loadingInitialComments: boolean,
  loadingMoreComments: boolean, 
  isVisible: boolean,
  onDeletedComment: (comment: { id: number }) => void
}

const CommentsList = ({
  comments,
  commentsTotalCount,
  contentItemData,
  onLoadMoreComments,
  loadMorePortionLimit,
  loadingInitialComments,
  loadingMoreComments,
  isVisible = true,
  onDeletedComment = (comment) => {}
}: Props) => {

  const SCROLLING_MASK_HEIGHT = 24;

  const [goToNewCommentPopover, setGoToNewCommentPopover] = useState({show: false, comment: null, commentEl: null, count: 0});

  const { containerRef: commentsListRef } = useKeepScrollPosition([comments]);  

  const onShowGoToNewCommentsPopover = (comment, commentEl) => {
    setGoToNewCommentPopover(prevData => {
      if (prevData.show && prevData.comment && Date.parse(prevData.comment.dt) <= Date.parse(comment.dt)) {
        // Previous new comment is older than this one.
        return { ...prevData, count: prevData.count + 1 };
      }

      return { show: true, comment, commentEl, count: 1 };
    });
  };

  const closeNewCommentsPopover = () => {
    setGoToNewCommentPopover({ show: false, comment: null, commentEl: null });
  }

  const scrollToFirstNewComment = () => {
    if (!goToNewCommentPopover.show) {
      return;
    }

    if (!goToNewCommentPopover.commentEl) {
      closeNewCommentsPopover();
      return;
    }

    commentsListRef.current.scroll({
      top: goToNewCommentPopover.commentEl.offsetTop - SCROLLING_MASK_HEIGHT,
      behavior: "smooth",
      block: "start"
    });
    
    closeNewCommentsPopover();
  }

  const renderCommentListItems = () => {
    let index = -1;
    const commentListItemComps = [];
    comments.forEach((comment, key) => {
      index++;
      commentListItemComps.push(
        <CommentListItem
          key={comment.id}
          comment={comment}
          contentItemData={contentItemData}
          scrollToBottomOnInit={(!comment.isnew && comments.size <= loadMorePortionLimit && index === comments.size - 1 && isVisible) || comment.scrolltome}
          onDeletedComment={onDeletedComment}
          onShowGoToNewCommentsPopover={onShowGoToNewCommentsPopover}
          isNewComment={comment.isnew}
          scrollableListContainer={commentsListRef.current}
          scrollingMaskHeight={SCROLLING_MASK_HEIGHT}
        />
      );
    });
    return commentListItemComps;
  };

  const renderLoadingSkeletonCommentListItems = () => {
    return new Array(2).fill().map((el, index, arr) => {
      return (
      <SkeletonCommentListItem 
        scrollToBottomOnInit={index === arr.length - 1 && isVisible}
        key={index}
        index={index}
        totalSkeletons={arr.length}
        scrollableListContainer={commentsListRef.current}
      />
    )});
  };

  const onScrollCommentsList = (event) => {
    // Check if we're on the end of the list and "View more N comments" is visible.
    if (!goToNewCommentPopover.show) {
      return;
    }

    const {scrollHeight, scrollTop, clientHeight} = event.target;

    if (Math.abs(scrollHeight - clientHeight - scrollTop) < 1) {
      // We've scrolled to the bottom.
      closeNewCommentsPopover();
    }
  };

  return (
    <React.Fragment>
      {loadingInitialComments || comments.size > 0 ? 
        <CommentsListWrapper>
          <ScrollableCommentsListWrapper ref={commentsListRef} scrollingMaskHeight={SCROLLING_MASK_HEIGHT} onScroll={onScrollCommentsList}>

            {loadingInitialComments ? renderLoadingSkeletonCommentListItems() : null}
            
            {!loadingInitialComments && comments.size > 0 ? 
              <React.Fragment>
                {loadingMoreComments || (!loadingMoreComments && commentsTotalCount > comments.size) ? 
                  <LoadMoreWrapper>
                    {loadingMoreComments ? <i class="fa-light fa-spin fa-circle-notch"></i> : null}
                    
                    {!loadingMoreComments && commentsTotalCount > comments.size ? 
                      <LoadMoreButton onClick={onLoadMoreComments}>
                        {__("load_more", "Load more")}
                      </LoadMoreButton>
                    : null}
                  </LoadMoreWrapper>
                : null}

                {renderCommentListItems()}
              </React.Fragment>
            : null}
          </ScrollableCommentsListWrapper>

          {!loadingInitialComments && comments.size > 0 ? 
            <NewCommentsPopoverWrapper $show={goToNewCommentPopover.show} onClick={scrollToFirstNewComment}>
              <i class="smallIcon fa-regular fa-chevron-down"></i>
              <NewCommentsLabel>{(goToNewCommentPopover.count != 1 ? __("total_new_comments", "%total% new comments").replace("%total%", goToNewCommentPopover.count) : __("new_comment", "New comment"))}</NewCommentsLabel>
            </NewCommentsPopoverWrapper>
          : null}
        </CommentsListWrapper>
      : null}

      {!loadingInitialComments && comments.size > 0 ? 
        <React.Fragment>
          <Tooltip id="view-profile-tooltip" className="cv-tooltip" noArrow={true} />
          <Tooltip id="delete-comment-tooltip" className="cv-tooltip" noArrow={true} />
        </React.Fragment>
      : null}

      {!loadingInitialComments && comments.size === 0 ? 
        <BlankCommentsListWrapper>
          <i class="mediumIcon fa-regular fa-message"></i>
          <Message>{__("empty_comments_list", "No comments yet.")}</Message>
        </BlankCommentsListWrapper>
      : null}
    </React.Fragment>
  );
};

export default CommentsList;

const CommentsListWrapper = styled.div`
  display: flex;
  flex: 1 0 0;
  overflow: hidden;
  position: relative;
`;

const ScrollableCommentsListWrapper = styled.div`
  --scrolling-mask-height: ${({scrollingMaskHeight}) => scrollingMaskHeight ? scrollingMaskHeight + "px" : "24px"};
  display: flex;
  padding: var(--spacing-lg, 24px) var(--spacing-lg, 24px) var(--spacing-none, 0px) var(--spacing-lg, 24px);
  flex-direction: column;
  /* justify-content: flex-end; DO NOT USE: breaks scrolling */
  align-items: flex-start;
  gap: var(--spacing-md, 16px);
  flex: 1 0 0;
  align-self: stretch;
  overflow: auto;
  box-sizing: border-box;

  mask: linear-gradient(to bottom, transparent, #FFF var(--scrolling-mask-height), #FFF calc(100% - var(--scrolling-mask-height)), transparent) no-repeat 0 0 / 100% 100%;


  & > :first-child {
    /* Holds the list as flex-end when there is enough space above. */
    margin-top: auto !important;
  }

  & > .comment-list-item:last-child {
    padding-bottom: var(--scrolling-mask-height)
  }
`;

const BlankCommentsListWrapper = styled.div`
  animation: fadeIn 0.1s normal forwards ease-in;
  display: flex;
  flex-direction: column;
  padding: var(--spacing-lg, 24px);
  gap: var(--spacing-md, 16px);
  align-items: center;
  justify-content: center;
  flex: 1;
  box-sizing: border-box;
  color: var(--text-secondary, #8C959D);
`;

const LoadMoreButton = styled.a`
  border: none;
  cursor: pointer;
  background: transparent;
  padding: 0;
  color: var(--text-link, #17BED0);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  &:hover {
    text-decoration: underline;
  }
`;

const LoadMoreWrapper = styled.div`
  min-height: 40px;
  margin: 0 auto;
  padding: var(--spacing-none, 0px) var(--spacing-md, 16px);
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    color: var(--color-grey-500, #C6CACE);
    font-size: var(--icon-medium, 18px);
  }
`;

const Message = styled.span`
  font-size: var(--base-font-size, 14px);
`;

const NewCommentsPopoverWrapper = styled.button`
  visibility: ${({$show}) => $show ? "visible" : "hidden"};
  opacity: ${({$show}) => $show ? "1" : "0"};
  transition: opacity 400ms ease-in-out;
  position: absolute;
  bottom: 0;
  right: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: var(--spacing-xs, 4px) var(--spacing-sm, 8px);
  box-sizing: border-box;
  border-radius: var(--spacing-lg, 24px);
  border: 1px solid var(--button-primary-background-default, #17BED0);
  background: var(--color-base-white, #FFF);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  z-index: 1;

  color: var(--text-primary, #282C2F);
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;

  i {
    color: var(--color-primary-900, #000D10);
    line-height: 16px;
    font-weight: 300;
  }
`;

const NewCommentsLabel = styled.span``;