import React from "react";
import styled, { css } from "styled-components";
import { __ } from "../../../../../lib/translate";
import hashManager from "../../../../../lib/hashManager";

const NotificationsWrap = styled.div`
    @media (min-width: 1024px) {
        box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.15);
    }
    background: var(--button-secondary-background-default);
    border: 1px rgba(0, 0, 0, 0.25);
    border-radius: var(--radius-minimum);
    padding: var(--spacing-sm) var(--spacing-none);
`;

const NotificationsInner = styled.div`
    max-height: 316px;
    width: 360px;
    overflow-y: auto;
    border-radius: var(--radius-minimum);
    background: var(--button-secondary-background-default);

    overflow-x: hidden !important;

    ${({ $isMobile }) =>
        $isMobile &&
        css`
            max-height: none;
            width: 100vw;
            height: calc(
                100dvh - ${({ $hasSticky, $stickyHeight }) => ($hasSticky ? (112 + $stickyHeight)+"px" : "112px")} -
                    (2 * var(--spacing-sm))
            );
            @media (min-width: 1024px) {
                height: calc(100dvh - 64px - (2 * var(--spacing-sm)));
            }
        `}

    &::-webkit-scrollbar {
        width: 6px;
        visibility: hidden;
    }

    &:hover {
        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
            background-color: #d9d9d9;
            border-radius: 5px;
        }

        &::-webkit-scrollbar {
            visibility: visible;
            display: inline-block;
            width: 6px;
            background-color: #f5f5f5;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #898991;
            border-radius: 5px;
        }
    }
`;
const NotificationHeader = styled.div`
    display: none;
    @media (max-width: 1023px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: var(--spacing-xs) var(--spacing-sm) var(--spacing-xs)
            var(--spacing-md);
    }
`;
const NotificationHeaderLink = styled.div`
    color: var(--text-link);
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
`;
const NotificationHeaderClose = styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

function NotificationsPopoverWrap({ children, isMobile }) {
    const htmlContainer = document.getElementsByTagName("html")[0];

    let stickyHeight = 0;
    let hasSticky = false;
    if (htmlContainer.classList.contains("sticky-business")) {
        hasSticky = true;
        stickyHeight = htmlContainer.querySelector('.trialinfo-bar-holder')?.offsetHeight
    }

    return (
        <NotificationsWrap>
            <NotificationHeader>
                <NotificationHeaderLink
                    onClick={() => {
                        hashManager.pushState({
                            page: "settings",
                            settings: "tab-notifications"
                        });
                    }}
                >
                    {__("Notification settings")}
                </NotificationHeaderLink>
                <NotificationHeaderClose className="popover-close">
                    <i className="baseIcon fa-regular fa-close" />
                </NotificationHeaderClose>
            </NotificationHeader>
            <NotificationsInner
                $isMobile={isMobile}
                $hasSticky={hasSticky}
                $stickyHeight={stickyHeight}
            >
                {children}
            </NotificationsInner>
        </NotificationsWrap>
    );
}

export default NotificationsPopoverWrap;
