// @flow

import PropTypes from "prop-types";
import React, { Component } from "react";
import styled from "styled-components";
import { hiDPI } from "polished";

type Props = {
  name: ?string,
  onChange: any => void,
  disableChange: boolean
};

type State = {
  isChecked: boolean
};

class CryptoLockButton extends Component<Props, State> {
  static defaultProps = {
    name: "",
    onChange: () => {},
    disableChange: false,
    value: true,
  };

  constructor(props: Props) {
    super(props);

    (this: any)._onChange = (this: any)._onChange.bind(this);
    this.state = {
      isChecked: props.value
    };
  }


  componentWillReceiveProps(newProps) {
    this.setState(prevState => {
      return { isChecked: newProps.value }
    })
  }

  _onChange() {
    const { onChange, disableChange } = this.props;

    if (!disableChange) {
      this.setState(prevState => {
        return { isChecked: !prevState.isChecked }
      });
    } else {
      $('input.cryptopass[type="password"]').focus();
    }

    onChange();
  };

  render() {
    const { name } = this.props;
    const { isChecked } = this.state;

    return (
      <Container>
        <input type="checkbox" name={name} checked={isChecked} onChange={this._onChange} />
        <Label />
      </Container>
    );
  }
}

export default CryptoLockButton;

const Container = styled.div`
    position: relative;
    display: inline-block;
    width: 56px;
    height: 40px;
    line-height: 40px;

    &::after,
    &::before {
        font-family: "Roboto", sans-serif;
        font-feature-settings: normal;
        font-kerning: auto;
        font-language-override: normal;
        font-stretch: normal;
        font-style: normal;
        font-synthesis: weight style;
        font-variant: normal;
        font-weight: normal;
        text-rendering: auto;
    }

    input {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 20px;
        opacity: 0;
        cursor: pointer;
        z-index: 100;
        margin: 0;
    }

    input:checked + label {
        background-color: var(--color-primary-500);
        border: 2px solid var(--color-primary-500);

        &:after {
            font-family: "Font Awesome 6 Pro";
            content: "\\f09c";
            width: 28px;
            height: 28px;
            background-color: var(--surface-secondary);
            left: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--text-icons-dark);
            font-weight: 900;
        }
    }
`;

const Label = styled.label`
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    width: 56px;
    height: 40px;
    background: var(--surface-primary);
    border: 2px solid var(--color-grey-800);
    line-height: 40px;
    border-radius: 100px;
    padding-bottom: 0 !important;
    transition: 0.4s;

    &:after {
        font-family: "Font Awesome 6 Pro";
        content: "\\f023";
        position: absolute;
        width: 28px;
        height: 28px;
        border-radius: 100%;
        left: 4px;
        top: 4px;
        background: var(--color-grey-800);
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        transition: 0.4s;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--color-base-white);
        font-weight: 900;
    }
`;
