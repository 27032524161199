import React from "react";
import styled from "styled-components";

const TrashTruncate = () => (
    <TrashStyle>
        {__(
            "efh_trash_explain_short",
            "Trash is where you will find all your deleted files from the last %days% days.",
            {
                days: HFN.config.user.trashrevretentiondays
            }
        )}
        <Link onClick={HFN.initEmptyTrashAll}>{__("Empty Trash")}</Link>
    </TrashStyle>
);

export default TrashTruncate;

const TrashStyle = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    padding: var(--spacing-xs) var(--spacing-none);

    color: var(--text-primary);

    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
`;

const Link = styled.div`
    display: flex;
    height: 40px;
    box-sizing: border-box;
    padding: var(--spacing-sm) var(--spacing-lg);
    justify-content: center;
    align-items: center;
    border-radius: var(--radius-full);
    text-decoration: none;
    cursor: pointer;

    border: 1px solid var(--button-secondary-border-default);
    background: var(--button-secondary-background-default);

    color: var(--button-secondary-label-default);
    text-align: center;

    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    white-space: nowrap;
`;
