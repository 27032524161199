import React from "react";
import styled from "styled-components";
import { FlexContainer, Img } from "./styles";
import AppStoreImg from "../../../../../root/img/backups/downloadappsbuttons/appstore.svg";
import GooglePlayImg from "../../../../../root/img/backups/downloadappsbuttons/googleplay.svg";
import {
    APP_STORE_APP_URL,
    PLAY_STORE_APP_URL
} from "@pcloud/web-utilities/dist/config/constants";
import ReactDOMServer from "react-dom/server";

const DownloadAppsButtons = ({ device = "mac", align = "left" }) => {
    return (
        <>
            <AppStoreLinks align={align}>
                {device !== "android" && (
                    <AppStore
                        data-tooltip-id="qr-code-tooltip"
                        data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                            <TooltipWrapper>
                                <TooltipLabel>
                                    {__(
                                        "backups_qr_code_title",
                                        "Get the app by QR code"
                                    )}
                                </TooltipLabel>
                                <QRCodeImage
                                    src={`/img/backups/downloadappsbuttons/qr-code-appstore.png`}
                                    srcSet={`/img/backups/downloadappsbuttons/qr-code-appstore@2x.png 2x`}
                                    alt="QR Code to open App Store."
                                    maxwidth="95px"
                                    width="95px"
                                    height="95px"
                                />
                            </TooltipWrapper>
                        )}
                        href={APP_STORE_APP_URL}
                    >
                        <AppStoreImg />
                    </AppStore>
                )}
                {device !== "ios" && (
                    <AppStore
                        data-tooltip-id="qr-code-tooltip"
                        data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                            <TooltipWrapper>
                                <TooltipLabel>
                                    {__(
                                        "backups_qr_code_title",
                                        "Get the app by QR code"
                                    )}
                                </TooltipLabel>
                                <QRCodeImage
                                    src={`/img/backups/downloadappsbuttons/qr-code-playstore.png`}
                                    srcSet={`/img/backups/downloadappsbuttons/qr-code-playstore@2x.png 2x`}
                                    alt="QR Code to open App Store."
                                    maxwidth="95px"
                                    width="95px"
                                    height="95px"
                                />
                            </TooltipWrapper>
                        )}
                        href={PLAY_STORE_APP_URL}
                    >
                        <GooglePlayImg />
                    </AppStore>
                )}
            </AppStoreLinks>
        </>
    );
};

export default DownloadAppsButtons;

const AppStoreLinks = styled(FlexContainer)`
    gap: 16px;
    justify-content: ${({ align }) => {
        if (align === "left") {
            return "flex-start";
        } else if (align === "center") {
            return "center";
        }
    }};
`;

const AppStore = styled.a`
    display: inline-block;
    cursor: pointer;
    width: 150px;
`;

const TooltipWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const TooltipLabel = styled.p`
    margin: 0 0 8px 0;
    color: var(--text-secondary);
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
`;

const QRCodeImage = styled(Img)`
    position: relative;
    z-index: 2;
`;
