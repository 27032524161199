import React from "react";
import SavePlaylistPanel from "./SavePlaylistPanel";
import LoadPlaylistPanel from "./LoadPlaylistPanel";
import VolumeOverlay from "./VolumeOverlay";
import SettingsOverlay from "./SettingsOverlay";
import usePlayerContext from "../../../hooks/usePlayerContext";
import PlayRateOverlay from "./PlayRateOverlay";
import SleepTimerOverlay from "./SleepTimerOverlay";

const OverlayControl = () => {
    const { controlsWithOverlay } = usePlayerContext();

    return (
        <>
            {controlsWithOverlay.showSavePlaylistPanel && <SavePlaylistPanel />}
            {controlsWithOverlay.showLoadPlaylistPanel && <LoadPlaylistPanel />}
            {controlsWithOverlay.showVolumeChangingPanel && <VolumeOverlay />}
            {controlsWithOverlay.showSettingsOptionPanel && <SettingsOverlay />}
            {controlsWithOverlay.showPlayRatePanel && <PlayRateOverlay />}
            {controlsWithOverlay.showSleepTimerPanel && <SleepTimerOverlay />}
        </>
    );
};

export default OverlayControl;
