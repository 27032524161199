import React from "react";
import {
    LinkButton,
    Progress,
    ProgressBar,
    ProgressContainer,
    SubContent,
    SubContentRow,
    SubContentRowDefault,
    SubContentRowError,
    SubContentRowLabel,
    SubContentRowValue
} from "../styles";
import { __ } from "../../../../../../lib/translate";
import Tooltip from "../../../../../Tooltip";
import { SERVICES_INFO } from "../../../../configurations";
import { getErrorReason } from "../../../../../ThirdpartyBackupComponents/constants";

const DesktopSubLayout = ({
    backup_metadata,
    exit_code,
    errorState,
    type,
    status,
    sourceid,
    accountInfo,
    NEXT_DATE_FORMATTED,
    LAST_DATE_FORMATTED,
    PROGRESS_PERCENT,
    triggerClick
}) => {
    return (
        <>
            <SubContent>
                {backup_metadata && (
                    <SubContentRow>
                        <SubContentRowLabel>
                            {__("Folder name")}:&nbsp;
                        </SubContentRowLabel>
                        <SubContentRowValue>
                            <LinkButton
                                $fontSize={12}
                                onClick={(e) => {
                                    triggerClick(e, backup_metadata.folderid);
                                }}
                            >
                                {backup_metadata.name}
                            </LinkButton>
                        </SubContentRowValue>
                    </SubContentRow>
                )}
                {LAST_DATE_FORMATTED && !errorState && (
                    <SubContentRow>
                        {type === "ongoing" && NEXT_DATE_FORMATTED && (
                            <>
                                <Tooltip
                                    title={
                                        __("next_backup", "Next backup") +
                                        (SERVICES_INFO[sourceid]
                                            .nextBackupTooltip
                                            ? ". " +
                                              __(
                                                  SERVICES_INFO[sourceid]
                                                      .nextBackupTooltip
                                              )
                                            : null)
                                    }
                                >
                                    <SubContentRowLabel>
                                        {__("next_updated_label_short", "Next")}
                                        :&nbsp;
                                    </SubContentRowLabel>
                                </Tooltip>
                                <SubContentRowValue>
                                    {NEXT_DATE_FORMATTED || "-"}
                                </SubContentRowValue>
                            </>
                        )}
                        <Tooltip
                            title={
                                type === "ongoing" && NEXT_DATE_FORMATTED
                                    ? __("last_backup", "Last backup")
                                    : null
                            }
                        >
                            <SubContentRowLabel>
                                {type === "ongoing" && NEXT_DATE_FORMATTED
                                    ? __("last_updated_label_short", "Last")
                                    : __("last_backup", "Last backup")}
                                :&nbsp;
                            </SubContentRowLabel>
                        </Tooltip>
                        <SubContentRowValue>
                            {LAST_DATE_FORMATTED || "-"}
                        </SubContentRowValue>
                    </SubContentRow>
                )}
                {errorState && (
                    <SubContentRow>
                        <SubContentRowError>
                            {getErrorReason(exit_code)}
                        </SubContentRowError>
                    </SubContentRow>
                )}
                {!accountInfo &&
                    !backup_metadata &&
                    !LAST_DATE_FORMATTED &&
                    !errorState && (
                        <SubContentRow>
                            <SubContentRowDefault>
                                {__(
                                    "thirdparty_backups_default_message",
                                    "Import your files from %name% to pCloud.",
                                    {
                                        name: SERVICES_INFO[sourceid]?.name
                                    }
                                )}
                            </SubContentRowDefault>
                        </SubContentRow>
                    )}
            </SubContent>
            {status === "running" && !errorState && (
                <ProgressContainer>
                    <ProgressBar>
                        <Progress $progress={PROGRESS_PERCENT} $isactive />
                    </ProgressBar>
                </ProgressContainer>
            )}
        </>
    );
};

export default DesktopSubLayout;
