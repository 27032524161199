import React from "react";
import { ContentHeader, ContentText } from "./styles";
import { __ } from "../../../lib/translate";
import styled from "styled-components";
import { boldConverter } from "../../../lib/componentifyConverters";
import Componentify from "react-componentify";

const AccountSettingsContent = () => {
    return (
        <Wrapper>
            <ContentHeader>
                {__("tutorial_title_account_settings", "Account Settings")}
            </ContentHeader>
            <ContentText>
                <Componentify
                    text={__(
                        "tutorial_message_account_settings",
                        "Find your account settings under your profile icon in the top-right corner for easy access."
                    )}
                    converters={[boldConverter]}
                />
            </ContentText>
        </Wrapper>
    );
};

export default AccountSettingsContent;

const Wrapper = styled.div`
    width: 280px;
`;
