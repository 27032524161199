import { setcookie } from "../lib/utils";

import Popup from "../lib/Popup";

import { default as apiConfig, EU_API, US_API, DEFAULT_LOCATIONS } from "@pcloud/web-utilities/dist/api/config.api";
import hashManager from "../lib/hashManager";

export { EU_API, US_API, DEFAULT_LOCATIONS }

const downloadLinksApiConfig = {
  ...apiConfig,
  errorCallback: (data) => {
    Popup.close();

    console.log(HFN.LOGIN_EXPIRED_CODES);
    console.log(data.result);

    if (HFN.ERROR_MESSAGE[data.result] != undefined) {
      HFN.message(__e(data), 'error');
    } else if (data.result.toString().charAt(0) == 3) {
      console.log('Ignored Error', data);
    } else if (HFN.LOGIN_EXPIRED_CODES.indexOf(data.result) !== -1) {
      HFN.message("Session Expired. Please login.", 'error');
      setcookie('pcauth', '', -1);
      setcookie('locationid', '', -1);
      hashManager.pushState({ page: 'login' }, 2);
      return;
    } else {
      console.log('Uncatched Error: ');
      console.log(data);
      // HFN.message(data.error, 'error');
    }
  }
}

export default downloadLinksApiConfig;
