import React from "react";
import usePlayerContext from "../../../hooks/usePlayerContext";
import { BoxPlayerWrapper } from "../../../userinterface/styles";
import BoxSongDetails from "./BoxSongDetails";
import BoxControls from "./BoxControls";
import MobileMiniLayout from "./MobileMiniLayout";
import DefaultLayout from "./DefaultLayout";
import SwipeBoxControlContainer from "./SwipeBoxControlContainer";
import { AnimatePresence } from "framer-motion";

const MainPlayerLayout = () => {
    const {
        isMobile,
        playerVariant,
        onShowMiniPlayer,
        additionalClass,
        boxPlayerRef
    } = usePlayerContext();

    const { isFullPage, isMiniPlayer, isMinimize } = playerVariant;

    const handleBoxClick = () => {
        if (!isMinimize || !isMobile) {
            return;
        }
        boxPlayerRef.current.style.removeProperty("height");
        onShowMiniPlayer();
    };

    const showTitleOnTop = isFullPage;
    const showBoxControls = !isMinimize;
    const showMobileMiniPlayer = isMobile && isMiniPlayer && !isMinimize;

    return (
        <SwipeBoxControlContainer>
            <BoxPlayerWrapper
                ref={boxPlayerRef}
                id={"boxPlayerWrapper"}
                className={additionalClass}
                onClick={handleBoxClick}
            >
                {showBoxControls && <BoxControls />}
                <AnimatePresence>
                    {showTitleOnTop && <BoxSongDetails />}
                </AnimatePresence>

                {showMobileMiniPlayer && <MobileMiniLayout />}
                {!showMobileMiniPlayer && <DefaultLayout />}
            </BoxPlayerWrapper>
        </SwipeBoxControlContainer>
    );
};

export default MainPlayerLayout;
