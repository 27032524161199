import React, { useRef } from "react";
import styled from "styled-components";
import { Button } from "../../Templates/TopBar/UploadButton";
import { __ } from "../../../../lib/translate";
import { useSelector } from "react-redux";
import { isUploadDisabledRedesign } from "../../../UploadManager/helpers";

const EmptyUploadMobile = () => {
    const isUploadDisabled = isUploadDisabledRedesign();
    const fileInputRef = useRef(null);
    const itemData = useSelector(({ content }) => content.itemData);

    const triggerFileInput = () => {
        if (typeof gtag === "function") {
            gtag("event", "upload_options_click", {
                action: "file upload",
                location: "empty state"
            });
        }

        fileInputRef.current.click();
        fileInputRef.current.value = "";
    };

    const triggerFolderCreateInput = () => {
        if (typeof gtag === "function") {
            gtag("event", "upload_options_click", {
                action: "new folder",
                location: "empty state"
            })
        }

        HFN.newFolder(itemData.folderid);
    };

    const handleFileChange = (event) => {
        HFN.uploadControl.onUpload({
            items: event.target.files,
            isDrop: false
        });
    };

    return (
        <Wrapper>
            <ButtonCustom
                disabled={isUploadDisabled} onClick={triggerFileInput}>
                {__("step_action_upload", "Upload file")}
            </ButtonCustom>
            <ButtonCustom
                disabled={isUploadDisabled} onClick={triggerFolderCreateInput}>
                {__("Create Folder")}
            </ButtonCustom>

            <InputFile
                type="file"
                multiple
                onChange={handleFileChange}
                ref={fileInputRef}
            />
        </Wrapper>
    );
};

export default EmptyUploadMobile;

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;

    @media (max-height: 490px) {
        flex-direction: row;
        margin-top: var(--spacing-sm)
    }
`;

const ButtonCustom = styled(Button)`
    width: 100%;
    max-width: 320px;
`;

const InputFile = styled.input`
    display: none;
`;
