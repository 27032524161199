import React from "react";
import styled, { keyframes } from "styled-components";

const PulseAnimation = ({ borderRadius, pulseCircle }) => {
    return (
        <>
            <Highlighter
                $borderRadius={borderRadius}
                $pulseCircle={pulseCircle}
            />
            <HighlighterChild $pulseCircle={pulseCircle} />
        </>
    );
};

export default PulseAnimation;

const pulse = keyframes`
    0% {
        opacity: 1;
        transform: scale(1);
    }

    50% {
        opacity: 0.2;
        transform: scale(1.5);
    }

    100% {
        opacity: 0.2;
        transform: scale(1.5);
    }
`;

const pulseInside = keyframes`
    0% {
        opacity: 1;
        transform: scale(1);
    }
    
    25% {
        opacity: 1;
        transform: scale(1);
    }

    100% {
        opacity: 0.2;
        transform: scale(1.5);
    }
`;

const Highlighter = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.2);
    border-radius: ${({ $pulseCircle, $borderRadius }) =>
        !$pulseCircle ? $borderRadius : "50%"};
    box-sizing: border-box;
    border: 1px solid #17bed0;
    z-index: 1;
    animation: ${pulse} 1.5s infinite;
`;

const HighlighterChild = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.25);
    border-radius: ${({ $pulseCircle }) => (!$pulseCircle ? "5px" : "50%")};
    z-index: 2;
    animation: ${pulseInside} 1.5s infinite;
`;
