import React from "react";
import { ContentHeader, ContentText } from "./styles";
import { __ } from "../../../lib/translate";
import styled from "styled-components";
import { boldConverter } from "../../../lib/componentifyConverters";
import Componentify from "react-componentify";

const DragAndDropContent = () => {
    return (
        <Wrapper>
            <ContentHeader>
                {__("tutorial_title_drag_and_drop", "Drag & Drop")}
            </ContentHeader>
            <ContentText>
                <Componentify
                    text={__(
                        "tutorial_message_drag_and_drop",
                        "Simply drag your photos, documents, or entire folders into the pCloud window, and watch them upload instantly. It’s that easy to manage your data!"
                    )}
                    converters={[boldConverter]}
                />
            </ContentText>
        </Wrapper>
    );
};

export default DragAndDropContent;

const Wrapper = styled.div`
    width: 280px;
`;
