import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { Tooltip } from "react-tooltip";

import ZoomOutSvg from "../../../root/img/svg/gallery/remove.svg";
import ZoomInSvg from "../../../root/img/svg/gallery/add.svg";

const ZoomControls = ({
  transformWrapper,
  zoomStep,
  zoom,
  setZoom,
  minZoom,
  maxZoom,
  panningStarted
}) => {
  const inputZoomRef = useRef(null);
  const maxZoomTooltip = useRef(null);
  const minZoomTooltip = useRef(null);
  const showZoomTooltip = useRef(null);

  useEffect(() => {
    if (panningStarted && inputZoomRef.current) {
      // When clicked on the photo we're handled here.
      if (document.activeElement === inputZoomRef.current) {
        // Deselect input.
        inputZoomRef.current.selectionStart = inputZoomRef.current.selectionEnd;
        inputZoomRef.current.blur();
      }
    }

    if (transformWrapper) {
      // If we clicked on the image while the zoom animation is in progress it stops zooming
      // and the shown zoom value in the zoom input became invalid, so we must update it.
      setZoom(Math.round(transformWrapper.instance.transformState.scale * 100));
    }
  }, [panningStarted]);

  const applyZoom = (newScaleValuePercents, animationTime = 0) => {
    if (!transformWrapper) {
      return newScaleValuePercents;
    }

    // Validate against max and min zoom values.
    let newScaleValuePercentsValidated = newScaleValuePercents;

    if (newScaleValuePercents > maxZoom) {
      newScaleValuePercentsValidated = maxZoom;
    } else if (newScaleValuePercents < minZoom) {
      newScaleValuePercentsValidated = minZoom;
    }

    const scaleToSet = newScaleValuePercentsValidated / 100;
    const currentScale = transformWrapper.instance.transformState.scale;
    if (scaleToSet == 1) {
      // 100%
      transformWrapper.resetTransform(animationTime);
    } else if (scaleToSet > currentScale) {
      transformWrapper.zoomIn(scaleToSet - currentScale, animationTime);
    } else if (scaleToSet < currentScale) {
      if (scaleToSet < 1) {

      }
      transformWrapper.zoomOut(currentScale - scaleToSet, animationTime);
    }

    return newScaleValuePercentsValidated;
  };

  const getZoomInStep = () => {
    if (zoom >= 200 && zoom < 300) {
      return 20;
    }

    if (zoom >= 300) {
      return 50;
    }

    return zoomStep;
  };

  const getZoomOutStep = () => {
    if (zoom > 200 && zoom <= 300) {
      return 20;
    }

    if (zoom > 300) {
      return 50;
    }

    return zoomStep;
  };

  const onZoomBtnClick = (newZoomPercents) => {
    const newZoom = applyZoom(newZoomPercents, 300);

    setZoom(newZoom);
  };

  const onZoomOutBtnClick = () => {
    onZoomBtnClick(Number(zoom) - getZoomOutStep());
  };

  const onZoomInBtnClick = () => {
    onZoomBtnClick(Number(zoom) + getZoomInStep());
  };

  const validateUsersNumber = (e) => {
    const key = e.which || e.keyCode || e.charCode;
    const pressedCharacter = String.fromCharCode(key);
    let val = e.target.value.replace(/\s+/g, "");

    val = val.trim();
    if (!/[0-9 ]/.test(pressedCharacter)) {
      e.preventDefault();
    }
  };

  return (
    <ZoomInOutWrapper>
      <div className="no-select" data-tooltip-id="zoom-tooltip" data-tooltip-content={zoom <= minZoom ? __("min_zoom_reached", "Minimum zoom reached") : ""}>
        <ZoomButton onClick={onZoomOutBtnClick} disabled={zoom <= minZoom}>
          <ZoomOutSvg />
        </ZoomButton>
      </div>
      <ZoomInputWrapper>
        <ZoomInput
          className="zoom-input"
          ref={inputZoomRef}
          value={zoom}
          onFocus={() => inputZoomRef.current.select()}
          onChange={(e) => setZoom(e.target.value)}
          // Important to use 0 for animation!
          onBlur={() => setZoom(applyZoom(zoom, 0))}
          // On enter key -> important to use 0 for animation!
          onKeyDown={(e) => e.keyCode === 13 ? setZoom(applyZoom(zoom, 0)) : null}
          onKeyPress={validateUsersNumber}
        />
        <Suffix>%</Suffix>
      </ZoomInputWrapper>
      <div className="no-select" data-tooltip-id="zoom-tooltip" data-tooltip-content={zoom >= maxZoom ? __("max_zoom_reached", "Maximum zoom reached") : ""}>
        <ZoomButton onClick={onZoomInBtnClick} disabled={zoom >= maxZoom}>
          <ZoomInSvg />
        </ZoomButton>
      </div>
      <Tooltip id="zoom-tooltip" className="gallery-tooltip" />
    </ZoomInOutWrapper>
  );
};

export default ZoomControls;

const ZoomInOutWrapper = styled.div`
  order: 2;
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 16px;

  min-width: 115px;

  button {
    cursor: pointer;
  }
`;

const ZoomButton = styled.button`
  background-color: transparent;
  border: 0;
  padding: 0;
  user-select: none;

  &:focus {
    outline: 0;
  }

  &:disabled {
    cursor: default;
    pointer-events: none;

    svg {
      opacity: 0.64;
      pointer-events: none;
    }
  }
`;

const ZoomInputWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 48px;
  box-sizing: border-box;

  &:focus-within {
    border-bottom: 1px solid #17bed0;
  }
`;

const ZoomInput = styled.input`
  font-family: Roboto;
  font-size: 14px;
  line-height: 24px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  min-width: 15px;
  background-color: transparent;
  color: rgba(255, 255, 255, 0.8);
  outline: none;
  border: none;

  /* Firefox */
  -moz-appearance: textfield;
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::selection {
    background: #7A8D8F !important;
    color: #fff;
  }
`;

const Suffix = styled.span``;
