import React from "react";
import usePlayerContext from "../../../hooks/usePlayerContext";
import {
    SettingsItemLabelWrapper,
    SettingsItemWrapper
} from "../../../userinterface/styles";
import OverlayPanelContainer from "./OverlayPanelContainer";
import { createPortal } from "react-dom";
import { getPlayerState } from "../../../helpers";

const values = [
    {
        value: 0.75
    },
    {
        value: 1
    },
    {
        value: 1.5
    },
    {
        value: 2
    },
    {
        value: 2.5
    }
];

const SleepTimerOverlay = () => {
    const {
        additionalClass,
        playRate,
        setPlayRate,
        onCloseAllOverlays,
        playerVariant
    } = usePlayerContext();

    const container = document.getElementById("playRateContainer");

    if (!container) {
        return null;
    }

    const classPosition =
        " play-rate" +
        (playerVariant.isMiniPlayer ? " position-top" : " position-bottom");
    return (
        <>
            {createPortal(
                <OverlayPanelContainer
                    className={additionalClass + classPosition}
                >
                    {values.map((item) => (
                        <SettingsItemWrapper
                            key={item.value}
                            className={
                                item.value === playRate
                                    ? "active only-text"
                                    : "only-text"
                            }
                            onClick={() => {
                                setPlayRate(item.value);
                                onCloseAllOverlays();

                                if (typeof gtag === "function") {
                                    gtag("event", "audio_player_click", {
                                        action: "playrate change",
                                        state: getPlayerState(playerVariant),
                                        eventValue: item.value
                                    });
                                }
                            }}
                        >
                            <SettingsItemLabelWrapper>
                                {item.value + "x"}
                            </SettingsItemLabelWrapper>
                        </SettingsItemWrapper>
                    ))}
                </OverlayPanelContainer>,
                container
            )}
        </>
    );
};

export default SleepTimerOverlay;
