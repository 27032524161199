// @flow

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import * as SETTINGS from "../settingsConstants";
import * as Style from "../preferences.style";
import LoginAccountsSetting from "../LoginAccountsSetting";
import BackupAccountsSetting from "../BackupAccountsSetting";
import LinkedAppsSetting from "../LinkedAppsSetting";

const LinkedAccounts = ({ settings }) => {
  const renderLoginAccounts = () => <LoginAccountsSetting/>;

  const renderBackupAccounts = () => <BackupAccountsSetting/>;

  const renderLinkedApps = () => <LinkedAppsSetting/>;

  const renderFunctionsPerSetting = {
    [SETTINGS.LOGIN_ACCOUNTS_SETTING]: renderLoginAccounts,
    [SETTINGS.BACKUP_ACCOUNTS_SETTING]: renderBackupAccounts,
    [SETTINGS.LINKED_APPS_SETTING]: renderLinkedApps,
  };

  return (
    <Style.Container className="account-container lang-wrapper">
      {settings.map((setting) => renderFunctionsPerSetting[setting] && renderFunctionsPerSetting[setting]())}
    </Style.Container>
  );
};

export default LinkedAccounts;
