import React, {
	createContext,
	forwardRef,
	Fragment,
	useState,
	useRef,
	useCallback,
	useMemo,
	memo,
	useEffect
} from "react";
import { useSelector } from "react-redux";
import { VariableSizeList, areEqual } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

import { useMenu } from '../../context/MenuContextProvider';
import { TEMPLATES } from "../../ContentData";
import type { ColumnsObjType } from "../../../../types/columns";
import { SELECT, TYPE_ICON, ACTIONS_COLUMN, NAME_SUB, SORT_DIRECTION_ASC, SORT_DIRECTION_DESC, SHOW_COMMENTS } from "../../../../config/constants";
import { getViewType, getIconType, getContentOptions, transormOptionsToList, getItemRightIcons } from "../../../../lib/utils";
import FileIcon from "../../../FileIcon";
import { Row, Header, HeaderColumn, Column, StickyRowWrapperList, IconButton, CheckboxButton, SortIcon } from "../../UI/Style";
import { OPTIONS } from "../../../../config/options";
import { __ } from "../../../../lib/translate";
import { LARGE_LIST_VIEW } from "../../../../config/constants";
import { getHeaderElements } from "../../ContentViewUtils";
import EmptyState from "../../UI/EmptyState";
import Credits from "../../UI/Footer/Credits";

const StickyListContext = createContext();
StickyListContext.displayName = "StickyListContext";

const TABLE_HEAD_ROW_HEIGHT = 39;
let folderListItemRowHeight = 40;
let folderListItemLargeRowHeight = 56;

const toggleSortDirection = (currentSortDirection) => {
	if (currentSortDirection === SORT_DIRECTION_ASC) {
		return SORT_DIRECTION_DESC;
	} else if (currentSortDirection === SORT_DIRECTION_DESC) {
		return SORT_DIRECTION_ASC
	}
}

const renderItemOptions = (item, showMenu, contentType, isMobileView, isMobileDevice, isBusiness, overwriteOptionsOnClick) => {
	const ref = useRef(null);
	const cryptoV2IsActive = useSelector(({ user }) => user.userinfo.cryptov2isactive);
	const isCollaborationEnabled = useSelector(({ user }) => user.collaboration || false);
	const availableOptions = useMemo(() => getContentOptions({
		content: [item],
		isBusiness: isBusiness,
		cryptoV2IsActive: cryptoV2IsActive,
		isCollaborationEnabled: isCollaborationEnabled,
		maxMainOptionsCnt: isMobileView ? 0 : 3,
		contentType: contentType,
		isMobileDevice: isMobileDevice
	}), [item, isBusiness, cryptoV2IsActive, isCollaborationEnabled]);
	const items = useMemo(() => transormOptionsToList(availableOptions.secondary, [item], overwriteOptionsOnClick), [availableOptions.secondary, item]);

	const mainOptions = availableOptions.main ? availableOptions.main.map(option => {
		const optionData = OPTIONS[option] || {};
		const { icon, optionTitle, onClick } = optionData;

		return (
			<IconButton
				key={option}
				data-id={item.id}
				className={"listRow" + (isMobileDevice ? " mobile" : "")}
				onClick={() => onClick([item], null, "list item hover")}
				data-tooltip-content={__(...optionTitle)}
				data-tooltip-id="cv-tooltip"
			>
				<i className={"smallIcon " + icon} />
			</IconButton>
		);
	}) : null;

	const secondaryOptions = Object.keys(availableOptions.secondary).length ? (
		<IconButton
			ref={ref}
			key={`${item.id}-options`}
			className={"listRow" + (isMobileDevice ? " mobile" : "")}
			onClick={(event) => {
				event.preventDefault();

				showMenu(items, ref);
			}}
			data-tooltip-content={__("tooltip_list_show_more", "Show more actions for this item")}
			data-tooltip-id="cv-tooltip"
		>
			<i className="smallIcon fa-regular fa-ellipsis-vertical" />
		</IconButton>
	) : null;

	return (
		<>
			{mainOptions}
			{secondaryOptions}
		</>
	);
};

const renderListHeader = (columns, sortIsSet, sortOptions, keysLen, selectedFolders, selectedFiles, onAllItemsSelect, onColumnSortClick, renderSelectByTypeMenu, isMobileView, isMobileDevice, isLargeList) => {
	return (
		<Header className={isMobileDevice ? "mobile" : ""}>
			{Object.keys(columns).map(id => {
				const { sortable, columnTitle, tooltip, width, stretchWidth, contentKey, sortType, showOnMobile = false, customClass = false } = columns[id];
				const isSortKey = sortIsSet && sortOptions && (sortOptions.sortBy === contentKey);
				const sortDirection = isSortKey ? sortOptions.sortDirection : SORT_DIRECTION_ASC;
				const folderFirst = sortOptions && sortOptions.folderFirst;
				const showColumn = isMobileView ? showOnMobile : true;
				const colClassList = [id + "-col"];
				const classList = [];

				if (isMobileDevice) {
					colClassList.push("mobile");
				}
				if (customClass) {
					colClassList.push(customClass);
				}
				if (id === SELECT) {
					classList.push("listCheckbox");
					if (isMobileDevice) {
						classList.push("mobile");
					}
					if ((selectedFolders.size || selectedFiles.size) && keysLen === (selectedFolders.size + selectedFiles.size)) {
						classList.push("checked");
					} else if (selectedFolders.size > 0 || selectedFiles.size > 0) {
						classList.push("intermediate");
					}
					if (isLargeList) {
						classList.push("lgList");
					}
				}

				return (showColumn && <HeaderColumn
					key={id}
					{...(tooltip ?
						{
							"data-tooltip-content": __(...tooltip),
							"data-tooltip-id": "cv-tooltip",
							"data-tooltip-delay-show": 500
						} : {})
					}
					className={colClassList.join(" ")}
					onClick={() => {
						if (sortable) {
							onColumnSortClick(contentKey, (isSortKey ? toggleSortDirection(sortDirection) : sortDirection), folderFirst, sortType)
						}
					}}
					$stretchWidth={stretchWidth}
					$isSortKey={isSortKey}
				>
					{id === SELECT ? (
						<CheckboxButton className={classList.join(" ")} onClick={onAllItemsSelect} />
					) : null}
					{columns[id].showSelectByType ? renderSelectByTypeMenu("hover-menu-button no-margin") : null}
					<span>{columnTitle ? __(...columnTitle) : ''}</span>
					{sortable ? <SortIcon $sortDirection={sortDirection} $isSortKey={isSortKey} /> : null}
				</HeaderColumn>
				);
			})}
		</Header>
	)
};

const renderSingleRow = (columns, item, showMenu, onSingleItemSelect, contentType, isLargeList, isMobileView, isMobileDevice, classList = [], selectedFolders = new Map(), selectedFiles = new Map(), overwriteOptionsOnClick = {}) => {
	const isBusiness = useSelector(({ user }) => user.userinfo.business);
	const shares = useSelector(({ sharedFolders }) => sharedFolders.sharedFolders.shares);
	const users = isBusiness ? useSelector(({ business }) => business.users) : [];

	return (
		<React.Fragment key={item.id}>
			{Object.keys(columns).map(id => {
				const {
					sortable,
					clickable,
					width,
					stretchWidth,
					innerColumns,
					secondLine,
					contentKey,
					getFieldValue,
					formatMethod,
					onClick,
					showOnMobile = false,
					customClass = false,
					ellipsisTooltip = false
				} = columns[id];
				let { visibleOnHover, visibleOnHoverOnly } = columns[id];
				const showColumn = isMobileView ? showOnMobile : true;

				if (!showColumn) {
					return null;
				}

				if ((selectedFolders.has(item.id.toString()) || selectedFiles.has(item.id.toString()))
					&& (selectedFolders.size + selectedFiles.size === 1)) {
					visibleOnHoverOnly = false;
				}

				if (isMobileView) {
					visibleOnHover = false;
					visibleOnHoverOnly = false;
				}

				let classNames = [id + '-col'];

				if (customClass) {
					classNames.push(customClass);
				}

				if (visibleOnHover) {
					classNames.push("visibleOnHover");
				} else if (visibleOnHoverOnly) {
					classNames.push("visibleOnHoverOnly");
				}

				if (clickable) {
					classNames.push("clickable");
				}

				if (isMobileDevice) {
					classNames.push("mobile");
				}

				if (isLargeList) {
					classNames.push("lgList");
				}

				if (id === SELECT) {
					if (selectedFolders.size > 0 || selectedFiles.size > 0) {
						classNames.push("visible");
					}

					const selectClassNames = ["listCheckbox"];
					if (selectedFolders.has(item.id.toString()) || selectedFiles.has(item.id.toString())) {
						selectClassNames.push("checked");
					}
					if (isMobileDevice) {
						selectClassNames.push("mobile");
					}
					if (isLargeList) {
						selectClassNames.push("lgList");
					}

					return (
						<Column key={`${item.id}_${id}`} className={classNames.join(" ")}>
							<CheckboxButton
								className={selectClassNames.join(" ")}
								onClick={(event) => { onSingleItemSelect(item, true, event) }}
								onDoubleClick={(event) => { event.preventDefault(); event.stopPropagation(); }}
							/>
						</Column>
					);
				}

				if (id === TYPE_ICON) {
					const iconType = useMemo(() => getIconType(item), [item, isLargeList]);
					const isVideo = item.category === HFN.CATEGORY.VIDEO;
					const fileIconClassList = [];
					if (isVideo) {
						fileIconClassList.push("playButton");
					}

					return (
						<Column $stretchWidth={stretchWidth} key={`${item.id}_${id}`} className={classNames.join(" ")}>
							{isLargeList && <div data-id={item.id} className={fileIconClassList.join(" ")} onClick={onClick ? (event) => onClick(event, item) : () => { }}>
								<FileIcon item={iconType} type={HFN.ICONS.SVG_LARGE_LIST} metadata={item} />
							</div>}
							{!isLargeList && <div data-id={item.id} className={fileIconClassList.join(" ")} onClick={onClick ? (event) => onClick(event, item) : () => { }}>
								<FileIcon item={iconType} type={HFN.ICONS.SVG_LIST} metadata={item} />
							</div>}
						</Column>
					);
				}

				if (id === ACTIONS_COLUMN) {
					return (
						<Column $stretchWidth={stretchWidth} key={`${item.id}_${id}`} className={classNames.join(" ")} >
							{renderItemOptions(item, showMenu, contentType, isMobileView, isMobileDevice, isBusiness, overwriteOptionsOnClick)}
						</Column>
					);
				}

				if (innerColumns) {
					return (
						<Column $stretchWidth={stretchWidth} key={`${item.id}_${id}`} className={classNames.join(" ")}>
							{renderSingleRow(innerColumns, item, showMenu, onSingleItemSelect, contentType, isLargeList, isMobileView, isMobileDevice, classList, selectedFolders, selectedFiles, overwriteOptionsOnClick)}
						</Column>
					);
				}

				const value = getFieldValue ? getFieldValue(item) : item[contentKey];
				const formattedValue = formatMethod ? formatMethod(value) : value;

				if (contentKey && (getFieldValue || item[contentKey])) {
					const innerClassNames = ["truncate"];
					if (clickable) {
						innerClassNames.push("clickable");
						classNames = classNames.filter(className => className !== "clickable");
					}

					let secLineContent = "";
					if (secondLine) {
						classNames.push('hasSecLine');
						const { secPrefix, secContentKey, secGetFieldValue, secFormatMethod, secShowOnMobileOnly } = secondLine;
						const secValue = secGetFieldValue ? secGetFieldValue(item) : item[secContentKey];

						if (isMobileDevice && secShowOnMobileOnly) {
							secLineContent = (
								<span className="subLine">
									{secPrefix ? __(...secPrefix) : ""}
									{secPrefix ? <>&nbsp;</> : ""}
									{secFormatMethod ? secFormatMethod(secValue) : secValue}
								</span>
							);
						}
					}

					let iconsAfterName = 0;
					let rightIcons = [];
					if (id === NAME_SUB) {
						if (isBusiness && !item.istime && !item.isdeleted && item.comments > 0) {
							iconsAfterName++;
						}
						rightIcons = useMemo(() => getItemRightIcons(item, shares, users), [item, shares, users]);
						iconsAfterName += rightIcons.length;

						if (iconsAfterName > 0) {
							innerClassNames.push("hasRightIcon");
							innerClassNames.push("fa-" + iconsAfterName + "-icons");
						}
					}

					return (
						<Column $stretchWidth={stretchWidth} key={`${item.id}_${id}`} className={classNames.join(" ")} >
							<span
								className={innerClassNames.join(" ")}
								data-id={item.id}
								onClick={onClick ? (event) => onClick(event, item) : () => { }}
								{...((ellipsisTooltip > 0 && formattedValue.length > ellipsisTooltip) ?
									{
										"data-tooltip-content": formattedValue,
										"data-tooltip-id": "cv-tooltip",
										"data-tooltip-delay-show": 500
									} : {})
								}
							>
								{formattedValue}

								{(iconsAfterName > 0) ? (
									<span className="rightIconsWrapper">
										{isBusiness && !item.istime && !item.isdeleted && item.comments > 0 ?
											<span className="rightIcon">
												<IconButton
													className={"listRow" + (isMobileDevice ? " mobile" : "")}
													onClick={(event) => !isMobileDevice && overwriteOptionsOnClick[SHOW_COMMENTS] ? overwriteOptionsOnClick[SHOW_COMMENTS]({ id: item.id }, undefined, undefined, event) : null}
													data-tooltip-content={(item.comments != 1 ? __("view_total_comments", "View %total% comments").replace("%total%", item.comments) : __("view_comment", "View comment"))}
													data-tooltip-id="cv-tooltip"
												>
													<i className="smallIcon lightColorIcon fa-solid fa-message" />
												</IconButton>
											</span>
											: null}
										{rightIcons.map((icon) => (
											<span
												key={icon.icon}
												className={icon.onClick === false ? "rightIcon defaultCursor" : "rightIcon"}
											>
												{(icon.onClick !== false && !isMobileDevice) ?
													<IconButton
														className={"listRow" + (isMobileDevice ? " mobile" : "")}
														onClick={(event) => {
															onSingleItemSelect(item, false, event);
															icon.onClick(event, item);
														}}
														{...(icon.tooltip !== false ?
															{
																"data-tooltip-content": __(...icon.tooltip),
																"data-tooltip-id": "cv-tooltip"
															} : {})
														}
													>
														<i className={"smallIcon lightColorIcon fa-solid " + icon.icon} />
													</IconButton>
													:
													<i
														className={"smallIcon lightColorIcon fa-solid " + icon.icon}
														onClick={(event) => { onSingleItemSelect(item, false, event) }}
														{...(icon.tooltip !== false ?
															{
																"data-tooltip-content": __(...icon.tooltip),
																"data-tooltip-id": "cv-tooltip"
															} : {})
														}
													/>
												}
											</span>
										))}
									</span>
								) : null}
							</span>
							{secLineContent}
						</Column>
					);
				}

				if (contentKey && !getFieldValue && !item[contentKey]) {
					return (
						<Column $stretchWidth={stretchWidth} key={`${item.id}_${id}`} className={classNames.join(" ")}>
							-
						</Column>
					);
				}
			}
			)}
		</React.Fragment>
	);
};

const ListRow = ({ data, index, style }) => {
	const { columns, showMenu, rowsCountBeforeItems, currentKeys, itemCount, contentData, contentType, onContentClick, onContentContextClick, onSingleItemSelect, selectedFolders, selectedFiles, headerGridItems, isLargeList, isMobileView, isMobileDevice, listFullWidth, overwriteOptionsOnClick } = data;
	const isHeaderRow = index < rowsCountBeforeItems - 1;
	if (isHeaderRow) {
		return (
			<div style={{ ...style, width: listFullWidth }}>
				{headerGridItems[index].element({ isMobileDevice: isMobileDevice })}
			</div>
		);
	}

	const classList = ["selectable"];
	const key = currentKeys[index - rowsCountBeforeItems];
	const item = contentData.items[key];

	if (item && (selectedFolders.has(item.id.toString()) || selectedFiles.has(item.id.toString()))) {
		classList.push("selected");
	}
	if (isLargeList) {
		classList.push("lgList");
	}
	if (isMobileDevice) {
		classList.push("mobile");
	}

	return (item ?
		<Row
			className={classList.join(" ")}
			key={`${item.id}_row`}
			data-id={item.id}
			onClick={onContentClick}
			onDoubleClick={TEMPLATES[contentType].onClick ? (event) => TEMPLATES[contentType].onClick(event, item) : () => { }}
			onContextMenu={onContentContextClick}
			style={{ ...style, width: listFullWidth }}
		>
			{renderSingleRow(columns, item, showMenu, onSingleItemSelect, contentType, isLargeList, isMobileView, isMobileDevice, classList, selectedFolders, selectedFiles, overwriteOptionsOnClick)}
		</Row>
		:
		(index === itemCount - 1 ? <Row
			className="credits"
			key="last-row"
			style={{ ...style, width: listFullWidth, top: "auto", bottom: 0 }}
		>
			<Credits />
		</Row> : null)
	);
};

const ItemWrapper = memo(({ data, index, style }) => {
	const { ItemRenderer, stickyIndices, ...rest } = data;

	if (stickyIndices && stickyIndices.includes(index)) {
		return null;
	}

	return <ItemRenderer index={index} style={style} data={rest} />;
}, areEqual);

const StickyRow = ({ isMobileDevice, style, showStickyBar, renderContentOptions, renderListHeader }) => (
	<StickyRowWrapperList style={style} className={(showStickyBar) ? 'sticky' : ''}>
		<div className={"optionsRow" + (isMobileDevice ? " mobile" : "")}>
			{renderContentOptions()}
		</div>
		{renderListHeader}
	</StickyRowWrapperList>
);

const innerElementType = forwardRef(({ children, style, ...rest }, ref) => (
	<StickyListContext.Consumer>
		{({ stickyIndices, showStickyBar, renderContentOptions, renderSelectByTypeMenu, headerGridItems, headerItemsHeight, contentType, sortIsSet, sortOptions, keysLen, selectedFolders, selectedFiles, onAllItemsSelect, onColumnSortClick, isMobileView, isMobileDevice, isLargeList, listFullWidth }) => (
			<div ref={ref} style={{ ...style, position: "relative", minHeight: "100%" }} {...rest}>
				{stickyIndices.map((index) => (
					<StickyRow
						isMobileDevice={isMobileDevice}
						showStickyBar={showStickyBar}
						renderContentOptions={renderContentOptions}
						renderListHeader={renderListHeader(TEMPLATES[contentType].COLUMNS, sortIsSet, sortOptions, keysLen, selectedFolders, selectedFiles, onAllItemsSelect, onColumnSortClick, renderSelectByTypeMenu, isMobileView, isMobileDevice, isLargeList)}
						key={index}
						style={{ top: headerItemsHeight, width: listFullWidth, height: headerGridItems[index].height }}
					/>
				))}

				{children}
			</div>
		)}
	</StickyListContext.Consumer>
));

const StickyList = ({ handleScroll, children, stickyIndices, showStickyBar, renderContentOptions, renderSelectByTypeMenu, headerGridItems, headerItemsHeight, contentType, isLargeList, sortIsSet, sortOptions, keysLen, selectedFolders, selectedFiles, onAllItemsSelect, onColumnSortClick, isMobileView, isMobileDevice, listFullWidth, listGridRef, itemData, scrollTo, ...rest }) => {
	const itemRowHeight = isLargeList ? folderListItemLargeRowHeight : folderListItemRowHeight;

	useEffect(() => {
		if (scrollTo && listGridRef && listGridRef.current) {
			const scrollToIndex = itemData.currentKeys.indexOf(scrollTo);
			if (scrollToIndex !== -1) {
				listGridRef.current.scrollToItem(scrollToIndex + itemData.rowsCountBeforeItems, "center");
			}
		}
	}, [scrollTo]);

	return (
		<StickyListContext.Provider value={{ ItemRenderer: children, stickyIndices, showStickyBar, renderContentOptions, renderSelectByTypeMenu, headerGridItems, headerItemsHeight, listGridRef, contentType, sortIsSet, sortOptions, keysLen, selectedFolders, selectedFiles, onAllItemsSelect, onColumnSortClick, isMobileView, isMobileDevice, isLargeList, listFullWidth }}>
			<VariableSizeList
				ref={listGridRef}
				className="listGridWindow"
				itemData={{ ItemRenderer: children, stickyIndices, showStickyBar, renderContentOptions, contentType, sortIsSet, sortOptions, keysLen, selectedFolders, selectedFiles, onAllItemsSelect, onColumnSortClick, headerGridItems, isMobileView, isMobileDevice, listFullWidth, ...itemData }}
				estimatedItemSize={itemRowHeight}
				onScroll={handleScroll}
				overscanCount={2}
				{...rest}
			>
				{ItemWrapper}
			</VariableSizeList>
		</StickyListContext.Provider>
	)
};

const FolderList = ({
	contentType,
	sortIsSet,
	sortOptions,
	isMobileView,
	isMobileDevice,
	columns,
	currentKeys,
	contentData,
	contentDataInitDone,
	onContentClick,
	onContentContextClick,
	onAllItemsSelect,
	onColumnSortClick,
	onSingleItemSelect,
	selectedFolders,
	selectedFiles,
	renderContentOptions,
	renderSelectByTypeMenu,
	noItemsLabel,
	listGridRef,
	hasExploreCards,
	scrollTo,
	overwriteOptionsOnClick
}) => {
	const keysLen = currentKeys.length;

	if (!contentDataInitDone) {
		return null;
	}

	if (!contentData || !contentData.items || !keysLen || !columns || !Object.keys(contentData.items).length) {
		return (
			<EmptyState noItemsLabel={noItemsLabel} />
		)
	}

	if (isMobileDevice) {
		folderListItemRowHeight = 56;
		folderListItemLargeRowHeight = 66;
	}
	const LIST_FULL_WIDTH = isMobileDevice ? '100%' : 'calc(100% - var(--spacing-sm))';
	const VIEW_SORT_ROW_HEIGHT = isMobileDevice ? 38 : 48;
	const FOOTER_HEIGHT = 60;
	const isLargeList = getViewType(contentType) === LARGE_LIST_VIEW;
	const { showMenu } = useMenu();
	const [showStickyBar, setShowStickyBar] = useState(true);
	const headerGridItems = [
		...(TEMPLATES[contentType].HEADER_PROMO_BLOCKS ? getHeaderElements(contentType, contentData, hasExploreCards) : []),
		{
			height: VIEW_SORT_ROW_HEIGHT + (isMobileDevice ? 0 : TABLE_HEAD_ROW_HEIGHT) // Views & sorting menus
		}
	];
	// if (TEMPLATES[contentType].VIEWS_MENU.length <= 1 && !TEMPLATES[contentType].SORTING_MENU) {
	// 	headerGridItems[headerGridItems.length - 1].height -= VIEW_SORT_ROW_HEIGHT;
	// }
	let headerItemsHeight = 0;
	headerGridItems.map((item) => {
		headerItemsHeight += item.height;
	});
	headerItemsHeight -= headerGridItems[headerGridItems.length - 1].height;
	let rowsCountBeforeItems = headerGridItems.length;

	const handleScroll = (e) => {
		const { scrollOffset } = e;

		if (showStickyBar && (scrollOffset < headerItemsHeight)) { //  || scrollDirection === "forward"
			setShowStickyBar(false);
		} else if (!showStickyBar && scrollOffset >= headerItemsHeight) { // scrollDirection === "backward" && 
			setShowStickyBar(true);
		}
	};

	const itemCount = keysLen + rowsCountBeforeItems + 1;

	const getRowHeight = useCallback((rowIndex) => {
		if (rowIndex < rowsCountBeforeItems) {
			return headerGridItems[rowIndex].height;
		} else {
			return isLargeList ? folderListItemLargeRowHeight : folderListItemRowHeight;
		}
	}, [headerGridItems, rowsCountBeforeItems]);

	return (
		<AutoSizer>
			{({ height, width }) => (
				<StickyList
					handleScroll={handleScroll}
					showStickyBar={showStickyBar}
					renderContentOptions={renderContentOptions}
					renderSelectByTypeMenu={renderSelectByTypeMenu}
					headerGridItems={headerGridItems}
					headerItemsHeight={headerItemsHeight}
					listGridRef={listGridRef}
					height={height}
					width={width}
					innerElementType={innerElementType}
					stickyIndices={[rowsCountBeforeItems - 1]}
					contentType={contentType}
					sortIsSet={sortIsSet}
					sortOptions={sortOptions}
					keysLen={keysLen}
					selectedFolders={selectedFolders}
					selectedFiles={selectedFiles}
					onAllItemsSelect={onAllItemsSelect}
					onColumnSortClick={onColumnSortClick}
					isMobileView={isMobileView}
					isMobileDevice={isMobileDevice}
					itemCount={itemCount}
					itemSize={getRowHeight}
					listFullWidth={LIST_FULL_WIDTH}
					scrollTo={scrollTo}
					isLargeList={isLargeList}
					itemData={{
						columns,
						showMenu,
						currentKeys,
						contentData,
						rowsCountBeforeItems,
						itemCount,
						onContentClick,
						onContentContextClick,
						onSingleItemSelect,
						isLargeList,
						overwriteOptionsOnClick
					}}
				>
					{ListRow}
				</StickyList>
			)}
		</AutoSizer>
	);
};

export default FolderList;
