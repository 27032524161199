import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import PulseAnimation from "./Components/PulseAnimation";
import PopupContainer from "./Components/PopupContainer";
import { AnimatePresence } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { ONBOARDING_REMOVE_ITEM } from "../../lib/state/constants/onboarding";
import {
    ONBOARDING_ID_ACCOUNT_SETTINGS,
    ONBOARDING_ID_CHATBOT,
    ONBOARDING_ID_DRAG_DROP,
    ONBOARDING_ID_UNLOCK_SPACE,
    ONBOARDING_ID_FILE_VIEW_OPTIONS,
    ONBOARDING_ID_UPLOAD_BUTTON
} from "./constants";
import { getOnboardingSkipName } from "./helpers";

export type PlaceTypes =
    | "top-left"
    | "top-right"
    | "bottom-left"
    | "bottom-right"
    | "left"
    | "right";

type Props = {
    id: string,
    content: Array<any>,
    children: any,
    readMore?: string,
    borderRadius?: string,
    delay?: number,
    temporaryHide?: boolean,
    showOverlay?: boolean,
    pulseAnimation?: boolean,
    pulseCircle?: boolean,
    place?: PlaceTypes,
    container?: HTMLElement
};

let stopInitialOnboarding = false;

const HighlightOnboardingComponent = ({
    hasDynamicSize = false,
    temporaryHide = false,
    notShowAfterClose = false,
    pulseAnimation = false,
    pulseCircle = false,
    borderRadius = "5px",
    showOverlay = true,
    readMore = null,
    delay = 0,
    zIndex = 0,
    id = null,
    content,
    place = "left",
    offset = 0,
    children,
    container = null,
    onStartVisible = () => {},
    onEndVisible = () => {}
}: Props) => {
    const [show, setShow] = useState(false);
    const parentRef = useRef(null);
    const onboarding = useSelector(({ onboarding }) => onboarding);
    const userid = useSelector(({ user }) => user.userinfo.userid);
    const onboardingQueue = onboarding.queue;
    const onboardingActive = onboarding.active;

    const dispatch = useDispatch();

    const INITIAL_EXECUTE_ORDER = [
        ONBOARDING_ID_UPLOAD_BUTTON,
        ONBOARDING_ID_DRAG_DROP,
        ONBOARDING_ID_FILE_VIEW_OPTIONS,
        ONBOARDING_ID_UNLOCK_SPACE,
        ONBOARDING_ID_ACCOUNT_SETTINGS,
        ONBOARDING_ID_CHATBOT
    ];

    const handleClose = (e, permanent = false, stopInitial = false) => {
        const onboardingSkipName = getOnboardingSkipName();
        setShow(false);
        onEndVisible();
        dispatch({ type: ONBOARDING_REMOVE_ITEM, id: id });

        if (stopInitial) {
            stopInitialOnboarding = true;
        }

        if (permanent || notShowAfterClose) {
            let onboardingskip = JSON.parse(
                localStorage.getItem(onboardingSkipName)
            );
            if (Array.isArray(onboardingskip)) {
                if (!onboardingskip.includes(id)) {
                    onboardingskip.push(id);
                    localStorage.setItem(
                        onboardingSkipName,
                        JSON.stringify(onboardingskip)
                    );
                }
            } else {
                onboardingskip = [id];
                localStorage.setItem(
                    onboardingSkipName,
                    JSON.stringify(onboardingskip)
                );
            }
        }
    };

    useEffect(() => {
        const onboardingSkipName = getOnboardingSkipName();
        let timer;

        if (!onboardingActive || onboardingSkipName === false) {
            return;
        }
        const onboardingskip = JSON.parse(
            localStorage.getItem(onboardingSkipName)
        );

        let finalQueue = Array.isArray(onboardingskip)
            ? onboardingQueue.filter((item) => !onboardingskip.includes(item))
            : onboardingQueue;

        if (stopInitialOnboarding) {
            finalQueue = finalQueue.filter((item) => !INITIAL_EXECUTE_ORDER.includes(item));
        }

        let targetItem = finalQueue.length ? finalQueue[0] : 0;

        if (!stopInitialOnboarding) {
            for (let i = 0; i < INITIAL_EXECUTE_ORDER.length; i++) {
                if (finalQueue.includes(INITIAL_EXECUTE_ORDER[i])) {
                    targetItem = INITIAL_EXECUTE_ORDER[i];
                    break;
                }
            }
        }

        if (!targetItem || targetItem !== id) {
            setShow(false);
            onEndVisible();
        } else if (onboardingQueue.length) {
            timer = setTimeout(() => {
                setShow(true);
                onStartVisible();
            }, delay);
        }

        return () => clearTimeout(timer);
    }, [onboardingQueue, onboardingActive, userid]);

    useEffect(() => {
        if (temporaryHide) {
            handleClose();
        }
    }, [temporaryHide]);

    if (!id || temporaryHide) {
        return <>{children}</>;
    }

    return (
        <Wrapper ref={parentRef}>
            {pulseAnimation && show && (
                <PulseAnimation
                    borderRadius={borderRadius}
                    pulseCircle={pulseCircle}
                />
            )}
            <AnimatePresence>
                {show && (
                    <PopupContainer
                        show={show}
                        onClose={handleClose}
                        content={content}
                        readMore={readMore}
                        parentRef={parentRef}
                        showOverlay={showOverlay}
                        hasDynamicSize={hasDynamicSize}
                        place={place}
                        container={container}
                        zIndex={zIndex}
                        offset={offset}
                    />
                )}
            </AnimatePresence>
            <Child $show={show}>{children}</Child>
        </Wrapper>
    );
};

export default HighlightOnboardingComponent;

const Wrapper = styled.div`
    position: relative;
`;

const Child = styled.div`
    position: relative;
    z-index: ${({ $show }) => ($show ? "1910" : "3")};
`;
