// @flow
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import Componentify from "react-componentify";

import apiMethod from "../../api/apiMethod";
import { __ } from "../../lib/translate";
import { styledSpanTagConverter } from "../../lib/componentifyConverters";
import { getBusinessAvatarSrc, getBusinessEntityName } from "../../lib/shareFolder-utils";

import ProgressLoader from "../ProgressLoader";
import Button from "../ButtonDefault";
import PasswordStrength, { getStrengthColor } from "../PasswordStrength";
import { TSpan } from "../intl";
import { TAB_INDEX, MIN_CRYPTO_PASS_STRENGTH } from "@pcloud/web-utilities/dist/config/constants";
import * as Style from "./StyleInviteFolder";
import * as StyleModal from "../Modals/styledComponents";
import { getNames } from "../../lib/user-utils";

const customConverter = Object.assign({}, styledSpanTagConverter, {
  props: {
    style: {
      display: "inline-block",
      color: "#17bed0",
      maxWidth: "300px",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap"
    }
  }
});

type Props = {
  folderId: number,
  usersWithoutCrypto: Array<any>,
  showCancelShare: boolean,
  setUsersWithoutCrypto: any => void,
  showManageTab: number => void,
  setShowCancelShare: boolean => void,
  setIsOpenModal: boolean => void
};

const InvitedWithoutCrypto = ({
  folderId,
  usersWithoutCrypto,
  setUsersWithoutCrypto,
  showManageTab,
  showCancelShare,
  setShowCancelShare,
  setIsOpenModal
}: Props) => {
  const token = useSelector(({ user }) => user.token);
  const message = useSelector(({ sharedFolders: { shareMessage } }) => shareMessage);
  const permissionId = useSelector(({ sharedFolders: { sharePermissionId } }) => sharePermissionId);
  const shareFolderName = useSelector(({ sharedFolders: { shareFolderName } }) => shareFolderName);
  const [strengthPassColor, setStrengthPassColor] = useState("grey");
  const [showCreatePassForm, setShowCreatePassForm] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [hint, setHint] = useState("");
  const [error, setError] = useState({ type: "", message: "" });
  const [usersList, setUsersList] = useState([]);
  const [usersCreatePass, setUsersCreatePass] = useState([]);

  const [progressLaoder, setProgressLaoder] = useState({
    message: "",
    load: false,
    total: 0
  });
  const [currentProgress, setCurrentProgress] = useState(0);
  const inputPass = useRef(null);
  const inputPassRepeat = useRef(null);
  const inputHint = useRef(null);

  const STRENGTH = {
    NONE: __("None"),
    WEAK: __("Weak"),
    MEDIUM: __("Medium"),
    STRONG: __("Strong")
  };

  const STRENGTH_PASS = {
    grey: { text: STRENGTH.NONE },
    red: { text: STRENGTH.WEAK },
    orange: { text: STRENGTH.MEDIUM },
    green: { text: STRENGTH.STRONG }
  };

  useEffect(() => {
    if (usersWithoutCrypto.length === 1) {
      setShowCreatePassForm(true);
      setUsersCreatePass([...usersWithoutCrypto]);
    }
    setUsersList([...usersWithoutCrypto]);
  }, [usersWithoutCrypto]);

  const resetCryptoForm = () => {
    setStrengthPassColor("grey");
    setShowCreatePassForm(false);
    setPassword("");
    setPasswordRepeat("");
    setHint("");
    setError({ type: "", message: "" });
    setUsersCreatePass([]);
  };

  const onRemoveShareClick = id => {
    setUsersList(prevUsers => {
      return prevUsers.filter(el => {
        const parseEl = getItemData(el);
        return getItemId(parseEl) !== id;
      });
    });
  };

  const getItemId = data => {
    const { userobj: { id: buserId } = {}, teamobj: { id: teamId } = {} } = data;
    return buserId || teamId || 0;
  };

  const getItemData = item => {
    const { buser = {}, bteam = {} } = item;
    let user = {};

    if (Object.keys(buser).length !== 0) {
      user = buser;
    } else if (Object.keys(bteam).length !== 0) {
      user = bteam;
    }
    return user;
  };

  const onContinueClick = () => {
    showManageTab(TAB_INDEX.MANAGE);
  };

  const onCancelClick = () => {
    setShowCancelShare(false);
  };

  const closeModalClick = () => {
    setIsOpenModal(false);
  };

  const onCreatePassClick = (e: any, user?: any) => {
    console.log("CREATE PASS>>>>>>>>>>>>>>, user", user);
    if (user) {
      setUsersCreatePass([user]);
      setShowCreatePassForm(true);
    } else {
      setUsersCreatePass([...usersWithoutCrypto]);
      setShowCreatePassForm(true);
    }
  };

  const onGoBackButtonClick = () => {
    resetCryptoForm();
  };

  // form handlers
  const isWeakPass = () => {
    const strength = pCloudCrypto.passwordStrength(password);
    return password.length < 8 || strength < MIN_CRYPTO_PASS_STRENGTH;
  };

  const passwordsMatch = () => {
    return password === passwordRepeat;
  };

  const validateHint = () => {
    console.log("HINT, PASS", { password, hint });
    return hint.trim().indexOf(password.trim()) === -1;
  };

  const updateErrorPass = (pass: string, passRepeat: string) => {
    if (passRepeat.length >= pass.length && passRepeat !== pass) {
      setError({ type: "pass", message: __("both passwords must be exact match.") });
    } else {
      setError({ type: "", message: "" });
    }
  };

  const onPasswordChange = () => {
    const pass = inputPass.current.value;
    const color = pass.length <= 5 ? "grey" : getStrengthColor(pass);
    setPassword(pass);
    setStrengthPassColor(color);
    updateErrorPass(pass, passwordRepeat);
  };

  const onPasswordRepeatChange = () => {
    const passwordRepeatValue = inputPassRepeat.current.value;

    setPasswordRepeat(passwordRepeatValue);
    updateErrorPass(password, passwordRepeatValue);
  };

  const onHintChange = () => {
    setHint(inputHint.current.value);
    setError({ type: "", error: "" });
  };

  const canSubmit = () => {
    return !isWeakPass() && passwordsMatch();
  };

  const onEnter = event => {
    if (event.keyCode === 13) {
      onCreatePassSubmit();
    }
  };

  const onCreatePassSubmit = () => {
    console.log("onCreatePassSubmit");
    if (isWeakPass()) {
      return;
    }

    if (!passwordsMatch()) {
      setError({ type: "pass", message: __("both passwords must be exact match.") });
      return;
    }

    if (!validateHint()) {
      setError({ type: "hint", message: __("Your Hint must not contain the Passphrase.") });
      return;
    }

    setProgressLaoder({
      load: true,
      message: __("share_folder_modal_generating_key", "Generating encryption key..."),
      total: 0
    });

    pCloudCrypto.createTempCryptoKeys(password, ({ privatekey, signature }) => {
      const params = {
        auth: token,
        folderid: folderId,
        hint,
        privatekey,
        signature,
        message,
        permissions: permissionId,
        name: shareFolderName
      };

      setProgressLaoder({
        load: true,
        message: __("share_folder_modal_sending_invitation", "Sharing..."),
        total: usersCreatePass.length
      });

      let xhr = [];
      let error = "";
      let counter = 0;

      console.log("usersCreatePass>>>>>>", usersCreatePass);
      usersCreatePass.forEach(user => {
        const item = getItemData(user);
        const itemId = getItemId(item);
        const { userobj, teamobj } = item;
        let methodName = "sharefolder";

        if (userobj) {
          params.mail = userobj.email;
        } else if (teamobj) {
          params.teamid = teamobj.id;
          methodName = "account_teamshare";
        } else {
          throw Error("Invalid useage neither email nor teamId teamid");
        }

        xhr.push(
          apiMethod(
            methodName,
            params,
            res => {
              setCurrentProgress(prevProgress => ++prevProgress);
              onRemoveShareClick(itemId);
              counter++;
            },
            {
              type: "post",
              errorCallback: error => {
                error = error.error;
                setCurrentProgress(prevProgress => ++prevProgress);
                onRemoveShareClick(itemId);
                counter++;
              }
            }
          )
        );
      });

      $.when.apply($, xhr).then(() => {
        console.log("finish invite");
        setShowCancelShare(false);
        if (error) {
          showManageTab(TAB_INDEX.INVITE);
        } else if (usersList.length === counter) {
          showManageTab(TAB_INDEX.MANAGE);
        } else {
          resetCryptoForm();
          setProgressLaoder({ load: false });
        }
      });
    });
  };

  // render
  const renderCancelShare = () => {
    return (
      <Style.CancelShareWrapper>
        <Style.StopAccessTitle>{__("Cancel Share")}</Style.StopAccessTitle>
        <Style.MessageCancel>
          {__(
            "share_folder_modal_cancel_share_msg",
            "These recipients won't have access to this folder. Are you sure you want to proceed?"
          )}
        </Style.MessageCancel>
        <StyleModal.Footer>
          <Button size="big" color="lightgray4" style={{ marginRight: "5px" }} onClick={onCancelClick}>
            {__("Cancel")}
          </Button>
          <Button size="big" color="red" style={{ marginLeft: "5px" }} onClick={closeModalClick}>
            {__("Yes")}
          </Button>
        </StyleModal.Footer>
      </Style.CancelShareWrapper>
    );
  };

  const renderWarningMesaage = (type?: string) => {
    let message = __("share_folder_modal_no_crypto_message");

    if (type === "create") {
      message = __(
        "share_folder_modal_create_crypto_pass_msg",
        "Enter a secure Crypto Pass that's different from your own Crypto Pass or account password. Don't forget to share the Crypto Pass with the members in order to provide access."
      );
    }

    return (
      <Style.MessageWrapper>
        <Style.Message>{message}</Style.Message>
      </Style.MessageWrapper>
    );
  };

  const renderRow = data => {
    const item = getItemData(data);
    const id = getItemId(item);

    return (
      <Style.Row key={item.value}>
        <Style.EmailWrapper type="type">
          <Style.Avatar src={getBusinessAvatarSrc(item)} />
          <Style.Email>{getBusinessEntityName(item)}</Style.Email>
        </Style.EmailWrapper>

        <Style.ButtonWrapper>
          <Style.CreatePassButton onClick={e => onCreatePassClick(e, data)}>
            {__("share_folder_modal_set_pass_btn", "Set Pass")}
          </Style.CreatePassButton>
          <Style.RemoveButton onClick={() => onRemoveShareClick(id)} />
        </Style.ButtonWrapper>
      </Style.Row>
    );
  };

  const renderButton = () => {
    let onClick = onContinueClick;
    let buttonText = __("Continue");

    if (usersList.length !== 0) {
      onClick = onCreatePassClick;
      buttonText = "share_folder_modal_set_pass_all_btn";
    }

    return (
      <Button color="cyan" size="big" onClick={onClick}>
        <TSpan id={buttonText} style={{ color: "#fff", minWidth: "180px" }} />
      </Button>
    );
  };

  const rednerCreatePassTitile = () => {
    let text = __("share_folder_modal_crypto_form_title_user_all");

    if (usersCreatePass.length === 1) {
      const item = getItemData(usersCreatePass[0]);
      const itemName = item.hasOwnProperty('userobj') ? getNames(item.userobj) : item.name;
      text = itemName ? __("share_folder_modal_crypto_form_title_user").replace("%name%", itemName) : __("Set Crypto Pass");
    }

    return (
      <Style.TitleCreatePass>
        <Componentify text={text} converters={[styledSpanTagConverter]} />
      </Style.TitleCreatePass>
    );
  };

  const renderProgressLoader = () => {
    const { load, total, message } = progressLaoder;
    const props: { message: String, total?: Number, current?: number } = { message };
    if (total) {
      props.total = total;
    }

    if (currentProgress) {
      props.current = currentProgress;
    }
    return load ? <ProgressLoader {...props} /> : null;
  };

  const renderCreatePassForm = () => {
    const strengthText = STRENGTH_PASS[strengthPassColor].text;

    return (
      <Style.WrapperCreatePass>
        {renderProgressLoader()}
        {rednerCreatePassTitile()}
        {renderWarningMesaage("create")}
        <Style.WrapperLabel>
          <Style.Label id="Crypto Pass">Crypto Pass</Style.Label>
          <Style.Strength color={strengthPassColor}>{strengthText}</Style.Strength>
        </Style.WrapperLabel>
        <Style.Input
          ref={inputPass}
          type="password"
          name="password"
          value={password}
          onChange={onPasswordChange}
          autoComplete="off"
          autoFill="off"
          onKeyDown={onEnter}
        />
        <Style.PassStrengWrap>
          <PasswordStrength password={password} />
        </Style.PassStrengWrap>
        <Style.WrapperLabel>
          <Style.Label id="Repeat Crypto Pass">Repeat Crypto Pass</Style.Label>
          {error.type === "pass" ? (
            <Style.ErrorMessage>{error.message}</Style.ErrorMessage>
          ) : null}
        </Style.WrapperLabel>
        <Style.WrapperInput>
          <Style.Input
            ref={inputPassRepeat}
            type="password"
            name="passwordRepeat"
            value={passwordRepeat}
            onChange={onPasswordRepeatChange}
            autoComplete="off"
            autoFill="off"
            onKeyDown={onEnter}
          />
          {canSubmit() ? <Style.Checkmark /> : null}
        </Style.WrapperInput>
        <Style.WrapperLabel>
          <Style.Label id="Hint (optional):">Hint (optional):</Style.Label>
          {error.type === "hint" && hint ? (
            <Style.ErrorMessage>{error.message}</Style.ErrorMessage>
          ) : null}
        </Style.WrapperLabel>
        <Style.Input
          className="hint"
          ref={inputHint}
          type="text"
          name="hint"
          value={hint}
          onChange={onHintChange}
          autoComplete="off"
          autoFill="off"
          onKeyDown={onEnter}
        />
        <Button
          inline
          color={!canSubmit() ? "gray" : "cyan"}
          disabled={!canSubmit()}
          onClick={onCreatePassSubmit}
        >
          {__("Set Crypto Pass")}
        </Button>
        {usersList.length !== 1 ? (
          <Style.GoBackButton onClick={onGoBackButtonClick}>
            {__("crypto_share_pass_goback_button", "Go back")}
          </Style.GoBackButton>
        ) : null}
      </Style.WrapperCreatePass>
    );
  };

  if (showCancelShare) {
    return renderCancelShare();
  }

  if (showCreatePassForm) {
    return renderCreatePassForm();
  }

  const title = __("share_folder_share_for").replace("%foldername%", shareFolderName);

  return (
    <Style.CryptoPassWrapper>
      <StyleModal.Header>
        <Componentify text={title} converters={[customConverter]} />
      </StyleModal.Header>
      {renderWarningMesaage()}
      <Style.Label id="share_folder_modal_require_crypto_pass">
        "Require a Crypto Pass:"
      </Style.Label>
      {usersList.length ? (
        <Style.Table>{usersList.map(renderRow)}</Style.Table>
      ) : (
        <Style.EmptyTable>
          {__(
            "share_folder_modal_no_users_require_pass",
            "There are no users or teams that require a temporary Crypto Pass."
          )}
        </Style.EmptyTable>
      )}
      <Style.Footer>{renderButton()}</Style.Footer>
    </Style.CryptoPassWrapper>
  );
};

export default InvitedWithoutCrypto;
