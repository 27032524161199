// @flow

import React from "react";
import styled from "styled-components";

import SuccessIcon from "../../../web-shared/images/svg/success-icon.svg";
import { __ } from "../../lib/translate";

const CancelSuccessStep = () => {
  return (
    <Container>
      <SuccessIcon width="64px" fill="#17bed0" />
      <Header>{__("change_region_cancel_success")}</Header>
    </Container>
  );
};

export default CancelSuccessStep;

const Container = styled.div`
  width: 640px;
  text-align: center;
  font-family: "Roboto", sans-serif;
  border-radius: 15px;
  box-shadow: 0px 10px 20px 0 rgba(0, 0, 0, 0.3);
  background-color: #ffffff;
  padding: 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

    @media (max-width: 700px) {
        width: 95vw;
        padding: 30px;
    }
`;

const Header = styled.div`
  font-size: 19px;
  font-weight: 500;
  text-align: center;
  margin-top: 35px;
`;
