// @flow

import React, { useState, useEffect, useRef, useCallback } from "react";
import styled from "styled-components";

import Modal, { OvalModalWrap, CloseModalIcon } from "../Modal";
import Picker from "../Picker/Picker";
import * as Style from "./styledComponents";

import { __ } from "../../lib/translate";
import { errorKeys } from "../../lib/errors";
import apiMethod from "../../api/apiMethod";
import InputText from "../InputText";
import hashManager from "../../lib/hashManager";

const errors = [2001, 2023, 2282, 2341, 2020];
const errorsCloseModal = [2076, 2022, 2021, 2002, 2024, 2005, 2045, 2351];

type AcceptShareModalProps = {
  share: any,
  callback: () => void
};

const AcceptShareModal = ({ share = {}, callback = () => {} }: AcceptShareModalProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [folderName, setFolderName] = useState(
    "sharename" in share ? share.sharename : share.foldername
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsOpen(true);
  }, []);

  const onEnter = useCallback((e: any) => {
    if (e.keyCode === 13) {
      acceptShareButtonRef.current.onChooseButtonClick();
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      console.log("Add keyup event AcceptShareModal");
      document.addEventListener("keyup", onEnter);
    } else {
      console.log("Remove keyup event AcceptShareModal");
      document.removeEventListener("keyup", onEnter);
    }
  }, [isOpen, onEnter]);

  const handleClose = () => {
    setIsOpen(false);
  };

  const onFolderNameChange = ({ target }) => {
    setFolderName(target.value);
  };

  const onPick = ({ data, isChecked: shouldOpenAfter = false }) => {
    const folderNameTrimed = folderName.trim();
    if (folderNameTrimed.length < 1) {
      HFN.message(__("Folder name is required."), "error");
      return;
    }

    const regex = new RegExp('[><"/]+');
    if (regex.test(folderNameTrimed)) {
      HFN.message(__("share_folder_modal_error_folder_name_special_chars"), "error");
      return;
    }

    if (isLoading) {
      return;
    }
    setIsLoading(true);

    const { itemId = -1 } = data;
    const params = {
      sharerequestid: share.sharerequestid,
      name: folderNameTrimed
    };

    if (share.sharerequestid) {
      params.sharerequestid = share.sharerequestid;
    } else if (share.code) {
      params.code = share.code;
    } else {
      HFN.message(__("System error, accept share."), "error");
      setIsLoading(false);
      return;
    }

    if (itemId != -1) {
      params.folderid = itemId;
    }

    apiMethod(
      "acceptshare",
      params,
      ret => {
        HFN.message(__("Share is accepted."));
        console.log(ret);
        handleClose();
        if (shouldOpenAfter && ret.metadata && ret.metadata.parentfolderid >= 0) {
          hashManager.pushState({
            page: "filemanager",
            folder: ret.metadata.parentfolderid,
            file: ret.metadata.id
          });
        } else if (callback) {
          callback();
        }
      },
      {
        errorCallback: ({ result, error }) => {
          setIsLoading(false);
          if (errors.indexOf(result) !== -1) {
            HFN.message(__(errorKeys[result]), "error");
          } else if (errorsCloseModal.indexOf(result)) {
            if (result === 2024) {
              // API message is: User already has access to this folder or subfolder of this folder.
              // Display message: Accepting this shared folder failed. You already have access to its parent or sub folder.
              result = "2024_acceptshare";
            }
            HFN.message(__(errorKeys[result]), "error");
            handleClose();
          } else {
            handleClose();
            HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
            throw new Error(error);
          }
        },
        showErrorMessage: false
      }
    );
  };

  const opts = {
    canCreateFolders: true,
    shouldRenderCheckbox: true,
    checkboxName: "accept_share_modal_checkbox_text", // Open the shared folder after accepting the invitation
    buttonText: "Accept Share"
  };

  const acceptShareButtonRef = useRef();
  return (
    <Modal animate onClose={handleClose} opened={isOpen}>
      <OvalModalWrap>
        <CloseModalIcon onClick={handleClose} />
        <Style.Container>
          <HeaderCopy>{__("Accept Share")}</HeaderCopy>
          <LabelCopy>{__("Folder name")}</LabelCopy>
          <InputText
            name="foldername"
            placeholder={__("Folder name")}
            onChange={onFolderNameChange}
            value={folderName}
            style={{ marginBottom: "17px" }}
            autoFocus
            onEnter={onEnter}
          />
          <LabelCopy>{__("accept_share_modal_choose_location", "Choose location")}</LabelCopy>
          <Picker
            folder={{ folderid: 0, name: "/", contents: null }}
            onPick={onPick}
            onCancel={handleClose}
            opts={opts}
            ref={acceptShareButtonRef}
          />
        </Style.Container>
      </OvalModalWrap>
    </Modal>
  );
};

export default AcceptShareModal;

const LabelCopy = styled(Style.Label)`
  height: 18px;
  margin-bottom: 5px;
`;

const HeaderCopy = styled(Style.Header)`
  height: 26px;
  margin-bottom: 16px;
`;
