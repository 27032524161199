import React from 'react';
import styled from 'styled-components';

const RadioButton = ({ label, sublabel, name, value, onChange, checked }) => {
  const handleClick = () => {
    if (!checked) {
      onChange({ target: { name, value } });
    }
  };

  return (
    <RadioWrapper onClick={handleClick}>
      <HiddenRadio
        type="radio"
        name={name}
        value={value}
        onChange={onChange}
        checked={checked}
      />
      <StyledRadio checked={checked} />
      <LabelsContainer>
        <RadioLabel>{label}</RadioLabel>
        {sublabel && <RadioSublabel>{sublabel}</RadioSublabel>}
      </LabelsContainer>
    </RadioWrapper>
  );
};

export default RadioButton;

const RadioWrapper = styled.label`
  display: block;
  position: relative;
  margin-bottom: 16px;
  cursor: pointer;
  user-select: none;
  padding: 3px 0 0 40px;

  -webkit-tap-highlight-color: transparent;

  &:focus, &:active {
    background-color: transparent; /* Or maintain the original background */
    outline: none;
  }
`;

const LabelsContainer = styled.label`
  display: flex;
  flex-direction: column;
`

const RadioLabel = styled.label`
  text-align: left;
  font-size: 14px;
  color: #000000;
`

const RadioSublabel = styled.label`
  text-align: left;
  font-size: 11px;
  color: #8C959D;
`

const HiddenRadio = styled.input`
  opacity: 0;
  position: absolute;
  cursor: pointer;

  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

const StyledRadio = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid ${({ checked }) => (checked ? "#17BED0" : "#1C1B1F")};
  box-sizing: border-box;

  &::after {
    content: '';
    position: absolute;
    display: ${({ checked }) => (checked ? "block" : "none")};
    top: 3px;
    left: 3px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #17BED0;
  }

  &:focus, &:active {
    background-color: transparent;
    outline: none;
    box-shadow: none;
  }
`;