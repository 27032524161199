import { useEffect, useRef, useState } from "react";
import { getHashComment } from "../../../lib/utils";
import { COMMENT, SHOW_COMMENTS, DEFAULT_SELECTED_ITEMS_INFO_TAB } from "../../../config/constants";
import { useSelector, useDispatch } from "react-redux";
import { setItemsInfoPanelData } from "../../../lib/state/actions/content";
import { sendGAEvent } from "../../../config/options";


const useItemsInfo = ({
  contentData,
  currentFolderData,
  selectedFiles, 
  selectedFolders, 
  contentDataInitDone,
  onSingleItemSelect,
  isMobileView,
  isBusiness
}) => {
  const dispatch = useDispatch();
  const itemsInfoData = useSelector((({ content }) => content.itemsInfoPanelData));

  const itemKeyToLandOnItemInfoCommentsTab = useRef(isBusiness ? getHashComment() : null);
  const selectedItemsInfoTab = useRef(itemKeyToLandOnItemInfoCommentsTab.current ? "comments" : DEFAULT_SELECTED_ITEMS_INFO_TAB);
  const autoFocusItemsInfoAddComment = useRef(false);
  const forceSelectedTabChange = useRef(false);
  //const [itemsInfoData, setItemsInfoData] = useState({show: !!itemKeyToLandOnItemInfoCommentsTab.current, tab: selectedItemsInfoTab.current, selectedItemsCount: 1, selectedItem: null});

  const [onAddOrViewCommentsClick, setOnAddOrViewCommentsClick] = useState();

  const OVERWRITE_OPTIONS_ONCLICK = {
    // onAddCommentClick
    [COMMENT]: (list, type, location) => {
      const data = list.length >= 1 ? list[0] : list;
      const { id } = data;

      sendGAEvent("add comment", location);

      setOnAddOrViewCommentsClick({itemId: id, action: COMMENT});
    },
    // onViewCommentsClick (icon -> FolderList or context menu -> option "View comments" for mobile devices)
    [SHOW_COMMENTS]: (list, type, location, event) => {
      // Prevent default selection of clicked list row.
      const data = list.length >= 1 ? list[0] : list;
      const { id } = data;

      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }

      sendGAEvent("view comments", location);

      setOnAddOrViewCommentsClick({itemId: id, action: SHOW_COMMENTS});
    }
  };

  useEffect(() => {
    if (!onAddOrViewCommentsClick) {
      return;
    }

    const { itemId, action } = onAddOrViewCommentsClick;
    if (!contentData.items[itemId]) {
      return;
    }

    selectedItemsInfoTab.current = "comments";
    autoFocusItemsInfoAddComment.current = action === COMMENT;
    forceSelectedTabChange.current = true;

    triggerOpenItemsInfo(contentData.items[itemId]);
  }, [onAddOrViewCommentsClick]);

  /* Temporary disable ItemsInfo Information tab side panel until it's ready.
  useEffect(() => {
    if (!contentDataInitDone) {
      return;
    }

    // Handle with &comments=<id> param in opened URL.
    if (itemKeyToLandOnItemInfoCommentsTab.current && (contentData.items[itemKeyToLandOnItemInfoCommentsTab.current] || (currentFolderData && currentFolderData.id === itemKeyToLandOnItemInfoCommentsTab.current))) {
      triggerOpenItemsInfo(contentData.items[itemKeyToLandOnItemInfoCommentsTab.current] || currentFolderData);
      itemKeyToLandOnItemInfoCommentsTab.current = null;
      return;
    }

    if (isMobileView) {
      return;
    }

    if (selectedFiles.size > 0 || selectedFolders.size > 0) {
      openItemsInfo(selectedFiles.size + selectedFolders.size === 1 ? ((selectedFiles.size > 0 ? selectedFiles : selectedFolders).values().next().value) : null, selectedFiles.size + selectedFolders.size);
    } else {
      closeItemsInfo();
    }

    // Next time we want to open the default info tab.
    selectedItemsInfoTab.current = DEFAULT_SELECTED_ITEMS_INFO_TAB;
    autoFocusItemsInfoAddComment.current = false;
    // If we deselect all -> force going to default tab next time.
    forceSelectedTabChange.current = selectedFiles.size == 0 && selectedFolders.size == 0;

  }, [selectedFiles, selectedFolders, contentDataInitDone]);

  const triggerOpenItemsInfo = (item) => {
    // !isMobileView -> onSingleItemSelect triggers useEffect hook which will openItemsInfo.  
    // isMobileView -> deselect others and select only one, because if we resize from mobileView to non mobile we should see only 1 selected.
    if (contentData.items[item.id]) {
      onSingleItemSelect(item);
    } 

    if (isMobileView || !contentData.items[item.id]) {
      openItemsInfo(item, 1);
    }
  };
  */

  // Temporary disable ItemsInfo Information tab side panel until it's ready. -> remove this useEffect.
  useEffect(() => {
    if (!contentDataInitDone) {
      return;
    }

    if (itemKeyToLandOnItemInfoCommentsTab.current && (contentData.items[itemKeyToLandOnItemInfoCommentsTab.current] || (currentFolderData && currentFolderData.id === itemKeyToLandOnItemInfoCommentsTab.current))) {
      triggerOpenItemsInfo(contentData.items[itemKeyToLandOnItemInfoCommentsTab.current] || currentFolderData);
      itemKeyToLandOnItemInfoCommentsTab.current = null;
    }
  }, [contentDataInitDone]);

  // Temporary disable ItemsInfo Information tab side panel until it's ready. -> remove this triggerOpenItemsInfo.
  const triggerOpenItemsInfo = (item) => {
    openItemsInfo(item, 1);
  };

  const openItemsInfo = (newSelectedItem, totalSelected) => {
    dispatch(setItemsInfoPanelData({
      show: totalSelected > 0,
      // Check if we are on the same item, don't change the selected tab.
      tab: !forceSelectedTabChange.current && newSelectedItem && itemsInfoData.selectedItem && newSelectedItem.id === itemsInfoData.selectedItem.id ? itemsInfoData.tab : selectedItemsInfoTab.current, 
      selectedItemsCount: (totalSelected) || 1,
      // Copy to trigger reload initial data.
      selectedItem: {...newSelectedItem},
      autoFocus: {value: autoFocusItemsInfoAddComment.current}
    }));
  };

  const closeItemsInfo = () => {
    dispatch(setItemsInfoPanelData(
      {...itemsInfoData, show: false}
    ));
  };

  return {
    OVERWRITE_OPTIONS_ONCLICK,
    initialItemKey: itemKeyToLandOnItemInfoCommentsTab.current
  }
};

export default useItemsInfo;