// @flow

import React, { useState, useEffect, useCallback, useRef, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { hiDPI } from "polished";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import Modal, { OvalModalWrap, OvalModalCloseSimple } from "../../components/Modal";
import ShareSettingsModalHeader from "./ShareSettingsModalHeader";
import ShareUploadSetting from "./ShareUploadSetting";
import ShareInviteEmailSetting from "./ShareInviteEmailSetting";
import ExpirationDateSetting from "./ExpirationDateSetting";
import PasswordProtectionSetting from "./PasswordProtectionSetting";
import BrandingUpgradeSetting from "./BrandingUpgradeSetting";
import DeleteLinkSetting from "./DeleteLinkSetting";
import ShortLinkSetting from "./ShortLinkSetting";
import LinkStatsSetting from "./LinkStatsSetting";
import DownloadRestrictionSetting from "./DownloadRestrictionSetting";
import { SlideAnimationWrapper } from "./SharedStyledComponents";

import { __ } from "../../lib/translate";
import { formatDt } from "../../lib/utils";
import { getEmailsWithAccessApiCall } from "./utils";
import { PUBLINK_TYPES } from "@pcloud/web-utilities/dist/config/constants";

import {
  defaultBoddyHeight,
  defaultSettingHeight,
  defaultHeaderHeight,
  defaultFooterHeight
} from "./DefaultValues";
import useWindowDimensions from "../../lib/hooks/useWindowDimensions";

const modalStyle = {
  width: "620px",
  overflow: "hidden",
  transition: "height 500ms ease-in-out",
  height: 0
};

type SettingsItem = {
  id: string,
  icon: Class<React$Component<any, any>>,
  title: string,
  description: string,
  status: string
};

type Props = {
  brandingData: {
    isActiveCL: boolean,
    isActiveGL: boolean
  },
  onBrandingButtonClick: () => void,
  onClose: () => void,
  container: any
};

const ShareSettingsModal = ({ brandingData = {}, onBrandingButtonClick, onClose, container }: Props) => {
  const token = useSelector(({ user }) => user.token);
  const userIsPremium = useSelector(({ user }) => user.userinfo.premium);
  const userIsBusiness = useSelector(({ user }) => user.userinfo.business);
  const linkData = useSelector(({ sharedLinks }) => sharedLinks.linkData);
  const {
    code,
    linkName,
    linkId,
    linkUrl,
    shortLinkUrl,
    type,
    hasPassword,
    canDownload = false,
    expirationDate,
    uploadSettings,
    emailsWithUploadAccess
  } = linkData;
  const { canEveryoneUpload, isRestrictedUpload } = uploadSettings;
  const [isOpen, setIsOpen] = useState(false);
  const [currentSettingId, setCurrentSettingId] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("");
  const [bodyHeight, setBodyHeight] = useState(0);
  const [modalHeight, setModalHeight] = useState(0);

  const modalContainer = useRef(null);
  const settingElement = useRef(null);
  const { bodyWidth } = useWindowDimensions();
  const dispatch = useDispatch();

  useEffect(() => {
    onOpen();
  }, []);

  useEffect(() => {
    const status = getUploadStatus();
    setUploadStatus(status);
  }, [emailsWithUploadAccess, uploadSettings, getUploadStatus]);

  useEffect(() => {
    if (isRestrictedUpload) {
      getEmailsWithAccessApiCall(token, linkId, onSuccessEmailsWithAccess);
    }
  }, [onSuccessEmailsWithAccess, isRestrictedUpload, token, linkId]);

  useLayoutEffect(() => {
    let height = 0;
    let settingHeight = 0;

    if (settingElement.current) {
      settingHeight = settingElement.current.offsetHeight;
    }

    if (currentSettingId == null) {
      height = (bodyHeight || defaultBoddyHeight) + defaultHeaderHeight + defaultFooterHeight;
    } else {
      height = (settingHeight || defaultSettingHeight) + defaultHeaderHeight;
    }

    setModalHeight(height);
  }, [bodyHeight, currentSettingId]);

  const measuredBodyHeightRef = useCallback(node => {
    if (node !== null) {
      const newBodyHeight = node.getBoundingClientRect().height;
      setBodyHeight(newBodyHeight);
      setModalHeight(newBodyHeight + defaultHeaderHeight + defaultFooterHeight);
    }
  }, []);

  const getUploadStatus = useCallback(numberOfEmails => {
    if (canEveryoneUpload) {
      return __("upload_setting_status_anyone", "Anyone").toLowerCase();
    } else if (isRestrictedUpload) {
      return renderRestrictedUsers(numberOfEmails);
    } else {
      return __("off", "Off").toLowerCase();
    }
  });

  const getBrandingStatus = () => {
    const { isActiveCL, isActiveGL } = brandingData;
    let status = __("off").toLowerCase();

    if (isActiveGL) {
      status = __("branding_general", "General").toLowerCase();
    }

    if (isActiveCL) {
      status = __("branding_custom", "Custom").toLowerCase();
    }

    return status;
  };

  const onSuccessEmailsWithAccess = list => {
    const status = getUploadStatus(list.length);
    dispatch({ type: "SET_EMAILS_WITH_UPLOAD_ACCESS", emails: list });
    setUploadStatus(status);
  };

  const onOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    if (typeof onClose === "function") {
      onClose();
    }
    setIsOpen(false);
  };

  const getSettingsComponents = () => {
    const settingsComponents = {
      upload: ShareUploadSetting,
      download: DownloadRestrictionSetting,
      password: PasswordProtectionSetting,
      expdate: ExpirationDateSetting,
      delete: DeleteLinkSetting,
      shortlink: ShortLinkSetting,
      share: ShareInviteEmailSetting,
      stats: LinkStatsSetting,
      brandingupgrade: BrandingUpgradeSetting,
    };

    return settingsComponents;
  };

  const getSettingsItems = (): Array<SettingsItem> => {
    const settings = [
      {
        id: "download",
        free: 1,
        icon: DownloadIcon,
        title: __("link_settings_download_preview", "Download"),
        description: __(
          "link_settings_download_description",
          "Allow anyone with this link to preview and download."
        ),
        status: canDownload ? __("on", "On").toLowerCase() : __("download_setting_preview_only", "Preview Only").toLowerCase()
      },
      {
        id: "password",
        free: 0,
        icon: PasswordIcon,
        title: __("link_settings_password", "Password protection"),
        description: __(
          "link_settings_password_description",
          "Secure this link by setting up a password."
        ),
        status: hasPassword ? __("on", "On").toLowerCase() : __("off", "Off").toLowerCase()
      },
      {
        id: "expdate",
        free: 0,
        icon: ExpirationIcon,
        title: __("link_settings_exp_date", "Expiration date"),
        description: __(
          "link_settings_exp_date_description",
          "Set an expiration date for this link. Once the link expires, it will not be accessible."
        ),
        status: expirationDate ? formatDt(expirationDate) : __("off", "Off").toLowerCase()
      },
      {
        id: "branding",
        free: 0,
        icon: BrandingIcon,
        title: __("branding-left-menu-title", "Branding"),
        description: __(
          "branding_share_tab",
          "Personalize this link with your own cover, logo and message."
        ),
        status: getBrandingStatus()
      }
    ];

    if (type === PUBLINK_TYPES["FOLDER"]) {
      settings.unshift({
        id: "upload",
        free: 1,
        icon: UploadIcon,
        title: __("link_settings_upload", "Upload"),
        description: __(
          "link_settings_upload_description",
          "Allow users to upload files to your account from this link."
        ),
        status: uploadStatus || __("off", "Off").toLowerCase()
      });
    }

    return settings;
  };

  const renderRestrictedUsers = numberOfEmails => {
    return (
      <RestrictionWrapper>
        {numberOfEmails || emailsWithUploadAccess.length}
        <RestrictionIcon />
        {__("upload_setting_status_restricted", "Restricted")}
      </RestrictionWrapper>
    );
  };

  const onBackClick = () => {
    setCurrentSettingId(null);
  };
  const getFeatureType = (id, free) => {
    if (id === "branding") {
      return ((brandingData?.brandedLinks > 0 && !brandingData.isActiveCL) || (brandingData?.brandedLinks > 1 && brandingData.isActiveCL) || brandingData.isActiveGL || userIsPremium || userIsBusiness) ? "premium" : "free"
    }

    return free ? "free" : "premium";
  }

  const onRowClick = (e: any, id: string, free: number) => {
    if (typeof gtag === "function") {
      gtag("event", "shared_link_settings_click", {
        action: `initiate ${id} setting change`,
        category: "setting",
        type: getFeatureType(id, free),
        user_premium: (userIsPremium || userIsBusiness) ? 1 : 0,
        location: "settings modal"
      })
    }
    if (id === "branding") {
      var result = onBrandingButtonClick(e);
      if (result === 'upgrade_to_premium') {
        setCurrentSettingId('brandingupgrade');
      } else {
        setIsOpen(false);
      }
    } else {
      setCurrentSettingId(id);
    }
  };

  const allSettings = (
    <AllSettingsWraper onClick={onBackClick}>{__("shared_links_all_settings")}</AllSettingsWraper>
  );

  const setOverflow = value => {
    modalContainer.current.style.overflow = value;
  };

  const renderRow = ({ icon, title, description, status, id, free }: SettingsItem) => {
    const Icon = icon;

    return (
      <Row key={title} onClick={e => onRowClick(e, id, free)}>
        <Icon />
        <TextWrapper>
          <RowTitle>{title}</RowTitle>
          <RowDescription>{description}</RowDescription>
        </TextWrapper>
        <Status>
          <StatusText>{status}</StatusText>
          <Arrow />
        </Status>
      </Row>
    );
  };

  const renderBody = () => {
    const settingsItems = getSettingsItems();
    return <div ref={measuredBodyHeightRef}>{settingsItems.map(renderRow)}</div>;
  };

  const renderFooter = () => {
    return (
      <Footer>
        <DeleteLink
          onClick={() => {
            if (typeof gtag === "function") {
              gtag("event", "shared_link_settings_click", {
                action: "initiate delete link",
                category: "delete",
                location: "settings modal",
              })
            }
            setCurrentSettingId("delete");
          }}
        >
          {__("Remove link")}
        </DeleteLink>
        <RightWrapper>
          <ShortLink
            onClick={() => {
              if (typeof gtag === "function") {
                gtag("event", "shared_link_settings_click", {
                  action: "initiate short link create",
                  category: "setting",
                  location: "settings modal",
                })
              }
              setCurrentSettingId("shortlink");
            }}
          >
            {__("dl_shortlink", "Short Link")}
          </ShortLink>
          <Dot />
          <LinkStats
            onClick={() => {
              if (typeof gtag === "function") {
                gtag("event", "shared_link_settings_click", {
                  action: "show statistics",
                  category: "statistics",
                  location: "settings modal",
                })
              }
              setCurrentSettingId("stats");
            }}
          >
            {__("Link Stats")}
          </LinkStats>
        </RightWrapper>
      </Footer>
    );
  };

  const renderCurrentSetting = () => {
    const settings = getSettingsComponents();
    const CurrentSettingComponent = settings[currentSettingId];
    const params = {
      allSettingsBack: allSettings,
      bodyHeight: bodyHeight,
      parentRef: settingElement,
      userIsPremium: userIsPremium,
      userIsBusiness: userIsBusiness,
      currentSettingId: currentSettingId,
      setModalHeight: setModalHeight
    };

    return <CurrentSettingComponent {...params} />;
  };

  if (bodyWidth < 800) {
    modalStyle.width = '95vw';
  }

  return (
    <Modal onClose={handleClose} opened={isOpen} container={container}>
      <OvalModalWrap
        style={{ ...modalStyle, height: modalHeight + "px" }}
        ref={modalContainer}
      >
        <OvalModalCloseSimple onClick={handleClose} />
        <ShareSettingsModalHeader
          link={linkUrl}
          shortlink={shortLinkUrl}
          name={linkName}
          onEmailIconClick={setCurrentSettingId}
        />
        <SlideAnimationWrapper>
          <TransitionGroup>
            <CSSTransition
              key={currentSettingId}
              addEndListener={(node, done) => node.addEventListener("transitionend", done, false)}
              classNames="slide"
              onEnter={() => setOverflow("hidden")}
              onExited={() => setOverflow("visible")}
            >
              {currentSettingId !== null ? renderCurrentSetting() : <div />}
            </CSSTransition>
          </TransitionGroup>
        </SlideAnimationWrapper>
        {renderBody()}
        {renderFooter()}
      </OvalModalWrap>
    </Modal>
  );
};

export default ShareSettingsModal;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 73px;
  padding: 20px;
  box-sizing: border-box;
  border-top: 1px solid #f2f2f2;
  cursor: pointer;

  &:last-child {
    border-bottom: 1px solid #f2f2f2;
  }

  &:hover {
    background-color: #fafafa;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: left;
`;

const RowTitle = styled.div`
  font-size: 15px;
  font-weight: 500;
`;

const RowDescription = styled.div`
  font-size: 13px;
  color: #888888;
  font-weight: normal;
  margin-top: 3px;
`;

const Status = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const StatusText = styled.div`
  font-size: 15px;
  line-height: 30px;
  color: #888888;
  text-transform: capitalize;
`;

const Arrow = styled.div`
  display: inline-block;
  margin-left: 10px;
  width: 6px;
  height: 10px;
  cursor: pointer;

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  background-image: url(${require("../../../root/img/share-link/next.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/share-link/next@2x.png")});
  }
`;

const SettingsIcon = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 20px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  @media only screen and (max-width: 400px) {
    display: none;
  }
`;

const UploadIcon = styled(SettingsIcon)`
  background-image: url(${require("../../../root/img/share-link/upload.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/share-link/upload@2x.png")});
  }
`;

const DownloadIcon = styled(SettingsIcon)`
  background-image: url(${require("../../../root/img/share-link/download.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/share-link/download@2x.png")});
  }
`;

const PasswordIcon = styled(SettingsIcon)`
  background-image: url(${require("../../../root/img/share-link/protection.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/share-link/protection@2x.png")});
  }
`;

const ExpirationIcon = styled(SettingsIcon)`
  background-image: url(${require("../../../root/img/share-link/expiration.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/share-link/expiration@2x.png")});
  }
`;

const BrandingIcon = styled(SettingsIcon)`
  background-image: url(${require("../../../root/img/share-link/branding.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/share-link/branding@2x.png")});
  }
`;

const Footer = styled.div`
  display: flex;
  height: 47px;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
`;

const LinkFooter = styled.div`
  display: inline-block;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const DeleteLink = styled(LinkFooter)`
  color: #ff4c4c;
`;

const ShortLink = styled(LinkFooter)`
  color: #17bed0;
`;

const LinkStats = styled(LinkFooter)`
  color: #17bed0;
`;

const Dot = styled.div`
  width: 3px;
  height: 3px;
  margin: 0 10px;
  background-color: #aeaeb5;
  border-radius: 100%;
`;

const RightWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const RestrictionWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const RestrictionIcon = styled.div`
  display: inline-block;
  margin: 0 10px;
  width: 17px;
  height: 13px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  background-image: url(${require("../../../root/img/share-link/users.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/share-link/users@2x.png")});
  }
`;

const AllSettingsWraper = styled.div`
  margin-top: 20px;
  margin-left: 20px;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  line-height: 1.2;
  letter-spacing: -0.23px;
  text-align: left;
  color: #000000;
  cursor: pointer;

  &::before {
    content: "";
    display: inline-block;
    width: 6px;
    height: 6px;
    border-bottom: 1.3px solid #3b3b3b;
    border-left: 1.3px solid #3b3b3b;
    transform: rotate(45deg);
    margin-right: 8px;
    position: relative;
    top: -2px;
  }
`;
