import React, { useRef, useCallback } from "react";
import styled from "styled-components";

import Row from "./Row";
import type { Notification as NotificationType } from "../../../../../lib/notifications/types";
import { VariableSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { __ } from "../../../../../lib/translate";
import useWindowDimensions from "../../../../../lib/hooks/useWindowDimensions";

function NotificationsList({
    list,
    isMobile,
    ...props
}: {
    list: Array<NotificationType>
}) {
    const { bodyWidth } = useWindowDimensions();
    const listRef = useRef();
    const sizeMap = useRef({});
    const setSize = useCallback((index, size) => {
        sizeMap.current = { ...sizeMap.current, [index]: size };
        listRef.current.resetAfterIndex(index);
    }, []);
    const getSize = (index) => sizeMap.current[index] || 63;

    return (
        <div
            {...props}
            style={{
                display: "flex",
                flexGrow: 1,
                height: isMobile || !list.length ? "100%" : 316
            }}
        >
            {list.length ? (
                <AutoSizer>
                    {({ height, width }) => {
                        return (
                            <List
                                ref={listRef}
                                itemData={{ list, setSize, bodyWidth }}
                                itemCount={list.length}
                                itemSize={getSize}
                                width={width}
                                height={height}
                            >
                                {Row}
                            </List>
                        );
                    }}
                </AutoSizer>
            ) : (
                <NotificationEmpty>{__("No Notifications")}</NotificationEmpty>
            )}
        </div>
    );
}

export default NotificationsList;

const NotificationEmpty = styled.div`
    min-height: 26px;
    padding: var(--spacing-2xl) var(--spacing-none);
    text-align: center;
    color: var(--text-secondary);
    width: 100%;

    font-family: Inter, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;

    &:hover {
        background-color: #f3fdfe;
    }
`;
