import styled from "styled-components";

import { Header, Message, InputPass, Link } from "../TwoFactorAuth/SharedComponents";

export const DRHeader = styled(Header)`
  margin-top: 0px;
  padding-bottom: 0px;
  margin-bottom: 12px;
`;

export const DRMessage = styled(Message)`
  margin-top: 69px !important;
`;

export const DRInputPass = styled(InputPass)`
  width: 360px !important;
    @media (max-width: 500px) {
        width: 100%!important;
    }
`;

export const DRLink = styled(Link)`
  margin-top: 88px !important;
`;

export const Container = styled.div`
  width: 640px;
  text-align: center;
  font-family: "Roboto", sans-serif;
  border-radius: 15px;
  box-shadow: 0px 10px 20px 0 rgba(0, 0, 0, 0.3);
  background-color: #ffffff;
  padding: 40px;
  box-sizing: border-box;

    @media (max-width: 700px) {
        width: 95vw;
        padding: 30px;
    }
`;
