/*
  How to add new setting:
    1. Create setting id.
    2. Add the new setting to SETTINGS_PER_TABS where it needs to be accessible.
    3. Each tab has its own file (it is inside ./tabs).
      Go to the file and crea e render function for the UI of the setting.
    4. Add the render function insde the renderFunctionsPerSetting object. 
*/

export const ACCOUNT_SETTINGS = 0;
export const LINKED_ACCOUNTS_SETTINGS = 1;
export const SECURITY_SETTINGS = 2;
export const ENCRYPTION_SETTINGS = 3;
export const NOTIFICATIONS_SETTINGS = 4;
export const PAYMENT_HISTORY_SETTINGS = 5;

export const USER_EMAIL = "userEmail";
export const DATA_REGION_SETTING = "dataRegionSetting";
export const USER_PLAN_INFO = "userPLanInfo";
export const HIDDEN_FILES_SETTING = "hiddenFilesSetting";
export const CHANGE_EMAIL_SETTING = "changeEmailSetting";
export const LANGUAGE_SETTING = "languageSetting";
export const DELETE_ACCOUNT_SETTING = "deleteAccountSetting";
export const LOGIN_ACCOUNTS_SETTING = "loginAccount";
export const BACKUP_ACCOUNTS_SETTING = "backupAccount";
export const LINKED_APPS_SETTING = "linkedApps";
export const TWO_FACTOR_AUTHENTICATION_SETTING = "twoFactorAuthentication";
export const CHANGE_PASS_SETTING = "changePassSetting";
export const CONNECTED_DEVICES_INFO = "connectedDevicesInfo";
export const ENCRYPTION_SETUP_SETTING = "encryptionSetupSetting";
export const RESET_CRYPTO_PASS_SETTING = "resetCryptoPassSetting";
export const CHANGE_CRYPTO_PASS_SETTING = "changeCryptoPassSetting";
export const NOTIFICATION_PREFERENCES_SETTING = "notificationPreferencesSetting";
export const INVOICE_DETAILS = "invoiceDetails";
export const PAYMENT_HISTORY_INFO = "paymentHistoryInfo";

export const SETTINGS_PER_TABS = {
  settingsPerTab: {
    [ACCOUNT_SETTINGS]: [
      USER_EMAIL,
      DATA_REGION_SETTING,
      USER_PLAN_INFO,
      HIDDEN_FILES_SETTING,
      LANGUAGE_SETTING,
      DELETE_ACCOUNT_SETTING
    ],
    [LINKED_ACCOUNTS_SETTINGS]: [
      LOGIN_ACCOUNTS_SETTING,
      BACKUP_ACCOUNTS_SETTING,
      LINKED_APPS_SETTING,
    ],
    [SECURITY_SETTINGS]: [
      CHANGE_PASS_SETTING,
      TWO_FACTOR_AUTHENTICATION_SETTING,
      CONNECTED_DEVICES_INFO,
    ],
    [ENCRYPTION_SETTINGS]: [
      ENCRYPTION_SETUP_SETTING,
      CHANGE_CRYPTO_PASS_SETTING,
      RESET_CRYPTO_PASS_SETTING,
    ],
    [NOTIFICATIONS_SETTINGS]: [
      NOTIFICATION_PREFERENCES_SETTING
    ],
    [PAYMENT_HISTORY_SETTINGS]: [
      INVOICE_DETAILS,
      PAYMENT_HISTORY_INFO,
    ]
  },
};

export const DEFAULT_TABS = [
  {
    tabId: ACCOUNT_SETTINGS,
    tabTitle: __("Account"),
    search: "tab-account"
  },
  {
    tabId: LINKED_ACCOUNTS_SETTINGS,
    tabTitle: __("Linked Accounts"),
    search: "tab-apps"
  },
  {
    tabId: SECURITY_SETTINGS,
    tabTitle: __("Security"),
    search: "tab-security"
  },
  {
    tabId: ENCRYPTION_SETTINGS,
    tabTitle: __("Encryption"),
    search: "tab-crypto"
  },
  {
    tabId: NOTIFICATIONS_SETTINGS,
    tabTitle: __("notifications_settings", "Notifications"),
    search: "tab-notifications"
  }
];
