// @flow

import React, { Component } from "react";
import styled, { keyframes } from "styled-components";
import { hiDPI } from "polished";
import { __ } from "../lib/translate";

import Button, { ButtonCentered } from "./ButtonDefault";
import Modal from "./Modal";
import { URLSITE } from "@pcloud/web-utilities/dist/config";

// max quota 2Tb = 1024*1024*1024*1024
const MAX_QUOTA = 1099511627776;

type Props = {
  quota: number,
  isBusiness: boolean,
  isBusinessOwner: boolean,
  isFolderMine: boolean,
};
type State = { isOpen: boolean };

class StorageQuotaFullModal extends Component<Props, State> {
  constructor() {
    super();

    this.state = {
      isOpen: false
    };

    (this: any).onClick = this.onClick.bind(this);
    (this: any).onClose = this.onClose.bind(this);
  }

  componentDidMount() {
    this.onOpen();
  }

  onOpen() {
    this.setState({ isOpen: true });
  }

  onClick() { }

  onClose() {
    this.setState({ isOpen: false });
  }

  renderMessage() {
    const { quota, isBusiness, isBusinessOwner, isFolderMine } = this.props;

    if (isBusinessOwner) {
      return __("full_quota_modal_msg_business_owner");
    }

    if (isBusiness) {
      return __("full_quota_modal_msg_business");
    }

    if (quota >= MAX_QUOTA) {
      return __("full_quota_modal_msg_support");
    }

    if (!isFolderMine) {
      return __("full_quota_modal_msg_ismine");
    }

    return __("full_quota_modal_msg");
  }

  render() {
    const { isBusiness, isBusinessOwner, quota, isFolderMine } = this.props;
    const { isOpen } = this.state;
    const shouldRenderButton = !isBusiness && quota < MAX_QUOTA && isFolderMine;

    return (
      <Modal opened={isOpen} onClose={this.onClose}>
        <ModalWrap>
          <Container>
            <FullIcon />
            <Header>{isFolderMine ? __("full_quota_modal_header") : __("full_quota_modal_header_ismine")}</Header>
            <Message>{this.renderMessage()}</Message>
            {shouldRenderButton ? (
              <ButtonCentered
                className="modal-centered-button"
                href={URLSITE + "cloud-storage-pricing-plans.html"}
                onClick={this.onClick}
              >
                {__("see_plans")}
              </ButtonCentered>
            ) : null}
          </Container>
          <CloseButton onClick={this.onClose} />
        </ModalWrap>
      </Modal>
    );
  }
}

export default StorageQuotaFullModal;

const ModalWrap = styled.div`
  position: relative;
`;

const Container = styled.div`
  text-align: center;
  border-radius: 15px;
  padding: 10px;
  background: #fefefe;
  max-width: 620px;
  min-width: 300px;
  height: 350px;
  font-family: "Roboto", sans-serif;
  color: #333;

  a {
    min-width: 150px;
  }

  @media only screen and (max-width: 500px) {
    a {
      width: 300px;
    }
  }

  .modal-centered-button {
    margin-bottom: 10px;
  }
`;

const FullIcon = styled.div`
  display: inline-block;
  margin: 45px;
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url(${require("../../root/img/full-icon.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../root/img//full-icon@2x.png")});
  }

  @media only screen and (max-width: 640px) {
    margin: 40px;
  }
`;

const Header = styled.div`
  font-size: 17px;
  font-weight: bold;
  margin: 0px 30px 25px 30px;
`;

const Message = styled.div`
  font-size: 15px;
  margin: 0px 70px 65px 70px;
  line-height: 1.2;
  & a {
    color: #17bed0;
  }

  @media only screen and (max-width: 500px) {
    margin: 0px 5px 30px 5px;

    @media only screen and (max-width: 640px) {
      margin: 0px 70px 30px 70px;
    }
  }
`;

const CloseButton = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  margin: 12px;
  background-position: center;
  cursor: pointer;
  background-size: contain;
  background-image: url(${require("../../root/img/updatedrive/close.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../root/img/updatedrive/close@2x.png")});
  }

  &:hover {
    opacity: 0.8;
  }
`;
