// @flow

import React, { PureComponent } from "react";
import { findDOMNode } from "react-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import styled, { css } from "styled-components";
import { hiDPI } from "polished";
import $ from "jquery";

import apiMethod from "../api/apiMethod";
import { __ } from "../lib/translate";
import { getHash, isUSLocation, rcookie } from "../lib/utils";

import SearchFilterDropdown from "./SearchFilterDropdown";
import FullTextSwitch from "./FullTextSwitch";
import Dropdown, { DIR_RIGHT } from "./Dropdown";
import hashManager from "../lib/hashManager";

const showError = () => {
  HFN.message(
    __("search_character_error", "You need to enter at least 3 characters."),
    "error"
  );
};

type Props = {
  folderId: number,
  isBusiness: boolean,
  changeSearch: boolean => void
};

type State = {
  isFocused: boolean,
  isExpanded: boolean,
  searchByContent: boolean,
  searchText: string,
  transitionEvent: string,
  selectedFilterId: number,
  componentWidth: number
};

class SearchBar extends PureComponent<Props, State> {
  static defaultProps = {
    folderId: 0,
    isBusiness: false,
    changeSearch: () => {}
  };

  constructor(props: Props) {
    super(props);

    (this: any).onFocus = (this: any).onFocus.bind(this);
    (this: any).onClose = (this: any).onClose.bind(this);
    (this: any).onKeyDown = (this: any).onKeyDown.bind(this);
    (this: any).onWrapperClick = (this: any).onWrapperClick.bind(this);
    (this: any).search = (this: any).search.bind(this);
    (this: any).onInputChange = (this: any).onInputChange.bind(this);
    (this: any).getInputRef = (this: any).getInputRef.bind(this);
    (this: any).getSelectedFilterId = (this: any).getSelectedFilterId.bind(
      this
    );
    (this: any).onClickFullTextSwitch = (this: any).onClickFullTextSwitch.bind(
      this
    );
    (this: any).handleInputClick = (this: any).handleInputClick.bind(this);
    (this: any).handleTransition = (this: any).handleTransition.bind(this);
    (this: any).updateComponentWidth = (this: any).updateComponentWidth.bind(
      this
    );

    this.state = {
      isFocused: false,
      isExpanded: false,
      searchByContent: false,
      transitionEvent: "",
      searchText: "",
      selectedFilterId: 0,
      componentWidth: 0
    };

    (this: any).wrapperRef = null;
    (this: any).inputSearch = null;
  }

  componentDidMount() {
    console.log("SearchBar componentDidMount");
    const { isBusiness } = this.props;
    const queryQ = getHash("q") || "";
    const queryFilter = getHash("filter") || 0;
    const queryFullText = getHash("fulltext");

    const paramSearchText = queryQ.split(":")[1] || "";
    const paramFilter =
      queryFilter === "all" ? HFN.CATEGORY.UNKNOWN : parseInt(queryFilter, 10);
    const shouldFocus = Boolean(queryQ);
    const shouldSearchByContent = false;
      // isBusiness && Boolean(shouldFocus ? queryFullText : true) && isUSLocation();

    if (shouldFocus) {
      setTimeout(() => {
        this.focusInput();
      }, 0);
    }
    this.setState({
      isFocused: shouldFocus,
      isExpanded: shouldFocus,
      searchText: paramSearchText.split(/\+/g).join(" "),
      selectedFilterId: shouldSearchByContent
        ? HFN.CATEGORY.DOCUMENT
        : paramFilter,
      searchByContent: shouldSearchByContent
    });

    this.updateComponentWidth();
    window.addEventListener("resize", this.updateComponentWidth);
  }

  componentWillUnmount() {
    const wrapper = findDOMNode((this: any).wrapperRef);
    const { transitionEvent } = this.state;

    wrapper &&
      wrapper.removeEventListener(transitionEvent, this.handleTransition);
    window.removeEventListener("resize", this.updateComponentWidth);
  }

  onWrapperClick(e: any) {
    this.focusInput();
  }

  handleInputClick(e: any) {
    this.onTransitionEnd();
  }

  handleTransition() {
    const { isFocused } = this.state;

    this.setState({ isExpanded: isFocused });
  }

  onTransitionEnd() {
    const wrapper = findDOMNode((this: any).wrapperRef);
    const transitions = {
      transition: "transitionend",
      OTransition: "oTransitionEnd",
      MozTransition: "transitionend",
      WebkitTransition: "webkitTransitionEnd"
    };
    let transitionEvent = "";

    for (let t in transitions) {
      if (wrapper.style[t] !== undefined) {
        transitionEvent = transitions[t];
      }
    }
    this.setState({ transitionEvent: transitionEvent });
    transitionEvent &&
      wrapper.addEventListener(transitionEvent, this.handleTransition);
  }

  updateComponentWidth() {
    this.setState({ componentWidth: $(".filemanager").width() });
  }

  onFocus() {
    this.setState({ isFocused: true });
  }

  focusInput() {
    if (this.inputSearch) {
      (this: any).inputSearch.focus();
    }
  }

  onClose(e: any) {
    e.stopPropagation();
    this.setState({ isFocused: false, isExpanded: false });
  }

  onKeyDown(e: any) {
    if (e.keyCode == 13) {
      this.search();
    }
  }

  getSelectedFilterId(id: number) {
    const { searchByContent } = this.state;
    const selectedId = searchByContent ? HFN.CATEGORY.DOCUMENT : id;

    this.setState({ selectedFilterId: selectedId });
    this.focusInput();
  }

  onClickFullTextSwitch(e: any) {
    const { changeSearch } = this.props;
    const unknownFilter = HFN.CATEGORY.UNKNOWN;
    const documentFilter = HFN.CATEGORY.DOCUMENT;

    this.setState(prevState => {
      changeSearch(!prevState.searchByContent);
      return {
        searchByContent: !prevState.searchByContent,
        selectedFilterId: !prevState.searchByContent
          ? documentFilter
          : unknownFilter
      };
    });
  }

  onInputChange(e: any) {
    this.setState({ searchText: e.target.value });
  }

  getInputRef(node) {
    return ((this: any).inputSearch = node);
  }

  search() {
    const { folderId } = this.props;
    const { searchText, selectedFilterId, searchByContent } = this.state;
    const textLength = searchText.length;

    if (!searchByContent && (textLength > 0 && textLength < 3)) {
      showError();
      return;
    } else if (searchByContent && textLength < 3) {
      showError();
      return;
    }

    hashManager.pushState(
      Object.assign(
        {},
        {
          page: "filemanager",
          q: "name:" + searchText,
          folderid: folderId,
          filter: selectedFilterId === 0 ? "all" : selectedFilterId
        },
        searchByContent ? { fulltext: true } : {}
      ),
      2
    );
  }

  renderSwitchMode() {
    const { searchByContent } = this.state;

    return (
      <React.Fragment>
        <FullTextSwitch
          name="fulltext"
          checked={searchByContent}
          onClick={this.onClickFullTextSwitch}
        />
      </React.Fragment>
    );
  }

  renderIcons() {
    const { searchByContent, selectedFilterId } = this.state;

    return (
      <React.Fragment>
        <SearchFilterDropdown
          selectedItemId={selectedFilterId}
          shouldSerchInDocs={searchByContent}
          onItemSelect={this.getSelectedFilterId}
        />
        <VerticalLine />
        <SearchIcon onClick={this.search} />
        <VerticalLine />
        <CloseButton onClick={this.onClose} />
      </React.Fragment>
    );
  }

  render() {
    const { isBusiness } = this.props;
    const { isFocused, isExpanded, searchText, componentWidth } = this.state;
    const shouldRender = isFocused || isExpanded;
    const shouldRenderFTS = isBusiness && isUSLocation();

    return (
      <Wrapper
        ref={node => {
          (this: any).wrapperRef = node;
        }}
        isFocused={isFocused}
        onKeyDown={this.onKeyDown}
        onClick={this.onWrapperClick}
        componentWidth={componentWidth}
      >
        <SearchInput
          ref={this.getInputRef}
          name="search"
          type="text"
          autoComplete="off"
          value={searchText}
          isFocused={isFocused}
          placeholder={__("search_bar_placeholder", "Search...")}
          onFocus={this.onFocus}
          onChange={this.onInputChange}
          onClick={this.handleInputClick}
        />
        {/* {shouldRender && shouldRenderFTS ? this.renderSwitchMode() : null} */}
        {shouldRender ? this.renderIcons() : <SearchIcon notActive />}
      </Wrapper>
    );
  }
}

export default connect(({ user: { userinfo: { business } } }) => {
  return { isBusiness: business };
})(SearchBar);

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  /* margin-bottom: 20px; */
  float: right;
  height: ${props => (props.isFocused ? "48px" : "42px")};
  padding-right: 18px;
  border-radius: 24px;
  border: solid 1px #e4e4e4;
  background-color: #ffffff;
  width: ${props => (props.isFocused ? "980px" : "253px")};
  transition: width 0.6s, height 0.6s ease-in-out;
  box-sizing: border-box;
  box-shadow: ${props =>
    props.isFocused ? "0px 2px 4px 0 rgba(0, 0, 0, 0.05)" : "none"};
  max-width: ${props =>
    props.componentWidth ? props.componentWidth + "px" : "980px"};
`;

const SearchInput = styled.input`
  flex: 1;
  flex-shrink: 0;
  height: ${props => (props.isFocused ? "48px" : "42px")};
  min-width: 172px;
  font-size: 15px;
  padding-left: 18px;
  padding-right: 10px;
  border-radius: 24px;
  outline: none !important;
  border: none !important;
  box-sizing: border-box;
  &::-ms-clear {
    display: none;
    height: 0;
    width: 0;
  }
`;

const VerticalLine = styled.div`
  width: 1px;
  height: 28px;
  margin: 0 18px;
  background-color: #e9e9e9;
`;
const FullTextSearch = styled.div``;

const SearchIcon = styled.div`
  display: block;
  width: 20px;
  height: 20px;
  cursor: ${props => (props.notActive ? "default" : "pointer")};
  opacity: ${props => (props.notActive ? "0.5" : "1")};

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url(${require("../../root/img/full-text-search/search.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../root/img/full-text-search/search@2x.png")});
  }

  ${props =>
    !props.notActive
      ? css`
          cursor: pointer;
          &:hover {
            background-image: url(${require("../../root/img/full-text-search/search-active.png")});
            ${hiDPI(1.5)} {
              background-image: url(${require("../../root/img/full-text-search/search-active@2x.png")});
            }
          }
        `
      : ""}
`;

const CloseButton = styled(SearchIcon)`
  width: 14px;
  height: 14px;

  background-image: url(${require("../../root/img/full-text-search/close.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../root/img/full-text-search/close@2x.png")});
  }

  &:hover {
    background-image: url(${require("../../root/img/full-text-search/close-active.png")});
    ${hiDPI(1.5)} {
      background-image: url(${require("../../root/img/full-text-search/close-active@2x.png")});
    }
  }
`;

const IndexOkIcon = styled.div`
  display: block;
  width: 18px;
  height: 18px;
  cursor: "pointer";

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url(${require("../../root/img/fts/all-ok.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../root/img/fts/all-ok@2x.png")});
  }
`;

const IndexWarningIcon = styled(IndexOkIcon)`
  background-size: contain;
  background-image: url(${require("../../root/img/fts/all-warning.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../root/img/fts/all-warning@2x.png")});
`;
