import React from "react";

const PlayerContext = React.createContext({
    data: {},
    isPublic: false,
    isMobile: false,
    headerRef: {},
    boxPlayerRef: {},

    audioRef: {},
    currentSong: {},
    queue: [],
    shuffleQueue: [],
    isReady: false,
    isPlaying: false,
    repeatState: false,
    volume: 0.5,
    duration: 0,
    playlist: {},
    playlistItems: [],
    isShuffle: false,
    podcastMode: false,
    analyzerData: {},
    loadedThumbs: {},
    additionalClass: "",
    playerVariant: {},
    controlsWithOverlay: {},
    playRate: 1,
    sleepTimer: 0,

    setAnalyzerData: () => {},
    setQueue: () => {},
    setDuration: () => {},
    setIsReady: () => {},
    setIsPlaying: () => {},
    setVolume: () => {},
    setPlaylist: () => {},
    setPlayRate: () => {},
    setSleepTimer: () => {},

    onPrev: () => {},
    onNext: () => {},
    onPrevProgress: () => {},
    onNextProgress: () => {},
    onVolumeChange: () => {},
    onVolumeChangeEnd: () => {},
    onMuteUnmute: () => {},
    onSelectSong: () => {},
    onToggleRepeat: () => {},
    onToggleShuffle: () => {},
    onTogglePlayPause: () => {},
    onRemoveFromQueue: () => {},
    onCreatePlaylist: () => {},
    onLoadPlaylist: () => {},
    onSharePlaylist: () => {},
    onLoadPlaylistItems: () => {},
    onTogglePodcastMode: () => {},
    loadPrimeThumb: () => {},
    onPlayerClose: () => {},
    onPlayerMinimize: () => {},
    onPlayerMaximize: () => {},
    onShowMiniPlayer: () => {},
    onShowFullPagePlayer: () => {},
    onShowPlayer: () => {},
    onOpenSettingsToggle: () => {},
    onVolumePanelToggle: () => {},
    onCloseAllOverlays: () => {},
    onOpenPlaylistCreateForm: () => {},
    onOpenPlaylistLoadForm: () => {},
    onOpenPlayRateToggle: () => {},
    onOpenSleepTimerToggle: () => {}
});

export default PlayerContext;
