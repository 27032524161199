import React, { useEffect, useRef } from "react";
import { areEqual } from "react-window";
import Notification from "./Notification";

const Row = React.memo(function Row({ data, index, style }) {
    const rowRef = useRef();
    const { setSize, bodyWidth } = data;
    const item = data.list[index];

    useEffect(() => {
        setSize(index, rowRef.current.getBoundingClientRect().height);
    }, [setSize, index, bodyWidth]);

    return (
        <div style={style}>
            <Notification
                rowRef={rowRef}
                notification={item}
                key={item.notificationid}
                url={item.url}
            />
        </div>
    );
}, areEqual);

export default Row;
