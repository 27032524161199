import React from "react";
import ActionButton from "../../../userinterface/ActionButton";

const FullPageBoxControls = ({
    onPlayerClose = () => {},
    onShowMiniPlayer = () => {}
}) => {
    return (
        <>
            <ActionButton
                onClick={onShowMiniPlayer}
                className={"svg-color-default"}
                label={
                    <i className="fa-solid fa-minus" style={{ fontSize: 16 }} />
                }
            />
            <ActionButton
                onClick={onPlayerClose}
                className={"svg-color-default"}
                label={
                    <i className="fa-solid fa-close" style={{ fontSize: 16 }} />
                }
            />
        </>
    );
};

export default FullPageBoxControls;
