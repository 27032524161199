import React, { useEffect, useState } from "react";

import { __ } from "../../../../../lib/translate";
import InputSelect from "../../../../InputSelect";
import usePlayerContext from "../../../hooks/usePlayerContext";
import {
    DisabledText,
    FlexWrapper,
    LinkButton,
    PlaylistControlsWrapper,
    PlaylistLabelWrapper
} from "../../../userinterface/styles";
import { createPortal } from "react-dom";
import { getPlayerState } from "../../../helpers";

const LoadPlaylistPanel = () => {
    const {
        playlistItems,
        onLoadPlaylist,
        onLoadPlaylistItems,
        onCloseAllOverlays,
        additionalClass,
        playerVariant
    } = usePlayerContext();

    const [playlistValue, setPlaylistValue] = useState(0);

    useEffect(() => {
        if (!playlistItems.length) {
            onLoadPlaylistItems();
        }
    }, []);

    const getOptions = (playlistIsToName) => {
        let result = [
            {
                value: 0,
                text: __("Select playlist to play"),
                disabled: true,
                readonly: true,
                hidden: true
            }
        ];

        for (let index in playlistIsToName) {
            result.push({
                text: playlistIsToName[index].name,
                value: playlistIsToName[index].id
            });
        }
        return result;
    };

    const container = document.getElementById("mainContainer");

    if (!container) {
        return null;
    }

    return (
        <>
            {createPortal(
                <PlaylistControlsWrapper className={additionalClass}>
                    <FlexWrapper>
                        <PlaylistLabelWrapper>
                            {__("Playlist")}
                        </PlaylistLabelWrapper>
                        <LinkButton onClick={onCloseAllOverlays}>
                            {__("Close")}
                        </LinkButton>
                    </FlexWrapper>
                    <FlexWrapper>
                        {playlistItems.length ? (
                            <InputSelect
                                name="playlist"
                                options={getOptions(playlistItems)}
                                value={playlistValue}
                                onChange={(e) => {
                                    if (e.target.value > 0) {
                                        onLoadPlaylist(
                                            e.target.value,
                                            onCloseAllOverlays
                                        );
                                        setPlaylistValue(e.target.value);

                                        if (typeof gtag === "function") {
                                            gtag(
                                                "event",
                                                "audio_player_click",
                                                {
                                                    action: "load playlist",
                                                    state: getPlayerState(
                                                        playerVariant
                                                    )
                                                }
                                            );
                                        }
                                    }
                                }}
                            />
                        ) : (
                            <DisabledText>{__("No Playlists")}</DisabledText>
                        )}
                    </FlexWrapper>
                </PlaylistControlsWrapper>,
                container
            )}
        </>
    );
};

export default LoadPlaylistPanel;
