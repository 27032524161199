import React from "react";
import { ContentHeader, ContentText } from "./styles";
import { __ } from "../../../lib/translate";
import styled from "styled-components";
import { boldConverter } from "../../../lib/componentifyConverters";
import Componentify from "react-componentify";

const ChatBotContent = () => {
    return (
        <Wrapper>
            <ContentHeader>
                {__("tutorial_title_chatbot", "Feedback Chatbot")}
            </ContentHeader>
            <ContentText>
                <Componentify
                    text={__(
                        "tutorial_message_chatbot",
                        "Your experience is important to us. Please take a moment to share your thoughts and suggestions. Your feedback helps us improve and serve you better!"
                    )}
                    converters={[boldConverter]}
                />
            </ContentText>
        </Wrapper>
    );
};

export default ChatBotContent;

const Wrapper = styled.div`
    width: 280px;
`;
