//@flow

import React from "react";
import styled, { keyframes } from "styled-components";
import { hiDPI } from "polished";
import { Tooltip as ReactTooltip } from "react-tooltip";

import { ColorType } from "../../../web-shared/dataTypes";
import { __ } from "../../lib/translate";
import { ButtonWithIcon } from "../../../web-shared/components/ButtonDefault";
import { Error, cardIcons } from "./SharedStyledComponents";

export type CreditCardFormTemplateProps = {
  cardNumber: string,
  cardType: string,
  expDate: string,
  CVC: string,
  holderName: string,
  validCardNumber: boolean,
  validExpDate: boolean,
  validCVC: boolean,
  validHolderName: boolean,
  errorMessage: string,
  paymentLoading: boolean,
  changeCard: boolean,
  handleCardNumberChange: (event: SyntheticInputEvent<HTMLInputElement>) => void,
  handleCardNumberKeyPress: (event: SyntheticInputEvent<HTMLInputElement>) => void,
  handleCardNumberPaste: (event: SyntheticInputEvent<HTMLInputElement>) => void,
  handleExpDateChange: (event: SyntheticInputEvent<HTMLInputElement>) => void,
  handleExpDateKeyUp: (event: SyntheticInputEvent<HTMLInputElement>) => void,
  handleCVCChange: (event: SyntheticInputEvent<HTMLInputElement>) => void,
  handleCVCKeyPress: (event: SyntheticInputEvent<HTMLInputElement>) => void,
  handleHolderNameChange: (event: SyntheticInputEvent<HTMLInputElement>) => void,
  handleHolderNameKeyPress: (event: SyntheticInputEvent<HTMLInputElement>) => void,
  handleSubmit: (event: SyntheticInputEvent<HTMLInputElement>) => void,
  handleCardChange: () => void,
  color?: ColorType,
  currency?: string,
  price?: number,
  shouldRenderInputs?: boolean,
  buttonText?: string,
  showSecurePaymentText?: boolean
};

const CreditCardFormTemplate = ({
  cardNumber,
  validCardNumber,
  cardType,
  handleCardNumberChange,
  handleCardNumberKeyPress,
  handleCardNumberPaste,
  expDate,
  validExpDate,
  handleExpDateKeyUp,
  handleExpDateChange,
  CVC,
  validCVC,
  handleCVCChange,
  handleCVCKeyPress,
  holderName,
  validHolderName,
  handleHolderNameChange,
  handleHolderNameKeyPress,
  errorMessage,
  paymentLoading,
  handleSubmit,
  changeCard,
  handleCardChange,
  color,
  currency,
  price,
  shouldRenderInputs = false,
  buttonText=__("checkout_right_btn"),
  showSecurePaymentText=false
}: CreditCardFormTemplateProps) => {
  if (price && currency) {
    buttonText = `${__("pay_button", "Pay")} ${price} ${currency}`;
  }

  return (
    <CardFormWrapper onSubmit={handleSubmit}>
      {errorMessage ? <Error className="payment-error-message">{errorMessage}</Error> : null}
      <CardNumberWrapper>
        <InputLabel>{__("card_number", "Card number")}</InputLabel>
        {changeCard ? (
          <ChangeCard disabled={paymentLoading} onClick={handleCardChange}>
            {__("undo_cart_change", "Undo change")}
          </ChangeCard>
        ) : null}
        <InputField id="card-number" data-invalid={!validCardNumber}>
          {shouldRenderInputs ? (
            <React.Fragment>
              <input
                placeholder="XXXX XXXX XXXX XXXX"
                type="text"
                value={cardNumber}
                name="cardnumber"
                autoComplete="cc-number"
                onChange={handleCardNumberChange}
                onKeyPress={handleCardNumberKeyPress}
                onPaste={handleCardNumberPaste}
                inputMode="numeric"
                autoFocus
              />
              {cardType ? cardIcons[cardType] : cardIcons["default"]}
            </React.Fragment>
          ) : null}
        </InputField>
      </CardNumberWrapper>
      <ExpDateWrapper>
        <InputLabel>{__("expiration_date_short", "Exp. date")}</InputLabel>
        <InputField id="card-expiry" data-invalid={!validExpDate}>
          {shouldRenderInputs ? (
            <input
              placeholder={__("MM", "MM") + " / " + __("YY", "YY")}
              type="text"
              value={expDate}
              name="cc-exp"
              maxLength="5"
              autoComplete="cc-exp"
              onChange={handleExpDateChange}
              onKeyUp={handleExpDateKeyUp}
            />
          ) : null}
        </InputField>
      </ExpDateWrapper>
      <CVCWrapper>
        <WrapperLabelIcon>
          <InputLabel>{__("CVC", "CVC")}</InputLabel>
          <IconWrapper>
            <div className="tooltip-icon" data-tooltip-id="cvs">
              <InfoIcon />
            </div>
            <ReactTooltip id="cvs" className="tooltip" place="bottom">{__(
              "find_card_cvc",
              "This is the secret code located on the back of your credit card."
            )}</ReactTooltip>
          </IconWrapper>
        </WrapperLabelIcon>
        <InputField id="card-cvc" data-invalid={!validCVC}>
          {shouldRenderInputs ? (
            <input
              placeholder="XXX"
              type="text"
              maxLength={(cardType === "amex" || cardType === "default") ? 4 : 3}
              value={CVC}
              name="cvc"
              autoComplete="cc-csc"
              onChange={handleCVCChange}
              onKeyPress={handleCVCKeyPress}
              inputMode="numeric"
            />
          ) : null}
        </InputField>
      </CVCWrapper>
      <CardHolderWrapper>
        <InputLabel>{__("card_holder_name", "Cardholder name")}</InputLabel>
        <InputField data-invalid={!validHolderName}>
          <input
            placeholder={__("card_holder_name", "Cardholder name")}
            type="text"
            value={holderName}
            name="ccname"
            maxLength="30"
            autoComplete="cc-name"
            onChange={handleHolderNameChange}
            onKeyPress={handleHolderNameKeyPress}
          />
        </InputField>
      </CardHolderWrapper>
      <ButtonWrapper>
        <ButtonWithIcon
          size="big"
          color={color}
          onClick={handleSubmit}
          disabled={false}
          height={42}
          loading={paymentLoading}
          text={buttonText}
          icon="secure"
          type="submit"
        />
      </ButtonWrapper>
      {showSecurePaymentText ? (
        <SecureText>
          <SecureIcon />
          <span>{__("secure_ssl_payment_short")}</span>
        </SecureText>
      ) : <SecureText>
            <span>{__("secure_payment")}</span>
          </SecureText>}
    </CardFormWrapper>
  );
};

export default CreditCardFormTemplate;

const CardFormWrapper = styled.form`
  text-align: left;
`;

const InputWrapper = styled.div`
  margin-top: 17px;
`;

const InputLabel = styled.label`
  display: inline-block;
  margin-bottom: 5px;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  color: #999999;
`;

const shake = keyframes`
    10%, 90% {
        transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
        transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
        transform: translate3d(4px, 0, 0);
    }
`;

const InputField = styled.div`
  width: 100%;
  height: 40px;
  padding: 11px 15px;
  box-sizing: border-box;
  border: 1px solid #cccccc;
  background-color: #ffffff;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #444444;
  outline: none;
  clear: both;

  &[data-invalid=true] {
    border: 1px solid #c2313a;
    animation-name: ${shake};
    animation-duration: 700ms;
    animation-delay: 500ms;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;
    animation-fill-mode: forwards;
  }

  input {
    width: 100%;
    border: none;
    outline: none;

    &::placeholder {
      color: #cccccc;
      opacity: 1;
    }
  }
`;

const CardNumberWrapper = styled(InputWrapper)`
  position: relative;
`;

const ExpDateWrapper = styled(InputWrapper)`
  display: inline-block;
  width: 48%;

  input {
    text-align: center;
  }
`;

const CVCWrapper = styled(InputWrapper)`
  display: flex;
  width: 48%;
  float: right;
  flex-direction: column;

  InputLabel {
    float: left;
  }

  .tooltip {
    padding: 15px 20px;
    max-width: 200px;
    border-radius: 6px;
    font-size: 12px;
    line-height: 1.35;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    text-align: center;
  }

  input {
    text-align: center;
  }
`;

const InfoIcon = styled.div`
  width: 16px;
  height: 16px;
  cursor: pointer !important;

  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  background-image: url(${require("../../../root/img/data-location/info.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/data-location/info@2x.png")});
  }
`;

const CardHolderWrapper = styled(InputWrapper)`
  clear: both;

  input {
    text-transform: uppercase;
  }
`;

const ChangeCard = styled.div`
  float: right;
  font-size: 13px;
  color: #000000;
  font-family: "Roboto", sans-serif;
  text-decoration: underline;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  cursor: ${props => (props.disabled ? "default" : "pointer")};
`;

const ButtonWrapper = styled.div`
  margin-top: 30px;
`;

const SecureText = styled.div`
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: center;
  color: #999999;
  margin-top: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SecureIcon = styled.div`
  display: inline-block;
  width: 21px;
  height: 21px;
  background-repeat: no-repeat;
  background-size: 21px 21px;
  background-position: center;
  background-image: url(${require("../../../root/img/secure.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/secure@2x.png")});
  }
  /* position: relative; */
  /* top: 5px; */
  margin-right: 10px;
`;

const IconWrapper = styled.div`
  display: inline-block;
  float: right;
`;

const WrapperLabelIcon = styled.div`
`;
