import React from "react";

import { __ } from "../../../../../lib/translate";
import usePlayerContext from "../../../hooks/usePlayerContext";
import {
    SettingItemIconWrapper,
    SettingsItemLabelWrapper,
    SettingsItemWrapper
} from "../../../userinterface/styles";
import { createPortal } from "react-dom";
import PlaylistSettingItems from "./PlaylistSettingItems";
import OverlayPanelContainer from "./OverlayPanelContainer";

const SettingsOverlay = () => {
    const {
        isPublic,
        additionalClass,
        podcastMode,
        onTogglePodcastMode,
        playerVariant
    } = usePlayerContext();

    const container = document.getElementById("settingsContainer");

    if (!container) {
        return null;
    }

    const showPlaylistSettings = !isPublic && !playerVariant.isMiniPlayer;

    return (
        <>
            {createPortal(
                <OverlayPanelContainer
                    className={additionalClass + " settings"}
                >
                    {showPlaylistSettings && <PlaylistSettingItems />}

                    {podcastMode && (
                        <SettingsItemWrapper onClick={onTogglePodcastMode}>
                            <SettingItemIconWrapper>
                                <i
                                    style={{ fontSize: 14, color: "#7A8D8F" }}
                                    className="fa-light fa-music-note"
                                />
                            </SettingItemIconWrapper>
                            <SettingsItemLabelWrapper>
                                {__("Music mode")}
                            </SettingsItemLabelWrapper>
                        </SettingsItemWrapper>
                    )}
                    {!podcastMode && (
                        <SettingsItemWrapper onClick={onTogglePodcastMode}>
                            <SettingItemIconWrapper>
                                <i
                                    style={{ fontSize: 14, color: "#7A8D8F" }}
                                    className="fa-solid fa-microphone-lines"
                                />
                            </SettingItemIconWrapper>
                            <SettingsItemLabelWrapper>
                                {__("Podcast mode")}
                            </SettingsItemLabelWrapper>
                        </SettingsItemWrapper>
                    )}
                </OverlayPanelContainer>,
                container
            )}
        </>
    );
};

export default SettingsOverlay;
