// @flow
import React, { useState } from "react";
import { useSelector } from "react-redux";

import * as Style from "./preferences.style";
import { __ } from "../../lib/translate";
import { apiMethod } from "../../api";

const ResetCryptoFolderSetting = () => {
  const cryptoSetup = useSelector(({ user }) => user.userinfo.cryptosetup)
  const cryptoV2IsActive = useSelector(({ user }) => user.userinfo.cryptov2isactive);
  const email = useSelector(({ user }) => user.userinfo.email);

  const [loading, setLoading] = useState(false);

  const onCryptoFolderReset = () => {
    if (loading) {
      return;
    }

    setLoading(true);

    apiMethod('crypto_reset', {}, ret => {
      HFN.message(__("delete_account_after_click", "We've sent you an email to <b>%email%</b>. Check your inbox and follow the instructions from the email. If you don't receive an email within few minutes, check your Junk/Spam folder.", { email: email }));
    }, {
      errorCallback: () => {
        HFN.message("payment_error_2215", "Oops! Something went wrong. Please try again later.", "error")
        setLoading(false);
      }
    });
  }

  if (!cryptoSetup || cryptoV2IsActive) {
    return null;
  }

  return (
    <>
      <Style.Row>
        <div>
          <Style.Title>{__("Reset Crypto")}</Style.Title>
          <Style.Description>{__("By resetting your Crypto Passphrase you will delete all currently encrypted files. Are you sure?")}</Style.Description>
        </div>
        <Style.RedButton disable={loading} onClick={onCryptoFolderReset}>{__("reset_crypto", "Reset Crypto Pass")}</Style.RedButton>
      </Style.Row>
    </>
  )
};

export default ResetCryptoFolderSetting;
