import React, { useEffect, useState } from "react";
import { apiMethod } from "../../../api";
import BackupAccountUI from "./BackupAccountUI";

const GooglePhotosLink = () => {
  const [account, setAccount] = useState(null);
  const [unlinkModal, setUnlinkModal] = useState(null);
  const [unlinkLoading, setUnlinkLoading] = useState(false);

  useEffect(() => {
    getToken().then(getIdentity);
  }, [])

  const getToken = () => {
    return new Promise((resolve, reject) => {
      apiMethod("social_assign", {
        type: "gphotos"
      }, response => {
        if (response.access_token) {
          GPhotos.token = response.access_token;
          resolve(response);
        }
      }, {
        errorCallback: (error) => { console.error(error) }
      })
    })

  }

  const getIdentity = () => {
    GPhotos.getUserIdentity(userinfo => {
      setAccount(userinfo);
    }, () => {
      apiMethod('social_assign', { type: 'gphotos', dismiss: 'true' }, () => {
        GPhotos.revoke_token();
      });
    });
  }

  const onLink = () => {
    GPhotos.getTokenCallback((token, expire) => {
      HFN.message('Token saved.');
      getIdentity();
    });
  };

  const onUnlink = () => {
    apiMethod('social_assign', { type: 'gphotos', dismiss: 'true' }, ret => {
      GPhotos.revoke_token();
      HFN.message(__('Account unlinked.'));
      setAccount(null);
    })
  }

  return (
    <BackupAccountUI
      type="gphotos"
      app={__("Google Photos Account")}
      account={account}
      linked={!!account}
      onLink={onLink}
      onUnlink={onUnlink}
    />
  )
}

export default GooglePhotosLink;