import React, { useState } from "react";
import Tabs from "./UI/Tabs";
import BackupCategory from "./UI/BackupCategory";
import styled from "styled-components";
import useBackupControls from "./hooks/useBackupControls";
import Componentify from "react-componentify";
import { __ } from "../../lib/translate";
import {
    linkConverter,
    linkConverterV2
} from "../../lib/componentifyConverters";
import ActionModal from "./UI/ActionModal";
import SkeletonLoad from "./UI/SkeletonLoad";

const BackupsContainer = (props) => {
    const [category, setCategory] = useState("all");

    const {
        categoryData,
        modalSettings,
        isOverquota,
        changeBackupStatus,
        stopBackup,
        isBackupStateLoading,
        initialLoadingComplete
    } = useBackupControls(props);

    return (
        <Wrapper>
            {isOverquota ? (
                <TopMessage>
                    <Componentify
                        text={__("thirdparty_backups_overquota")}
                        converters={[linkConverter, linkConverterV2]}
                    />
                </TopMessage>
            ) : null}
            <Tabs
                category={category}
                setCategory={setCategory}
            />
            {initialLoadingComplete &&
                categoryData.map((item, index) => {
                    if (
                        category === "all" ||
                        item.categories.includes(category)
                    ) {
                        return (
                            <BackupCategory
                                activeCategory={category}
                                category={item}
                                key={index}
                                changeBackupStatus={changeBackupStatus}
                                stopBackup={stopBackup}
                                isOverquota={isOverquota}
                                isBackupStateLoading={isBackupStateLoading}
                            />
                        );
                    }

                    return null;
                })}
            {!initialLoadingComplete && <SkeletonLoad />}
            <ActionModal {...modalSettings} />
        </Wrapper>
    );
};

export default BackupsContainer;

const Wrapper = styled.div`
    @media (max-width: 1023px) {
        padding: 0 var(--spacing-md);
    }

    @media (max-width: 800px) {
        margin-bottom: 150px;
    };
`;

const TopMessage = styled.div`
    border-radius: 13.5px;
    border: solid 1px #fcf2d5;
    background-color: #fff8e0;
    padding: 20px;
    box-sizing: border-box;
    margin-bottom: 8px;
    margin-top: 8px;
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000;

    a {
        text-decoration: none;
        color: #17bed0;
        font-weight: bold;
    }

    @media (max-width: 768px) {
        border-radius: 0px;
        border-bottom: 1px solid #e4e4e4;
        margin-bottom: 0px;
    }
`;
