import React from "react";
import { ButtonWrapper } from "./styles";

const ActionButton = ({
    className = "",
    disabled = false,
    onClick,
    label,
    buttonWidth,
    buttonHeight,
    styles = {},
    longPressEvents = {}
}) => {
    return (
        <ButtonWrapper
            className={className + (disabled ? " disabled " : "")}
            $width={buttonWidth}
            $height={buttonHeight}
            onClick={disabled ? () => {} : onClick}
            style={styles}
            {...longPressEvents}
        >
            {label}
        </ButtonWrapper>
    );
};

export default ActionButton;
