import React, { useMemo, useRef } from 'react';
import styled, { createGlobalStyle, css } from 'styled-components';
import { __ } from '../../lib/translate';

import useRightSectionSlideAnimationManager from '../ContentView/hooks/useRightSectionSlideAnimationManager';
import CommentsContainer from "../CommentsContainer/CommentsContainer";
import { getIconType } from "../../lib/utils";
import { getThumbInfo, getIconInfo } from "../../lib/icons";
import FileIcon from "../FileIcon";
import { getItemClickActionAndLabel } from "../ContentView/ContentData"; 
import useWindowDimensions from '../../lib/hooks/useWindowDimensions';

type Props = {
  isOpened: boolean,
  selectedItem: {
    id: string,
    fileid: number,
    name: string,
    category: number,
    thumb: boolean,
    width: number,
    height: number,
    contenttype: string,
    size: number,
    created: string,
    modified: string,
    exifdatetime: number,
    hash: number,
    comments: number,
    isshared: boolean,
    ismine: boolean,
    icon: number,
    parentfolderid: number,
  } | {
    id: string,
    folderid: number,
    name: string,
    ismine: boolean,
    thumb: string,
    created: string,
    modified: string,
    comments: number,
    isshared: boolean,
    icon: number,
    parentfolderid: number
  },
  onCloseItemsInfo: () => void,
  selectedTab: "info" | "comments",
  onTabChange: (tab: "info" | "comments") => void,
  autoFocusAddComment: {value: boolean},
  isMobileView: boolean,
  isBusiness: boolean
}

const ItemsInfoContainer = ({
  containerId = "items-info",
  isOpened = true,
  selectedItemsCount,
  selectedItem,
  onCloseItemsInfo = () => {},
  selectedTab = "info",
  onTabChange = () => {},
  autoFocusAddComment = false,
  isMobileView = false,
  isBusiness = false
}: Props) => {

  const { bodyHeight } = useWindowDimensions();
  const MOBILE_HEIGHT_BREAKPOINT = 545;
  const isMobileHeightView = bodyHeight <= MOBILE_HEIGHT_BREAKPOINT;

  const isDlink = HFN.config.isDlink();

  const containerRef = useRef();

  const otherRightSectionId = "upload-section";

  const stickyHeight = useMemo(() => document.querySelector('.trialinfo-bar-holder')?.offsetHeight || 0, []);

  useRightSectionSlideAnimationManager({
    containerRef,
    currentRightSectionId: containerId,
    otherRightSectionId,
    isOpened,
    isToggled: false,
    closeRightSection: onCloseItemsInfo
  });

  const { iconType, itemsInfoPreviewData } = useMemo(() => { 
    return {
      itemsInfoPreviewData: selectedItem ? getItemClickActionAndLabel(selectedItem, {[HFN.CATEGORY.ARCHIVE]: true}) : null,
      iconType: selectedItem ? getIconType(selectedItem) : null
    }
  }, [selectedItem]);

  const fileIconData = useMemo(() => {
    return {
      thumbInfo: getThumbInfo(HFN.ICONS.SVG_ITEM_PREVIEW),
      iconInfo: getIconInfo(undefined, HFN.ICONS.SVG_ITEM_PREVIEW),
      thumbInfoM: getThumbInfo(HFN.ICONS.SVG_LIST),
      iconInfoM: getIconInfo(undefined, HFN.ICONS.SVG_LIST),
      type: !isMobileHeightView ? HFN.ICONS.SVG_ITEM_PREVIEW : HFN.ICONS.SVG_LIST,
      thumbOptions: {
        crop: !isMobileHeightView ? 0 : 1
      }
    }
  }, [isMobileHeightView]);

  const onPreviewButtonClick = (event) => {
    if (selectedItem.isfolder && isMobileView) {
      onCloseItemsInfo();
    }
    itemsInfoPreviewData.onClick(event, selectedItem, selectedTab === "comments" && !isMobileView ? { comments: selectedItem.id } : null);
  }

  const renderContent = () => {
    return (
      <ItemsInfoWrapper
        ref={containerRef}
        className="items-info-wrapper fixed-content"
        $isClosed={!isOpened}
      >
        <TopContainer>
          <HeaderText $mobileHeightBreakpoint={MOBILE_HEIGHT_BREAKPOINT + "px"}>
            {/*
              Temporary disable ItemsInfo Information tab side panel until it's ready.
              {isMobileView && selectedItemsCount === 1 && selectedItem && selectedItem.name ? selectedItem.name : selectedItemsCount + " " + __(selectedItemsCount === 1 ? "selected_one" : "selected_multiple")}
            */}
            <span>{selectedItemsCount === 1 && selectedItem && selectedItem.name ? selectedItem.name : null}</span>
            <span className="mobile-height-only">
              {selectedItemsCount + " " + __(selectedItemsCount === 1 ? "selected_one" : "selected_multiple")}
            </span>
          </HeaderText>
          <IconsContainer onClick={onCloseItemsInfo}>
            <i className="mediumIcon lightColorIcon fa-regular fa-chevron-right"/>
          </IconsContainer>
        </TopContainer>

        <MainContainer $mobileHeightBreakpoint={MOBILE_HEIGHT_BREAKPOINT + "px"}>
                   
          <ItemPreviewWrapper $mobileHeightBreakpoint={MOBILE_HEIGHT_BREAKPOINT + "px"}>
            {selectedItemsCount === 1 && selectedItem ? 
            <React.Fragment>
              <FileIconContainer $mobileHeightBreakpoint={MOBILE_HEIGHT_BREAKPOINT + "px"} $thumbHeight={fileIconData.thumbInfo.height}>
                <FileIconWrapper $mobileHeightBreakpoint={MOBILE_HEIGHT_BREAKPOINT + "px"} key={selectedItem.id + "_" + (isMobileHeightView ? "mobile" : "")} $thumbWidth={fileIconData.thumbInfo.width} $thumbHeight={fileIconData.thumbInfo.height} $iconWidth={fileIconData.iconInfo.width} $iconHeight={fileIconData.iconInfo.height} $thumbWidthM={fileIconData.thumbInfoM.width} $thumbHeightM={fileIconData.thumbInfoM.height} $iconWidthM={fileIconData.iconInfoM.width} $iconHeightM={fileIconData.iconInfoM.height}>
                  <FileIcon item={iconType} type={fileIconData.type} metadata={selectedItem} thumbOptions={fileIconData.thumbOptions} />
                </FileIconWrapper>
              </FileIconContainer>
              <ItemName $mobileHeightBreakpoint={MOBILE_HEIGHT_BREAKPOINT + "px"}>{selectedItem.name}</ItemName>
              <ItemPreviewActionButton $mobileHeightBreakpoint={MOBILE_HEIGHT_BREAKPOINT + "px"} onClick={onPreviewButtonClick}>{__(itemsInfoPreviewData.label)}</ItemPreviewActionButton>
            </React.Fragment>
            : null }
          </ItemPreviewWrapper>
          
          {selectedItemsCount === 1 ?
            <React.Fragment>

              {/*
               // Temporary disable ItemsInfo Information tab side panel until it's ready.             
              {!isMobileView && isBusiness ? 
                <Tabs>
                  <Tab className={selectedTab === "info" ? "selected" : ""} onClick={() => onTabChange("info")}>{__("Information", "Information")}</Tab>
                  <Tab className={selectedTab === "comments" ? "selected" : ""} onClick={() => onTabChange("comments")}>{__("Comments", "Comments")}</Tab>
                </Tabs>
              : null}
               
              {selectedTab === "info" ? 
                <ItemInfoContainer>
                  Item info will be here...
                </ItemInfoContainer>
              : null}
              */}
              

              {selectedTab === "comments" ? 
                <CommentsContainer
                  contentItemData={selectedItem}
                  isVisible={isOpened}
                  autoFocusAddComment={autoFocusAddComment}
                />
              : null}
            </React.Fragment>
          : null}

          {selectedItemsCount > 1 ?
            <InfoOnSelectedMultipleItems>{__("multiple_selected_items_label", "To view more detailed information about these items select only one.")}</InfoOnSelectedMultipleItems>
          : null}
        </MainContainer>
      </ItemsInfoWrapper>
    );
  };


  if (!isDlink) {  
    return (
      <React.Fragment>
        <GlobalStyle $isClosed={!isOpened} $otherRightSectionId={otherRightSectionId} $stickyHeight={stickyHeight} />
        {renderContent()}
      </React.Fragment>
    )
  }

  return null;
};

export default ItemsInfoContainer;

const GlobalStyle = createGlobalStyle`
  ${({ $isClosed, $otherRightSectionId, $stickyHeight }) =>
        css`
            html {
              --items-info-content-width: ${$isClosed ? "0px !important" : "var(--right-content-width, 400px) !important"};
              #content-right-section-inner.opened-${$otherRightSectionId} {
                .items-info-wrapper {
                  transition: right 0.5s ease-in-out;
                  right: ${$isClosed ? 'calc(0px - var(--right-content-width, 400px))' : '0'};
                }
              }

              @media (max-width: 1023px) {
                --items-info-content-right-mobile: ${$isClosed ? '-100%!important' : '0 !important'};
                #content-right-section-inner {
                  width: ${$isClosed ? '0px !important' : 'inherit !important'}
                }

                &.sticky-business {
                  #content-right-section {
                    height: calc(100dvh - ${(64 + $stickyHeight) + "px"}) !important;
                  }
                }
              }
            }
        `}
`;

const ItemsInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  overflow: hidden;
  position: relative;
  height: 100%;
  width: var(--right-content-width, 400px) - var(--right-content-border-width, 1px));
  border-radius: var(--radius-rounded, 16px) 0px 0px 0px;
  border-top: 1px solid var(--divider-primary);
  border-left: ${({ $isClosed }) => ($isClosed ? "none" : "var(--right-content-border-width, 1px) solid var(--divider-primary)")};
  background: #fff;
  box-sizing: border-box;

  @media (max-width: 1023px) {
    width: 100%;
    border: none;
    border-radius: 0;
  }
`;


const TopContainer = styled.div`
    background: var(--surface-primary, #F4F5F6);
    padding: var(--spacing-xs, 4px) var(--spacing-none, 0px) var(--spacing-xs, 4px) 16px;
    display: flex;
    justify-content: space-between;
    height: 49px;
    align-items: center;
    box-sizing: border-box;
    width: calc(var(--right-content-width, 400px) - var(--right-content-border-width, 1px));
    gap: 8px;
    @media (max-width: 1023px) {
      width: 100%;
      align-self: stretch;
    }
`;

const HeaderText = styled.p`
    color: var(--text-primary, #282C2F);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: inline;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    
    .mobile-height-only {
      display: none;
    }

    @media (max-height: ${({$mobileHeightBreakpoint}) => $mobileHeightBreakpoint ? $mobileHeightBreakpoint : "620px"}) {
      span:not(.mobile-height-only) {
        display: none;
      }
      
      .mobile-height-only {
        display: block;
      }
    }
`;

const IconsContainer = styled.div`
    display: flex;
    align-items: center;
    width: 40px;
    height: 40px;
    justify-content: center;
    cursor: pointer;
    flex-shrink: 0;
`;

const MainContainer = styled.div`
  display: flex;
  height: 100%;
  padding: var(--spacing-md, 16px) var(--spacing-none, 0px);
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  overflow-x: auto;
  overflow-y: hidden;
  width: calc(var(--right-content-width, 400px) - var(--right-content-border-width, 1px));
  
  @media (max-width: 1023px) {
    width: 100%;
  }

  @media (max-height: ${({$mobileHeightBreakpoint}) => $mobileHeightBreakpoint ? $mobileHeightBreakpoint : "620px"}) {
    padding: var(--spacing-sm, 8px) var(--spacing-none, 0px) var(--spacing-md, 16px) var(--spacing-none, 0px);
  }
`;
const ItemPreviewWrapper = styled.div`
  display: flex;
  padding: var(--spacing-sm) 16px var(--spacing-sm, 8px) 16px;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-sm);
  align-self: stretch;
  min-height: 178px;
  box-sizing: border-box;
  justify-content: space-between;

  @media (max-height: ${({$mobileHeightBreakpoint}) => $mobileHeightBreakpoint ? $mobileHeightBreakpoint : "620px"}) {
    flex-direction: row;
    min-height: auto;
    justify-content: flex-start;
  }
`;

const FileIconContainer = styled.div`
  --thumb-border-width: 1px;
  min-height: calc(${({$thumbHeight}) => $thumbHeight ?? "104px"} + (var(--thumb-border-width) * 2));

  @media (max-height: ${({$mobileHeightBreakpoint}) => $mobileHeightBreakpoint ? $mobileHeightBreakpoint : "620px"}) {
    min-height: auto;
    flex-shrink: 0;
  }
`;

const FileIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;

  .iconwrap {
    margin: 0!important;
    min-width: auto!important;
    float: none!important;

    /* If name is visible -> remove display: flex and min-height.. */
    display: flex;
    flex-direction: column;
    /* If name is visible -> remove + (var(--spacing-sm) * 3) */
    min-height: calc(${({$iconHeight}) => $iconHeight ?? "60px"} + (var(--spacing-sm) * 3));
    justify-content: end;

    @media (max-height: ${({$mobileHeightBreakpoint}) => $mobileHeightBreakpoint ? $mobileHeightBreakpoint : "620px"}) {
      min-height: auto;
    }
  }

  img {
    border: var(--thumb-border-width) solid transparent;
    width: ${({$iconWidth}) => $iconWidth ?? "auto"} !important;
    height: ${({$iconHeight}) => $iconHeight ?? "60px"} !important;
    /* If name is visible -> remove margin-bottom */
    margin-bottom: var(--spacing-sm);
    max-width: 100%;

    @media (max-height: ${({$mobileHeightBreakpoint}) => $mobileHeightBreakpoint ? $mobileHeightBreakpoint : "620px"}) {
      margin-bottom: var(--spacing-none, 0);
      width: ${({$iconWidthM}) => $iconWidthM ?? "24px"} !important;
      height: ${({$iconHeightM}) => $iconHeightM ?? "24px"} !important;
    }
  }

  &.thumbready {
    img {
      border-radius: 4px;
      border: var(--thumb-border-width) solid var(--border-secondary, #EFF0F1);
      width: auto !important;
      height: auto !important;
      max-width: ${({$thumbWidth}) => parseInt($thumbWidth) + "px" ?? "184px"};
      max-height: ${({$thumbHeight}) => parseInt($thumbHeight) + "px" ?? "104px"};

      @media (max-height: ${({$mobileHeightBreakpoint}) => $mobileHeightBreakpoint ? $mobileHeightBreakpoint : "620px"}) {
        width: ${({$iconWidthM}) => $iconWidthM ?? "24px"} !important;
        height: ${({$iconHeightM}) => $iconHeightM ?? "24px"} !important;
        max-width: unset;
        max-height: unset;
      }
    }
  }
`;

const ItemName = styled.div`
  color: var(--text-primary, #282C2F);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  
  display: none;
  
  @media (max-height: ${({$mobileHeightBreakpoint}) => $mobileHeightBreakpoint ? $mobileHeightBreakpoint : "620px"}) {
    display: inline;
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const ItemPreviewActionButton = styled.button`
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: var(--text-primary, #282C2F);
  padding: var(--spacing-sm, 8px);
  border-radius: var(--radius-full, 9999px);
  background: #F0F4F5;
  border: none;
  cursor: pointer;
  min-width: 120px;

  @media (max-height: ${({$mobileHeightBreakpoint}) => $mobileHeightBreakpoint ? $mobileHeightBreakpoint : "620px"}) {
    font-size: var(--base-font-size, 15px);
    padding: var(--spacing-xs, 4px);
    flex-shrink: 0;
  }
`;

const Tabs = styled.div`
  display: flex;
  height: 48px;
  padding: var(--spacing-sm, 8px) 16px var(--spacing-none, 0px) 16px;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-bottom: 1px solid var(--divider-secondary, #EFF0F1);
  box-sizing: border-box;
`;

const Tab = styled.button`
  cursor: pointer;
  background: inherit;
  border: none;
  color: var(--text-primary, #282C2F);
  display: flex;
  height: 40px;
  padding: var(--spacing-none, 0px) 16px;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  &.selected {
    border-bottom: 2px solid var(--Primary-500, #17BED0);
    cursor: default;
  }
`;

const ItemInfoContainer = styled.div`
  display: flex;
  flex: 1;
  border: none;
  align-items: center;
  text-align: center;
  padding: var(--spacing-md, 16px);
`;

const InfoOnSelectedMultipleItems = styled.div`
  display: flex;
  flex: 1;
  border: none;
  align-items: center;
  text-align: center;
  padding: var(--spacing-md, 16px);
`;