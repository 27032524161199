// @flow

import React, { Component } from "react";
import Componentify from "react-componentify";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { hiDPI } from "polished";
import MobileDetect from "mobile-detect";

import apiMethod from "../../api/apiMethod";
import { __ } from "../../lib/translate";
import errors from "../../lib/errors";
import { boldConverter } from "../../lib/componentifyConverters";
import {
  VERIFICATION_CODE_LENGTH,
  PLAY_STORE_AUTHENTICATOR_URL,
  APP_STORE_AUTHENTICATOR_URL,
  TFA_TYPE
} from "@pcloud/web-utilities/dist/config/constants";
import { setTwoFactorActiveType } from "../../lib/state/actions/user";

import { ButtonCentered } from "../../components/ButtonDefault";
import { InputCode, ErrorMessage, Loader } from "./SharedComponents";
import SetupPassStep from "./SetupPassStep";

const mobileDetect = new MobileDetect(window.navigator.userAgent);
// delete
window.mobileDetect = new MobileDetect(window.navigator.userAgent);

type Props = {
  token: string,
  password: string,
  setTwoFactorActiveType: boolean => void,
  onSuccess: () => void
};

type State = {
  secretKey: string,
  qrUrl: string,
  verificationCode: string,
  showErrorMessage: boolean,
  errorMessage: string,
  isValidCode: boolean,
  isLoading: boolean,
  isLoadingQR: boolean
};

class GoogleAuthenticatorStep extends Component<Props, State> {
  static defaultProps = {
    token: "",
    password: "",
    setTwoFactorActiveType: () => {},
    onSuccess: () => {}
  };

  constructor(props: Props) {
    super(props);

    (this: any).onSubmit = this.onSubmit.bind(this);
    (this: any).onCodeChange = this.onCodeChange.bind(this);
    (this: any).onEnter = this.onEnter.bind(this);
    (this: any).copySecretKeyToClipboard = this.copySecretKeyToClipboard.bind(this);

    this.state = {
      secretKey: "",
      qrUrl: "",
      verificationCode: "",
      showErrorMessage: false,
      errorMessage: "",
      isValidCode: false,
      isLoading: false,
      shouldResendCode: true,
      isLoadingQR: false
    };
  }

  componentDidMount() {
    this.getSecretKey();
  }

  onCodeChange(e: any) {
    let code = e.target.value.replace(/[^0-9]/g, "").slice(0, 6);

    this.setState({
      verificationCode: code,
      showErrorMessage: false,
      isValidCode: this.validateCode(code)
    });
  }

  getSecretKey() {
    const { token, password } = this.props;

    this.setState({ isLoadingQR: true });

    apiMethod(
      "tfa_getsecret",
      {
        auth: token,
        password
      },
      ({ result, data: { secret, url } }) => {
        this.setState({
          secretKey: secret,
          qrUrl: url,
          isLoadingQR: false
        });
      },
      {
        forseFresh: true,
        errorCallback: ({ result, error }) => {
          HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
          throw new Error(error);
        }
      }
    );
  }

  validateCode(code: string) {
    return code.length === VERIFICATION_CODE_LENGTH;
  }

  onSubmit() {
    const { verificationCode, isLoading, isValidCode, secretKey } = this.state;
    const { token, onSuccess, password, setTwoFactorActiveType } = this.props;

    if (isLoading) {
      return;
    }

    if (!verificationCode.length) {
      return;
    }

    if (!isValidCode) {
      this.setState({
        errorMessage: __("Wrong code"),
        showErrorMessage: true
      });

      return;
    }

    this.setState({ isLoading: true });

    apiMethod(
      "tfa_verifysecret",
      {
        auth: token,
        code: verificationCode,
        secret: secretKey,
        password
      },
      () => {
        this.setState({ isLoading: false });
        setTwoFactorActiveType(TFA_TYPE.AUTHENTICATOR_APP_TYPE);
        onSuccess();
      },
      {
        errorCallback: ({ result, error }) => {
          let errorMessage = "";

          if (errors[result]) {
            errorMessage = errors[result];
          } else {
            errorMessage = __("something_went_wrong_refresh_and_try_again");
          }

          if (mobileDetect.mobile() !== null) {
            HFN.message(errorMessage, "error");
          }

          this.setState({
            errorMessage,
            showErrorMessage: true,
            isLoading: false
          });

          if (!errors[result]) {
            throw new Error(error);
          }
        }
      }
    );
  }

  copySecretKeyToClipboard() {
    const sectertKeyInput = document.getElementById("secretKey");
    sectertKeyInput && sectertKeyInput.select();
    document.execCommand("copy");
    HFN.message("Copied to clipboard.");
  }

  onEnter(e: any) {
    if (e.keyCode === 13) {
      this.onSubmit();
    }
  }

  renderAppStoreImageMobile() {
    if (mobileDetect.os() === "iOS") {
      return (
        <a
          className="app"
          target="_blank"
          rel="noopener noreferrer"
          href={APP_STORE_AUTHENTICATOR_URL}
        >
          <AppStoreImage className="mobile-view-app" />
        </a>
      );
    } else if (mobileDetect.os() === "AndroidOS") {
      return (
        <a
          className="app"
          target="_blank"
          rel="noopener noreferrer"
          href={PLAY_STORE_AUTHENTICATOR_URL}
        >
          <GooglePlayImage className="mobile-view-app" />
        </a>
      );
    }

    return null;
  }

  renderDesktopView() {
    const {
      secretKey,
      qrUrl,
      verificationCode,
      showErrorMessage,
      errorMessage,
      isValidCode,
      isLoading,
      isLoadingQR
    } = this.state;

    return [
      <Message key="mgs">
        <Step key="step1">
          <StepNumber>{__("tfa_setup_authenticator_step_1")}</StepNumber>
          <StepHeading>{__("tfa_setup_authenticator_step_1_heading")}</StepHeading>
          <a
            className="app"
            target="_blank"
            rel="noopener noreferrer"
            href={APP_STORE_AUTHENTICATOR_URL}
            style={{marginBottom: "10px"}}
          >
            <AppStoreImage key="iphone" />
          </a>

          <a
            className="app"
            target="_blank"
            rel="noopener noreferrer"
            href={PLAY_STORE_AUTHENTICATOR_URL}
          >
            <GooglePlayImage key="google" />
          </a>
        </Step>
        <Step key="step2">
          <StepNumber>{__("tfa_setup_authenticator_step_2")}</StepNumber>
          <StepHeading>{__("tfa_setup_authenticator_step_2_heading")}</StepHeading>
          {isLoadingQR ? <LoaderQR /> : <ImageQR src={qrUrl} alt="QR" />}
        </Step>
        <Step key="step3">
          <StepNumber>{__("tfa_setup_authenticator_step_3")}</StepNumber>
          <StepHeading>{__("tfa_setup_authenticator_step_3_heading")}</StepHeading>
          <ErrorMessage show={showErrorMessage}>{errorMessage}</ErrorMessage>
          <InputCode
            className="verification-input"
            name="code"
            type="text"
            autoFocus
            value={verificationCode}
            onChange={this.onCodeChange}
            onKeyUp={this.onEnter}
            shouldRenderRedBorder={showErrorMessage}
          />
          <ButtonCentered
            disabled={isLoading || !verificationCode.length}
            loading={isLoading}
            onClick={this.onSubmit}
          >
            {__("Activate")}
          </ButtonCentered>
        </Step>
      </Message>,
      <Subheading key="subheading" className="secret-key">
        {__("tfa_setup_authenticator_secret_heading")}
      </Subheading>,
      <SecretKey key="secret-key">{secretKey}</SecretKey>,
      <Subheading key="time-based" className="time-based">
        <Componentify
          converters={[boldConverter]}
          text={__("2fa_setup_timebased", "The setup needs to be <b>time-based</b>")}
        />
      </Subheading>
    ];
  }

  renderMobileView() {
    const { secretKey, showErrorMessage, verificationCode, isLoading, isValidCode } = this.state;

    return [
      <MessageMobile key="msg-mobile">
        <StepMobile key="step-mobile1">
          <StepHeadingMobile>
            <StepNumberMobile>1.</StepNumberMobile> {__("tfa_setup_authenticator_step_1_heading")}
          </StepHeadingMobile>
          <ImageStoreApp>{this.renderAppStoreImageMobile()}</ImageStoreApp>
        </StepMobile>
        <StepMobile key="step-mobile2">
          <StepHeadingMobile>
            <StepNumberMobile>2.</StepNumberMobile>
            {__("tfa_setup_authenticator_step_2_heading_mobile")}
          </StepHeadingMobile>
          <SecretKeyInput
            id="secretKey"
            type="text"
            value={secretKey}
            onClick={this.copySecretKeyToClipboard}
          />
          <TapToCopy>{__("tfa_setup_authenticator_copy_key")}</TapToCopy>
        </StepMobile>
        <StepMobile key="step-mobile3">
          <StepHeadingMobile>
            <StepNumberMobile>3.</StepNumberMobile> {__("tfa_setup_authenticator_step_3_heading")}
          </StepHeadingMobile>
          <InputCodeMobile
            className="verification-input mobile-view-input"
            name="code"
            type="text"
            value={verificationCode}
            onChange={this.onCodeChange}
            onKeyUp={this.onEnter}
            shouldRenderRedBorder={showErrorMessage}
          />
        </StepMobile>
      </MessageMobile>,
      <ButtonCentered
        key="btn-mobile"
        className="btn-mobile-view"
        disabled={isLoading || !isValidCode}
        loading={isLoading}
        onClick={this.onSubmit}
      >
        {__("Activate")}
      </ButtonCentered>
    ];
  }

  render() {
    return (
      <Container>
        <Header>
          <Heading>{__("tfa_setup_authenticator_heading")}</Heading>
          <Subheading>{__("tfa_setup_authenticator_subheading")}</Subheading>
        </Header>
        {mobileDetect.mobile() !== null ? this.renderMobileView() : this.renderDesktopView()}
      </Container>
    );
  }
}

export default connect(
  ({ user }) => {
    const { token, userinfo: { password } = {} } = user;

    return {
      token,
      password
    };
  },
  dispatch =>
    bindActionCreators(
      {
        setTwoFactorActiveType
      },
      dispatch
    )
)(GoogleAuthenticatorStep);

const Container = styled.div`
  padding: 30px 15px;
  text-align: center;
  border-radius: 15px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.25);
  background: #fefefe;
  max-width: 800px;
  width: 800px;
  min-width: 425px;
  min-height: 456px;
  font-family: "Roboto", sans-serif;
  color: #333;
  box-sizing: border-box;

  input:focus {
    outline: none;
  }
  .mobile-view-app {
    width: 140px;
    height: 42px;

    a {
      width: 140px;
      height: 42px;
    }
  }

  .app {
    display: block;
    position: relative;
    top: 4px;
    width: 206px;
    height: 61px;
  }

  .verification-input,
  a {
    width: 206px;
  }

  .verification-input {
    margin-bottom: 10px;
  }

  .mobile-view-input {
    width: 140px;
    padding: 0;
  }

  .btn-mobile-view {
    margin-top: 20px;
    width: 90%;
  }

  @media only screen and (max-width: 768px) {
    width: 90vw;
    min-width: 0;
  }
`;

const Header = styled.div`
  /* height: 102px;
  padding-top: 36px; */
  box-sizing: border-box;

  @media only screen and (max-width: 560px) {
    padding: 26px;
  }
  @media only screen and (max-width: 425px) {
    padding: 0;
  }
`;

const Heading = styled.div`
  font-size: 18px;
  /* line-height: 22px; */
  letter-spacing: -0.3px;
  color: #222222;
  font-weight: bold;
  margin-top: -3px;
  /* padding-bottom: 3px; */
  padding-bottom: 15px;
  line-height: 26px;
`;

const Subheading = styled.div`
  /* font-size: 13px; */
  line-height: 16px;
  /* padding: 0 20px; */
  letter-spacing: normal;
  text-align: center;
  /* color: #888888; */
  font-size: 15px;
  color: #000;
  padding: 0 60px 15px 60px;

  &.time-based,
  &.secret-key {
    font-size: 13px;
    padding-bottom: 0;
  }
  &.time-based {
    color: #f73c3c;
  }
  &.secret-key {
    color: #000;
  }

  @media (max-width: 768px) {
    padding: 0;
  }
`;

const Message = styled.div`
  padding: 30px 0 0 40px;
  display: table;
  border-top: 1px solid #eeeeee;
  padding: 15px 0px;
  border-top: none;
  border-spacing: 10px 0;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
  }
`;

const MessageMobile = styled.div`
  display: table;
  border-spacing: 20px;
  padding: 20px;
  border-bottom: 1px solid #eeeeee;
  border-top: 1px solid #eeeeee;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 32px;
    border-spacing: 0;
  }
`;

const Step = styled.div`
  display: table-cell;
  text-align: center;
  width: 33%;
  /* padding-right: 39px; */
  border-radius: 6px;
  border: solid 1px #eee;
  background-color: #fafafa;
  padding: 20px;
  margin-right: 10px;

  &:last-child > div:nth-child(2) {
    padding-bottom: 8px;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
    box-sizing: border-box;
  }
`;

const StepMobile = styled.div`
  display: table-row;
  width: 100%;

  @media (max-width: 768px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

const StepNumber = styled.div`
  font-size: 15px;
  font-weight: bold;
  line-height: 18px;
  text-align: left;
  color: #000;
  margin-bottom: 13px;
`;

const StepNumberMobile = styled.div`
  display: inline;
  font-size: 15px;
  font-weight: bold;
  line-height: 18px;
  text-align: left;
  color: #17bed0;
`;

const StepHeading = styled.div`
  font-size: 13px;
  line-height: 16px;
  text-align: left;
  color: #222222;
  padding-bottom: 27px;
`;

const StepHeadingMobile = styled.div`
  display: table-cell;
  vertical-align: middle;
  font-size: 13px;
  line-height: 16px;
  text-align: left;
  padding-bottom: 10px;
  color: #222222;
`;

const SecretKey = styled.div`
  font-size: 18px;
  font-weight: bold;
  line-height: 1.22;
  letter-spacing: normal;
  /* color: #17bed0; */
  color: #000;
  padding-top: 15px;
`;

const InputCodeMobile = styled(InputCode)`
  display: table-cell;
  width: 140px;
  height: 42px;
  border-radius: 10px;
  box-shadow: inset 0px 3px 5px 0 rgba(0, 0, 0, 0.14);
  background-color: #fafafa;
  border: solid 2px #cacaca;
`;

const SecretKeyInput = styled.input`
  display: table-cell;
  width: 140px;
  height: 42px;
  border-radius: 10px;
  border: solid 2px #17bed0;
  box-sizing: border-box;
  padding: 0 5px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
  }
`;

const TapToCopy = styled.div`
  position: relative;
  top: 2px;
  font-size: 12px;
  color: #888;
`;

const ImageQR = styled.img`
  display: inline-block;
  width: 150px;
  height: 150px;
  margin-bottom: 22px;
  margin-top: -10px;
  }
`;

const ImageStoreApp = styled.div`
  display: table-cell;
  width: 140px;
  height: 42px;
`;

const AppStoreImage = styled.div`
  width: 206px;
  height: 60px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url(${require("../../../root/img/2fa-login/appstore.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/2fa-login/appstore@2x.png")});
  }
`;

const GooglePlayImage = styled(AppStoreImage)`
  background-image: url(${require("../../../root/img/2fa-login/google-play.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/2fa-login/google-play@2x.png")});
  }
`;

export const LoaderQR = styled(Loader)`
  position: relative;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  margin: 40px auto 76px;
`;
