import React from "react";
import DragAndDrop from "../../Header/DragAndDrop";
import EmptyDesktopIcon from "../../../../../../root/img/grid/empty-state/filemanager-desktop.svg";
import EmptyMobileIcon from "../../../../../../root/img/grid/empty-state/filemanager-mobile.svg";
import EmptyCryptoDesktopIcon from "../../../../../../root/img/grid/empty-state/crypto-desktop.svg";
import EmptyCryptoMobileIcon from "../../../../../../root/img/grid/empty-state/crypto-mobile.svg";
import useWindowDimensions from "../../../../../lib/hooks/useWindowDimensions";
import { MOBILE_WIDTH_BREAKPOINT } from "../../../../../config/constants";
import EmptyUploadMobile from "../../Header/EmptyUploadMobile";
import {
    EmptyStateMessage,
    EmptyStateMessageSubTitle,
    EmptyStateMessageTitle,
    EmptyStateWrapper,
    ImageContainer
} from "../styles";
import { __ } from "../../../../../lib/translate";
import { useSelector } from "react-redux";
import AlertCryptoV1 from "../../../../../containers/CryptoPages/parts/AlertCryptoV1";

const Filemanager = ({ noItemsLabel }) => {
    const { bodyWidth } = useWindowDimensions();
    const isMobile = bodyWidth < MOBILE_WIDTH_BREAKPOINT;
    const itemData = useSelector(({ content }) => content.itemData);
    const isCrypto = !!itemData.encrypted;
    const isCryptoSetup = !!sessionStorage.getItem('cryptosetupcompleted');

    return (
        <>
            {isCrypto && isCryptoSetup && <AlertCryptoV1 />}
            <EmptyStateWrapper $isMobile={isMobile}>
                <EmptyStateMessage $isMobile={isMobile}>
                    <EmptyStateMessageTitle>
                        {isCrypto
                            ? __(
                                "crypto_empty_title",
                                "Your Crypto folder is empty."
                            )
                            : __(...noItemsLabel)}
                    </EmptyStateMessageTitle>
                    {isCrypto && (
                        <EmptyStateMessageSubTitle>
                            {__(
                                "crypto_empty_message",
                                "Upload your most precious files in the Crypto and make sure they are protected with the most complex encryption type possible for cloud storage. You are the one who own the key to the encryption."
                            )}
                        </EmptyStateMessageSubTitle>
                    )}
                </EmptyStateMessage>

                <ImageContainer $isMobile={isMobile}>
                    {!isCrypto && (
                        <>
                            {!isMobile && <EmptyDesktopIcon />}
                            {isMobile && <EmptyMobileIcon />}
                        </>
                    )}
                    {isCrypto && (
                        <>
                            {!isMobile && <EmptyCryptoDesktopIcon />}
                            {isMobile && <EmptyCryptoMobileIcon />}
                        </>
                    )}
                </ImageContainer>

                {!isMobile && <DragAndDrop />}
                {isMobile && <EmptyUploadMobile />}
            </EmptyStateWrapper>
        </>
    );
};

export default Filemanager;
