import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { setGridHeaderHeight } from "../../../../../lib/state/actions/content";
import TrashExtend from "./TrashExtend";
import TrashTruncate from "./TrashTruncate";

const TrashHeader = () => {
    const contentRef = useRef(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!contentRef.current) {
            return;
        }
        const parent = contentRef.current;

        const handleDynamicWidth = () => {
            const height = parent.offsetHeight + 10;

            if (TrashHeaderHeight !== height) {
                TrashHeaderHeight = height;
                dispatch(setGridHeaderHeight(height));
            }
        };

        handleDynamicWidth();
        const observer = new ResizeObserver(handleDynamicWidth);
        observer.observe(parent);

        return () => {
            observer.unobserve(parent);
        };
    }, [contentRef.current]);

    const showTrashExtend = !HFN.config.isEFH() && !HFN.config.isBusiness();
    const showTrashTruncate = !HFN.config.isMobile() && document.body.offsetWidth > 1024;

    return (
        <div ref={contentRef}>
            {showTrashExtend && <TrashExtend />}
            {showTrashTruncate && <TrashTruncate />}
        </div>
    );
};

export default TrashHeader;

export let TrashHeaderHeight = 100;