import React, { useEffect } from "react";

const useOutsideClicker = (ref, callback, toggleRef) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      const disabledContainer = document.getElementsByClassName('disable-outsideclicker')[0];
      if (
          (
              !disabledContainer ||
              !disabledContainer.contains(event.target)
          ) &&
          ref.current &&
        !ref.current.contains(event.target) &&
        (!toggleRef || (toggleRef.current && !toggleRef.current.contains(event.target)))) {
        callback(event);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, toggleRef]);
};

export default useOutsideClicker;