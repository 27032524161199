import React from "react";
import styled from "styled-components";
import { errorKeys } from "../../lib/errors";
import LongTextAnimationContainer from "../LongTextAnimationContainer";

const UploadFailedFolderItem = ({ item, style, failedFolderError }) => {
  const isFile = typeof item === 'object';
  const path = isFile ? item.webkitRelativePath : item;
  const name = isFile ? item.name : item.split('/')[item.split('/').length - 1];
  const extensionIndex = name.lastIndexOf('.');
  const extension = isFile ? name.substring(extensionIndex) : "";
  const baseName = isFile ? name.substring(0, extensionIndex) : name;

  return (
    <>
      <FailedItem style={style} >
        <FileNameLeft $isFile={isFile} data-tooltip-id="failed-item-tooltip" data-tooltip-content={path}>
          {baseName && <BaseName>{baseName}</BaseName>}
          {extension && <FileExtension>{extension}</FileExtension>}
        </FileNameLeft>
        <LongTextAnimationContainer animateOnHover={true} >
          <Text>
            {failedFolderError > 0 && !item.errorCode && __(errorKeys[failedFolderError])}
            {!(failedFolderError > 0 && !item.errorCode) && (
                <>
                  {item.errorCode && errorKeys.hasOwnProperty(item.errorCode) ? __(errorKeys[item.errorCode]) : __('something_went_wrong_refresh_and_try_again')}
                </>
            )}
          </Text>
        </LongTextAnimationContainer>
      </FailedItem>
    </>
  )
}

export default UploadFailedFolderItem;

const FailedItem = styled.div`
  height: 55px;
  border-bottom: 1px solid #EFF0F1;
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 24px;
  padding: 8px 0px;
  text-align: left;
`

const Text = styled.div`
  height: 20px;
  font-size: 13px;
  line-height: 13px;
  color: #8C959D;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const FileNameLeft = styled.div`
  width: 100%;
  display: flex;
`

const BaseName = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 20px);
`;

const FileExtension = styled.span`
  flex-shrink: 0;
`;
