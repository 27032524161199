import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { exploreOptions } from "../LeftMenu/leftMenuOptions";
import { __ } from "../../lib/translate";
import {
  AUTOSCROLL_INTERVAL,
  AUTOSCROLL_PROGRESS_INTERVAL,
  EXPLORE_CARDS_TRANSITION_DURATIONS,
  EXPLORE_CARD_MENU_BIG,
  EXPLORE_CARD_MENU_SMALL,
  ONLY_ONE_CARD_SCREEN_WIDTH,
  EXPLORE_MENU_ITEMS
} from "../LeftMenu/constants";
import { useDispatch } from "react-redux";
import { decrementActiveExploreCard, incrementActiveExploreCard, setActiveExploreCard } from "../../lib/state/actions/content";
import useWindowDimensions from "../../lib/hooks/useWindowDimensions";
import { detectIsMobile } from "@pcloud/web-utilities/dist/lib/utils";
import {
  ExploreCardsContainer,
  ExploreHeader,
  CircleIconWrapper,
  StyledFontAwesomeIcon,
  DescriptionWrapper,
  Name,
  Card,
  DotsContainer,
  Dot,
  DotFiller,
  CardsWrapper,
  CardsContainer,
  Description,
  FontAwesomeIconArrow
} from "./styles";
import PromoCardTemplate from "./PromoCardTemplate";
import hashManager from "../../lib/hashManager";
import { sendAnalyticsEvent } from "../../lib/sendAnalyticsEvent";

const ExploreCards = ({ isInMenu = true, closeMobileMenu = () => { } }) => {
  const isMobileDevice = detectIsMobile();
  const { bodyWidth } = useWindowDimensions();
  const isOnlyOneCard = (bodyWidth < ONLY_ONE_CARD_SCREEN_WIDTH) && isMobileDevice;
  const dispatch = useDispatch();
  const leftMenuContent = useSelector(({ content }) => content.leftMenuContent) || undefined;
  const activeExploreCardIndex = useSelector(({ content }) => content.activeExploreCardIndex);
  const pageInfo = useSelector(({ content }) => content.pageInfo) || undefined;
  const { explorecards } = pageInfo;
  const { toggled, activeItem, activeItemIndex, isProfileOpened } = leftMenuContent;
  const exploreCardsRef = useRef(null);
  const autoScrollTimer = useRef(null);
  const progressIntervalRef = useRef(null);
  const touchStartX = useRef(0);
  const touchEndX = useRef(0);
  const [progress, setProgress] = useState(0);
  const [transitionDuration, setTransitionDuration] = useState(EXPLORE_CARDS_TRANSITION_DURATIONS.DEFAULT);
  const [currentStep, setCurrentStep] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const totalSteps = AUTOSCROLL_INTERVAL / AUTOSCROLL_PROGRESS_INTERVAL;
  let originalCards = { ...exploreOptions };
  const exploreKeys = explorecards.map(item => item.name);

  for (const key in originalCards) {
    if (!exploreKeys.includes(key)) {
      delete originalCards[key];
    }
  }

  originalCards = Object.entries(originalCards);

  explorecards.forEach((item, key) => {
    if (['promocard'].includes(item.name)) {
      originalCards.unshift(['promocard_'+key, item]);
    }
  });

  const cardsCount = originalCards.length;
  const cards = [
    originalCards[cardsCount - 1],
    ...originalCards,
    originalCards[0],
  ];

  const isSingleCard = cardsCount === 1;
  const swipeable = (isInMenu || isOnlyOneCard) && !isSingleCard;
  const isSmall = isInMenu && toggled;
  const cardWidth = (isOnlyOneCard && !isInMenu) ? (window.innerWidth) : toggled ? EXPLORE_CARD_MENU_SMALL : EXPLORE_CARD_MENU_BIG;

  const onCardClick = ([key, value]) => {
    if (typeof value.page === 'object') {
      hashManager.pushState(value.page, 2);
    } else {
      hashManager.pushState({ page: value.page }, 2);
    }
    if (typeof gtag === "function") {
      gtag("event", "explore_cards_click", {
        action: "click",
        location: isInMenu ? "navigation menu" : "home page",
        type: value.name,
      })
    }
    closeMobileMenu();
  }

  const resetAutoScrollTimer = (isAutoScroll) => {
    setTransitionDuration(EXPLORE_CARDS_TRANSITION_DURATIONS.DEFAULT);
    if (swipeable) {
      if (isAutoScroll) {
        setProgress(0);
        setCurrentStep(0);
      }
      clearInterval(autoScrollTimer.current);
      clearInterval(progressIntervalRef.current);
      progressIntervalRef.current = setInterval(() => {
        setCurrentStep((prevStep) => {
          const newStep = prevStep + 1;
          setProgress((newStep / totalSteps) * 100);
          return newStep;
        });
      }, AUTOSCROLL_PROGRESS_INTERVAL);
      autoScrollTimer.current = setInterval(goToNextCard, AUTOSCROLL_INTERVAL - (isAutoScroll ? 0 : (AUTOSCROLL_INTERVAL * progress / 100)));
    }
  };

  const goToNextCard = () => {
    if (swipeable) {
      dispatch(incrementActiveExploreCard());
    }
  };

  const goToPreviousCard = () => {
    if (swipeable) {
      dispatch(decrementActiveExploreCard());
    }
  };

  const goToCard = (index) => {
    if (swipeable) {
      dispatch(setActiveExploreCard(index));
    }
  };

  const handleSwipe = () => {
    setTransitionDuration(EXPLORE_CARDS_TRANSITION_DURATIONS.SWIPE);
  };

  useEffect(() => {
    if (swipeable && transitionDuration === EXPLORE_CARDS_TRANSITION_DURATIONS.SWIPE) {
      if (touchEndX.current < touchStartX.current - 50) {
        goToNextCard();
      } else if (touchEndX.current > touchStartX.current + 50) {
        goToPreviousCard();
      }
    }
  }, [transitionDuration]);

  useEffect(() => {
    const promocards = explorecards.filter(({ name }) => name === "promocard");

    if (promocards?.length) {
      promocards.forEach((item, key) => { 
        sendAnalyticsEvent({
            category: "webcard",
            action: "show",
            label: item.label,
            os: 4
          }, {
            channelid: item.channelid
          }
        );
      });
    }
  }, []);

  useEffect(() => {
    if (isHovered) {
      clearInterval(progressIntervalRef.current);
      clearInterval(autoScrollTimer.current);
    } else if (swipeable) {
      resetAutoScrollTimer();
    }

    return () => {
      clearInterval(progressIntervalRef.current);
    };
  }, [isHovered]);

  useEffect(() => {
    if (swipeable) {
      const track = exploreCardsRef.current;
      if (activeExploreCardIndex === cardsCount + 1) {
        track.style.transition = `transform ${transitionDuration}ms ease-in-out`;
        track.style.transform = `translateX(-${activeExploreCardIndex * cardWidth}px)`;

        setTimeout(() => {
          track.style.transition = "none";
          track.style.transform = `translateX(-${cardWidth}px)`;
          track.offsetHeight;
          dispatch(setActiveExploreCard(1));
        }, transitionDuration);
      } else if (activeExploreCardIndex === 0) {
        track.style.transition = `transform ${transitionDuration}ms ease-in-out`;
        track.style.transform = `translateX(0px)`;

        setTimeout(() => {
          track.style.transition = "none";
          track.style.transform = `translateX(-${cardsCount * cardWidth}px)`;
          track.offsetHeight;
          dispatch(setActiveExploreCard(cardsCount));
        }, transitionDuration);
      } else {
        track.style.transition = `transform ${transitionDuration}ms ease-in-out`;
        track.style.transform = `translateX(-${activeExploreCardIndex * cardWidth}px)`;
      }
      resetAutoScrollTimer(true);
      return () => {
        clearInterval(autoScrollTimer.current);
      }
    }
  }, [activeExploreCardIndex, toggled, isProfileOpened, swipeable, activeItem, cardWidth]);

  return (
    <ExploreCardsContainer $swipeable={swipeable}>
      {!isSmall && <ExploreHeader $isInMenu={isInMenu} $isMobileDevice={isMobileDevice}>{__("explore_cards_title", "Explore")}</ExploreHeader>}
      <CardsWrapper
        id="explore-cards-wrapper"
        $width={cardWidth}
        $swipeable={swipeable}
        onTouchStart={(e) => (touchStartX.current = e.changedTouches[0].screenX)}
        onTouchEnd={(e) => {
          touchEndX.current = e.changedTouches[0].screenX;
          handleSwipe();
        }}>
        <CardsContainer ref={exploreCardsRef} $swipeable={swipeable} $isInMenu={isInMenu}>
          {(swipeable ? cards : originalCards).map(([key, value], index) => {
            if (value.name === EXPLORE_MENU_ITEMS.PROMO_CARD) {
              return (
                  <PromoCardTemplate
                      promoCardData={value}
                      isInMenu={isInMenu}
                      swipeable={swipeable}
                      toggled={toggled}
                      isSingleCard={isSingleCard}
                      key={index}
                      isSmall={isSmall}
                      setIsHovered={setIsHovered}
                  />
              );
            }

            const IconCircle = value.iconCircle;
            const IconCircleSmall = value.iconCircleSmall;
            return (
              <Card key={index} $isInMenu={isInMenu} $swipeable={swipeable} $toggled={toggled} $isSingleCard={isSingleCard} className={key} onClick={() => onCardClick([key, value])}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                data-tooltip-id="left-menu-item-tooltip"
                data-tooltip-content={isSmall ? __(value.name) : ""}
                $isMobileDevice={isMobileDevice}
              >
                <CircleIconWrapper $isSmall={isSmall}>
                  {isSmall ? <IconCircleSmall className="icon-circle" /> : <IconCircle className="icon-circle" />}
                  <StyledFontAwesomeIcon $isSmall={isSmall} $toggled={toggled} className={value.icon} />
                </CircleIconWrapper>
                {!isSmall && <Name>{__(value.name)}</Name>}
                <DescriptionWrapper $isInMenu={swipeable}>
                  {!isSmall && <FontAwesomeIconArrow $isInMenu={isInMenu} className="icon fa-solid fa-circle-arrow-right" />}
                </DescriptionWrapper>
                {!isInMenu && <Description>{__(value.description)}</Description>}
              </Card>
            )
          })}
        </CardsContainer>
      </CardsWrapper>
      {swipeable && <DotsContainer>
        {originalCards.map(([key, value], index) => {
          const isActive = (activeExploreCardIndex === index + 1) ||
            (activeExploreCardIndex === 0 && index === cardsCount - 1) ||
            (activeExploreCardIndex === cardsCount + 1 && index === 0)
          return (<Dot
            key={index}
            $active={isActive}
            $toggled={toggled}
            $transitionDuration={transitionDuration}
            $isMobileDevice={isMobileDevice}
            $isInMenu={isInMenu}
            onClick={() => goToCard(index + 1)}>
            {isActive && <DotFiller width={progress} />}
          </Dot>)
        })}
      </DotsContainer>}
    </ExploreCardsContainer>
  )
}

export default ExploreCards;