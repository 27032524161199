import React from "react";
import { ContentHeader, ContentText } from "../styles";
import { __ } from "../../../../lib/translate";
import styled from "styled-components";
import { boldConverter } from "../../../../lib/componentifyConverters";
import Componentify from "react-componentify";

const UploadButtonContent = () => {
    return (
        <Wrapper>
            <ContentHeader>
                {__("tutorial_title_upload", "Upload Files & Folders")}
            </ContentHeader>
            <ContentText>
                <Componentify
                    text={__(
                        "tutorial_message_get_started_upload",
                        "Use the ‘Add’ button to upload files, create folders, or organize your storage in seconds."
                    )}
                    converters={[boldConverter]}
                />
            </ContentText>
        </Wrapper>
    );
};

export default UploadButtonContent;

const Wrapper = styled.div`
    width: 280px;
`;
