import React, { useEffect } from "react";

import DownloadIcon from "../../../../root/img/dlink/download-public-icon.svg";
import SaveIcon from "../../../../root/img/dlink/save-to-cloud-pub-icon.svg";
import { isSameLocation } from "../../../lib";
import { __ } from "../../../lib/translate";
import PublicButton from "./PublicButton";
import { FlexColumnWrapper } from "./styles";
import usePlayerContext from "../hooks/usePlayerContext";

const PublicLinks = () => {
    const {
        isMobile,
        queue,
        data,
        isWhiteBackground,
        isSingleSongPlayer
    } = usePlayerContext();

    useEffect(() => {
        if (!isSingleSongPlayer || !queue.length) {
            return;
        }

        if (data && data.metadata && data.metadata.isfolder) {
            HFN.dLink.addHeaderFilename(data.metadata.name);
        }

        if (isMobile) {
            HFN.dLink.addMobileButton(HFN.dLink.createShareButtonMobile());
            if (HFN.dLink._actionSheetDownloadItems(data).length !== 0) {
                HFN.dLink.addMobileButton(
                    HFN.dLink.createDownloadButtonMobile(data)
                );
            }
        } else {
            HFN.dLink.addDesktopButton(HFN.dLink.getShareHeaderIcon());
        }
    }, [queue.length]);

    if (!data?.candownload || !queue.length || isMobile) {
        return null;
    }

    const renderDownloadButton = () => {
        return (
            <PublicButton
                onClick={() => {
                    HFN.dLink._actionDownloadFile(data);
                }}
                white={true}
                iconSvg={
                    <DownloadIcon
                        fill={isWhiteBackground ? "#017986" : "#17BED0"}
                    />
                }
                value={__("direct_download")}
            />
        );
    };

    const renderSaveButton = () => {
        if (!isSameLocation()) {
            return null;
        }

        return (
            <PublicButton
                onClick={() => {
                    HFN.dLink._actionCopyToCloud(data);
                }}
                iconSvg={<SaveIcon />}
                value={__("save_to_pcloud")}
            />
        );
    };

    return (
        <FlexColumnWrapper
            style={{
                rowGap: 16,
                width: 200,
                marginTop: 16
            }}
        >
            {renderSaveButton()}
            {renderDownloadButton()}
        </FlexColumnWrapper>
    );
};

export default PublicLinks;
