//  @flow

import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import { changePublinkApiCall } from "./utils";

import { __ } from "../../lib/translate";

import ShareDropdown from "./ShareDropdown";
import TopBar from "./TopBar";
import { ButtonCentered } from "../../components/ButtonDefault";
import {
  SettingsWrapper,
  SettingsHeader,
  SettingsTitle,
  SettingsDescription
} from "./SharedStyledComponents";

import { defaultHeaderHeight } from "./DefaultValues";

type Props = {
  allSettingsBack: any,
  bodyHeight: number,
  parentRef: any,
  setModalHeight: () => void
};

const DownloadRestrictionSetting = ({
  allSettingsBack,
  bodyHeight,
  parentRef,
  userIsPremium,
  userIsBusiness,
  currentSettingId,
  setModalHeight
}: Props) => {
  const token = useSelector(({ user }) => user.token);
  const linkData = useSelector(({ sharedLinks }) => sharedLinks.linkData);
  const { linkId, canDownload } = linkData;
  const [selectedId, setSelectedId] = useState(canDownload ? 0 : 1);
  const [isOpenDropdown, setIsOpenDropdown] = useState(canDownload);
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (parentRef.current) {
      setModalHeight(parentRef.current.offsetHeight + defaultHeaderHeight);
    }
  }, [selectedId, isOpenDropdown, parentRef, setModalHeight]);

  const getDownloadDropDownList = () => {
    return [
      {
        id: 0,
        title: __("download_setting_allow", "Preview and download"),
        description: __(
          "download_setting_allow_info",
          "Anyone with the link can preview and download."
        )
      },
      {
        id: 1,
        title: __("download_setting_preview_only", "Preview only"),
        description: __(
          "download_setting_preview_only_info",
          "Anyone with the link can only preview."
        )
      }
    ];
  };

  const getSelectedItemId = useCallback(() => {
    return canDownload ? 0 : 1;
  }, [canDownload]);

  const onChangeSelectedId = (id: number) => {
    console.log("selectedSettingId", id);
    setSelectedId(id);
  };

  const onSaveClick = cb => {
    const canSave = selectedId !== getSelectedItemId();

    if (isLoading || !canSave) {
      return;
    }

    setLoading(true);
    const downloadenabled = selectedId ? false : true;
    const params = { auth: token, linkid: linkId, disabledownload: !downloadenabled };

    if (typeof gtag === "function") {
      gtag("event", "shared_link_settings_click", {
        action: "download setting change",
        category: "setting",
        type: "free",
        user_premium: (userIsPremium || userIsBusiness) ? 1 : 0,
        location: "settings modal",
        value: downloadenabled ? "download allowed" : "download disabled",
      })
    }

    changePublinkApiCall(
      params,
      () => {
        setLoading(false);
        dispatch({ type: "SET_DOWNLOAD_SETTING", canDownload: downloadenabled });

        if (cb && typeof cb === "function") {
          cb();
        } else {
          HFN.message(__("shared_links_settings_updated", "Settings updated."), "success");
        }
      },
      ({ error }) => {
        setLoading(false);
        HFN.message(error, "error");
        throw new Error(error);
      }
    );
  };

  const renderSaveButton = () => {
    const canSave = selectedId !== getSelectedItemId();

    return (
      <ButtonWrapper>
        <ButtonCentered
          disabled={isLoading || !canSave}
          loading={isLoading}
          onClick={onSaveClick}
          style={{
            minWidth: "120px",
            borderRadius: "3.1px",
            marginTop: "27px"
          }}
        >
          {__("Save")}
        </ButtonCentered>
      </ButtonWrapper>
    );
  };

  const renderDropdown = () => {
    return (
      <ShareDropdown
        list={getDownloadDropDownList()}
        selectedItemId={selectedId}
        onItemDropdownClick={onChangeSelectedId}
        isOpen={isOpenDropdown}
        setIsOpen={setIsOpenDropdown}
      />
    );
  };

  const renderFooter = () => {
    if (isOpenDropdown) {
      return null;
    }

    return (
      <React.Fragment>
        {selectedId === 1 ? <Disclaimer>{__("download_setting_disclaimer")}</Disclaimer> : null}
        {renderSaveButton()}
      </React.Fragment>
    );
  };

  return (
    <SettingsWrapper bodyHeight={bodyHeight} ref={parentRef}>
      <TopBar allSettingsBack={allSettingsBack} settingId={currentSettingId}/>
      <SettingsHeader>
        <SettingsTitle>{__("link_settings_download", "Download")}</SettingsTitle>
        <SettingsDescription>
          {__(
            "link_settings_download_info",
            "Allow anyone with this link to preview and download, or disable downloading at all."
          )}
        </SettingsDescription>
      </SettingsHeader>
      <DropDownWrapper>{renderDropdown()}</DropDownWrapper>
      {renderFooter()}
    </SettingsWrapper>
  );
};

export default DownloadRestrictionSetting;

const DropDownWrapper = styled.div`
  margin-top: 27px;
  margin-bottom: 20px;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

const ButtonWrapper = styled.div`
  a[disabled] {
    background-color: #dddddd;
    color: #a2a2a2;
    opacity: 1;
  }
`;

const Disclaimer = styled.div`
  font-size: 11px;
  font-weight: normal;
  text-align: left;
  margin: 0 45px;
`;
