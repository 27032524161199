import $ from 'jquery';
import { __ }from "./translate"

const stats_template = [
	'<div>',
		'<div class="linkstats-wrap">',
			'<div class="linkstats">',
				'<div class="days">',
					//'<div class="pad"></div>',
				'</div>',
				'<div class="overview">',
					'<div class="summary">',
						'<div class="title"></div>',
						'<div class="border left"></div>',
						'<div class="border right"></div>',
						'<div class="summary-wrap">',
							'<div class="summary-text"></div>',
						'</div>',
					'</div>',
				'</div>',
			'</div>',
			'<div class="wrap-loading"></div>',
		'</div>',
	'</div>',
].join("\n");

function getDownloadLinkPeriod(page) {
	var pageOffset = page * 3600 * 24 * 7 * 1000;

	return {
		begin: HFN.toParseDt(Date.now() - (3600 * 24 * 6 * 1000) - pageOffset),
		end: HFN.toParseDt(Date.now() - pageOffset)
	}
}

export function displayDownloadLinkTraffic(traffic, place, page, linkid) {
	var cont = $(stats_template).appendTo(place.empty());
	var days = cont.find('.days');
	var min = 0;
	var periodMin = 0;
	var max = 0;
	var periodMax = 0;
	var sum = 0;
	var cnt = 0;
	var avg = 0;
	var maxHeight = 140;

	var dateRange = getDownloadLinkPeriod(page);
	var timeRange = {
		begin: new Date(dateRange.begin).getTime(),
		end: new Date(dateRange.end).getTime()
	};
	var avgObj;

	function inPeriod(day) {
		var dayTime = new Date(day.dt).getTime();

		return dayTime >= timeRange.begin && dayTime <= timeRange.end;
	}

	traffic = traffic.map(function(day) {
		day.inperiod = inPeriod(day);
		return day;
	});

	traffic.forEach(function(day) {
		console.log('== day', day);

		if (max < parseInt(day.traffic))
			max = parseInt(day.traffic);

		if (min == undefined || min > parseInt(day.traffic))
			min = parseInt(day.traffic);

		if (inPeriod(day)) {
			sum += parseInt(day.traffic);
			cnt += 1;

			if (periodMax < parseInt(day.traffic))
				periodMax = parseInt(day.traffic);

			if (periodMin > parseInt(day.traffic))
				periodMin = parseInt(day.traffic);
		}
	});

	if (cnt) {
		avg = parseFloat((sum / cnt).toFixed(1));
	}

	console.log('=== === ===');
	var yAxisVals = generateYAxisValues(min, max, 3).slice(1);
	console.log(yAxisVals);

	console.log('max', max);
	max = yAxisVals[1];

	console.log('=== === ===');

	console.log('max', max);
	console.log('cnt', cnt);
	console.log('sum', sum);
	console.log('avg', avg);

	console.log('da traffic', traffic);

	days.append(traffic.map(function(day, i) {
		var bar = $('<div></div>')
			.addClass('bar')
			/*.attr('title', [
				'Date: ' + day.dt,
				'Downloads: ' + day.downloads,
				'Traffic: ' + HFN.formatSize(day.traffic)
			].join("<br>\n"))*/
			.append(
				$('<span></span>')
					.html(HFN.toShortDt(day.dt).split('/')[0])
			)
			.css('height', day.traffic / max * maxHeight)
			.attr('alt', day.traffic + ' | ' + max)


		if (day.inperiod) {
			bar.addClass('inperiod');
		}

		if (!day.traffic) {
			bar.addClass('empty');
		}

		if (day.traffic > 0) {
			trackTooltip.setup(bar, {
				text: [
					//HFN.toShortDt(day.dt),
					//day.downloads + ' / ' + HFN.formatSize(day.traffic)

					//'Day: ' + day.dt,
					'Downloads: ' + day.downloads,
					'Traffic: ' + HFN.formatSize(day.traffic)
				].join("<br>\n"),
				html: true,
				verticalPosition: trackTooltip.verticalPositionEnum.TOP,
				horizontalPosition: trackTooltip.horizontalPositionEnum.CENTER,
				container: cont
			});
		}

		return bar;
	}));

	days.append('<div class="underline"></div>');


	/* calculating Lines */

	var perSlice = 250;
	while (max / perSlice > 1024) {
		console.log(max, perSlice, 1024);
		perSlice *= 1024;
	}

	var per90 = Math.trunc((.90 * max) / perSlice) * perSlice;
	var per50 = Math.trunc((.55 * max) / perSlice) * perSlice;

	function lineVal(val) {
		if (val / max > 1.15) {
			return;
		}

		days.append(
			$('<div class="line-legend left"></div>')
				.text(HFN.formatSize(val))
				.css('bottom', (val / max * maxHeight) + 'px')
		);

		days.append(
			$('<div class="line-legend right"></div>')
				.text(HFN.formatSize(val))
				.css('bottom', (val / max * maxHeight) + 'px')
		);

		if (val) {
			days.append(
				$('<div class="line">')
					.css('bottom', (val / max * maxHeight) + 'px')
			);
		}
	}


	// drawing lines
	lineVal(yAxisVals[1]);
	lineVal(yAxisVals[0]);
	lineVal(0);


	cont.find('.title').text(__('weekly_traffic_statistics'));
		//.html(HFN.formatDt(dateRange.begin) + ' - ' + HFN.formatDt(dateRange.end));

	var prev;
	var next;

	cont.find('.summary-text')
		.append(
			prev = $('<div class="prev"></div>')
				.attr('title', 'Previous Week')
				.tooltip()
				.on('click', function(e) {
					cont.find('.linkstats-wrap').addClass('inloading');
					showDownloadLinkTrafficSummary(linkid, place, page + 1);
				})
		)
		.append(HFN.toBeautyDate(dateRange.begin) + ' - ' + HFN.toBeautyDate(dateRange.end))
		.append(
			next = $('<div class="next"></div>')
				.attr('title', 'Next Week')
				.tooltip()
				.on('click', function(e) {
					cont.find('.linkstats-wrap').addClass('inloading');
					showDownloadLinkTrafficSummary(linkid, place, page - 1);
				})
		);

	if (page == 0) {
		next.addClass('inactive').off('click').tooltip('destroy');
	}

	/*
	setTimeout(function() {
		cont.find('.title').css('margin-left', -1 * cont.find('.title').outerWidth() / 2);
		cont.find('.summary-text').css('margin-left', -1 * cont.find('.summary-text').outerWidth() / 2);
	}, 1);
	*/

	return cont;
}

export function getDownloadLinkTrafficSummary(linkid, page, cb) {
	var trafficPeriod 	= getDownloadLinkPeriod(page);
	var bTimestamp 		= new Date(trafficPeriod.begin).getTime();
	var eTimestamp 		= new Date(trafficPeriod.end).getTime();
	var params = {
		bdate: trafficPeriod.begin,
		edate: trafficPeriod.end,
		linkids: linkid,
		groupby: 'date'
	};
	var data = {};

	// prefill
	for (var i = bTimestamp; i <= eTimestamp; i += 3600 * 24 * 1000) {
		data[HFN.toParseDt(i)] = {
			dt: HFN.toParseDt(i),
			traffic: 0,
			downloads: 0
		};
	}

	HFN.apiMethod('getpublinkstats', params, function(ret) {
		for (var n = 0; n < ret.stats.length; ++n) {
			data[ret.stats[n].dt] = ret.stats[n];
		}

		cb(ob2ar(data));
	});
}

export function showDownloadLinkTrafficSummary(linkid, place, page = 0, onSuccess = () => {}) {
	getDownloadLinkTrafficSummary(linkid, page, function(traffic) {
    displayDownloadLinkTraffic(traffic, place, page, linkid);
    onSuccess();
	});
}
