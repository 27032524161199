import React, { useMemo } from "react";
import styled from "styled-components";

import FileIcon from "../../../FileIcon";
import { getIconType, getContentOptions } from "../../../../lib/utils";
import { __ } from "../../../../lib/translate";
import { OPTIONS } from "../../../../config/options";
import { IconButton } from "../../UI/Style";

const onPlayButtonClick = (event, metadata) => {
    event.preventDefault();
    event.stopPropagation();

    HFN.audioPlayer.play(HFN.getAlbumSongs({ songs: Object.values(metadata.items) }));
};

const contentActionButtons = (metadata, options) => {
    const buttons = options.map(option => {
        const optionData = OPTIONS[option] || {};
        const { icon, optionTitle, onClick } = optionData;

        return (
            <IconButton
                key={option}
                data-id={metadata.id}
                className={"listRow"}
                onClick={() => onClick([metadata])}
                data-tooltip-content={__(...optionTitle)}
                data-tooltip-id="cv-tooltip"
            >
                <i className={"smallIcon " + icon} />
            </IconButton>
        );
    });

    return (
        <ActionButtonsWrapper>
            {buttons}
        </ActionButtonsWrapper>
    );
}

const LeftSideview = ({ contentData, isMobileView, isMobileDevice }) => {
    const isAlbum = contentData.isalbum || false;
    const isPlaylist = contentData.isplaylist || false;
    const itemsCount = Object.keys(contentData.items || {}).length;
    const iconType = useMemo(() => getIconType(contentData), [contentData]);
    const mobileClassName = isMobileDevice ? "mobile" : "";

    const availableOptions = isPlaylist ? useMemo(() => getContentOptions({
        content: [{ isplaylist: true }]
    }), [isPlaylist]) : null;

    return (
        <Wrapper className={mobileClassName}>
            <Cover className={mobileClassName}>
                <FileIcon item={iconType} type={HFN.ICONS.SVG_AUDIO_SIDEVIEW_COVER} metadata={contentData} thumbOptions={{ crop: 1 }} />
            </Cover>
            <MobileInfoWrapper className={mobileClassName}>
                {isMobileDevice ?
                    <Info className={mobileClassName}>
                        <Title>

                            {isAlbum && <>
                                {contentData.artistname} - {contentData.name}
                            </>}
                            {isPlaylist && <>
                                {contentData.name} - {itemsCount} {__('Songs').toLowerCase()}
                            </>}
                        </Title>
                    </Info>
                    :
                    <Info>
                        <Title>
                            {isAlbum && contentData.artistname}
                            {isPlaylist && contentData.name}
                        </Title>
                        <Subtitle>
                            {isAlbum && contentData.name}
                            {isPlaylist && <>
                                {itemsCount} {__('Songs').toLowerCase()}
                            </>}
                        </Subtitle>
                    </Info>
                }
                <MobileActionsWrapper className={mobileClassName}>
                    {itemsCount ?
                        <PlayButton className={mobileClassName} onClick={(event) => { onPlayButtonClick(event, contentData) }}>
                            {__("Play")}
                        </PlayButton> : null
                    }
                    {isPlaylist && isMobileDevice && contentActionButtons(contentData, availableOptions.main)}
                </MobileActionsWrapper>
                {isPlaylist && !isMobileDevice && contentActionButtons(contentData, availableOptions.main)}
            </MobileInfoWrapper>
        </Wrapper>
    );
};

export default LeftSideview;

const Wrapper = styled.div`
    position: absolute;
    left: 0;
    top: 7px;
    width: 180px;

    &.mobile {
        top: 0;
        width: 100%;
        height: calc(100px + var(--spacing-sm));
        display: flex;
        padding: 0 var(--spacing-sm);
    }
`;

const Cover = styled.div`
    width: 180px;
    height: 180px;
    background: var(--surface-secondary);
    border-radius: var(--radius-minimum);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;

    &:not(.thumbready) .iconwrap {
        margin: 0;
        width: 40%;
        height: 40%;

        img {
            width: 100% !important;
            height: 100% !important;
        }
    }

    &.thumbready {
        border: 1px solid var(--border-secondary);

        .iconwrap {
            margin: 0 !important;

            img {
                width: 100% !important;
                height: 100% !important;
            }
        }
    }

    &.mobile {
        width: 100px;
        height: 100px;

        &.thumbready {
            .iconwrap {
                img {
                    width: 100px !important;
                    height: 100px !important;
                }
            }
        }
    }
`;

const MobileInfoWrapper = styled.div`
    &.mobile {
        display: flex;
        flex-direction: column;
        padding-left: var(--spacing-md);
    }
`;

const Info = styled.div`
    margin: var(--spacing-sm) 0;
    padding: var(--spacing-sm) var(--spacing-xs);
    display: flex;
    flex-direction: column;
    gap: 2px;

    &.mobile {
        padding: var(--spacing-xs);
        padding-top: 0;
    }
`;

const Title = styled.div`
    font-size: var(--base-font-size);
`;

const Subtitle = styled.div`
    font-size: 12px;
    color: var(--text-secondary);
`;

const PlayButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--button-primary-background-default);
    cursor: pointer;
    color: var(--color-base-white);
    padding: var(--spacing-sm) 0;
    font-size: 15px;
    font-weight: 500;
    height: 40px;
    text-align: center;
    border-radius: var(--radius-minimum);
    box-sizing: border-box;

    &.mobile {
        width: fit-content;
        padding: var(--spacing-sm) var(--spacing-xl);
    }
`;

const ActionButtonsWrapper = styled.div`
    display: flex;
    padding-top: var(--spacing-sm);
    justify-content: space-between;
`;

const MobileActionsWrapper = styled.div`
    &.mobile {
        display: flex;
        
        ${ActionButtonsWrapper} {
            align-items: center;
            padding-top: 0;
            padding-left: var(--spacing-sm);
            gap: var(--spacing-xs);
        }
    }
`;
