import React, { useState, useRef } from "react";
import styled from "styled-components";
import { Input } from "../styles";
import PasswordStrengthMeter from "@pcloud/web-utilities/dist/authentication/components/passUserAuthentication/SetPassStep/PasswordStrengthMeter";

const PasswordInput = ({
    placeholder = "",
    className = "",
    value = "",
    usePasswordChecker = false,
    autoFocus = false,
    invalid = false,
    onChange = () => {},
    setRequirementsAreMet = () => {},
    ...rest
}) => {
    const inputRef = useRef(null);
    const [showPassword, setShowPassword] = useState(false);

    return (
        <Wrapper>
            <Input
                ref={inputRef}
                value={value}
                onChange={onChange}
                className={className}
                autoFocus={autoFocus}
                autoComplete="off"
                placeholder={placeholder}
                $isInvalid={!!invalid}
                type={showPassword ? "text" : "password"}
                {...rest}
            />
            {usePasswordChecker && (
                <PasswordCheckerWrapper>
                    <PasswordStrengthMeter
                        score={28}
                        password={value}
                        numberOfSections={6}
                        onRequirementsMeet={() => setRequirementsAreMet(true)}
                        onRequerementsReject={() =>
                            setRequirementsAreMet(false)
                        }
                    />
                </PasswordCheckerWrapper>
            )}
            {!!invalid && <Error>{invalid}</Error>}
            {inputRef.current && inputRef.current.value && (
                <ShowPasswordBtn onClick={() => setShowPassword(!showPassword)}>
                    <i
                        className={
                            showPassword
                                ? "fa-solid fa-eye"
                                : "fa-solid fa-eye-slash"
                        }
                    ></i>
                </ShowPasswordBtn>
            )}
        </Wrapper>
    );
};

export default PasswordInput;

const Wrapper = styled.div`
    width: 100%;
    position: relative;

    & input {
        padding-right: 40px;
    }
`;

const PasswordCheckerWrapper = styled.div`
    width: 100%;
    display: flex;
    padding: 8px 18px 6px 18px;
    box-sizing: border-box;
    flex-direction: column;

    & > div {
        margin: 0;

        & > div {
            height: 3px;
            border-radius: 3px;
        }
    }
`;

const Error = styled.div`
    color: var(--text-danger);
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
    margin: 2px 16px 0;
`;

const ShowPasswordBtn = styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-secondary);
    font-size: 18px;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
`;
