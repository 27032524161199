// @flow

import React, { Fragment, useState, useEffect, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import styled, { keyframes } from "styled-components";
import { hiDPI } from "polished";

import ArrowLeft from "../../root/img/svg/pagination-arrow-left.svg";
import ArrowRight from "../../root/img/svg/pagination-arrow-right.svg";
import { __ } from "../lib/translate";

const MAX_PAGES = 10;
const FIRST_PAGE = 1;

type Props = {
  numOfPages: number,
  itemsPerPage: number,
  getCurrentPage: () => {}
};

const Pagination = ({ numberOfItems = 200, itemsPerPage = 10, getCurrentPage = () => {}}: Props) => {
  const [activePage, setActivePage] = useState(1);
  const [availablePages, setAvailablePages] = useState([]);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [listOfPages, seListOfPages] = useState([]);

  useEffect(() => {
    getCurrentPage(activePage);
  }, [activePage])

  useEffect(() => {
    const numOfPages = Math.ceil(numberOfItems / itemsPerPage);
    let pages = [];
    setNumberOfPages(numOfPages);
    for (let index = 0; index < numOfPages; index++) {
      pages.push(index + 1);
    }
    seListOfPages(pages);

    if (numOfPages <= MAX_PAGES) {
      setAvailablePages([...pages]);
    } else {
      const firstElements = pages.slice(0, MAX_PAGES - 1);
      const [lastItem] = pages.slice(-1);
      setAvailablePages([...firstElements, "...", lastItem]);
    }
  }, [itemsPerPage, numberOfItems]);

  const updateAvailablePages = (pageNumber) => {
    if (numberOfPages < MAX_PAGES) {
      return;
    }
    let currentPages = [...availablePages];
    const pageIndex = currentPages.findIndex((el) => el === pageNumber);

    // click on element before "..." in the end
    if (pageIndex === currentPages.length - 3) {
      if (typeof currentPages[2] === "string") {
        // remove "..." in the beggining
        currentPages.splice(2, 1);
      } else {
        // remove two elements from index 1 -> 2
        currentPages.splice(1, 2);
        // add "..." in the beginnig
        currentPages.splice(1, 0, "...");
      }

      if (pageNumber === listOfPages[listOfPages.length - 3]) {
        // remove "..." in the end
        currentPages.splice(currentPages.length - 2, 1);
        currentPages.splice(2, 0, listOfPages[currentPages[3] - 1]);
      }
      // add element in the end
      currentPages.splice(pageIndex, 0, listOfPages[pageNumber]);
    }

    // click on element after "..." in the beginnig
    if (pageIndex === 2 && typeof currentPages[1] === "string") {
      // add element before clicked page
      currentPages.splice(2, 0, listOfPages[pageNumber - 2]);

      // remove "..." in the beginning
      if (pageNumber == 3) {
        currentPages.splice(1, 1);
        currentPages.splice(8, 0, listOfPages[8]);
      }

      if (typeof currentPages[currentPages.length - 2] !== "string") {
        // add "..." in the end
        currentPages.splice(currentPages.length - 1, 0, "...");
      }
      // remove element before "..." in the end
      currentPages.splice(currentPages.length - 3, 1);
    }

    // click on first element
    if (pageIndex === 0) {
      const [lastItem] = listOfPages.slice(-1);
      const lastElements = ["...", lastItem];
      // get firts 8 elements
      currentPages = listOfPages.slice(0, 9);
      // add last 2 elements
      currentPages.push(...lastElements);
    }

    // click on last element
    if (pageIndex === MAX_PAGES) {
      const firstElements = [1, "..."];
      // get last 8 elements
      currentPages = listOfPages.slice(-8);
      // add first 2 elements
      currentPages.unshift(...firstElements);
    }
    setAvailablePages(currentPages);
  };

  const onFirstClick = () => {
    const [firstElement, ...rest] = listOfPages;
    setActivePage(firstElement);
    updateAvailablePages(firstElement);
  };

  const onPageClick = (pageNumber) => {
    setActivePage(pageNumber);
    updateAvailablePages(pageNumber);
  };

  const onPrevClick = () => {
    if (activePage > listOfPages.slice(0, 1)[0]) {
      const pageNumber = activePage - 1;

      setActivePage(pageNumber);
      updateAvailablePages(pageNumber);
    }
  };

  const onNextClick = () => {
    if (activePage < listOfPages.length) {
      const pageNumber = activePage + 1;

      setActivePage(pageNumber);
      updateAvailablePages(pageNumber);
    }
  };

  const onLastClick = () => {
    const [lastItem] = listOfPages.slice(-1);

    setActivePage(lastItem);
    updateAvailablePages(lastItem);
  };

  const renderPage = (pageNumber) => {
    if (pageNumber === "...") {
      return <BreakLabel>...</BreakLabel>;
    } else {
      return (
        <Page
          key={`page-number-${pageNumber}`}
          className={activePage === pageNumber ? "active" : ""}
          onClick={() => onPageClick(pageNumber)}
        >
          {pageNumber}
        </Page>
      );
    }
  };

  console.log({ availablePages });
  return (
    <Wrapper>
      <First onClick={onFirstClick}>{__("First")}</First>
      <Prev onClick={onPrevClick}>
        <ArrowLeftIcon width="14px" />
      </Prev>
      {availablePages.map(renderPage)}
      <Next onClick={onNextClick}>
        <ArrowRightIcon width="14px" />
      </Next>
      <Last onClick={onLastClick}>{__("Last")}</Last>
    </Wrapper>
  );
};

export default Pagination;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const First = styled.div`
  box-sizing: border-box;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #b1b1b1;
  background-color: #fff;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 500;
  color: #69696e;
  cursor: pointer;

  &:hover {
    color: #000;
  }
`;

const Last = First;

const Prev = styled.div`
  margin-left: 30px;
  cursor: pointer;
`;

const Next = styled.div`
  margin-right: 30px;
  cursor: pointer;
`;

const Page = styled.div`
  height: 32px;
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 32px;
  letter-spacing: normal;
  text-align: left;
  color: #69696e;
  padding: 0 15px;
  cursor: pointer;
  box-sizing: border-box;

  &:hover {
    color: #000;
  }

  &.active {
    border-radius: 5px;
    color: #fff;
    background-color: #17bed0;
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`;

const ArrowLeftIcon = styled(ArrowLeft)`
  margin-right: 15px;
  &:hover {
    fill: red !important;
  }
`;

const ArrowRightIcon = styled(ArrowRight)`
  margin-left: 15px;
  &:hover {
    fill: red !important;
  }
`;

const BreakLabel = styled.div`
  box-sizing: border-box;
  padding: 8px 12px 6px;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 500;
  color: #69696e;
`;
