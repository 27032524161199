import React from "react";
import {
    ButtonWrapper,
    ExpandedContent,
    ExpandedContentDefault,
    ExpandedContentError,
    ExpandedContentLabel,
    ExpandedContentRow,
    ExpandedContentValue,
    LinkButton,
    Progress,
    ProgressBar,
    ProgressContainer
} from "../styles";
import { __ } from "../../../../../../lib/translate";
import { AnimatePresence } from "framer-motion";
import { SERVICES_INFO } from "../../../../configurations";
import { getErrorReason } from "../../../../../ThirdpartyBackupComponents/constants";
import styled from "styled-components";
import Button from "../../../../../ButtonDefault";
import InitButton from "./InitButton";

const MobileSubLayout = ({
    backup_metadata,
    exit_code,
    is_running,
    is_paused,
    errorState,
    type,
    status,
    sourceid,
    accountInfo,
    passedNextExecutionDate,
    NEXT_DATE_FORMATTED,
    LAST_DATE_FORMATTED,
    PROGRESS_PERCENT,
    triggerClick,
    onButtonClick,
    unlinkAccount,
    disabledButton,
    isOverquota,
    isExpanded
}) => {
    return (
        <>
            <AnimatePresence exitBeforeEnter>
                {isExpanded && (
                    <ExpandedContent
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        initial={{ height: 0, opacity: 0, marginTop: 0 }}
                        animate={{
                            height: "auto",
                            opacity: 1,
                            marginTop: 16
                        }}
                        exit={{ height: 0, opacity: 0, marginTop: 0 }}
                        transition={{ duration: 0.3 }}
                    >
                        {backup_metadata && (
                            <ExpandedContentRow>
                                <ExpandedContentLabel>
                                    {__("Folder name")}
                                    <br />
                                    <LinkButton
                                        $fontSize={14}
                                        onClick={(e) => {
                                            triggerClick(
                                                e,
                                                backup_metadata.folderid
                                            );
                                        }}
                                    >
                                        <span>/</span>
                                        {backup_metadata.name}
                                    </LinkButton>
                                </ExpandedContentLabel>
                            </ExpandedContentRow>
                        )}
                        {type === "ongoing" && NEXT_DATE_FORMATTED && (
                            <ExpandedContentRow>
                                <ExpandedContentLabel>
                                    {__("next_backup", "Next backup")}
                                </ExpandedContentLabel>
                                <ExpandedContentValue>
                                    {NEXT_DATE_FORMATTED}
                                </ExpandedContentValue>
                            </ExpandedContentRow>
                        )}
                        {LAST_DATE_FORMATTED && (
                            <ExpandedContentRow>
                                <ExpandedContentLabel>
                                    {__("last_backup", "Last backup")}
                                </ExpandedContentLabel>
                                <ExpandedContentValue>
                                    {LAST_DATE_FORMATTED}
                                </ExpandedContentValue>
                            </ExpandedContentRow>
                        )}
                        {errorState && (
                            <ExpandedContentRow>
                                <ExpandedContentError>
                                    {getErrorReason(exit_code)}
                                </ExpandedContentError>
                            </ExpandedContentRow>
                        )}
                        {!accountInfo &&
                            !backup_metadata &&
                            !LAST_DATE_FORMATTED &&
                            !errorState && (
                                <ExpandedContentRow>
                                    <ExpandedContentDefault>
                                        {__(
                                            "thirdparty_backups_default_message",
                                            "Import your files from %name% to pCloud.",
                                            {
                                                name:
                                                    SERVICES_INFO[sourceid]
                                                        ?.name
                                            }
                                        )}
                                    </ExpandedContentDefault>
                                </ExpandedContentRow>
                            )}
                    </ExpandedContent>
                )}
            </AnimatePresence>
            <ControlsWrapper>
                {status === "running" && !errorState ? (
                    <ProgressContainer>
                        <ProgressBar>
                            <Progress $progress={PROGRESS_PERCENT} $isactive />
                        </ProgressBar>
                    </ProgressContainer>
                ) : (
                    <>
                        {accountInfo && !is_running && !is_paused && (
                            <ButtonWrapper>
                                <Button
                                    size="bigger"
                                    padding="12px 20px"
                                    fontWeight="400"
                                    textSize={15}
                                    borderRadius="50"
                                    minWidth="81px"
                                    color="lightCyan"
                                    onClick={unlinkAccount}
                                >
                                    {__("Disconnect")}
                                </Button>
                            </ButtonWrapper>
                        )}

                        <InitButton
                            disabledButton={disabledButton}
                            type={type}
                            status={status}
                            passedNextExecutionDate={passedNextExecutionDate}
                            onButtonClick={onButtonClick}
                            isOverquota={isOverquota}
                        />
                    </>
                )}
            </ControlsWrapper>
        </>
    );
};

export default MobileSubLayout;

const ControlsWrapper = styled.div`
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: end;
    padding: var(--spacing-md) var(--spacing-md) 0;
`;
