import React, { useEffect, useState, useRef } from "react";
import LocoIcon from "../../root/img/time/logo.svg";
import { __ } from "../lib/translate";
import styled from "styled-components";
import useWindowDimensions from "../lib/hooks/useWindowDimensions";

const TimeHome = () => {
    const { bodyWidth } = useWindowDimensions();
    const isMobileView = bodyWidth < 800;
    const dateRef = useRef(null);
    const [datepickerContent] = useState(
        Gridlist.templates.folderlist.getTimePicker()
    );
    const showExtendInfo = !HFN.config.isEFH() && !HFN.config.isBusiness();

    useEffect(() => {
        $(dateRef.current)
            .empty()
            .append(datepickerContent);
    }, [isMobileView]);

    return (
        <Wrapper>
            <LogoWrapper>
                <LocoIcon />
            </LogoWrapper>
            {isMobileView && (
                <DateWrapper className="time-heading-inner" ref={dateRef} />
            )}
            <InfoWrapper>
                <Title>
                    {__(
                        "time_title",
                        "Access up to %days% days of account history.",
                        {
                            days: HFN.config.user.trashrevretentiondays
                        }
                    )}
                </Title>
                <Description>
                    {__(
                        "time_description",
                        "Rewind helps you recover old file revisions, shared work, or files moved to the Trash. Permanently deleted files can't be recovered. Pick a date and rewind."
                    )}

                    <LearnMore
                        target="_blank"
                        href="https://www.pcloud.com/help/general-help-center/what-is-pcloud-rewind"
                    >
                        &nbsp;{__("learn_more", "Learn more")}
                    </LearnMore>
                </Description>
            </InfoWrapper>
            {!isMobileView && (
                <DateWrapper className="time-heading-inner" ref={dateRef} />
            )}
            <ContentImageWrapper>
                <img src={window.devicePixelRatio >= 2 ? "/ZCDNURL/img/time/content-image@x2.png" : "/ZCDNURL/img/time/content-image.png"} />
            </ContentImageWrapper>
            {showExtendInfo && (
                <ButtonWrapper>
                    <ButtonInfo>
                        {__(
                            "time_extend_info",
                            "Get Extended File History to keep revisions for up to one year."
                        )}
                    </ButtonInfo>
                    <Link href="https://www.pcloud.com/cloud-storage-pricing-plans.html?step=purchase&product=extended-history&period=year">
                        {__("efh_extendnow")}
                    </Link>
                </ButtonWrapper>
            )}
        </Wrapper>
    );
};

export default TimeHome;

const Wrapper = styled.div`
    display: flex;
    padding: var(--spacing-xs) var(--spacing-md) var(--spacing-md)
        var(--spacing-md);
    flex-direction: column;
    justify-content: start;
    align-items: center;
    gap: 32px;
    align-self: stretch;
    overflow: auto;
    height: calc(100dvh - 80px);

    & .time-heading-inner {
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: 800px) {
            justify-content: start;
        }
    }

    & .time-heading-inner,
    & .time_settings {
        margin: 0;
        padding: 0;
        width: 100%;
    }

    @media (max-width: 1023px) {
        height: calc(100dvh - 150px);
    }

    @media (max-width: 800px) {
        align-items: start;
        height: calc(100dvh - 150px);
    }
`;
const LogoWrapper = styled.div`
    @media (max-width: 800px) {
        display: none;
    }
`;
const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    align-self: stretch;
    text-align: center;

    @media (max-width: 800px) {
        align-items: start;
        text-align: left;
    }
`;
const ContentImageWrapper = styled.div`
    img {
        width: 100%;
        max-width: 740px;
    }
`;
const Title = styled.div`
    color: #000;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
`;
const Description = styled.div`
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
`;
const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    align-self: stretch;

    @media (max-width: 800px) {
        align-items: start;
        text-align: left;
    }
`;
const ButtonInfo = styled.div`
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
`;
const Link = styled.a`
    text-decoration: none;
    border-radius: 143px;
    background: linear-gradient(180deg, #45c91f 5.43%, #3bb01b 100%);
    width: 100%;
    max-width: 340px;
    height: 48px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    align-self: center;
`;
const DateWrapper = styled.div``;
const LearnMore = styled.a`
    text-decoration: none;
    color: var(--text-link);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
`;
