// @flow

import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Modal, { OvalModalWrap, OvalModalCloseV2 } from "../../Modal";
import Button from "../../ButtonDefault";
import * as Form from "../../FormSharedComponents";
import * as Style from "../styledComponents";
import InputText from "../../InputText";
import InputStyledCheckbox from "../../InputStyledCheckbox";

import apiMethod from "../../../api/apiMethod";
import { __ } from "../../../lib/translate";
import hashManager from "../../../lib/hashManager";

const CreateNewTeamModal = ({ folderid = 0 }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [teamName, setTeamName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [shouldOpenAfter, setShouldOpenAfter] = useState(false);

  useEffect(() => {
    setIsOpen(true);
  }, []);

  const handleClose = () => {
    setIsOpen(false);
    setTeamName("");
  };

  const onTeamNameChange = ({ target }) => {
    setTeamName(target.value);
  };

  const onEnter = (e: any) => {
    if (e.keyCode === 13) {
      onCreateButtonClick();
    }
  };

  const onCreateButtonClick = () => {
    if(isLoading) {
      return;
    }
    setIsLoading(true);

    if (teamName.trim().length < 1) {
      HFN.message(__("error_team_name_required", "Team name is required."), "error");
      setIsLoading(false);
      return;
    }

    apiMethod('account_teamcreate', { name: teamName }, function (ret) {
      HFN.message(__('Team Created'));
      PCB.refreshAll();
      handleClose();

      if (shouldOpenAfter) {
        hashManager.pushState({ page: 'b_team', id: ret.team.id });
      }

      if (PCB.hltips) {
        PCB.hltips.callback(ret);
      }
    }, {
      errorCallback: ({ error, result, field }) => {
        setIsLoading(false);
        let errorMessage = __("something_went_wrong_refresh_and_try_again");

        if (result === 2103) {
          if (field === "name") {
            errorMessage = __("business_edit_user_team_name_error");
          }
        } else if (result === 2082) {
          errorMessage = __("business_edit_user_team_name_exists_error");
        } else if (result === 2076) {
          errorMessage = __("error_2076", "You do not have the permissions for this action.");
        }

        HFN.message(errorMessage, "error");
        throw new Error(error);
      }
    });
  };

  return (
    <Modal animate onClose={handleClose} opened={isOpen}>
      <OvalModalWrap>
        <OvalModalCloseV2 onClick={handleClose} />
        <Style.Container>
          <Style.Header>{__("create_team", "Create Team")}</Style.Header>
          <Style.Label>{__("Name", "Name")}</Style.Label>
          <InputText
            name="teamName"
            onChange={onTeamNameChange}
            value={teamName}
            autoFocus
            onKeyDown={onEnter}
          />
          <Style.CheckboxWrapper>
            <Style.CheckboxLabel for="open">
              <InputStyledCheckbox
                id="open"
                name="openLocationAfter"
                size="small"
                checked={shouldOpenAfter}
                onChange={e => setShouldOpenAfter(e.target.checked)}
              />
              <Style.CheckboxLabelText>{__("Open after creation")}</Style.CheckboxLabelText>
            </Style.CheckboxLabel>
          </Style.CheckboxWrapper>
          <Style.Footer>
            <Button
              color="lightgray4"
              style={{
                marginRight: "5px"
              }}
              onClick={handleClose}
            >
              {__("Cancel")}
            </Button>
            <Button
              color="cyan"
              style={{
                marginLeft: "5px"
              }}
              disabledColor="#dddddd"
              onClick={onCreateButtonClick}
            >
              {__("Create")}
            </Button>
          </Style.Footer>
        </Style.Container>
      </OvalModalWrap>
    </Modal>
  );
};

export default CreateNewTeamModal;
