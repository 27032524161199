import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { __ } from "../../../lib/translate";
import apiMethod from "../../../api/apiMethod";
import { findIndex } from "../../../../web-shared/utils";
import {
    DEFAULT_MODAL_SETTINGS,
    DEFAULT_CATEGORY_DATA,
    DEFAULT_ONGOING_BACKUPS_STATE,
    SOURCES
} from "../configurations";

const useBackupControls = ({ snimp }) => {
    const [categoryData, setCategoryData] = useState(DEFAULT_CATEGORY_DATA);
    const [backupsState, setBackupsState] = useState(
        DEFAULT_ONGOING_BACKUPS_STATE
    );
    const [modalSettings, setModalSettings] = useState(DEFAULT_MODAL_SETTINGS);
    const [isBackupStateLoading, setIsBackupStateLoading] = useState(true);
    const [initialLoadingComplete, setInitialLoadingComplete] = useState(false);

    let updateTimeout = useRef(null);
    const usedquota = useSelector(({ user }) => user.userinfo.usedquota);
    const quota = useSelector(({ user }) => user.userinfo.quota);
    const isOverquota = usedquota >= quota;

    useEffect(() => {
        updateBackupsState();
    }, []);

    useEffect(() => {
        clearTimeout(updateTimeout.current);
        const hasRunningBackup = backupsState.some(
            ({ is_running, is_paused }) => is_running || is_paused
        );

        console.log("hasRunningBackup", hasRunningBackup);
        if (hasRunningBackup) {
            updateTimeout.current = setTimeout(() => {
                updateBackupsState();
            }, 15000);
        }
    }, [backupsState]);

    const closeModal = () => {
        setModalSettings(DEFAULT_MODAL_SETTINGS);
    };

    const insertThirdPartyStatesIntoCategory = (categoryDataCopy, backupsStateCopy) => {
        const getBackupsByType = (type) => {
            return backupsStateCopy.filter(({ type: currentBackupType }) => currentBackupType === type);
        }

        const oneTimeBackups = getBackupsByType("onetime");
        const ongoingBackups = getBackupsByType("ongoing");

        const oneTimeIndex = categoryDataCopy.findIndex((item) => {
            return item.id === "one-time";
        });
        const ongoingIndex = categoryDataCopy.findIndex((item) => {
            return item.id === "ongoing";
        });
        categoryDataCopy[oneTimeIndex].items = oneTimeBackups;
        categoryDataCopy[ongoingIndex].items = ongoingBackups;
        return categoryDataCopy;
    }

    const openStopModal = ({ backupID }) => {
        const sourceData = new SOURCES[backupID]();
        console.log("sourceData", sourceData, SOURCES, backupID);

        setModalSettings({
            isOpened: true,
            primaryClick: () => stopBackup({ backupID }),
            primaryText: __("Stop Backup"),
            secondaryClick: closeModal,
            secondaryText: __("Cancel"),
            title: __("Stop Backup") + ": " + sourceData.title,
            description:
                __("You are stopping the backup of") + ": " + sourceData.title,
            primaryColor: "red",
            onClose: closeModal
        });
    };

    const stopBackup = ({ backupID, successCallback }) => {
        const backupStateCopy = [...backupsState];
        let categoryDataCopy = [...categoryData];
        const backupObj = backupStateCopy.find(
            ({ sourceid }) => sourceid === backupID
        );
        const { sourceid } = backupObj;

        apiMethod(
            "backup_modify",
            {
                type: sourceid,
                action: "stop"
            },
            (ret) => {
                backupObj.is_running = false;
                backupObj.is_paused = false;
                backupObj.is_stopped = true;
                backupObj.is_aborted = false;
                backupObj.is_inactive = false;
                backupObj.next_execution = null;
                setBackupsState(backupStateCopy);

                categoryDataCopy = insertThirdPartyStatesIntoCategory(categoryDataCopy, backupStateCopy);
                setCategoryData(categoryDataCopy);

                if (successCallback) {
                    successCallback();
                } else {
                    HFN.message(__("Backup stopped."));
                    closeModal();
                }
            }
        );
    };

    const changeBackupStatus = ({ backupID }) => {
        const backupStateCopy = [...backupsState];
        const backupObj = backupStateCopy.find(
            ({ sourceid }) => sourceid === backupID
        );
        if (
            backupObj.is_stopped ||
            backupObj.is_inactive ||
            backupObj.type == "onetime"
        ) {
            backupObj.srcobj.getTokenCallback((token, expire) => {
                snimp.initStartBackup(backupObj.sourceid, () => {
                    backupObj.is_stopped = false;
                    backupObj.is_running = true;
                    backupObj.is_paused = false;
                    backupObj.is_aborted = false;
                    backupObj.is_inactive = false;
                    setBackupsState(backupStateCopy);

                    let categoryDataCopy = [...categoryData];
                    categoryDataCopy = insertThirdPartyStatesIntoCategory(categoryDataCopy, backupStateCopy);
                    setCategoryData(categoryDataCopy);
                });
            }, !backupObj.srcobj.token);
        } else if (backupObj.is_aborted) {
            backupObj.srcobj.getTokenCallback((token, expire) => {
                snimp.initRestartBackup(backupObj.sourceid, () => {
                    backupObj.is_running = true;
                    backupObj.is_paused = false;
                    backupObj.is_stopped = false;
                    backupObj.is_aborted = false;
                    backupObj.is_inactive = false;
                    setBackupsState(backupStateCopy);

                    let categoryDataCopy = [...categoryData];
                    categoryDataCopy = insertThirdPartyStatesIntoCategory(categoryDataCopy, backupStateCopy);
                    setCategoryData(categoryDataCopy);
                });
            }, !backupObj.srcobj.token);
        } else if (
            !backupObj.is_stopped &&
            !backupObj.is_aborted &&
            !backupObj.is_running &&
            !backupObj.is_paused
        ) {
            openStopModal({ backupID });
        } else if (
            !backupObj.is_stopped &&
            !backupObj.is_running &&
            !backupObj.is_paused &&
            new Date(backupObj.finished).getTime() <
                new Date().getTime() - 30 * 60 * 1000
        ) {
            backupObj.srcobj.getTokenCallback((token, expire) => {
                snimp.initRestartBackup(backupObj.sourceid, () => {
                    backupObj.is_running = true;
                    backupObj.is_paused = false;
                    backupObj.is_stopped = false;
                    backupObj.is_aborted = false;
                    backupObj.is_inactive = false;
                    setBackupsState(backupStateCopy);

                    let categoryDataCopy = [...categoryData];
                    categoryDataCopy = insertThirdPartyStatesIntoCategory(categoryDataCopy, backupStateCopy);
                    setCategoryData(categoryDataCopy);
                });
            }, !backupObj.srcobj.token);
        }
    };

    const updateBackupsState = () => {
        const backupsStateCopy = [...backupsState];
        let categoryDataCopy = [...categoryData];

        const desktopIndex = categoryDataCopy.findIndex((item) => {
            return item.id === "desktop";
        });
        const mobileIndex = categoryDataCopy.findIndex((item) => {
            return item.id === "mobile";
        });

        categoryDataCopy[desktopIndex].items = [];
        categoryDataCopy[mobileIndex].items = [];
        apiMethod("web/getbackups", { iconformat: "id" }, ({ thirdpartybackups, desktopdevices, mobiledevices }) => {
            let wait = [];

            if (thirdpartybackups.length) {
                thirdpartybackups.forEach((backupinfo) => {
                    const index = findIndex(backupsStateCopy, ({sourceid}) => sourceid === backupinfo.sourceid);
                    backupsStateCopy[index] = { ...backupsStateCopy[index], is_inactive: false, ...backupinfo, sourceid: backupinfo.sourceid };
                });
            }

            if (desktopdevices && desktopdevices.length) {
                desktopdevices.forEach((item) => {
                    let device;
                    switch (item.icon) {
                        case 30:
                            device = "linuxdesk";
                            break;
                        case 28:
                            device = "macdesk";
                            break;
                        case 29:
                            device = "windowsdesk";
                            break;
                    }

                    categoryDataCopy[desktopIndex].items.push({
                        sourceid: device,
                        folderid: item.folderid,
                        modified: item.modified,
                        title: item.name
                    });
                });
            }
            if (mobiledevices && mobiledevices.length) {
                mobiledevices.forEach((item) => {
                    categoryDataCopy[mobileIndex].items.push({
                        sourceid: "mobilephone",
                        folderid: item.folderid,
                        modified: item.modified,
                        title: item.name
                    });
                });
            }

            const onDone = () => {
                setBackupsState(backupsStateCopy);

                categoryDataCopy = insertThirdPartyStatesIntoCategory(categoryDataCopy, backupsStateCopy);
                setCategoryData(categoryDataCopy);
                setIsBackupStateLoading(false);
                setInitialLoadingComplete(true);
            };

            $.when.apply($, wait).then(onDone);
        });
    };

    return {
        categoryData,
        modalSettings,
        isOverquota,
        changeBackupStatus,
        stopBackup,
        isBackupStateLoading,
        initialLoadingComplete
    };
};

export default useBackupControls;
