import React from "react";
import { __ } from "../../../../../lib/translate";
import useWindowDimensions from "../../../../../lib/hooks/useWindowDimensions";
import { MOBILE_WIDTH_BREAKPOINT } from "../../../../../config/constants";
import EmptyDesktopIcon from "../../../../../../root/img/grid/empty-state/songs-desktop.svg";
import EmptyMobileIcon from "../../../../../../root/img/grid/empty-state/songs-mobile.svg";
import {
    EmptyStateMessage,
    EmptyStateMessageSubTitle,
    EmptyStateMessageTitle,
    EmptyStateWrapper,
    ImageContainer
} from "../styles";
import styled from "styled-components";
import { Button } from "../../../Templates/TopBar/UploadButton";
import hashManager from "../../../../../lib/hashManager";

const Songs = ({ noItemsLabel }) => {
    const { bodyWidth, bodyHeight } = useWindowDimensions();
    const isMobile = bodyWidth < MOBILE_WIDTH_BREAKPOINT;

    return (
        <EmptyStateWrapper $isMobile={isMobile}>
            <EmptyStateMessage $isMobile={isMobile}>
                <EmptyStateMessageTitle>
                    {__(...noItemsLabel)}
                </EmptyStateMessageTitle>
                <EmptyStateMessageSubTitleCustom>
                    {__(
                        "songs_empty_message",
                        "Go to My pCloud where your files in the account are, and simply drop your audio files on the page, so you can use play them via our web player after that."
                    )}
                </EmptyStateMessageSubTitleCustom>
            </EmptyStateMessage>

            <ImageContainer $isMobile={isMobile}>
                {!isMobile && <EmptyDesktopIcon />}
                {isMobile && <EmptyMobileIcon />}
            </ImageContainer>

            <ButtonWrapper $isMobile={isMobile || bodyHeight < 450}>
                <Button onClick={() => {
                    hashManager.pushState({page: "filemanager"}, 2)
                }}>
                    {__("go_to_my_label", "Go to My pCloud")}
                </Button>
            </ButtonWrapper>
        </EmptyStateWrapper>
    );
};

export default Songs;

const EmptyStateMessageSubTitleCustom = styled(EmptyStateMessageSubTitle)`
    padding: 16px 0;
`;
const ButtonWrapper = styled.div`
    display: flex;
    margin-top: ${({$isMobile}) => $isMobile ? 'var(--spacing-lg)' : '0'};
    padding: ${({$isMobile}) => $isMobile ? '0' : '64px 0'};
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
`;