// @flow

import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { __ } from "../../lib/translate";
import apiMethod from "../../api/apiMethod";
import * as Style from "./preferences.style";
import Modal, { OvalModalClose, OvalModalWrap } from "../Modal";
import RevealPassword from "../../containers/UserAuthentication2/MultiplatformComponents/Elements/RevealPassword";
import HidePassword from "../../containers/UserAuthentication2/MultiplatformComponents/Elements/HidePassword";
import ButtonDefault from "../ButtonDefault";
import InputPassword from "../InputPassword";

const ChangePassSetting = () => {
  const haspassword = useSelector(({ user }) => user.userinfo.haspassword)
  const [showChangePassModal, setShowChangePassModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPass, setCurrentPass] = useState({
    value: "",
    error: "",
  });
  const [newPass, setNewPass] = useState({
    value: "",
    error: "",
  });
  const [repeatNewPass, setRepeatNewPass] = useState({
    value: "",
    error: "",
  });

  const onModalClose = () => {
    if (loading) {
      return;
    }

    setShowChangePassModal(false)
  };

  const onChange = (event, inputType) => {
    const newValue = event.target.value;

    let set = () => {};

    if (inputType === "current") {
      set = setCurrentPass;
    } else if (inputType === "new") {
      set = setNewPass;
    } else if (inputType === "repeatnew") {
      set = setRepeatNewPass;
    }

    set((prev = {}) => {
      return {
        ...prev,
        value: newValue
      }
    })
  };

  const handleSubmit = event => {
    event.preventDefault();

    if (!currentPass.value || !newPass.value || !repeatNewPass.value) {
      HFN.message(__('Passwords are required.'), 'error');
      return;
    }

    if (newPass.value != repeatNewPass.value) {
      HFN.message(__('Both new passwords should match.'), 'error');
      return;
    }

    setLoading(true);

    apiMethod('changepassword', {
        oldpassword: currentPass.value,
        newpassword: newPass.value,
        regetauth: 1
      }, ret => {
        const userInfo = { ...HFN.config.user };
        userInfo.auth = ret.auth;
        HFN.userLoginAfter(userInfo, ret => {
          HFN.message(__("Your password has been changed."));
          onModalClose();
          setLoading(false);
          HFN.pages.goto('filemanager');
        });
      }, {
        errorCallback: ret => {
          HFN.message(__(ret.error), 'error');
          setLoading(false);
        },
        type: 'POST'
      }
    );
  }

  if (!haspassword) {
    return null;
  }

  return (
    <>
      <Style.Row>
        <Style.Title>{__("Change Your Password")}</Style.Title>
        <Style.Button onClick={() => setShowChangePassModal(true)}>{__("Change")}</Style.Button>
      </Style.Row>
      <Modal opened={showChangePassModal} width="100%">
        <ModalContainer>
          <OvalModalClose onClick={onModalClose}/>
          <ModalHeader>{__("Change Your Password")}</ModalHeader>
          <Form onSubmit={handleSubmit}>
            <InputPassword
              value={currentPass.value}
              disabled={loading}
              autoFocus
              onChange={event => onChange(event, "current")}
              placeholder={__("Old Password")}
              name="current-password"
              autoComplete="current-password"
              id="current-password"
              revealPassword={true}
              InputComponent={Input}

            />
            <InputPassword
              value={newPass.value}
              disabled={loading}
              onChange={event => onChange(event, "new")}
              placeholder={__("New Password")}
              type={newPass.reveal ? "text" : "password"}
              name="new-password"
              autoComplete="new-password"
              id="new-password"
              revealPassword={true}
              InputComponent={Input}
            />
            <InputPassword
              value={repeatNewPass.value}
              disabled={loading}
              onChange={event => onChange(event, "repeatnew")}
              placeholder={__("Repeat New Password")}
              type={repeatNewPass.reveal ? "text" : "password"}
              name="repeat-new-password"
              autoComplete="new-password"
              id="new-password"
              revealPassword={true}
              InputComponent={Input}
            />
            <ButtonWrapper>
              <ButtonDefault disabled={loading} color="lightgray4" onClick={onModalClose}>{__("Close")}</ButtonDefault>
              <ButtonDefault loading={loading} type="submit" disabled={loading || !currentPass.value || !newPass.value || !repeatNewPass.value} onClick={handleSubmit}>{__("Change Password")}</ButtonDefault>
            </ButtonWrapper>
          </Form>
        </ModalContainer>
      </Modal>
    </>
  )
};

export default ChangePassSetting;

const ModalContainer = styled.div`
  text-align: center;
  border-radius: var(--radius-lg);
  box-shadow: rgba(0, 0, 0, 0.25) 3px 3px 5px;
  padding: var(--spacing-xl);
  background: var(--color-base-white);
  max-width: 480px;
  width: 100%;
  min-height: auto;
  color: var(--color-base-black);
  box-sizing: border-box;
  margin: 0 auto;
  position: relative;

  @media (max-width: 768px) {
    width: 90vw;
  }
`;

const ModalHeader = styled.div`
  font-size: var(--font-size-18);
  color: var(--color-base-black);
  line-height: 23px;
  font-weight: bold;
  margin-top: -3px;
  margin-bottom: var(--spacing-lg);
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: var(--spacing-md);
`;

const Input = styled.input`
  width: 100%;
  box-shadow: rgba(41, 38, 38, 0.05) 0px 3px 2px 0px inset;
  border-radius: var(--radius-xs);
  height: 38px;
  line-height: 26px;
  font-size: var(--font-size-14);
  color: var(--color-base-black);
  background: var(--color-base-white);
  padding-left: 10px;
  padding-right: var(--spacing-2xl);
  border: var(--border-size) solid var(--border-primary);
  box-sizing: border-box;
  outline: none;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-sm);
  margin-top: var(--spacing-md);
`;
