import React, { createContext, useState, useContext } from 'react';

const MenuContext = createContext();

export const MenuProvider = ({ children }) => {
	const [menuItems, setMenuItems] = useState([]);
	const [anchorPoint, setAnchorPoint] = useState(null);
	const [anchorRef, setAnchorRef] = useState(null);
	const [isOpen, setIsOpen] = useState(false);

	const showMenu = (items, anchor) => {
		setMenuItems(items);
		if (anchor.x !== undefined && anchor.y !== undefined) {
			setAnchorPoint(anchor);
			setAnchorRef(null);
		} else {
			setAnchorRef(anchor);
			setAnchorPoint(null);
		}
		setIsOpen(true);
	};

	const hideMenu = () => {
		setMenuItems([]);
		setAnchorPoint(null);
		setAnchorRef(null);
		setIsOpen(false);
	};

	return (
		<MenuContext.Provider value={{ menuItems, anchorPoint, anchorRef, isOpen, showMenu, hideMenu }}>
			{children}
		</MenuContext.Provider>
	);
};

export const useMenu = () => useContext(MenuContext);
