import React from "react";
import styled from "styled-components";

const StatusIndicator = ({status}) => {
    if (status === "aborted") {
        return (
            <ServiceIconIndicator className={"icon-aborted"}>
                <i className="fa-regular fa-triangle-exclamation"></i>
            </ServiceIconIndicator>
        );
    }

    if (status === "finished") {
        return (
            <ServiceIconIndicator className={"icon-success"}>
                <i className="fa-regular fa-check"></i>
            </ServiceIconIndicator>
        );
    }

    if (status === "running") {
        return (
            <ServiceIconIndicator>
                <i className="fa-regular fa-circle-notch fa-spin" />
            </ServiceIconIndicator>
        );
    }

    return null;
}

export default StatusIndicator;

const ServiceIconIndicator = styled.div`
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    left: -7px;
    bottom: -5px;
    border: 1px solid var(--surface-secondary);
    border-radius: 50%;
    background: var(--surface-secondary);

    & i {
        font-size: 14px;
        color: #7a8d8f;
    }

    &.icon-aborted {
        background: var(--color-functional-danger500);

        & i {
            font-size: 12px;
            color: #fff;
            transform: translateY(-1px);
        }
    }

    &.icon-success {
        background: var(--text-positive);

        & i {
            font-size: 12px;
            color: #fff;
            transform: translateY(1px);
        }
    }
`;