import React, { useEffect, useState } from "react";
import { MenuProvider } from "../../context/MenuContextProvider";
import useWindowDimensions from "../../../../lib/hooks/useWindowDimensions";
import MobileLayout from "./MobileLayout";
import DesktopLayout from "./DesktopLayout";
import { MOBILE_WIDTH_BREAKPOINT } from "../../../../config/constants";

export const disableSearchOnPages = ['time', 'trash']

const TopBar = () => {
    const { bodyWidth } = useWindowDimensions();
    const isMobile = bodyWidth < MOBILE_WIDTH_BREAKPOINT;
    const [searchBarExpanded, setSearchBarExpanded] = useState(false);

    useEffect(() => {
        HFN.showRibbon();
    }, [isMobile]);

    return (
        <MenuProvider>
            {isMobile && (
                <MobileLayout
                    searchBarExpanded={searchBarExpanded}
                    setSearchBarExpanded={setSearchBarExpanded}
                />
            )}

            {!isMobile && (
                <DesktopLayout
                    searchBarExpanded={searchBarExpanded}
                    setSearchBarExpanded={setSearchBarExpanded}
                />
            )}
        </MenuProvider>
    );
};

export default TopBar;
