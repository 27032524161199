const MENTIONS_REG_EX = /\[\^([^\]]+)\]/;

export const cleanMention = (mention) => mention.replace('[', '').replace(']', '').replace('^', '');

export const searchAndReplaceMentions = (text, users) => {
  let textWithMentionsForOut = text;
  const mentions = textWithMentionsForOut.match(new RegExp(MENTIONS_REG_EX, "g"));
  if (mentions && mentions.length > 0) {
    for (const mention of mentions) {
      const mentionEmail = cleanMention(mention);
      const mentionUser = users.find(({email}) => email === mentionEmail);
      if (mentionUser) {
        // Prepare for Componentify linkConverterV2
        textWithMentionsForOut = textWithMentionsForOut.replace(mention, `<link #page=b_user&id=${mentionUser.id}|${mentionUser.firstname} ${mentionUser.lastname}>`)
      }
    }
  }

  return textWithMentionsForOut;
}

export const extractMentionedUserIdsFromText = (text, users) => {
  const mentionedUserIds = [];
  const mentions = text.match(new RegExp(MENTIONS_REG_EX, "g"));
  if (mentions && mentions.length > 0) {
    for (const mention of mentions) {
      const mentionEmail = cleanMention(mention);
      const mentionUser = users.find(({email}) => email === mentionEmail);
      if (mentionUser) {
        mentionedUserIds.push(mentionUser.id);
      }
    }
  }
  return mentionedUserIds;
};

export const isElementVisible = (el, holder, offset = 0) => {
  holder = holder || document.body
  const { top, bottom, height } = el.getBoundingClientRect()
  const holderRect = holder.getBoundingClientRect()

  return top <= holderRect.top
    ? holderRect.top - top <= height - offset
    : bottom - holderRect.bottom <= height - offset
}