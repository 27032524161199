// @flow

import React, { Component } from "react";
import styled, { keyframes } from "styled-components";
import { hiDPI } from "polished";

import Modal, { OvalModalCloseV2 } from "../../components/Modal";

type Props = {
  show: boolean,
  shouldCloseOnOverlayClick: boolean,
  shouldCloseOnEscape: Boolean,
  flow: any,
  code: string,
  closeButton: Class<React$Component<any, any>>,
  onSuccess: () => void,
  onBack: () => void,
  onClose: () => void
};

type State = {
  currentStep: number
};

class FlowManager extends Component<Props, State> {
  static defaultProps = {
    show: false,
    shouldCloseOnOverlayClick: false,
    shouldCloseOnEscape: true,
    flow: [],
    code: "",
    style: {},
    onSuccess: () => {},
    onBack: () => {},
    onClose: () => {},
    closeButton: OvalModalCloseV2
  };

  constructor(props: Props) {
    super(props);

    (this: any).onSuccess = (this: any).onSuccess.bind(this);
    (this: any).onBack = (this: any).onBack.bind(this);
    (this: any).onClose = (this: any).onClose.bind(this);

    this.state = {
      currentStep: 0
    };
  }

  onSuccess(data = {}) {
    const { skipNext = false } = data;
    const { flow } = this.props;
    const { currentStep } = this.state;
    let nextStep = currentStep;

    if (skipNext) {
      ++nextStep;
    }

    if (flow && flow.length - 1 === nextStep) {
      this.onClose();
    } else {
      this.setState({ currentStep: nextStep + 1 });
    }
  }

  onBack() {
    const { currentStep } = this.state;

    this.setState({ currentStep: currentStep - 1 });
  }

  onClose() {
    const { onClose } = this.props;

    onClose();
    this.setState({ currentStep: 0 });
  }

  render() {
    const { show, shouldCloseOnOverlayClick, shouldCloseOnEscape, flow, onClose, closeButton: CloseBtn, loading, style, ...res } = this.props;
    const { currentStep } = this.state;
    const Step = flow[currentStep] || null;

    return (
      <Modal
        animate
        opened={Boolean(flow.length && Step)}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        shouldCloseOnEscape={!loading && shouldCloseOnEscape}
        onClose={this.onClose}
      >
        <ModalWrap style={style}>
          {Step !== null ? (
            <React.Fragment>
              <Step {...res} loading={loading} onSuccess={this.onSuccess} onBack={this.onBack} onClose={this.onClose} />
              <CloseBtn onClick={this.onClose} />
            </React.Fragment>
          ) : null}
        </ModalWrap>
      </Modal>
    );
  }
}

export default FlowManager;

export const ModalWrap = styled.div`
  position: relative;
  margin-top: 80px;

  .float-right {
    float: right;
  }

  .float-left {
    float: left;
  }
  @media only screen and (max-width: 800px) {
    margin-top: 40px;
  }

  @media only screen and (max-width: 425px) {
    margin-top: 0;
    display: table;
  }
`;
