import React, { useEffect, useState } from "react";
import { apiMethod } from "../../../api";
import BackupAccountUI from "./BackupAccountUI";

const OneDriveLink = () => {
  const [account, setAccount] = useState(null);
  const [unlinkModal, setUnlinkModal] = useState(null);
  const [unlinkLoading, setUnlinkLoading] = useState(false);

  useEffect(() => {
    getToken().then(getIdentity);
  }, [])

  const getToken = () => {
    return new Promise((resolve, reject) => {
      apiMethod("social_assign", {
        type: "odn"
      }, response => {
        if (response.access_token) {
          OneDrive.token = response.access_token;
        }
        resolve();
      }, {
        errorCallback: (error) => { console.error(error) }
      })
    })

  }

  const getIdentity = () => {
    OneDrive.apiCallGet('drive', {}, apiRet => {
      setAccount((apiRet.owner.user.displayName || apiRet.owner.user.id));
    }, {
      onMissingToken: () => {
        apiMethod('social_assign', { type: 'odn', dismiss: 'true' }, () => {
          OneDrive.remToken(() => {});
        })
      },
    });
  }

  const onLink = () => {
    OneDrive.getTokenCallback((token, expire) => {
      HFN.message('Token saved.');
      getIdentity();
    });
  };

  const onUnlink = () => {
    apiMethod('social_assign', { type: 'odn', dismiss: 'true' }, ret => {
      OneDrive.remToken(() => {
        HFN.message(__('Account unlinked.'));
        setAccount(null);
      });
    })
  }

  return (
    <BackupAccountUI
      type="odn"
      app={__("OneDrive Account")}
      account={account}
      linked={!!account}
      onLink={onLink}
      onUnlink={onUnlink}
    />
  )
}

export default OneDriveLink;