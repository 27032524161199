import React, { useMemo, useRef, useEffect } from "react";
import { ControlledMenu, MenuItem, SubMenu, MenuDivider, MenuHeader, useMenuState, useHover, useClick } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import { Tooltip } from "react-tooltip";

import { MenuIcon, MenuActiveIcon, MenuLabel, HoverMenuButton, OptionIcon } from "./Style";

const HoverMenu = ({
    menuButton,
    menuComponent,
    menuItems,
    iconSetup = {},
    arrow = false,
    portal = true,
    direction = "bottom",
    align = "center",
    menuKey = "sort-menu",
    menuButtonClassName = "hover-menu-button",
    menuButtonIconClass = "extraSmallIcon fa-light",
    menuButtonTooltip = "",
    triggerOnClick = false,
    onOpen = () => { },
    onClose = () => { }
}) => {
    const ref = useRef(null);
    const [menuState, toggle] = useMenuState({ transition: true });
    const { anchorProps, hoverProps } = useHover(menuState.state, toggle);
    const anchorPropsClick = useClick(menuState.state, toggle);

    useEffect(() => {
        if (menuState.state === "open") {
            onOpen();
        } else if (menuState.state === "closed") {
            onClose();
        }
    }, [menuState.state]);

    const renderMenuItems = useMemo(() => {
        return (items) => {
            return items.map((item, index) => {
                if (item.header) {
                    return <MenuHeader key={index}>{__(...item.header)}</MenuHeader>;
                } else if (item.divider) {
                    return <MenuDivider key={index} />;
                } else if (item.submenu) {
                    const SubMenuComponent = (
                        <React.Fragment>
                            {item.icon && (
                                <MenuIcon>
                                    <i className={"smallIcon lightColorIcon " + item.icon} />
                                </MenuIcon>
                            )}
                            <MenuLabel>{__(...item.label)}</MenuLabel>
                        </React.Fragment>
                    );
                    return (
                        <SubMenu key={index} label={SubMenuComponent}>
                            {renderMenuItems(item.submenu)}
                        </SubMenu>
                    );
                } else {
                    return (
                        <MenuItem
                            key={index}
                            onClick={() => item.onClick(...(item.params || []))}
                            disabled={item.disabled}
                            data-tooltip-content={item.tooltip ? __(...item.tooltip) : ""}
                            data-tooltip-id="cv-tooltip"
                        >
                            {item.icon && (
                                <MenuIcon>
                                    <i className={"smallIcon lightColorIcon " + item.icon} />
                                </MenuIcon>
                            )}
                            {item.iconComponent && item.iconComponent}
                            <MenuLabel>
                                {__(...item.label)}
                                <MenuActiveIcon>
                                    {item.active && (
                                        <i className="smallIcon linkColorIcon fa-regular fa-check" />
                                    )}
                                </MenuActiveIcon>
                            </MenuLabel>
                        </MenuItem>
                    );
                }
            });
        };
    }, [menuItems]);

    return (
        <>
            <HoverMenuButton
                {...(triggerOnClick ? anchorPropsClick : anchorProps)}
                ref={ref}
                key={menuKey}
                className={menuButtonClassName}
                data-tooltip-content={menuButtonTooltip ? __(...menuButtonTooltip) : ""}
                data-tooltip-id="cv-tooltip"
            >
                {typeof menuComponent !== "undefined" ? (
                    menuComponent
                ) : menuButton.icon ? (
                    <OptionIcon className="hoverMenuButton">
                        <i className={"baseIcon darkColorIcon " + menuButton.icon} />
                    </OptionIcon>
                ) : null}

                {iconSetup.hasOwnProperty("open") && iconSetup.hasOwnProperty("close") ? (
                    <>
                        {menuState.state === "open" && iconSetup.open}
                        {menuState.state !== "open" && iconSetup.close}
                    </>
                ) : (
                    <i className={menuButtonIconClass + " " + (menuState.state === "open" ? 'fa-chevron-up' : 'fa-chevron-down')} />
                )}
            </HoverMenuButton>
            <ControlledMenu
                {...(triggerOnClick ? {} : hoverProps)}
                {...menuState}
                anchorRef={ref}
                onClose={() => toggle(false)}
                arrow={arrow}
                portal={portal}
                direction={direction}
                viewScroll="close"
                align={align}
                position="auto"
                boundingBoxPadding="16"
            >
                {renderMenuItems(menuItems)}
            </ControlledMenu>
        </>
    );
};

export default HoverMenu;
