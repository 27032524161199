import React from "react";
import styled, { css } from "styled-components";
import ExploreCards from "../../../ExploreCards/ExploreCards";
import { useSelector } from "react-redux";

const ExplorePcloud = ({ isMobileDevice }) => {
	const pageInfo = useSelector(({ content }) => content.pageInfo) || undefined;
	const { explorecardsleft, explorecards } = pageInfo;

	if (explorecardsleft || explorecards.length === 0) {
		return null;
	}

	return <ExplorePcloudWrapper $isMobileDevice={isMobileDevice}>
		<ExploreCards isInMenu={false} />
	</ExplorePcloudWrapper>
};

export default ExplorePcloud;

export const ExplorePcloudHeight = 170;

export const ExplorePcloudWrapper = styled.div`
  padding-left: ${({ $isMobileDevice }) => $isMobileDevice ? "var(--spacing-md)" : 0};
	@media only screen and (max-width: 460px) {
		${({ $isMobileDevice }) => $isMobileDevice && css`
			padding: 0;
		`}
  }
`;
