import React, { useRef, useState } from "react";
import styled from "styled-components";
import { moreAppsOptions } from "../../../LeftMenu/leftMenuOptions";
import HoverMenu from "../../UI/HoverMenu";

const MoreAppsItem = () => {
    const [isOpen, setIsOpen] = useState(false);
    const buttonRef = useRef(null);

    const getDropDownList = () => {
        const returnData = [];
        moreAppsOptions.forEach((item, index) => {
            const SvgIcon = item.icon;
            returnData.push({
                label: [item.name],
                params: [],
                iconComponent: (
                    <SvgIcon />
                ),
                id: index,
                onClick: () => { window.open(item.href, '_blank'); }
            });
        })
        return returnData;
    };

    const onOpen = () => {
        setIsOpen(true);
    };

    const onClose = () => {
        setIsOpen(false);
    };

    const iconSetup = {
        open: null,
        close: null
    };

    return (
        <HoverMenu
            triggerOnClick={true}
            align={"end"}
            arrow={false}
            iconSetup={iconSetup}
            menuButtonClassName={"no-margin no-padding"}
            menuComponent={<Wrapper ref={buttonRef} $isOpen={isOpen}>
                <i className="mediumIcon fa-solid fa-grid-round" />
            </Wrapper>}
            menuItems={getDropDownList()}
            onOpen={onOpen}
            onClose={onClose}
        />
    );
};

export default MoreAppsItem;

const Wrapper = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--radius-full);
    height: 40px;
    width: 40px;
    transition: background-color 0.3s ease-in-out;

    @media (max-width: 1023px) {
        background: ${({ $isOpen }) => ($isOpen ? "#f0f4f5" : "inherit")};
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1001;
    background-color: var(--color-base-white);
    border: 1px rgba(0, 0, 0, 0.25);
    box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.15);
    width: 201px;
    padding: var(--spacing-sm) var(--spacing-none);
    border-radius: var(--radius-minimum);
`;

const ContentItem = styled.a`
    display: flex;
    padding: var(--spacing-xs) var(--spacing-lg) var(--spacing-xs)
        var(--spacing-lg);
    align-items: center;
    gap: var(--spacing-sm);
    color: var(--color-base-black);
    font-size: var(--base-font-size);
    font-weight: 400;
    line-height: 24px;
    width: 100%;
    cursor: pointer;
    box-sizing: border-box;
    text-decoration: none;
    justify-content: flex-start;

    &:hover {
        background-color: var(--color-grey-300);
    }
`;
