import React from "react";
import { __ } from "../../../../../lib/translate";
import useWindowDimensions from "../../../../../lib/hooks/useWindowDimensions";
import { MOBILE_WIDTH_BREAKPOINT } from "../../../../../config/constants";
import EmptyDesktopIcon from "../../../../../../root/img/grid/empty-state/search-desktop.svg";
import EmptyMobileIcon from "../../../../../../root/img/grid/empty-state/search-mobile.svg";
import {
    EmptyStateMessage,
    EmptyStateMessageSubTitle,
    EmptyStateMessageTitle,
    EmptyStateWrapper,
    ImageContainer
} from "../styles";

const Search = () => {
    const { bodyWidth } = useWindowDimensions();
    const isMobile = bodyWidth < MOBILE_WIDTH_BREAKPOINT;

    return (
        <EmptyStateWrapper $isMobile={isMobile}>
            <EmptyStateMessage $isMobile={isMobile}>
                <EmptyStateMessageTitle>
                    {__("search_empty_title", "No results found")}
                </EmptyStateMessageTitle>
                <EmptyStateMessageSubTitle>
                    {__(
                        "search_empty_message",
                        "Try another search, or use search options to find a file by type."
                    )}
                </EmptyStateMessageSubTitle>
            </EmptyStateMessage>

            <ImageContainer $isMobile={isMobile}>
                {!isMobile && <EmptyDesktopIcon />}
                {isMobile && <EmptyMobileIcon />}
            </ImageContainer>

            <div />
        </EmptyStateWrapper>
    );
};

export default Search;
