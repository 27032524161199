import React from "react";

import { __ } from "../../../../lib/translate";
import usePlayerContext from "../../hooks/usePlayerContext";
import {
    HeaderControlsWrapper,
    HeaderTitleWrapper,
    HeaderWrapper
} from "../../userinterface/styles";
import ActionButton from "../../userinterface/ActionButton";

const Header = () => {
    const {
        headerRef,
        queue,
        playlist,
        isPublic,
        isMobile,
        playerVariant,
        onPlayerClose,
        onPlayerMinimize,
        onShowMiniPlayer,
        onShowFullPagePlayer,
        onShowPlayer,
        additionalClass,
        isSingleSongPlayer
    } = usePlayerContext();

    const showStandartBtns = !isPublic || (isMobile && queue.length > 1);
    const showCloseBtns = !isMobile || queue.length > 1 || !isPublic;
    const showPublicBtns = isPublic && !isMobile;

    const handleHeaderClick = (e) => {
        e.stopPropagation();
        if (isMobile && !playerVariant.isMinimize && !isSingleSongPlayer) {
            onPlayerMinimize();
        }
    };

    return (
        <HeaderWrapper
            ref={headerRef}
            className={additionalClass + " animate"}
            onClick={handleHeaderClick}
        >
            <HeaderTitleWrapper>
                <>
                    {__("Currently playing")}{" "}
                    {playlist && playlist.name ? '"' + playlist.name + '"' : ""}
                </>
            </HeaderTitleWrapper>
            <HeaderControlsWrapper
                id={"headerControlsWrapper"}
                className={additionalClass}
            >
                {showStandartBtns && (
                    <>
                        <ActionButton
                            onClick={
                                playerVariant.isMiniPlayer
                                    ? onShowPlayer
                                    : onShowMiniPlayer
                            }
                            className={"svg-color-default"}
                            label={
                                <i className="baseIcon fa-solid fa-list-ul" />
                            }
                        />
                        <ActionButton
                            onClick={onPlayerMinimize}
                            className={"svg-color-default"}
                            label={
                                <i className="baseIcon fa-solid fa-chevron-down" />
                            }
                        />
                    </>
                )}
                {showPublicBtns && (
                    <>
                        <ActionButton
                            onClick={onShowFullPagePlayer}
                            className={"svg-color-default"}
                            label={
                                <i className="smallIcon fa-regular fa-expand-arrows" />
                            }
                        />
                    </>
                )}
                {showCloseBtns && (
                    <ActionButton
                        onClick={onPlayerClose}
                        className={"svg-color-default"}
                        label={<i className="baseIcon fa-solid fa-close" />}
                    />
                )}
            </HeaderControlsWrapper>
        </HeaderWrapper>
    );
};

export default Header;
