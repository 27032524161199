import React, { useEffect, useState } from "react";
import Breadcrumb from "../../Templates/TopBar/Breadcrumb";
import styled from "styled-components";
import Notifications from "../../Templates/TopBar/Notifications";
import SearchBar from "../../Templates/TopBar/SearchBar";
import UploadButton from "../../Templates/TopBar/UploadButton";
import UploadProgressSection from "../../Templates/TopBar/UploadProgressSection";
import { FlexWrapper } from "../Style";
import { disableSearchOnPages } from "./index";
import { getHashPage } from "../../../../lib";

const DesktopLayout = ({ searchBarExpanded, setSearchBarExpanded }) => {
    const [page, setPage] = useState(getHashPage());

    useEffect(() => {
        const handleHashChange = () => {
            const hashPage = getHashPage();
            setPage(hashPage);
        };

        window.addEventListener("hashchange", handleHashChange);

        return () => {
            window.removeEventListener("hashchange", handleHashChange);
        };
    }, []);

    return (
        <DesktopHeaderWrapper>
            <LeftSection>
                <Breadcrumb />
            </LeftSection>

            <RightSection>
                <UploadProgressSection />
                <div id="ribbon-container" />
                <UploadButton short={searchBarExpanded} />
                <SearchBar
                    isDisabled={disableSearchOnPages.includes(page)}
                    searchBarExpanded={searchBarExpanded}
                    setSearchBarExpanded={setSearchBarExpanded}
                />
                <Notifications />
            </RightSection>
        </DesktopHeaderWrapper>
    );
};

export default DesktopLayout;

export const DesktopHeaderWrapper = styled(FlexWrapper)`
    height: 56px;
    padding: 0 var(--spacing-lg) 0 var(--spacing-md);
    box-sizing: content-box;
    width: auto;
    position: relative;
    z-index: 1;
`;
const LeftSection = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    min-width: 0;
    width: 100%;
`;

const RightSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--spacing-lg);
    height: 100%;
`;
