import React, { useEffect, useState } from "react";
import { apiMethod } from "../../../api";
import BackupAccountUI from "./BackupAccountUI";

const DropBoxBusinessLink = () => {
  const [account, setAccount] = useState(null);
  const [unlinkModal, setUnlinkModal] = useState(null);
  const [unlinkLoading, setUnlinkLoading] = useState(false);
  const [unlinkUser, setUnlinkUser] = useState(null);

  useEffect(() => {
    getToken().then(getIdentity);
  }, [])

  const getToken = () => {
    return new Promise((resolve, reject) => {
      apiMethod("social_assign", {
        type: "dbxb"
      }, response => {
        if (response.access_token) {
          DropboxBusiness.token = response.access_token;
          resolve(response);
        }
      }, {
        errorCallback: (error) => { console.error(error) }
      })
    })

  }

  const getIdentity = () => {
    DropboxBusiness.apiCallGet('2/team/token/get_authenticated_admin', {}, apiRet => {
      setAccount(apiRet.admin_profile.name.display_name);
      setUnlinkUser(apiRet.admin_profile.team_member_id);
    }, {
      onMissingToken: function () {
        HFN.apiMethod('social_assign', { type:'dbxb', dismiss: 'true' }, ret => {});
      }
    });
  }

  const onLink = () => {
    DropboxBusiness.getTokenCallback((token, expire) => {
      HFN.message('Token saved.');
      getIdentity();
    });
  };

  const onUnlink = () => {
    apiMethod('social_assign', { type: 'dbxb', dismiss: 'true' }, ret => {
      DropboxBusiness.apiCallGet('2/auth/token/revoke?select_user=' + unlinkUser, {}, () => {
        HFN.message(__('Account unlinked.'));
        setUnlinkUser(null);
        setAccount(null);
      });
    })
  }

  return (
    <BackupAccountUI
      type="dbxb"
      app={__("Dropbox Business Account")}
      account={account}
      linked={!!account}
      onLink={onLink}
      onUnlink={onUnlink}
    />
  )
}

export default DropBoxBusinessLink;