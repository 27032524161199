import React from "react";
import Tooltip from "../../../../../Tooltip";
import { __ } from "../../../../../../lib/translate";
import { ButtonWrapper } from "../styles";
import Button from "../../../../../ButtonDefault";
import InputCheckbox from "../../../../../InputCheckbox";

const InitButton = ({
    disabledButton,
    type,
    status,
    passedNextExecutionDate,
    onButtonClick,
    isOverquota
}) => {
    const renderButton = () => {
        return (
            <ButtonWrapper>
                <Button
                    size="bigger"
                    padding="12px 20px"
                    fontWeight="400"
                    textSize={15}
                    borderRadius="50"
                    minWidth="81px"
                    onClick={onButtonClick}
                    disabledColor="#eeeeee"
                    disabledTextColor="#b2b2b2"
                    disabled={disabledButton || status === "unavailable"}
                >
                    {__("Start")}
                </Button>
            </ButtonWrapper>
        );
    };

    if (type === "onetime") {
        if (
            disabledButton &&
            status !== "inactive" &&
            !passedNextExecutionDate
        ) {
            return (
                <Tooltip
                    title={__(
                        "back_available_info_tooltip",
                        "Backup available once every 30 days."
                    )}
                >
                    {renderButton()}
                </Tooltip>
            );
        } else {
            return <>{renderButton()}</>;
        }
    }
    return (
        <ButtonWrapper>
            <InputCheckbox
                name=""
                checked={Boolean(
                    status === "running" ||
                        status === "paused" ||
                        status === "finished"
                )}
                onChange={onButtonClick}
                color="cyan"
                size="modern"
                disabled={
                    status === "unavailable" ||
                    status === "running" ||
                    status === "paused" ||
                    (isOverquota && status !== "finished")
                }
            />
        </ButtonWrapper>
    );
};

export default InitButton;
