import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ItemsInfoContainer from "./ItemsInfoContainer";
import useWindowDimensions from "../../lib/hooks/useWindowDimensions";
import { MOBILE_WIDTH_BREAKPOINT } from "../../config/constants";
import { setItemsInfoPanelData } from "../../lib/state/actions/content";

type Props = {
  currentFolderData: {
    id: string,
    folderid: number,
    name: string,
    ismine: boolean,
    thumb: string,
    created: string,
    modified: string,
    comments: number,
    isshared: boolean,
    icon: number,
    parentfolderid: number
  }
};

const ItemsInfoPanel = ({
  containerId = "items-info"
}: Props) => {

  const dispatch = useDispatch();

  const itemsInfoData = useSelector((({ content }) => content.itemsInfoPanelData));

  const isBusiness = useSelector(({ user }) => user.userinfo.business);

  const { bodyWidth } = useWindowDimensions();
  const isMobileView = bodyWidth < MOBILE_WIDTH_BREAKPOINT;

  const closeItemsInfo = () => {
    dispatch(setItemsInfoPanelData(
      {...itemsInfoData, show: false}
    ));
  };

  const onItemsInfoTabChange = (tab) => {
    dispatch(setItemsInfoPanelData(
      {...itemsInfoData, tab, forceTab: false}
    ));
  }

  {/* Temporary disable ItemsInfo Information tab side panel until it's ready. -> remove (isBusiness ? : null)*/}
  return !isBusiness ? null : 
    <ItemsInfoContainer 
      containerId={containerId}
      isOpened={itemsInfoData.show} 
      onCloseItemsInfo={() => closeItemsInfo()}
      selectedItemsCount={itemsInfoData.selectedItemsCount}
      selectedItem={itemsInfoData.selectedItem}
      selectedTab={itemsInfoData.tab}
      onTabChange={onItemsInfoTabChange}
      autoFocusAddComment={itemsInfoData?.autoFocus}
      isMobileView={isMobileView}
      isBusiness={isBusiness}
    />;
  
};

export default ItemsInfoPanel;