// @flow

import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { hiDPI } from "polished";

import { __ } from "../../lib/translate";

type Props = {
  selectedItemId: number,
  isOpen: boolean,
  setIsOpen: boolean => voide,
  onItemDropdownClick: number => void
};

type Item = {
  id: number,
  title: string,
  description: string
};

const ShareDropdown = ({ selectedItemId, onItemDropdownClick, isOpen, setIsOpen, list }): Props => {
  const linkData = useSelector(({ sharedLinks }) => sharedLinks.linkData);

  const onItemClick = (id: number) => {
    if (isOpen) {
      onItemDropdownClick(id);
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  const renderItem = ({ id, title, description }: Item) => {
    const isSelected = selectedItemId === id;

    return (
      <ItemWrapper key={id} isOpen={isOpen} isSelected={isSelected} onClick={() => onItemClick(id)}>
        {isSelected ? <CheckIcon /> : null}
        <TextWrapper isOpen={isOpen}>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </TextWrapper>
        {!isOpen ? <DropdownArrow /> : null}
      </ItemWrapper>
    );
  };

  const renderList = () => {
    return <div>{list.map(renderItem)}</div>;
  };

  const renderSelectedItem = () => {
    const item = list.find(({ id }) => id === selectedItemId);

    return <div>{renderItem(item)}</div>;
  };

  if (isOpen) {
    return renderList();
  } else {
    return renderSelectedItem();
  }
};

export default ShareDropdown;

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 496px;
  height: 54px;
  box-shadow: 0px 2px 0 0 rgba(0, 0, 0, 0.02);
  border-top: solid 1px #e1e1e1;
  border-left: solid 1px #e1e1e1;
  border-right: solid 1px #e1e1e1;
  border-bottom: ${props => (props.isOpen ? "none" : "solid 1px #e1e1e1")};
  background-color: #ffffff;
  padding: 15px;
  padding-left: ${props => (props.isSelected ? "15px" : "43px")};
  box-sizing: border-box;
  cursor: pointer;
  
  @media (max-width: 800px) {
    width: 100%;
    height: auto;
  }

  &:last-child {
    border-bottom: solid 1px #e1e1e1;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:hover {
    background-color: #fafafa;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  flex: 1;
`;

const Title = styled.div`
  font-size: 13px;
  font-weight: 500;
  line-height: 1.23;
  letter-spacing: -0.13px;
`;

const Description = styled(Title)`
  color: #878787;
  font-weight: normal;
  letter-spacing: -0.2px;
`;

const CheckIcon = styled.div`
  display: inline-block;
  width: 13px;
  height: 9px;
  margin-right: 15px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url(${require("../../../root/img/share-link/selected.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/share-link/selected@2x.png")});
  }
`;

const DropdownArrow = styled.div`
  display: inline-block;
  width: 11px;
  height: 6px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url(${require("../../../root/img/share-link/open.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/share-link/open@2x.png")});
  }
`;
