import React from "react";
import styled from "styled-components";

const UploadProgressSection = () => {
    return <Wrapper id="uploadProgressSection" />;
};

export default UploadProgressSection;

const Wrapper = styled.div``;
