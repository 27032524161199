//@flow

import React from "react";
import styled from "styled-components";

import DlLoadingIcon from "../../../root/img/speed-test/dl-loading.svg";
import UplLoadingIcon from "../../../root/img/speed-test/upl-loading.svg";
import DlDoneIcon from "../../../root/img/speed-test/dl-done.svg";
import UplDoneIcon from "../../../root/img/speed-test/upl-done.svg";
import DownloadIcon from "../../../root/img/speed-test/download.svg";
import UploadIcon from "../../../root/img/speed-test/upload.svg";

import { SPEED_STATUS, DL_COLOR, UP_COLOR } from "@pcloud/web-utilities/dist/config/constants";
import { __ } from "../../lib/translate";

const spaceNumbers = ["0", "10", "20", "50", "100+"];

type Props = {
  speed: number,
  progressStatus: number,
  scaleDirection: "left" | "right",
  type: "download" | "upload"
};

const ProgressBox = ({
  speed = 57,
  progressStatus = 2,
  scaleDirection = "left",
  type = "download"
}: Props) => {
  const isDownload = type === "download";
  const color = isDownload ? DL_COLOR : UP_COLOR;
  const renderIcon = () => {
    if (SPEED_STATUS["DONE"] === progressStatus) {
      return (
        <IconOutherline>
          <IconInnerline style={{ padding: "12px 9px" }}>
            {isDownload ? (
              <DlDoneIcon width="22px" height="16px" />
            ) : (
              <UplDoneIcon width="22px" height="16px" />
            )}
          </IconInnerline>
        </IconOutherline>
      );
    } else if (SPEED_STATUS["RUNNING"] === progressStatus) {
      return (
        <IconOutherline>
          <IconInnerline>
            {isDownload ? (
              <DlLoadingIcon id="loader" width="26px" height="26px" />
            ) : (
              <UplLoadingIcon id="loader" width="26px" height="26px" />
            )}
          </IconInnerline>
        </IconOutherline>
      );
    } else {
      return (
        <IconOutherline>
          <IconInnerline style={{ padding: 0 }}>
            {isDownload ? (
              <DownloadIcon width="40px" height="40px" fill="#fff" />
            ) : (
              <UploadIcon width="40px" height="40px" fill="#fff" />
            )}
          </IconInnerline>
        </IconOutherline>
      );
    }
  };

  const renderBox = () => {
    const bottomText = isDownload ? __("download_speed") : __("upload_speed");
    const getBarHeight = () => {
      if (speed <= 20) {
        return speed * 2.5;
      } else if (speed > 20 && speed <= 50) {
        return 50 + (speed - 20) * 0.833;
      } else if (speed > 50 && speed <= 100) {
        return 75 + (speed - 50) * 0.5;
      } else {
        return 100;
      }
    };

    return (
      <BoxContainer>
        <Box>
          <Progress>
            <SpeedWrapper>
              <SpeedNumber speed={speed}>{speed}</SpeedNumber>
              <SpeedUnit speed={speed}>Mbps</SpeedUnit>
            </SpeedWrapper>
            <Bar colorTop={color.top} colorBottom={color.bottom} height={getBarHeight()} />
          </Progress>
          {renderIcon()}
        </Box>
        <BottomText>{bottomText}</BottomText>
      </BoxContainer>
    );
  };

  return (
    <Wrapper>
      {scaleDirection == "left" ? (
        <React.Fragment>
          <Scale direction={scaleDirection}>
            {spaceNumbers.map(number => (
              <SpaceNumber key={number}>{number}</SpaceNumber>
            ))}
          </Scale>
          {renderBox()}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {renderBox()}
          <Scale direction={scaleDirection}>
            {spaceNumbers.map(number => (
              <SpaceNumber key={number}>{number}</SpaceNumber>
            ))}
          </Scale>
        </React.Fragment>
      )}
    </Wrapper>
  );
};

export default ProgressBox;

const Wrapper = styled.div`
  display: flex;
  gap: 10px;

  #loader {
    animation: spin 2s linear infinite;
    transform-origin: 50% 50%;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Scale = styled.div`
  display: flex;
  flex-direction: column-reverse;
  height: 258px;
  justify-content: space-between;
  padding: 20px 10px;
  text-align: ${props => (props.direction === "left" ? "right" : "left")};

  @media (max-width: 768px) {
    padding: 20px 4px;
  }
`;

const SpaceNumber = styled.div`
  font-size: 11px;
  color: #aaaaaa;
`;

const BoxContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Box = styled.div`
  width: 220px;
  height: 360px;
  padding: 20px 20px 13px;
  border-radius: 34px;
  border: solid 1px #f6f6f6;
  background-color: #f8f8f8;
  box-sizing: border-box;
  align-items: center;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    width: 100%;
    height: 300px;
  }
`;

const Progress = styled.div`
  width: 180px;
  height: 260px;
  border-radius: 15px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);
  border: solid 1px #f2f2f2;
  background-color: #ffffff;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: flex-end;
  overflow: hidden;

  @media (max-width: 768px) {
    width: 100%;
    height: 200px;
  }
`;

const SpeedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const SpeedNumber = styled.div`
  font-size: 48px;
  font-weight: 300;
  text-align: center;
  color: ${props => (props.speed > 0 ? "#000" : "#aaa")};

  @media (max-width: 768px) {
    font-size: 22px;
  }
`;

const SpeedUnit = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: ${props => (props.speed > 0 ? "#000" : "#aaa")};
`;

const IconOutherline = styled.div`
  background-color: #eeeeee;
  width: 54px;
  height: 54px;
  border-radius: 100%;
  padding: 7px;
  box-sizing: border-box;
  margin-top: 13px;
`;

const IconInnerline = styled.div`
  background-color: #ffffff;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  box-sizing: border-box;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.04);
  padding: 7px;
`;

const BottomText = styled.div`
  margin: 14px 0;
  font-size: 13px;
`;

const Bar = styled.div`
  --color-top: ${props => props.colorTop};
  --color-bottom: ${props => props.colorBottom};
  width: 100%;
  height: ${props => props.height + "%"};
  background-image: linear-gradient(to bottom, var(--color-top), var(--color-bottom));
  transition: height 400ms linear;
`;
