import React, { useState, useRef, useEffect } from "react";
import styled from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';
import { apiMethod } from "../../api/index";
import { extractMentionedUserIdsFromText } from "./utils/utils";
import useMentionUsersMenu from "./hooks/useMentionUsersMenu";

type Props = {
  contentItemData: {
    isfolder: boolean,
    folderid: number,
    fileid: number
  },
  usersForMentions: {
    id: number,
    email: string,
    firstname: string,
    lastname: string
  },
  minRows: number,
  maxRows: number,
  loadingComments: boolean,
  onPostedComment: (comment: {
    id: number,
    comment: string,
    dt: string,
    revisionid: number,
    usermeta: {
      id: number,
      firstname: string,
      lastname: string,
      avatar: {
        path: string
      }
    }
  }, {folderid: number, fileid: number}) => void,
  autoFocus: {value: boolean},
  isVisible: boolean,
  postCommentInProgress: boolean,
  setPostCommentInProgress: (postCommentInProgress: boolean) => void
}

const PostComment = ({
  contentItemData,
  usersForMentions,
  minRows = 1,
  maxRows = 4,
  onPostedComment = () => {},
  autoFocus = {value: false},
  isVisible = false,
  postCommentInProgress = false,
  setPostCommentInProgress = () => {}
}: Props) => {
  const [inputHeight, setInputHeight] = useState(0);
  const [inputFocused, setInputFocused] = useState(false);
  const [commentText, setCommentText] = useState("");
  const [onEscapeKey, setOnEscapeKey] = useState({event: null});
  
  const textareaRef = useRef();
  const textareaWrapperRef = useRef();
  const lastTriggerPlace = useRef("");
  const preventSendCommentOnEnter = useRef(false);
  const preventBlur = useRef(false);

  const onSelectUserForMention = () => {
    preventSendCommentOnEnter.current = true;
  }

  const { textComplete, UsersForMentionsStyle } = useMentionUsersMenu({
    textareaRef,
    textareaWrapperRef,
    users: usersForMentions,
    onSelectUserForMention,
    inputFocused
  });

  useEffect(() => {
    window.addEventListener("keydown", onWindowKeyDown);
    return () => {
      window.removeEventListener("keydown", onWindowKeyDown)
    }
  }, []);

  useEffect(() => {
    // Focus the textearea if triggered by "Add comment" button.
    if (autoFocus && autoFocus.value && isVisible && textareaRef.current) {
      // Add delay, otherwise it doesn't get focus :(
      setTimeout(() => {
        // Important to prevent scroll, otherwise it breaks the showing transition effect.
        textareaRef.current.focus({ preventScroll: true });
      }, 100);
    }
  }, [autoFocus, isVisible]);

  useEffect(() => {
    // Focus the textearea after successfully sent comment by "Enter" key.
    if (!postCommentInProgress && lastTriggerPlace.current === "enterKey") {
      textareaRef.current.focus();
      lastTriggerPlace.current = "";
    }
  }, [postCommentInProgress]);

  useEffect(() => {
    if (onEscapeKey.event && inputFocused) {
      // Prevent closing selected item.
      onEscapeKey.event.stopImmediatePropagation();
    }
  }, [onEscapeKey]);

  const onTextareaKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      if (!preventSendCommentOnEnter.current) {
        sendComment("enterKey");
      }
    }
  };

  const onWindowKeyDown = (event) => {
    if (event.key === "Escape" && textComplete.current) {
      setOnEscapeKey({ event });
    }
  };

  const onTextareaKeyUp = () => {
    preventSendCommentOnEnter.current = false;
  }

  const sendComment = (triggerPlace = "submitButton") => {
    if (postCommentInProgress || commentText === "") {
      return;
    }

    setPostCommentInProgress(true);

    // Hide mentions dropdown
    if (textComplete.current && textComplete.current.isShown()) {
      textComplete.current.hide();
    }

    const params = {
      comment: commentText,
      withavatars: 1
    };

    if (contentItemData.isfolder) {
      params.folderid = contentItemData.folderid;
    } else {
      params.fileid = contentItemData.fileid;
    }

    const mentionedUserIds = extractMentionedUserIdsFromText(commentText, usersForMentions);
    if (mentionedUserIds.length > 0) {
      params.mentionusers = mentionedUserIds.join(',');
    }

    apiMethod("commentpost", params, (res) => {
      lastTriggerPlace.current = triggerPlace;

      if (res.commentmeta) {
        res.commentmeta.iscommentpostresponse = true;
        res.commentmeta.scrolltome = true;
        res.commentmeta.comment += "";
        onPostedComment(res.commentmeta, { folderid: params.folderid, fileid: params.fileid });
        // If we have received some new comments during posting we should add them after this one is inside comments list.
        // setPostCommentInProgress(false) will be invoked after added to the list -> check useManageCommentsListData.js
      } else {
        HFN.message(__('Comment Posted.'));
        setPostCommentInProgress(false);
      }

      setCommentText("");
    },
    {
      errorCallback: (err) => {
        HFN.message(err.error, 'error');
        setPostCommentInProgress(false);
        lastTriggerPlace.current = triggerPlace;
      },
      onXhrError: (xhr, status, error) => {
        setPostCommentInProgress(false);
        lastTriggerPlace.current = triggerPlace;
        HFN.message(__("Please check your Internet connection."), "error");
      }
    });
  };

  const onPostCommentClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    sendComment();
  };

  return (
    <PostCommentWrapper>
      <UsersForMentionsStyle />
      <PostCommentInnerWrapper ref={textareaWrapperRef} onMouseDown={() => {preventBlur.current = true; setInputFocused(true)}} onClick={() => {preventBlur.current = false; textareaRef.current.focus()}} className={inputFocused ? "focused" : ""} $inputHeight={inputHeight}>
        
        <TextareaAutosize 
          ref={textareaRef}
          value={commentText}
          onChange={(e) => setCommentText(e.target.value)}
          onKeyDown={onTextareaKeyDown}
          onKeyUp={onTextareaKeyUp}
          className="post-comment-input"
          placeholder={__("add_comment", "Add comment")} 
          onFocus={() => setInputFocused(true)}
          onBlur={(e) => !preventBlur.current && setInputFocused(false)}
          maxRows={maxRows}
          minRows={minRows}
          onHeightChange={(height) => setInputHeight(height)}
          disabled={postCommentInProgress}
        />

        <PostCommentButton onClick={onPostCommentClick} disabled={postCommentInProgress || commentText === ""} className={commentText !== "" ? "active" : ""} $inputHeight={inputHeight}>
          <i className={`baseIcon fa-regular ${postCommentInProgress ? "fa-spin fa-circle-notch" : "fa-paper-plane"}`}></i>
        </PostCommentButton>
        
      </PostCommentInnerWrapper>
    </PostCommentWrapper>
  )
};

export default PostComment;

const PostCommentWrapper = styled.div`
  display: flex;
  padding: 0 var(--spacing-md, 16px) var(--spacing-none, 0px) var(--spacing-md, 16px);
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

const PostCommentInnerWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  border-radius: ${({$inputHeight}) => $inputHeight >= 32 ? "var(--radius-rounded, 16px)" : "88px"};
  border: 1px solid var(--border-primary, #E4E5E7);
  background: var(--surface-secondary, #FCFCFC);
  box-sizing: border-box;

  &.focused {
    color: var(--text-primary, #282C2F);
    border: 1px solid var(--Primary-500, #17BED0);

    button:not([disabled]) {
      i {
        color: var(--text-icons, #7A8D8F);
      }
    }
  }

  .post-comment-input {
    scroll-padding: 11px;
    padding: 11px 0 11px var(--spacing-md, 16px);
    margin-right: 52px;
    border-radius: ${({$inputHeight}) => $inputHeight >= 32 ? "var(--radius-rounded, 16px)" : "88px"};
    width: 100%;
    resize: none;
    outline: none;
    border: none;
    background: var(--surface-secondary, #FCFCFC);
    box-sizing: border-box;
  }
`;

const PostCommentButton = styled.button`
  position: absolute;
  bottom: 0;
  right: 4px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;
  border-radius: ${({$inputHeight}) => $inputHeight >= 32 ? "var(--radius-rounded, 16px)" : "88px"};

  i {
    color: var(--text-icons-light, #C6CACE);
  }

  &.active {
    i {
      color: var(--text-icons, #7A8D8F);
    }
  }

  &[disabled] {
    cursor: default;
  }
`;