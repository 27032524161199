import React, { useEffect, useRef } from "react";
import styled, { keyframes } from "styled-components";
import { CommentItemWrapper, CommentItem } from "./StyledComponents/Style";

const SkeletonCommentListItem = ({ 
    scrollToBottomOnInit = false,
    index,
    totalSkeletons = 1,
    scrollableListContainer = null
  },
) => {

  const commentItemRef = useRef();

  useEffect(() => {
    if (scrollToBottomOnInit && scrollableListContainer) {
      scrollableListContainer.scrollTo({
        top: scrollableListContainer.scrollHeight,
        behavior: "instant"
      })
    }
  }, [scrollToBottomOnInit]);

  return (
    <CommentItemWrapper className="comment-list-item" ref={commentItemRef}>
      <CommentItem $borderColor={`rgba(239, 240, 241, ${(1 / (totalSkeletons - index)).toFixed(2)})`}>
        <SkeletonCommentHeader>
          <SkeletonAvatar $opacity={(1 / (totalSkeletons - index + 0.7)).toFixed(2)} />
          <SkeletonRowWrapper>
            <SkeletonRow $width="160px" $opacity={(1 / (totalSkeletons - index + 0.8)).toFixed(2)} />
            <SkeletonRow $width="107px" $opacity={(1 / (totalSkeletons - index + 0.6)).toFixed(2)}/>
          </SkeletonRowWrapper>
        </SkeletonCommentHeader>

        <SkeletonRowWrapper>
          <SkeletonRow $width="100%" $opacity={(1 / (totalSkeletons - index + 0.4)).toFixed(2)} />
          <SkeletonRow $width="44%" $opacity={(1 / (totalSkeletons - index)).toFixed(2)} />
        </SkeletonRowWrapper>
        <LoaderAnimation />
      </CommentItem>
    </CommentItemWrapper>
  );
};

export default SkeletonCommentListItem;

const loadingBackground = keyframes`
  100% {
    background-position-x: calc(100% + 200px);
  }
`;

const SkeletonCommentHeader = styled.div`
  display: flex;
  align-items: flex-start;
  gap: var(--spacing-sm, 8px);
  align-self: stretch;
`;

const SkeletonAvatar = styled.div`
  opacity: ${({$opacity}) => $opacity ? $opacity : "1"};
  width: var(--avatar-width, 32px);
  height: var(--avatar-height, 32px);
  border-radius: var(--radius-full, 9999px);
  background: var(--surface-hover-dark, #EFF0F1);
`;

const SkeletonRowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
`;

const SkeletonRow = styled.div`
  opacity: ${({$opacity}) => $opacity ? $opacity : "1"};
  width: ${({$width}) => $width ? $width : "100%"};
  border-radius: 4px;
  height: 12px;
  background: var(--surface-hover-dark, #EFF0F1);
`;

const LoaderAnimation = styled.div`
  background: linear-gradient(100deg, rgba(255, 255, 255, 0) 40%,
  rgba(255, 255, 255, .5) 50%, rgba(255, 255, 255, 0) 60%) transparent;
  background-size: 200px 100%;
  background-position-x: -200px;
  background-repeat: no-repeat;
  animation: 1.5s ${loadingBackground} ease-in-out infinite;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
`;