// @flow

import React, { PureComponent } from "react";
import { findDOMNode } from "react-dom";
import styled from "styled-components";
import { hiDPI } from "polished";

import { __ } from "../../lib/translate";
import Button from "../../components/ButtonDefault";
import { Message } from "./CommonComponents";

import type { PubLink } from "../../types/publiclinks";

type CustomLinkRowProps = {
  linkData: PubLink,
  updateCustomLinksOnRemove: () => void,
  onEditCustomBranding: () => void
};

class CustomLinkRow extends PureComponent<CustomLinkRowProps> {
  constructor(props: CustomLinkRowProps) {
    super(props);

    (this: any).openLinkSettings = this.openLinkSettings.bind(this);
  }

  componentDidMount() {
    const { linkData } = this.props;
    const icon = findDOMNode(this.icon);
    const iconType = HFN.ICONS.SVG_LIST;

    setupThumb($(icon), linkData.metadata, iconType, { size: "24x24" });
  }

  openLinkSettings() {
    const { linkData } = this.props;

    HFN.sharePublink(linkData, {
      openTab: ".branding"
    });
  }

  render() {
    const { linkData, updateCustomLinksOnRemove, onEditCustomBranding } = this.props;
    const linkid = linkData.linkid;
    const name = linkData.metadata.name;
    const iconType = linkData.metadata.thumb ? HFN.ICONS.GRID : HFN.ICONS.LIST;
    const icon =
      linkData.metadata.isfolder && linkData.metadata.folderid == 0
        ? HFN.ICO.VIRTUALFOLDER
        : linkData.metadata.icon;
    const iconData = HFN.createIcon(icon, iconType);

    return (
      <Row>
        <Group>
          <Link href={linkData.link} target="_blank">
            <Icon src={iconData[0].src} ref={ref => (this.icon = ref)} />
            <Title>{name}</Title>
          </Link>
        </Group>
        <Group className="buttons">
          <Button
            onClick={event => onEditCustomBranding({ event, link: linkData })}
            color="lightCyan"
            borderRadius={3}
            inline
          >
            {__("Edit")}
          </Button>
          <Button
            className="removeButton"
            onClick={() => updateCustomLinksOnRemove(linkid)}
            color="lightCaution"
            borderRadius={3}
            inline
          >
            <StopIcon />
          </Button>
        </Group>
      </Row>
    );
  }
}

type CustomLinkSettingsProps = {
  customLinks: Array<Object>,
  initCustomLinkStop: () => void,
  onEditCustomBranding: () => void
};

class CustomLinkSettings extends PureComponent<CustomLinkSettingsProps> {
  constructor(props: CustomLinkSettingsProps) {
    super(props);

    (this: any).renderCustomLinks = this.renderCustomLinks.bind(this);
    (this: any).renderInstructions = this.renderInstructions.bind(this);
  }

  renderCustomLinks() {
    const { customLinks, initCustomLinkStop, onEditCustomBranding } = this.props;

    return customLinks.map(link => {
      return (
        <CustomLinkRow
          key={link.linkid}
          linkData={link}
          updateCustomLinksOnRemove={initCustomLinkStop}
          onEditCustomBranding={onEditCustomBranding}
        />
      );
    });
  }

  renderInstructions() {
    return <Message>{__("custom_branding_free")}</Message>;
  }

  render() {
    const { customLinks } = this.props;
    console.log("customLinks", customLinks);
    return (
      <TabContent>
        {customLinks.length ? this.renderCustomLinks() : this.renderInstructions()}
      </TabContent>
    );
  }
}

export default CustomLinkSettings;

const TabContent = styled.div`
  margin: 0 auto;
  width: 100%;
  position: relative;
`;

const Row = styled.div`
  width: 100%;
  height: 48px;
  border-bottom: 1px solid #eee;
  padding: 9px;
  padding-right: 15px;
  box-sizing: border-box;
  &:last-of-type {
    border-bottom: none;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Group = styled.div`
  display: inline-block;
  &.buttons a {
    height: 29px;
    margin: 0px;
    line-height: 27px;
    width: 90px;
    padding: 0px;
    font-size: 11px;
    box-shadow: 0px 1px 0 0 rgba(0, 0, 0, 0.03);
  }

  &.buttons .removeButton {
    width: 29px;
    margin-left: 11px;
  }
`;

const StopIcon = styled.div`
  display: inline-block;
  width: 11px;
  height: 11px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  top: 2px;
  cursor: pointer;
  background-image: url(${require("../../../root/img/branding-settings/path.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/branding-settings/path@2x.png")});
  }
`;

const Icon = styled.img`
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  vertical-align: middle;
`;

const Link = styled.a`
  color: #000;
  text-decoration: none;

  &:hover {
    span {
      text-decoration: underline;
    }
  }
`;

const Title = styled.span`
  position: absolute;
  left: 51px;
  line-height: 28px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 62%;
  max-width: 600px;
  display: inline-block;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.5);
`;
