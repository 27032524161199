import React from "react";
import { __ } from "../../../../../lib/translate";
import useWindowDimensions from "../../../../../lib/hooks/useWindowDimensions";
import { MOBILE_WIDTH_BREAKPOINT } from "../../../../../config/constants";
import { EmptyStateMessage, EmptyStateMessageTitle, EmptyStateWrapper } from "../styles";

const Default = ({ noItemsLabel }) => {
    const { bodyWidth } = useWindowDimensions();
    const isMobile = bodyWidth < MOBILE_WIDTH_BREAKPOINT;

    return (
        <EmptyStateWrapper $isMobile={isMobile}>
            <EmptyStateMessage $isMobile={isMobile}>
                <EmptyStateMessageTitle>
                    {__(...noItemsLabel)}
                </EmptyStateMessageTitle>
            </EmptyStateMessage>
        </EmptyStateWrapper>
    );
};

export default Default;
