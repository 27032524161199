//@flow

import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { hiDPI } from "polished";

import { SettingsWrapper } from "./SharedStyledComponents";
import TopBar from "./TopBar";
import Button from "../../components/ButtonDefault";

import { showDownloadLinkTrafficSummary } from "../../lib/linkstats";
import { __ } from "../../lib/translate";
import { formatDt, formatSize } from "../../lib/utils";

type Props = {
  allSettingsBack: any,
  bodyHeight: string,
  parentRef: any
};

const LinkStatsSetting = ({ allSettingsBack, bodyHeight, parentRef, currentSettingId }: Props) => {
  const linkData = useSelector(({ sharedLinks }) => sharedLinks.linkData);
  const canBuyTraffic = HFN.config.canBuyTraffic();
  const { linkId, created, downloads, traffic } = linkData;

  const statsContainer = useRef(null);

  useEffect(() => {
    if (canBuyTraffic && typeof gtag === "function") {
      gtag("event", "shared_link_settings_view", {
        action: "buy traffic",
        category: "conversion",
        location: "settings modal"
      })
    }
  }, []);

  useEffect(() => {
    showDownloadLinkTrafficSummary(linkId, $(statsContainer.current));
  }, [linkId]);

  const renderButton = () => {
    if (!canBuyTraffic) {
      return null;
    }
    return (
      <BuyTrafficButton
        color="cyan"
        onClick={() => {
          if (typeof gtag === "function") {
            gtag("event", "shared_link_settings_click", {
              action: "buy traffic",
              category: "conversion",
              location: "settings modal"
            })
          }
          window.open(HFN.config.URLSITE + "additional-traffic.html", "_blank")
        }}
      >
        {__("get_traffic_button", "Get traffic")}
      </BuyTrafficButton>
    );
  };

  return (
    <SettingsWrapper bodyHeight={bodyHeight} ref={parentRef} className="share-publink">
      <TopBar allSettingsBack={allSettingsBack} renderButton={renderButton} settingId={currentSettingId}/>
      <div className="stats" ref={statsContainer} />
      <StatsSummaryContainer>
        <StatsSummary>
          <IconCreated />
          <Texts>
            <StatHeading>{__("link_created", "Link created")}</StatHeading>
            <StatValue>{formatDt(created)}</StatValue>
          </Texts>
        </StatsSummary>
        <StatsSummary>
          <IconDownloads />
          <Texts>
            <StatHeading>{__("total_downloads", "Total downloads")}</StatHeading>
            <StatValue>{downloads}</StatValue>
          </Texts>
        </StatsSummary>
        <StatsSummary>
          <IconTraffic />
          <Texts>
            <StatHeading>{__("total_traffic", "Total traffic")}</StatHeading>
            <StatValue>{formatSize(traffic)}</StatValue>
          </Texts>
        </StatsSummary>
      </StatsSummaryContainer>
    </SettingsWrapper>
  );
};

export default LinkStatsSetting;

const StatsSummaryContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 10px;
`;

const StatsSummary = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled.div`
  display: inline-block;
  width: 32px;
  height: 32px;
  background-size: 32px 32px;
  @media (max-width: 450px) {
    display: none;
  }
`;

const IconCreated = styled(Icon)`
  background-image: url(${require("../../../root/img/dlink/link-created.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/dlink/link-created@2x.png")});
  }
`;

const IconDownloads = styled(Icon)`
  background-image: url(${require("../../../root/img/dlink/link-downloaded.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/dlink/link-downloaded@2x.png")});
  }
`;

const IconTraffic = styled(Icon)`
  background-image: url(${require("../../../root/img/dlink/link-traffic.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/dlink/link-traffic@2x.png")});
  }
`;

const Texts = styled.div`
  margin-left: 10px;
`;

const StatHeading = styled.div`
  font-size: 11px;
  line-height: 1.18;
  text-align: left;
  color: #aaaaaa;
`;

const StatValue = styled.div`
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
  text-align: left;
  color: #000000;
`;

const BuyTrafficButton = styled(Button)`
  width: auto;
  height: 32px;
  line-height: 32px;
  box-sizing: border-box;
  max-width: 200px;
  position: absolute;
  top: 20px;
  right: 20px;
`;
