import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import BackupIcon from "../../../root/img/header/backup.svg";
import BackupDoneIcon from "../../../root/img/header/backup-done.svg";
import useUploadContext from "./hooks/useUploadContext";
import { createPortal } from "react-dom";
import { AnimatePresence, motion } from "framer-motion";
import { ONBOARDING_ADD_ITEM } from "../../lib/state/constants/onboarding";
import { ONBOARDING_ID_UPLOAD_PROGRESS } from "../HighlightOnboardingComponent/constants";
import UploadProgressContent from "../HighlightOnboardingComponent/Contents/UploadManager/UploadProgressContent";
import HighlightOnboardingComponent from "../HighlightOnboardingComponent";
import { useDispatch } from "react-redux";

const TopBarProgress = () => {
    const [isUploading, setIsUploading] = useState(0);
    const [perimeter, setPerimeter] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [queueCount, setQueueCount] = useState(0);
    const dispatch = useDispatch();
    const {
        uploadQueue,
        donePercentage,
        isOpened,
        handleToggleState
    } = useUploadContext();
    const progressRef = useRef(null);
    const container = document.getElementById("uploadProgressSection");

    useEffect(() => {
        let timeout;
        if (donePercentage.isUploading) {
            if (!isUploading) {
                const progressRing = document.querySelector(
                    ".progress-ring__border"
                );
                if (progressRing) {
                    progressRing.style.removeProperty("stroke-dashoffset");
                }
            }

            setIsUploading(true);
            if (totalCount === 0) {
                setTotalCount(donePercentage.pendingItems);
                setQueueCount(uploadQueue.length);
            } else if (queueCount <= uploadQueue.length) {
                setTotalCount(totalCount + (uploadQueue.length - queueCount));
            }
        } else {
            timeout = setTimeout(() => {
                setIsUploading(false);
                setQueueCount(0);
                setTotalCount(0);
            }, 2000);
        }

        return () => {
            clearTimeout(timeout);
        };
    }, [donePercentage.isUploading, uploadQueue.length]);

    useEffect(() => {
        if (!progressRef.current) {
            return;
        }
        calcPerimeter();
    }, [progressRef.current]);

    useEffect(() => {
        if (
            !container ||
            !isOpened ||
            !progressRef.current ||
            !perimeter ||
            !totalCount
        ) {
            return;
        }

        dispatch({
            type: ONBOARDING_ADD_ITEM,
            id: ONBOARDING_ID_UPLOAD_PROGRESS
        });

        calcPerimeter();
        setProgress();
    }, [
        isOpened,
        progressRef.current,
        totalCount,
        donePercentage.doneItems,
        perimeter
    ]);

    const calcPerimeter = () => {
        const progress = document
            .querySelector(".progress-ring")
            .getBoundingClientRect();
        setPerimeter(2 * (progress.width + progress.height - 16));

        if (perimeter > 0) {
            const progressSvgList = document.querySelectorAll(
                ".progress-ring rect"
            );
            progressSvgList.forEach((progressSvg) => {
                progressSvg.style.width = (progress.width - 4) + 'px';
                progressSvg.style.height = (progress.height - 4) + 'px';
            });
        }
    };

    const setProgress = () => {
        const percentage =
            ((totalCount - donePercentage.pendingItems) / totalCount) * 100;
        const progressRing = document.querySelector(".progress-ring__border");
        progressRing.style.strokeDashoffset =
            perimeter * ((100 - percentage) / 100);
    };

    if (!container || !isOpened) {
        return null;
    }

    return (
        <>
            {createPortal(
                <ProgressWrapper id="uploadProgressSection">
                    <HighlightOnboardingComponent
                        id={ONBOARDING_ID_UPLOAD_PROGRESS}
                        content={[<UploadProgressContent />]}
                        notShowAfterClose={true}
                        pulseAnimation={true}
                        pulseCircle={false}
                        showOverlay={true}
                        hasDynamicSize={true}
                        place={"bottom-right"}
                        borderRadius={"9999px"}
                        delay={500}
                    >
                    <Progress
                        onClick={handleToggleState}
                        $perimeter={perimeter}
                        $isUploading={isUploading}
                        ref={progressRef}
                        layout={"position"}
                    >
                        <svg className="progress-ring">
                            {perimeter > 0 && (
                                <>
                                    <rect
                                        className="progress-ring__background"
                                        x="2"
                                        y="2"
                                        rx="20"
                                        ry="20"
                                    />
                                    <rect
                                        className="progress-ring__border"
                                        x="2"
                                        y="2"
                                        rx="20"
                                        ry="20"
                                    />
                                </>
                            )}
                        </svg>
                        <ProgressContainer $isUploading={isUploading}>
                            <Logo layout={false}>
                                {isUploading ? (
                                    <BackupIcon />
                                ) : (
                                    <BackupDoneIcon />
                                )}
                            </Logo>
                            {!!isUploading && (
                                <CounterText>
                                    {Math.max(
                                        totalCount -
                                            donePercentage.pendingItems,
                                        0
                                    )}
                                    /{totalCount}
                                </CounterText>
                            )}
                        </ProgressContainer>
                    </Progress>
                    </HighlightOnboardingComponent>
                </ProgressWrapper>,
                container
            )}
        </>
    );
};

export default TopBarProgress;

const ProgressWrapper = styled.div`
    display: flex;
    margin-left: 24px;
`;
const Progress = styled(motion.div)`
    display: grid;
    place-items: center;
    position: relative;
    box-sizing: border-box;
    cursor: pointer;

    & svg.progress-ring {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        & rect {
            transition: ${({ $isUploading }) =>
                $isUploading ? "stroke-dashoffset 1s ease-in-out" : ""};
            width: 36px
                ${({ $isUploading }) => ($isUploading ? "" : "!important")};
            height: 36px
                ${({ $isUploading }) => ($isUploading ? "" : "!important")};
        }

        .progress-ring__background {
            fill: none;
            stroke: var(--border-primary);
            stroke-width: 4;
        }

        .progress-ring__border {
            fill: none;
            stroke: var(--color-primary-700);
            stroke-width: 4;
            stroke-dasharray: ${({ $perimeter }) =>
                $perimeter ? $perimeter : 0};
            stroke-dashoffset: ${({ $perimeter }) =>
                $perimeter ? $perimeter : 0};
        }
    }
`;
const ProgressContainer = styled.div`
    width: auto;
    height: 40px;
    background: var(--button-primary-label-default);
    border-radius: var(--radius-full);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: ${({ $isUploading }) => ($isUploading ? "0 16px" : "0 8px")};
    box-sizing: border-box;
`;
const CounterText = styled.span`
    display: inline-block;
    color: #000;
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    white-space: nowrap;
`;
const Logo = styled(motion.span)`
    width: 24px;
    height: 24px;
`;
