import React from "react";
import { PublicButtonWrapper } from "./styles";

const PublicButton = ({
    onClick,
    value,
    iconSvg,
    white = false,
    styles = {}
}) => {
    return (
        <PublicButtonWrapper onClick={onClick} style={styles} $white={white}>
            {iconSvg}
            {value}
        </PublicButtonWrapper>
    );
};

export default PublicButton;
