// @flow
import React, { useState } from "react";
import { useSelector } from "react-redux";
import Componentify from "react-componentify";

import apiMethod from "../../api/apiMethod";
import { __ } from "../../lib/translate";
import { VIEW } from "@pcloud/web-utilities/dist/config/constants";
import { linkConverter, styledSpanTagConverter } from "../../lib/componentifyConverters";
import { getUserInitials } from "../../lib/shareFolder-utils";

import SuccessIcon from "../../../root/img/family/success.svg";
import Button from "../ButtonDefault";
import { AvatarWrap } from "../UserAvatar";
import { TSpan } from "../intl";
import * as Style from "./StyleInviteFolder";
import * as StyleModal from "../Modals/styledComponents";

const customConverter = Object.assign({}, styledSpanTagConverter, {
  props: {
    style: {
      display: "inline-block",
      color: "#17bed0",
      maxWidth: "300px",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap"
    }
  }
});

type Props = {
  folderId: number,
  usersFromOtherRegion: Array<any>,
  showComponent: () => void
};

const InviteOtherRegion = ({ folderId, usersFromOtherRegion, showComponent }: Props) => {
  const permissionId = useSelector(({ sharedFolders: { sharePermissionId } }) => sharePermissionId);
  const shareFolderName = useSelector(({ sharedFolders: { shareFolderName } }) => shareFolderName);
  const [shouldRenderSuccMsg, setShouldRenderSuccMsg] = useState(false);

  const onSendDownloadLink = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    let xhr = [];
    let error = "";

    usersFromOtherRegion.forEach(user => {
      const params = {
        folderid: folderId,
        mail: user.email
      };

      if (permissionId !== VIEW) {
        params.canupload = true;
      }

      xhr.push(
        apiMethod(
          "publink/createfolderlinkandsend",
          params,
          res => {
            // HFN.message("success", "success");
          },
          {
            errorCallback: ret => {
              error = ret.result;
            }
          }
        )
      );
    });

    $.when.apply($, xhr).then(() => {
      console.log("links sent");
      if (error) {
        if (HFN.ERROR_MESSAGE[error]) {
          HFN.message(__(HFN.ERROR_MESSAGE[error]), 'error');
        } else {
          HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
        }
        setShouldRenderSuccMsg(false);
        throw new Error(error);
      } else {
        setShouldRenderSuccMsg(true);
      }
    });
  };

  // render
  const renderSuccessMessage = () => {
    return (
      <Style.SuccesWrapper>
        <Style.MessageSuccWrapper>
          <SuccessIcon />
          <Style.Message style={{ marginTop: "15px" }}>{__("invite_flow_success_message")}</Style.Message>
        </Style.MessageSuccWrapper>
        <StyleModal.Footer>
          <Button
            inline
            color="cyan"
            size="big"
            onClick={() => showComponent(false)}
          >
            {__("share_folder_invite_more_people_btn", "Invite more")}
          </Button>
        </StyleModal.Footer>
      </Style.SuccesWrapper>
    );
  };

  const renderWarningMesaage = () => {
    return (
      <Style.MessageWrapper>
        <Style.Message>
          <Componentify
            text={
              permissionId !== VIEW
                ? __("share_folder_modal_diff_regions_message2")
                : __("share_folder_modal_diff_regions_message")
            }
            converters={[linkConverter]}
          />
        </Style.Message>
      </Style.MessageWrapper>
    );
  };

  const renderRow = data => {
    return (
      <Style.Row key={data.email}>
        <Style.EmailWrapper type="type">
          <AvatarWrap>{getUserInitials(data.email)}</AvatarWrap>
          <Style.Email>{data.email}</Style.Email>
        </Style.EmailWrapper>
      </Style.Row>
    );
  };

  if (shouldRenderSuccMsg) {
    return renderSuccessMessage();
  }

  const title = __("share_folder_share_for").replace("%foldername%", shareFolderName);

  return (
    <Style.InviteOtherRegionWrapper>
      <StyleModal.Header>
        <Componentify text={title} converters={[customConverter]} />
      </StyleModal.Header>
      {renderWarningMesaage()}
      <Style.Label id="users_outside_region_title">Users outside region</Style.Label>
      {usersFromOtherRegion.length ? (
        <Style.Table>{usersFromOtherRegion.map(renderRow)}</Style.Table>
      ) : (
        <Style.EmptyTable>{__("users_outside_region_message")}</Style.EmptyTable>
      )}
      <Style.Footer>
        <Button inline color="cyan" size="big" onClick={onSendDownloadLink}>
          <TSpan
            id={__("dl_send_link", "Send link")}
            style={{ color: "#fff", minWidth: "180px" }}
          />
        </Button>
      </Style.Footer>
    </Style.InviteOtherRegionWrapper>
  );
};

export default InviteOtherRegion;
