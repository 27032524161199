// @flow

import React, { PureComponent } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import $ from "jquery";

import apiMethod from "../../api/apiMethod";
import { abortAll } from "../../lib/XhrComponentUtils";
import errors from "../../lib/errors";
import { __ } from "../../lib/translate";
import { validateEmail, formatSizeInGb } from "../../lib/utils";
import {
  MIN_USER_QUOTA,
  QUOTA_STEP,
  MAX_MESSAGE_LENGTH
} from "@pcloud/web-utilities/dist/config/constants";

import Button, { ButtonCentered } from "../../components/ButtonDefault";
import Slider from "../../components/Slider";
import StorageManagment from "./StorageManagment";
import { LoaderFamily, CloseButtonFamily } from "./SharedFamilyComponents";

type Props = {
  token: string,
  ownerEmail: string,
  quota: number,
  usedQuota: number,
  disabled: boolean,
  onSuccess: () => void,
  onClose: () => void
};

type State = {
  contactsLoaded: boolean,
  contacts: Array<string>,
  quota: number,
  inviteLink: string,
  showInvitationSuccess: boolean,
  invitedEmail: string,
  isLoading: boolean
};

class InviteFamilyForm extends PureComponent<Props, State> {
  static defaultProps = {
    token: "",
    ownerEmail: "",
    onSuccess: () => {}
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      contactsLoaded: false,
      contacts: [],
      quota: 0,
      inviteLink: "",
      showInvitationSuccess: false,
      invitedEmail: "",
      isLoading: false
    };

    (this: any).sendInvitations = this.sendInvitations.bind(this);
    (this: any).getQuota = this.getQuota.bind(this);

    (this: any).inputPrepared = false;
    (this: any).qEngine = null;
    (this: any).combo = null;
    (this: any).xhrList = [];
  }

  componentDidMount() {
    this.getContactList();
  }

  componentWillUnmount() {
    abortAll((this: any).xhrList);
  }

  getQuota(quota: number) {
    this.setState({ quota });
  }

  sendInvitations() {
    const { ownerEmail, disabled } = this.props;
    const { combo, qEngine } = this;

    if (disabled) {
      return;
    }
    let vals = combo.getFullVals(true);
    let emails = [];
    let fb = [];
    let fbNames = [];
    let message = $("textarea").val();
    let manuallyAdded = [];

    console.log("!! full vals", vals);
    console.log("!! full message", message);

    for (let n = 0; n < vals.length; ++n) {
      if (vals[n].data && vals[n].data.source == 2) {
        fb.push({ id: vals[n].data.value, obj: vals[n].obj });
        fbNames.push(vals[n].data.name);
      } else if (vals[n].data && vals[n].data.source == 3) {
        emails.push({ email: vals[n].data.value, obj: vals[n].obj });
      } else if (validateEmail(vals[n].val)) {
        emails.push({ email: vals[n].val, obj: vals[n].obj });
        if (!vals[n].data && manuallyAdded.indexOf(vals[n].val) == -1) {
          qEngine.add([{ name: vals[n].val, value: vals[n].val, source: 1 }]);
          manuallyAdded.push(vals[n].val);
        }
      } else {
        vals[n].obj
          .addClass("error")
          .find(".combo-res")
          .tooltip("destroy")
          .attr("title", __("This is not a valid contact or an email."));
      }
    }

    if (message.length > MAX_MESSAGE_LENGTH) {
      HFN.message(
        __("error_2245", "Maximum message length is 500 characters."),
        "error"
      );
      return;
    }

    if (!emails.length && !fb.length) {
      HFN.message(__("error_invalid_email"), "error");
      return;
    }

    if (emails[0].email === ownerEmail) {
      HFN.message(
        __(
          "error_cannot_enter_your_own_email",
          "You cannot enter your own email address in this field"
        ),
        "error"
      );
      return;
    }

    this.setState({ isLoading: true });

    const { token, onSuccess } = this.props;
    const { quota } = this.state;
    const email = emails[0].email;

    (this: any).xhrList.push(
      apiMethod(
        "fm_sendinvitation",
        { auth: token, mail: email, quota, message },
        ({ result, link, invitationid }) => {
          this.setState({
            inviteLink: link,
            isLoading: false,
            showInvitationSuccess: true,
            invitedEmail: email
          });
          emails[0].obj.remove();
          combo.onRemove();
          HFN.message("family_resend_message");
          onSuccess({ email, invitationid, quota, invitelink: link });
        },
        {
          errorCallback: ({ result, error }) => {
            this.setState({ isLoading: false });
            emails[0].obj
              .addClass("error")
              .find(".combo-res")
              .tooltip("destroy")
              .tooltip(/*{ container: '.tomails' }*/);

            if (result === 2314) {
              HFN.message(
                __(
                  "family_error_max_members",
                  "You can't invite any more members"
                ),
                "error"
              );
              return;
            }

            if (result === 1064) {
              HFN.message(__("error_invalid_email"), "error");
              return;
            }

            if (errors[result]) {
              HFN.message(errors[result], "error");
            } else {
              HFN.message(
                __("something_went_wrong_refresh_and_try_again"),
                "error"
              );
              throw new Error(error);
            }
          }
        }
      )
    );
  }

  getContactList() {
    (this: any).xhrList.push(
      apiMethod(
        "contactlist",
        { auth: this.props.token },
        ret => {
          this.setState({ contactsLoaded: true, contacts: ret.contacts });
        },
        {
          errorCallback: ret => {
            this.setState({ contactsLoaded: true, contacts: [] });

            if (errors[result]) {
              HFN.message(errors[result], "error");
            } else {
              HFN.message(
                __("something_went_wrong_refresh_and_try_again"),
                "error"
              );
              throw new Error(error);
            }
          }
        }
      )
    );
  }

  prepareSendInput(contacts) {
    const { disabled } = this.props;
    if (this.inputPrepared) {
      return;
    }

    (this: any).inputPrepared = true;

    let filteredContacts = HFN.filterMailContacts(contacts);
    let qEngine;
    let combo;

    setTimeout(() => {
      let tokenizer = function(name) {
        let ret = Bloodhound.tokenizers.whitespace(name.replace(/\(?\)?/g, ""));
        if (ret.indexOf(name.replace(/\(?\)?/g, "")) == -1)
          ret.push(name.replace(/\(?\)?/g, ""));
        return ret;
      };

      qEngine = new Bloodhound({
        name: "name",
        datumTokenizer: function(r) {
          let ret = Bloodhound.tokenizers.whitespace(
            r.name.replace(/\(?\)?/g, "")
          );
          if (ret.indexOf(r.name.replace(/\(?\)?/g, "")) == -1)
            ret.push(r.name.replace(/\(?\)?/g, ""));
          return ret;
        },
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        local: filteredContacts
      });
      (this: any).qEngine = qEngine;

      let orig_get = qEngine.get;
      qEngine.get = function(query, cb) {
        return orig_get.apply(qEngine, [
          query,
          function(suggestions) {
            if (!suggestions) return cb(suggestions);
            suggestions.forEach(function(s) {
              let tokens = tokenizer(s.name.toLowerCase()),
                score = 0;
              for (let n = 0; n < tokens.length; ++n) {
                if (tokens[n].indexOf(query) == 0) score++;
              }
              s.exact_match = score;
            });
            console.log(suggestions);
            suggestions.sort(function(a, b) {
              return a.exact_match > b.exact_match
                ? -1
                : a.exact_match < b.exact_match
                ? 1
                : 0;
            });
            cb(suggestions);
          }
        ]);
      };

      qEngine.initialize();

      let comboOptions = {
        place: ".tomails",
        name: "emails",
        sourceKey: "name",
        disabled: disabled,
        source: qEngine,
        placeholder: __(
          "family_invite_form_email_input",
          "Enter your family member's email address"
        ),
        maxNotFoundSymbols: 5,
        maxItems: 1,
        splitBy: [9, 32, 13, 188],
        getShowVal: function(r) {
          return r;
        },

        fancyResultBox: function(box, data) {
          let ent;

          console.log(box.data());

          if (data && data.data) {
            ent = data.data;
            if (ent.source == 2)
              box.prepend(
                '<img src="/img/fb-tag.png" width="16" height="16" class="inf">'
              );
            else if (ent.source == 3)
              box.prepend(
                '<img src="/img/gm-tag.png" width="16" height="16" class="inf">'
              );
            else if (ent.source == 1)
              box.prepend(
                '<img src="/img/or-mail-small.png" width="16" height="16" class="inf">'
              );
          } else if (box.data("val")) {
            if (validateEmail(box.data("val")))
              box.prepend(
                '<img src="/img/or-mail-small.png" width="16" height="16" class="inf">'
              );
          }
        },

        imgCloseFile: "/img/close-label.png",

        extraMulticompleteOpts: {
          suggestAt: ".tomails",
          boxRender: function(data) {
            let box = $("<div>"),
              img = $('<img width="32" height="32">');

            console.log("fkhsajkfhjhfjhasfjd ", box);

            box
              .addClass("clearfix")
              .append($('<div class="iconw"></div>').append(img))
              .append(
                $('<div class="textpart"></div>')
                  .append($('<span class="title"></span>').text(data.name))
                  .append($('<span class="explain"></span>'))
              );
            console.log("Data fsjahf ", data);

            if (data.source == "import") {
              if (data.value == "Facebook") {
                img.attr("src", "/img/fab.png");
              } else if (data.value == "Gmail") {
                img.attr("src", "/img/gmail.png");
              }
              box.find(".explain").text(
                __("Import contacts from %source%", false, {
                  source: data.value
                })
              );
            } else if (data.source == 3) {
              img.attr("src", "/img/gmail.png");
              box.find(".explain").text(data.value);
            } else if (data.source == 2) {
              img.attr(
                "src",
                "https://graph.facebook.com/" +
                  data.value +
                  "/picture?type=square"
              );
              box
                .find(".iconw")
                .append('<img src="/img/fb-over.png" class="smicon">');
              box.find(".explain").text(__("Facebook Message"));
            } else if (data.source == 1) {
              img.attr("src", "/img/or-mail.png");
              box.find(".explain").text(__("Send Email"));
            }

            return box;
          }
        }
      };

      combo = new combo2(comboOptions);
      (this: any).combo = combo;
    }, 0);
  }

  render() {
    const {
      contactsLoaded,
      contacts,
      showInvitationSuccess,
      isLoading
    } = this.state;
    const { quota, usedQuota, disabled } = this.props;
    const freeOwnerQuota = quota - usedQuota;
    let maxValue = 0;
    if (usedQuota < MIN_USER_QUOTA) {
      maxValue = freeOwnerQuota - MIN_USER_QUOTA;
    } else {
      maxValue = freeOwnerQuota;
    }

    if (contactsLoaded) {
      this.prepareSendInput(contacts);
    } else {
      return <LoaderFrom />;
    }

    if (maxValue < MIN_USER_QUOTA) {
      this.inputPrepared = false;
      return null;
    }

    return (
      <Wrapper>
        <Title disabled={disabled}>{__("family_invite_form_header", "Email address:")}</Title>
        <Form>
          <div className="tomails" />
          <Line style={{ margin: 0 }}/>
          <InputMessage
            disabled={disabled}
            type="text"
            placeholder={__(
              "family_invite_form_msg_input",
              "Enter a message (optional)"
            )}
          />
        </Form>
        <StorageManagment
          startValue={MIN_USER_QUOTA}
          minQuota={MIN_USER_QUOTA}
          maxValue={maxValue}
          getQuota={this.getQuota}
          shouldDisableSlider={disabled}
          minStartValue={MIN_USER_QUOTA}
        />
        <ButtonCentered disabled={disabled} disabledColor="#ccc" loading={isLoading} onClick={this.sendInvitations}>
          {__("family_invite_form_button", "Send Invite")}
        </ButtonCentered>
      </Wrapper>
    );
  }
}

export default connect(({ user }) => {
  const { token, userinfo: { quota, usedquota, email } = {} } = user;

  return { token, quota, usedQuota: usedquota, ownerEmail: email };
})(InviteFamilyForm);

export const Wrapper = styled.div`
  border-top: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
  background-color: #fafafa;
  padding: 20px 105px;
  margin-top: 30px;
  opacity: ${props => (props.disabled ? 0.4 : 1)};


    @media only screen and (max-width: 768px) {
        padding: 20px;
    }

  a {
    margin-top: 0 !important;
  }

  .tomails {
    width: 100%;
    border: none;

    .combo-contain {
      width: 100% !important;
      min-height: 40px;
      background-color: #ffffff;
      font-size: 15px;
      padding: 15px;
      border-radius: 5px;
      border: none;
      outline: none;
      box-sizing: border-box;
      padding: 5px;
      box-shadow: none;

      input {
        height: 28px;
        padding: 0 10px;
        font-size: 14px;
        box-sizing: border-box;
      }

      .combo-res {
        height: 18px;
        line-height: 18px;
        background: #f2f2f2;
        border-radius: 12px;
        padding: 3px 5px;
        margin: 0 5px 2px 0;

        .inf {
          margin: -1px 5px 0 0;
        }
      }
    }
    .combo-contain.focu,
    .combo-contain.focu input {
      background: #fff !important;
    }
  }
`;

export const Title = styled.div`
  opacity: ${props => (props.disabled ? 0.4 : 1)};
  font-size: 13px;
  margin-bottom: 10px;
`;

const InputEmail = styled.input`
  width: 100%;
  height: 40px;
  background-color: #ffffff;
  font-size: 15px;
  padding: 15px;
  border: 1px solid #e9e9e9;
  outline: none;
  box-sizing: border-box;
`;
const InputMessage = styled.textarea`
  width: 100%;
  height: 70px;
  min-height: 70px;
  background-color: #ffffff;
  font-size: 14px;
  padding: 15px;
  font-family: "Roboto", sans-serif;
  border: 1px solid #e9e9e9;
  border-top: none;
  box-sizing: border-box;
  outline: none;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e9e9e9;
  margin: 20px 0;
`;

const LoaderFrom = styled(LoaderFamily)`
  margin: 20px 0 0 -25px;
`;

const Form = styled.div`
  margin-bottom: 40px;
  border-radius: 6px;
  border: solid 1px #eee;
  background-color: #fafafa;

  textarea {
    border: none;
    resize: none;
  }
`;
