import React from "react";
import { __ } from "../../../../../lib/translate";
import useWindowDimensions from "../../../../../lib/hooks/useWindowDimensions";
import { MOBILE_WIDTH_BREAKPOINT } from "../../../../../config/constants";
import EmptyDesktopIcon from "../../../../../../root/img/grid/empty-state/playlists-desktop.svg";
import EmptyMobileIcon from "../../../../../../root/img/grid/empty-state/playlists-mobile.svg";
import {
    EmptyStateMessage,
    EmptyStateMessageSubTitle,
    EmptyStateMessageTitle,
    EmptyStateWrapper,
    ImageContainer
} from "../styles";
import styled from "styled-components";
import { Button } from "../../../Templates/TopBar/UploadButton";

const Playlists = ({ noItemsLabel }) => {
    const { bodyWidth, bodyHeight } = useWindowDimensions();
    const isMobile = bodyWidth < MOBILE_WIDTH_BREAKPOINT;

    return (
        <EmptyStateWrapper $isMobile={isMobile}>
            <EmptyStateMessage $isMobile={isMobile}>
                <EmptyStateMessageTitle>
                    {__(...noItemsLabel)}
                </EmptyStateMessageTitle>
                <EmptyStateMessageSubTitleCustom>
                    {__(
                        "playlists_empty_message",
                        "You did not add any songs in playlists."
                    )}
                </EmptyStateMessageSubTitleCustom>
            </EmptyStateMessage>

            <ImageContainer $isMobile={isMobile}>
                {!isMobile && <EmptyDesktopIcon />}
                {isMobile && <EmptyMobileIcon />}
            </ImageContainer>

            <ButtonWrapper $isMobile={isMobile || bodyHeight < 450}>
                <Button onClick={() => {
                    HFN.initCreatePlaylist();
                }}>
                    {__("Create Playlist")}
                </Button>
            </ButtonWrapper>
        </EmptyStateWrapper>
    );
};

export default Playlists;

const EmptyStateMessageSubTitleCustom = styled(EmptyStateMessageSubTitle)`
    padding: 16px 0;
`;
const ButtonWrapper = styled.div`
    display: flex;
    margin-top: ${({$isMobile}) => $isMobile ? 'var(--spacing-lg)' : '0'};
    padding: ${({$isMobile}) => $isMobile ? '0' : '64px 0'};
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
`;