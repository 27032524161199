import React, { useEffect, useState, useRef, useCallback } from 'react';
import styled from 'styled-components';
import FileItem from "./FileItem";
import FailedSvg from "../../../root/img/svg/upload-manager/failed.svg";
import { FixedSizeList } from 'react-window';
import { UPLOAD_STATUS, FILE_LIST_HEIGHT_WITH_FAILED_UPLOADS, FILE_LIST_HEIGHT, FILE_ITEM_SIZE, FILE_LIST_WIDTH, UPLOAD_TAB } from "./constants";
import { countFailedItems, isUploadDisabled } from "./helpers";
import CloudDropDisabledSvg from "../../../root/img/svg/upload-manager/cloud-drop-disabled-grey.svg";
import AutoSizer from "react-virtualized-auto-sizer";
import useUploadContext from './hooks/useUploadContext';
import { __ } from "../../lib/translate"
import { Tooltip as ReactTooltip } from 'react-tooltip';
import useWindowDimensions from "../../lib/hooks/useWindowDimensions";

const FilesList = () => {
  const {
    opts,
    initializeUploads,
    uploadQueue,
    activeTab,
    filteredUploadQueue,
    isMobile,
  } = useUploadContext();

  const { bodyWidth } = useWindowDimensions();
  const fileInputRef = useRef(null);
  const folderInputRef = useRef(null);
  const [initialHeight, setInitialHeight] = useState(0);
  const [height, setHeight] = useState(0);
  const isDlink = HFN.config.isDlink();

  useEffect(() => {
    const container = document.getElementById('upload-main-container');
    const topBar = document.getElementById('topbar-first-line');
    if (container) {
      if (!initialHeight) setInitialHeight(container.offsetHeight);

      let newHeight = (initialHeight ? initialHeight : container.offsetHeight) - 100;

      if (!isDlink && isMobile && topBar) {
        const root = container.parentNode;
        const rectTopBar = topBar.getBoundingClientRect();
        const rectContainer = root.getBoundingClientRect();
        const bottomOffset = document.body.offsetHeight - rectContainer.bottom;
        const topOffset = rectTopBar.bottom;

        root.style.maxHeight = (document.body.offsetHeight - bottomOffset - topOffset) + 'px';
        container.style.maxHeight = (document.body.offsetHeight - bottomOffset - topOffset - 48) + 'px';
        newHeight = document.body.offsetHeight - bottomOffset - topOffset - 88;
      }

      setHeight(newHeight)
    }
  }, [isMobile, bodyWidth]);

  const handleFileChange = (event) => {
    initializeUploads({ items: event.target.files, isDrop: false });
  };


  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const triggerFolderInput = () => {
    folderInputRef.current.click();
  };

  const failedItemsLength = useCallback(countFailedItems(uploadQueue), [uploadQueue]);

  const renderFailedBanner = useCallback(() => {
    if (failedItemsLength > 0) {
      return (
        <FailedItemsBanner>
          <StyledFailedSvg />
          {failedItemsLength === 1 ? __('upload_manager_item_failed', '1 item failed.') : __('upload_manager_items_failed', '%itemslength% items failed.').replace('%itemslength%', failedItemsLength)}
        </FailedItemsBanner>
      )
    }
  }, [uploadQueue])

  const renderEmptyFilesScreen = useCallback(() => {
    const noUploadsScreen = <>
      {isUploadDisabled() &&
        <CloudDropDisabledSvg />
      }
      <EmptyUploadsTitle disabled={isUploadDisabled()}>
        {isUploadDisabled() ? __('upload_manager_droparea_unavailable', 'Upload currently unavailable.') : __('upload_manager_empty_uploads', 'No uploads in progress.')}
      </EmptyUploadsTitle>
      {!isUploadDisabled() &&
        <>
          <UploadLinksContainer>
            <UploadLink onClick={triggerFileInput}>{__('Upload files', 'Upload files')}</UploadLink>
            {!isMobile && <UploadLink onClick={triggerFolderInput}>{__('upload_manager_upload_folder', 'Upload folder')}</UploadLink>}
            <input type='file' style={{ display: "none" }} multiple onChange={handleFileChange} ref={fileInputRef} />
            {!isMobile && <input type='file' style={{ display: "none" }} webkitdirectory="true" onChange={handleFileChange} ref={folderInputRef} />}
          </UploadLinksContainer>
          {!isMobile && <DropArea>
            {__('upload_manager_drop_here', 'Drop your files to upload')}
          </DropArea>}
        </>
      }
    </>

    switch (activeTab) {
      case UPLOAD_TAB.ACTIVE:
        return noUploadsScreen
      case UPLOAD_TAB.ALL:
        return noUploadsScreen
      case UPLOAD_TAB.COMPLETED:
        return <EmptyUploadsTitle>
          {__('upload_manager_no_completed_uploads', 'No completed uploads')}
        </EmptyUploadsTitle>
      case UPLOAD_TAB.FAILED:
        return <EmptyUploadsTitle>
          {__('upload_manager_no_failed_uploads', 'No failed uploads')}
        </EmptyUploadsTitle>
      default:
        break;
    }
  }, [activeTab])
  return (
    <>
      {renderFailedBanner()}
      <FilesListContainer $height={height}>
        {filteredUploadQueue.length === 0 ?
          <EmptyUploadsContainer>{renderEmptyFilesScreen()}</EmptyUploadsContainer> :
          <AutoSizer disableHeight>
            {({ width }) => (
              <>
                <FixedSizeList
                  className="files-container"
                  height={height}
                  itemCount={filteredUploadQueue.length}
                  itemSize={FILE_ITEM_SIZE} // Adjust based on your item height
                  width={width}
                >
                  {FileItem}
                </FixedSizeList>
              </>
            )}
          </AutoSizer>
        }
      </FilesListContainer>
    </>
  );
};

export default FilesList;

const FilesListContainer = styled.div`
  width: 100%;
  height: ${({ $height }) => $height ? $height + 'px' : '280px'};
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;

  .files-container {
    /* For WebKit (Safari, Chrome, Edge) */
    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: #CACACEA6;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #A2A2AA;
    }

    /* For Firefox */
    scrollbar-color: #CACACEA6 #ffffff; /* thumb and track color */
    scrollbar-width: thin; /* 'auto' or 'thin' (Firefox does not support custom widths) */
  }
`

const EmptyUploadsTitle = styled.h3`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  margin: ${({ disabled }) => disabled ? 16 : 0}px 0 16px 0;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

`

const UploadLinksContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;
  margin-bottom: 32px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const UploadLink = styled.span`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #17BED0;
  cursor: pointer;
`

const DropArea = styled.div`
  width: 100%;
  height: 60px;
  border: 2px dashed #0000001C;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 50px;
  padding: 8px 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const FailedItemsBanner = styled.div`
  width: 100%;
  display: flex;
  background-color: #F73C3C1A;
  color: #F73C3C;
  align-items: center;
  min-height: 40px;
  padding: 4px 16px;
  gap: 5px;
  box-sizing: border-box;
  font-size: 13px;
  font-family: Roboto;
`

const StyledFailedSvg = styled(FailedSvg)`
  flex-shrink: 0;
`

const EmptyUploadsContainer = styled.div`
  padding: 0 16px;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`