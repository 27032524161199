import React, { useRef } from "react";
import styled from "styled-components";
import { Button } from "../../Templates/TopBar/UploadButton";
import { isUploadDisabledRedesign } from "../../../UploadManager/helpers";

const DragAndDrop = () => {
    const isUploadDisabled = isUploadDisabledRedesign();
    const fileInputRef = useRef(null);
    const folderInputRef = useRef(null);

    const triggerFolderInput = () => {
        if (typeof gtag === "function") {
            gtag("event", "upload_options_click", {
                action: "folder upload",
                location: "empty state"
            });
        }

        folderInputRef.current.click();
        folderInputRef.current.value = "";
    };
    const triggerFileInput = () => {
        if (typeof gtag === "function") {
            gtag("event", "upload_options_click", {
                action: "file upload",
                location: "empty state"
            });
        }

        fileInputRef.current.click();
        fileInputRef.current.value = "";
    };

    const handleFileChange = (event) => {
        HFN.uploadControl.onUpload({
            items: event.target.files,
            isDrop: false
        });
    };

    return (
        <DragAndDropStyle>
            <Text>
                {__("Drop files to upload,")}
                <span>{__('or use the "Add" button.')}</span>
            </Text>
            <Controls>
                <Button disabled={isUploadDisabled} onClick={triggerFileInput}>
                    {__("step_action_upload", "Upload file")}
                </Button>
                <Button
                    disabled={isUploadDisabled}
                    onClick={triggerFolderInput}
                >
                    {__("upload_manager_upload_folder", "Upload folder")}
                </Button>

                <InputFile
                    type="file"
                    multiple
                    onChange={handleFileChange}
                    ref={fileInputRef}
                />
                <InputFile
                    type="file"
                    webkitdirectory="true"
                    onChange={handleFileChange}
                    ref={folderInputRef}
                />
            </Controls>
        </DragAndDropStyle>
    );
};

export default DragAndDrop;

const DragAndDropStyle = styled.div`
    border: 2px dashed var(--color-primary-500);
    box-sizing: border-box;
    padding: 64px var(--spacing-none);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 48px;
    align-self: stretch;
`;

const Text = styled.div`
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    span {
        color: var(--color-grey-600);
        padding-left: var(--spacing-xs);
    }
`;

const Controls = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
`;

const InputFile = styled.input`
    display: none;
`;
