import React, { useState } from "react";
import { __ } from "../../../../../lib/translate";
import { SERVICES_INFO } from "../../../configurations";
import { formatDt } from "../../../../../lib";
import { AnimatePresence } from "framer-motion";
import {
    SubContentRow,
    SubContentRowValue,
    ExpandButton,
    ExpandedContent,
    ExpandedContentLabel,
    ExpandedContentRow,
    ExpandedContentValue,
    PrimeContent,
    ServiceIcon,
    ServiceName,
    SubContent,
    TextArea,
    Wrapper, SubContentRowLabel
} from "./styles";
import styled from "styled-components";
import hashManager from "../../../../../lib/hashManager";

const CardFolderBackup = ({
    category,
    sourceid,
    title = null,
    folderid = null,
    modified = null
}) => {

    const triggerClick = () => {
        if (!folderid) {
            return;
        }

        if (!["mobile", "desktop"].includes(category)) {
            return;
        }

        const stateParams = {
            page: "filemanager",
            folder: folderid
        };
        if (category === "mobile") {
            stateParams.backupmobile = 1;
        }
        if (category === "desktop") {
            stateParams.backupdesktop = 1;
        }

        hashManager.pushState(stateParams, 2);
    };

    return (
        <CustomWrapper onClick={triggerClick}>
            <CustomPrimeContent>
                <ServiceIcon>{SERVICES_INFO[sourceid]?.icon}</ServiceIcon>
                <CustomTextArea>
                    <ServiceName>
                        {title ?? SERVICES_INFO[sourceid]?.name}
                    </ServiceName>
                    <CustomSubContent>
                        {modified && (
                            <SubContentRow>
                                <SubContentRowLabel>
                                    {__("last_updated_label", "Last updated")}:&nbsp;
                                </SubContentRowLabel>
                                <SubContentRowValue>
                                    {formatDt(modified)}
                                </SubContentRowValue>
                            </SubContentRow>
                        )}
                    </CustomSubContent>
                </CustomTextArea>
            </CustomPrimeContent>
        </CustomWrapper>
    );
};

export default CardFolderBackup;

const CustomWrapper = styled(Wrapper)`
    cursor: pointer;
    &:hover {
        box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.12);
    }

    @media (max-width: 800px) {
        padding: var(--spacing-md);
    }
`;

const CustomPrimeContent = styled(PrimeContent)`
    height: 100%;
    @media (max-width: 800px) {
        padding: 0;
    }
`;

const CustomTextArea = styled(TextArea)`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
`;

const CustomSubContent = styled(SubContent)`
    padding: 0;
`;
