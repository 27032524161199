// @flow

import React, { PureComponent } from "react";
import { connect } from "react-redux";
import styled, { css } from "styled-components";
import { hiDPI } from "polished";

import apiMethod from "../../api/apiMethod";
import errors from "../../lib/errors";
import { abortAll } from "../../lib/XhrComponentUtils";
import { __ } from "../../lib/translate";
import { formatSizeInGb } from "../../lib/utils";

import Tooltip from "../../components/Tooltip";
import FlowManager from "../TwoFactorAuth/FlowManager";
import { ActionIcon } from "./SharedFamilyComponents";
import type { Member, Invite } from "./types";
import {
  ResendInvitationModal,
  CancelInvitationModal,
  ManageQuotaModal,
  RemoveMemberModal,
  CopyLinkModal
} from ".";

const modalFlows = {
  removeMember: [RemoveMemberModal],
  cancelInvitation: [CancelInvitationModal],
  manageMember: [ManageQuotaModal],
  resendInvitation: [ResendInvitationModal],
  copyLink: [CopyLinkModal]
};

type Props = {
  token: string,
  members: Array<Member>,
  invites: Array<Invite>,
  disabled: boolean,
  removeMember: number => void,
  manageMember: (number, number) => void,
  cancelInvite: number => void
};

type State = {
  currentFlow: ?Array<Class<React$Component<any, any>>> | null,
  currentItem: Member | Invite | null,
  currentEmail: string
};

class MembersList extends PureComponent<Props, State> {
  static defaultProps = {
    token: "",
    members: [],
    invites: [],
    removeMember: () => {},
    manageMember: () => {},
    cancelInvite: () => {}
  };

  constructor(props: Props) {
    super(props);

    (this: any).state = {
      currentFlow: [],
      currentItem: null,
      currentEmail: ""
    };

    (this: any).onCloseModal = this.onCloseModal.bind(this);
    (this: any).rednerMember = this.rednerMember.bind(this);
    (this: any).renderInvite = this.renderInvite.bind(this);
    (this: any).onRemoveMemberButton = this.onRemoveMemberButton.bind(this);
    (this: any).onUpdateMemberButton = this.onUpdateMemberButton.bind(this);
    (this: any).onCancelInviteButton = this.onCancelInviteButton.bind(this);
    (this: any).copyToClipboard = this.copyToClipboard.bind(this);

    (this: any).xhrList = [];
  }

  componentWillUnmount() {
    abortAll((this: any).xhrList);
  }

  onOpenModal(currentModal: string, currentItem: Member | Invite) {
    const { disabled } = this.props;
    const currentFlow = modalFlows[currentModal];

    if (disabled) {
      return;
    }
    (this: any).setState({ currentFlow, currentItem });
  }

  onCloseModal() {
    this.setState({ currentFlow: [], currentItem: null });
  }

  copyToClipboard() {
    const inviteLinkInput = document.getElementById("inviteLink");
    inviteLinkInput && inviteLinkInput.select();
    console.log("inviteLinkInput", inviteLinkInput);
    document.execCommand("copy");
    HFN.message(__("Copied to clipboard."));
  }

  onRemoveMemberButton(id: number) {
    const { token, removeMember } = this.props;

    (this: any).xhrList.push(
      apiMethod(
        "fm_removemember",
        {
          auth: token,
          userid: id
        },
        res => {
          removeMember(id);
        },
        {
          errorCallback: ({ result, error }) => {
            if (errors[result]) {
              HFN.message(errors[result], "error");
            } else {
              HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
              throw new Error(error);
            }
          }
        }
      )
    );
  }

  onUpdateMemberButton(id: number, updatedQuota: number) {
    const { token, manageMember } = this.props;

    (this: any).xhrList.push(
      apiMethod(
        "fm_changequota",
        {
          auth: token,
          userid: id,
          quota: updatedQuota
        },
        res => {
          manageMember(id, updatedQuota);
          HFN.message(__("family_manage_quota_updated"));
        },
        {
          errorCallback: ({ result, error }) => {
            if (errors[result]) {
              HFN.message(errors[result], "error");
            } else {
              HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
              throw new Error(error);
            }
          }
        }
      )
    );
  }

  onCancelInviteButton(id: number) {
    const { token, cancelInvite } = this.props;

    (this: any).xhrList.push(
      apiMethod(
        "fm_cancelinvitation",
        {
          auth: token,
          invitationid: id
        },
        res => {
          cancelInvite(id);
        },
        {
          errorCallback: ({ result, error }) => {
            if (errors[result]) {
              HFN.message(errors[result], "error");
            } else {
              HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
              throw new Error(error);
            }
          }
        }
      )
    );
  }

  onPendingButtonClick(invite: Invite) {
    const { token } = this.props;
    const { invitationid } = invite;

    (this: any).xhrList.push(
      apiMethod(
        "fm_resendinvitation",
        {
          auth: token,
          invitationid
        },
        res => {
          this.onOpenModal("resendInvitation", invite);
        },
        {
          errorCallback: ({ result, error }) => {
            if (errors[result]) {
              HFN.message(errors[result], "error");
            } else {
              HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
              throw new Error(error);
            }
          }
        }
      )
    );
  }

  rednerMember(member: Member) {
    const { email, quota, isowner, userid } = member;
    const { disabled } = this.props;

    if (isowner) {
      return null;
    }

    return (
      <Row key={userid}>
        <FirstCol className="bold">{email}</FirstCol>
        <Col>{formatSizeInGb(quota, 0)}</Col>
        <Col>{__("Active")}</Col>
        <LastCol>
          <Tooltip shouldRenderTooltip={!disabled} title={__("Manage")}>
            <ManageIcon disabled={disabled} onClick={() => this.onOpenModal("manageMember", member)} />
          </Tooltip>
          <Tooltip shouldRenderTooltip={!disabled} title={__("family_remove")}>
            <CancelIcon disabled={disabled} onClick={() => this.onOpenModal("removeMember", member)} />
          </Tooltip>
        </LastCol>
      </Row>
    );
  }

  renderInvite(invite: Invite) {
    const { email, quota, invitationid, invitelink } = invite;
    const { disabled } = this.props;

    return (
      <Row key={invitationid}>
        <FirstCol className="bold">{email}</FirstCol>
        <Col>{formatSizeInGb(quota, 0)}</Col>
        <Col className="color">{__("pending")}</Col>
        <LastCol>
          <Tooltip shouldRenderTooltip={!disabled} title={__("copy_invite_link", "Copy Invite link")}>
            <CopyIcon disabled={disabled} onClick={() => this.onOpenModal("copyLink", invite)} />
          </Tooltip>
          <Tooltip shouldRenderTooltip={!disabled} title={__("family_member_list_resend_button", "Resend invitation")}>
            <ResendIcon disabled={disabled} onClick={() => this.onPendingButtonClick(invite)} />
          </Tooltip>
          <Tooltip shouldRenderTooltip={!disabled} title={__("family_member_list_cancel_invitation_button", "Cancel invitation")}>
            <CancelIcon disabled={disabled} onClick={() => this.onOpenModal("cancelInvitation", invite)} />
          </Tooltip>
        </LastCol>
      </Row>
    );
  }

  renderNoInvatationsText() {
    return (
      <SmallText>
        {__("family_invite_small_text", "You have not invited any of your family yet.")}
      </SmallText>
    );
  }

  render() {
    const { currentFlow, currentItem } = this.state;
    const { members, invites, token, disabled } = this.props;

    if (members.length <= 1 && invites.length === 0) {
      return this.renderNoInvatationsText();
    }

    return (
      <Wrapper disabled={disabled}>
        <FirstRow key="firt-row">
          <FirstCol>{__("family_member_list_col_family_member", "Family Member:")}</FirstCol>
          <Col>{__("Quota")}</Col>
          <Col>{__("Status")}</Col>
          <LastCol>{__("family_member_list_col_action", "Action")}</LastCol>
        </FirstRow>
        {members.map(this.rednerMember)}
        {invites.map(this.renderInvite)}
        <FlowManager
          auth={token}
          key="flow"
          currentItem={currentItem}
          flow={currentFlow}
          shouldCloseOnOverlayClick={true}
          onClose={this.onCloseModal}
          onRemoveMemberButton={this.onRemoveMemberButton}
          onUpdateMemberButton={this.onUpdateMemberButton}
          onCancelInviteButton={this.onCancelInviteButton}
        />
      </Wrapper>
    );
  }
}

export default connect(({ user: { token } }) => {
  return { token };
})(MembersList);

const Wrapper = styled.table`
    opacity: ${props => (props.disabled ? 0.4 : 1)};
    width: 100%;
    margin-top: 24px;
    border-spacing: 0;
`;

const Row = styled.tr`
  width: 100%;
  height: 40px;
  padding: 6px;
  box-sizing: border-box;
  font-size: 11px;
  border-bottom: 1px solid #e9e9e9;
`;

const FirstRow = styled(Row)`
  border-top: 1px solid #e9e9e9;
  background-color: #f4f4f4;
  font-weight: bold;
  color: #aaaaaa;
  margin-top: 20px;
  line-height: 25px;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const Col = styled.td`
    padding: var(--spacing-xs) var(--spacing-xs) var(--spacing-xs) var(--spacing-sm);
  &.color {
    color: #999999;
  }
`;

const FirstCol = styled(Col)`
  padding-right: 10px;

  &.bold {
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 13px;
    font-weight: bold;
  }

  &.link-firs-col {
    width: 658px;
  }
`;

const LastCol = styled(Col)`
  text-align: right;
  align-items: right;
  vertical-align: middle;

  &.link-last-col {
    width: 32px;
  }
`;

const ManageIcon = styled(ActionIcon)`
  cursor: ${props => (props.disabled ? "initial" : "pointer")};
  background-image: url(${require("../../../root/img/family/manage.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/family/manage@3x.png")});
  }
`;

const ResendIcon = styled(ManageIcon)`
  cursor: ${props => (props.disabled ? "initial" : "pointer")};
  margin-left: 6px;
  background-image: url(${require("../../../root/img/family/resend.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/family/resend@2x.png")});
  }
`;

const CancelIcon = styled(ManageIcon)`
  cursor: ${props => (props.disabled ? "initial" : "pointer")};
  margin-left: 6px;
  background-image: url(${require("../../../root/img/family/cancel.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/family/cancel@3x.png")});
  }
`;

const CopyIcon = styled(ManageIcon)`
  cursor: ${props => (props.disabled ? "initial" : "pointer")};
  margin-left: 6px;
  background-image: url(${require("../../../root/img/family/copy-link.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/family/copy-link@3x.png")});
  }
`;


const SmallText = styled.div`
  font-size: 13px;
  text-align: center;
  color: #999999;
  margin-top: 20px;
`;

const InviteLink = styled.input`
  display: hidden;
  width: 100%;
  height: 32px;
  font-size: 13px;
  padding: 0 5px;
  border: 1px solid rgb(202, 202, 202);
  box-shadow: inset 0px 3px 0 0 rgba(0, 0, 0, 0.03);
  background-color: #fafafa;
  box-sizing: border-box;
`;
