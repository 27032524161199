// @flow

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import * as SETTINGS from "../settingsConstants";
import * as Style from "../preferences.style";
import TwoFactorAuthenticationSetting from "../TwoFactorAuthenticationSetting";
import ChangePassSetting from "../ChangePassSetting";
import ConnectedDevicesInfo from "../ConnectedDevicesInfo";

const Security = ({ settings }) => {
  const renderTFA = () => <TwoFactorAuthenticationSetting/>;

  const renderChangePassSetting = () => <ChangePassSetting/>;

  const renderConnectedDevicesInfo = () => <ConnectedDevicesInfo/>

  const renderFunctionsPerSetting = {
    [SETTINGS.TWO_FACTOR_AUTHENTICATION_SETTING]: renderTFA,
    [SETTINGS.CHANGE_PASS_SETTING]: renderChangePassSetting,
    [SETTINGS.CONNECTED_DEVICES_INFO]: renderConnectedDevicesInfo,
  };

  return (
    <Style.Container className="account-container lang-wrapper">
      {settings.map((setting) => renderFunctionsPerSetting[setting] && renderFunctionsPerSetting[setting]())}
    </Style.Container>
  );
};

export default Security;
