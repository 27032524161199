// @flow

import React from 'react';
import styled from "styled-components";

type Props = {
  data: Array<number>,
  allowMultipleOpen?: boolean,
  renderItem: (id: number, isOpen: boolean) => Array<any>
};

type State = {
  openSections: Array<number>
};

class Accordion extends React.Component<Props, State> {
  static defaultProps = {
    data: null,
    allowMultipleOpen: false,
    renderItem: () => []
  };

  constructor(props: Props) {
    super(props);

    this.state = { openSections: [] };

    (this: any).renderAccordionSection = this.renderAccordionSection.bind(this);
  }

  onClick(id: number) {
    const { allowMultipleOpen } = this.props;
    const { openSections } = this.state;

    const index = openSections.indexOf(id);

    if (index !== -1) {
      this.setState(state => ({ openSections: state.openSections.filter(item => item !== id) }));
      return;
    }

    if (allowMultipleOpen) {
      this.setState(state => ({ openSections: [...state.openSections, id] }));
    } else {
      this.setState({ openSections: [id] });
    }

  };

  renderAccordionSection(itemId: number) {
    const { openSections } = this.state;
    const { renderItem } = this.props;
    const isOpen = openSections.indexOf(itemId) !== -1;

    return <Wrapper key={itemId} onClick={this.onClick.bind(this, itemId)}>{renderItem(itemId, isOpen)}</Wrapper>
  }

  render() {
    const { data } = this.props;

    return data.map(this.renderAccordionSection);
  }
}

export default Accordion;

const Wrapper = styled.div`
  position: relative;
  cursor: pointer;
  &:after {
    content: '';
    height: 1px;
    width: auto;
    background-color: #ddd;
    display: block;
    position: relative;
    bottom: 0;
    margin-left: 60px;
  }

  &:last-child:after {
    margin-left: 0;
  }
`;