// @flow

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import * as SETTINGS from "../settingsConstants";
import * as Style from "../preferences.style";
import InvoiceDetails from "../InvoiceDetails";
import PaymentHistoryInfo from "../PaymentHistoryInfo";

const PaymentHistory = ({ settings }) => {
  const renderInvoiceDetails = () => <InvoiceDetails/>;

  const renderPaymentHistoryInfo = () => <PaymentHistoryInfo/>;

  const renderFunctionsPerSetting = {
    [SETTINGS.INVOICE_DETAILS]: renderInvoiceDetails,
    [SETTINGS.PAYMENT_HISTORY_INFO]: renderPaymentHistoryInfo,
  };

  return (
    <Style.Container>
      {settings.map((setting) => renderFunctionsPerSetting[setting] && renderFunctionsPerSetting[setting]())}
    </Style.Container>
  );
};

export default PaymentHistory;
