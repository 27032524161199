// @flow

import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import styled from "styled-components";
import { hiDPI } from "polished";

import apiMethod from "../../api/apiMethod";

import Dropdown, { DIR_DOWN, DIR_CENTER } from "../../components/Dropdown";
import EditBookmark from "./EditBookmark";
import { __ } from "../../lib/translate";
import { detectIsMobile } from "../../lib/utils";

type Props = {
  code: string,
  locationId: number,
  name: string,
  description: string,
  whiteMode: boolean,
  holderObject: Element
};

const EditBookmarkTooltip = ({ code, locationId, name = "", description = "", holderObject, whiteMode = false }: Props) => {
  const [isSaved, setIsSaved] = useState(false);
  const [savedName, setSavedName] = useState(name);
  const [savedDescription, setSavedDescription] = useState(description);
  const [shouldClose, setShouldClose] = useState(false);

  const isPinned = useRef(isSaved);
  let bookmarkName = useRef(name);
  let bookmarkDescription = useRef(description);

  if (!holderObject) {
    holderObject = document.createElement("div");
  }

  const onOpenDropdown = () => {
    // Discard all form changes
    bookmarkName = { current: savedName };
    bookmarkDescription = { current: savedDescription };
    renderEditBookmarkPopup();
  };

  const onSave = (newName, newDescription) => {
    // Set new values to be loaded next time when opened.
    setSavedName(newName);
    setSavedDescription(newDescription);
    setShouldClose(true);
  };

  const onRemove = () => {
    setIsSaved(false);
    // Discard all changes in the input and revert them to the initial state.
    setSavedName(name);
    setSavedDescription(description);
    setShouldClose(true);
  };

  useEffect(() => {
    isPinned.current = isSaved;
  }, [isSaved]);

  useEffect(() => {
    if (shouldClose) {
      // on Close we are here
      setShouldClose(false);
    }
  }, [shouldClose]);

  const getIsPinned = () => {
    return isPinned.current;
  };

  useEffect(() => {
    renderEditBookmarkPopup();
  }, [code, locationId, name, holderObject]);

  const renderEditBookmarkPopup = () => {
    ReactDOM.render(
      <EditBookmark
        type="tooltip"
        code={code}
        locationId={locationId}
        bookmarkName={bookmarkName}
        bookmarkDesc={bookmarkDescription}
        setSavedName={setSavedName}
        setSavedDescription={setSavedDescription}
        getIsPinned={getIsPinned}
        setIsPinned={setIsSaved}
        onSave={onSave}
        onRemove={onRemove}
      />,
      holderObject
    );
  };

  const _prepareHolder = (obj, level) => {
    if (level === 0) {
      obj.find("ul").prepend(holderObject);
    }
    return obj;
  };

  const onPinClick = (e) => {
    e.stopPropagation();

    if (isSaved || name.length === 0) {
      return;
    }

    apiMethod(
      "publink/pin",
      {
        code,
        locationid: locationId,
        name,
        description
      },
      () => {
        HFN.message(__("bookmark_added", "Bookmark added. Access from my.pCloud.com > Bookmarks"), "success", false, false, false, "true", true);
        setIsSaved(true);
        setSavedName(name);
        setSavedDescription(description);
      },
      {
        errorCallback: ({ result, error, limit }) => {
          let errorMessage = __("something_went_wrong_refresh_and_try_again");

          if (result === 2326) {
            // This link is already pinned.
            return;
          }

          if (limit) {
            errorMessage =
              limit === 100
                ? __("bookmark_name_error", "100-character name limit exceeded.")
                : __("bookmark_desc_error", "255-character description limit exceeded.");
          }
          HFN.message(errorMessage, "error");
          console.log("error", error);
        }
      }
    );
  };

  const classDropdown = detectIsMobile() ? "mobile-bookmark" : "";

  return (
    <DropdownWrapper isMobile={detectIsMobile()}>
      <Dropdown
        holderClass={classDropdown}
        direction={DIR_CENTER}
        childDirection={DIR_DOWN}
        prepareHolder={_prepareHolder}
        forceClose={shouldClose}
        onOpen={onOpenDropdown}
      >
        {isSaved ? (
          <React.Fragment>
            <div data-tooltip-id="pinned">
              <BookmarkIconActive onClick={onPinClick} className={whiteMode ? "white-mode" : null} />
            </div>
            <ReactTooltip id="pinned" place="right" className="tooltip-container">
              {__("bookmarks_edit_title", "Edit bookmark")}
            </ReactTooltip>
          </React.Fragment>
        ) : (
          <React.Fragment>
              <div data-tooltip-id="unpinned">
              <BookmarkIcon onClick={onPinClick} className={whiteMode ? "white-mode" : null} />
            </div>
            <ReactTooltip id="unpinned" place="right" className="tooltip-container">
              {__("add_bookmark", "Add bookmark")}
            </ReactTooltip>
          </React.Fragment>
        )}
      </Dropdown>
    </DropdownWrapper>
  );
};

export default EditBookmarkTooltip;

const DropdownWrapper = styled.div`
  &.dropdown {
    height: 285px;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.05), 0px 10px 30px 0 var(--black-20);
    background-color: #ffffff;
  }
`;

const BookmarkIcon = styled.div`
  display: block;
  width: 21px;
  height: 20px;
  cursor: pointer;

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url(${require("../../../root/img/dlink/link-fav.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/dlink/link-fav@2x.png")});
  }
  
  &.white-mode {
    width: 28px;
    height: 28px;
    background-image: url(${require("../../../root/img/dlink/link-fav-white.png")});
  }
`;

const BookmarkIconActive = styled(BookmarkIcon)`
  background-image: url(${require("../../../root/img/dlink/link-faved.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/dlink/link-faved@2x.png")});
  }
  
  &.white-mode {
    width: 28px;
    height: 28px;
    background-image: url(${require("../../../root/img/dlink/link-faved-white.png")});
  }
`;
