// @flow
import React, { useState } from "react";
import styled from "styled-components";

import { useSelector } from "react-redux";

import { __ } from "../../../lib/translate";
import apiMethod from "../../../api/apiMethod";

import Alert, { AlertIcon } from "../../Alert";
import PassWithHint from "./PassWithHint";
import { Header, Container } from "../../Modals/styledComponents";
import ButtonDefault from "../../ButtonDefault";

type Props = {
  onPassOk: () => void
};

const EnterPass = ({ onPassOk = () => {}, onSuccess = () => {} }: Props) => {
  const [password, onPassChange] = useState("");
  const [loading, setLoading] = useState(false);
  const token = useSelector(({ user }) => user.token);

  const checkPassword = e => {
    e.preventDefault();
    if (loading || !password.length) {
      return;
    }
    setLoading(true);

    pCloudCrypto.unlockCrypto(password, ok => {
      if (ok) {
        apiMethod("crypto_sendchangeuserprivate", { auth: token }, res => {
          if (res.result == 0) {
            onPassOk();
            onSuccess();
          }
        });
      } else {
        HFN.message(__("wrong_password"), "error")
        setLoading(false);
      }
    });
  };

  return (
    <ModalContainer className="clearfix" onSubmit={checkPassword}>
      <ModalHeader>{__("heading_change_crypto_pass")}</ModalHeader>
      <ModalDescription>{__("change_crypto_pass_desc_step_2")}</ModalDescription>
      <PassWithHint onPassChange={onPassChange} password={password} />
      <ButtonWrapper>
        <ButtonDefault
          size="big"
          onClick={checkPassword}
          loading={loading}
          disabled={!password.length}
        >
          {__("Continue")}
        </ButtonDefault>
      </ButtonWrapper>
    </ModalContainer>
  );
};

export default EnterPass;

const ModalContainer = styled.div`
  text-align: center;
  border-radius: var(--radius-lg);
  box-shadow: rgba(0, 0, 0, 0.25) 3px 3px 5px;
  padding: var(--spacing-xl);
  background: var(--color-base-white);
  max-width: 480px;
  width: 100%;
  min-height: auto;
  color: var(--color-base-black);
  box-sizing: border-box;
`;

const ModalHeader = styled.div`
  font-size: var(--font-size-18);
  color: var(--color-base-black);
  line-height: 23px;
  font-weight: bold;
  margin-top: -3px;
  margin-bottom: var(--spacing-lg);
`;

const ModalDescription = styled.div`
  font-size: 15px;
  color: var(--color-base-black)
  padding: 0px var(--spacing-md);
  text-align: center;
  margin-bottom: 16px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-sm);
  margin-top: var(--spacing-md);
`;

