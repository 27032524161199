// @flow

import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { __ } from "../../lib/translate";
import apiMethod from "../../api/apiMethod";
import * as Style from "./preferences.style";
import { TSpan } from "../intl";
import CountryCodeToName from "../../lib/countryUtils";
import InvoiceModal from "../InvoiceModal";

const InvoiceDetails = () => {
  const billingInfo = useSelector(({ user }) => user.userSettings.billingInfo);
  const [opened, setOpened] = useState(false);

  if (!Object.keys(billingInfo).length) {
    return (
      <>
        <InvoiceModal
          isBusiness={false}
          cta={Object.keys(billingInfo).length ? __("Update My Invoice Information", "Update") : __("insert_invoice_info", "Add")}
          billingInfo={billingInfo}
          manageExternal={true}
          openedExternal={opened}
          onClose={() => setOpened(false)}
        />
        <Style.Row>
          <div>
            <Style.Title>{__("ph_invoice_info", "Invoice details")}</Style.Title>
            <Style.Description>{__("ph_billing_note", "Please add your billing details, in order to get an invoice for your payment.")}</Style.Description>
          </div>

          <Style.Button onClick={() => setOpened(true)}>
            {__("insert_invoice_info", "Add")}
          </Style.Button>
        </Style.Row>
      </>
    )
  }

  return (
    <>
      <InvoiceModal
        isBusiness={false}
        cta={Object.keys(billingInfo).length ? __("Update My Invoice Information", "Update") : __("insert_invoice_info", "Add")}
        billingInfo={billingInfo}
        manageExternal={true}
        openedExternal={opened}
        onClose={() => setOpened(false)}
      />
      <Style.Row>
        <div>
          <Style.Title>{__("ph_invoice_info", "Invoice details")}</Style.Title>
          <Style.Description>{`${billingInfo.company ? (billingInfo.company + ", ") : ""}${billingInfo.names ? (billingInfo.names + ", ") : ""}${billingInfo.country ? (CountryCodeToName[billingInfo.country] + ", ") : ""}${billingInfo.billingAddress || billingInfo.billingaddress || ""}`}</Style.Description>
        </div>
        <Style.Button onClick={() => setOpened(true)}>
          {__("Update My Invoice Information", "Update")}
        </Style.Button>
      </Style.Row>
    </>
  )
};

export default InvoiceDetails;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const IntendedRow = styled.div`
  padding-left: 42px;
  box-sizing: border-box;
  border-bottom: var(--border-size) solid var(--border-secondary);

  ${Style.Row} {
    padding: var(--spacing-sm);
    min-height: 48px;

    &:first-of-type {
      border-top: var(--border-size) solid var(--border-secondary);
    }

    &:last-of-type {
      border-bottom: none;
    }
  }

  ${Style.Title} {
    font-size: var(--font-size-14);

    @media (max-width: 768px) {
      font-size: var(--font-size-13)
    }
  }

  ${Style.Title} {
    font-size: 14px;
  }

  ${Style.Description} {
    margin-top: 0;
  }
`;
