function createDummyElement(text, options) {
    const element = document.createElement("div");
    const textNode = document.createTextNode(text);

    element.appendChild(textNode);

    element.style.fontFamily = options.font;
    element.style.fontSize = options.fontSize;
    element.style.fontWeight = options.fontWeight;
    element.style.lineHeight = options.lineHeight;
    element.style.position = "absolute";
    element.style.visibility = "hidden";
    element.style.left = "-999px";
    element.style.top = "-999px";
    element.style.width = options.width;
    element.style.height = "auto";
    element.style.wordBreak = options.wordBreak;

    document.body.appendChild(element);

    return element;
}

function destroyElement(element) {
    element.parentNode.removeChild(element);
}

const cache = {};

export default (text, options = {}) => {
    const cacheKey = JSON.stringify({ text: text, options: options });

    if (cache[cacheKey]) {
        return cache[cacheKey];
    }

    // Prepare options with default values
    options.font = options.font || "Times";
    options.fontSize = options.fontSize || "16px";
    options.fontWeight = options.fontWeight || "normal";
    options.lineHeight = options.lineHeight || "normal";
    options.width = options.width || "auto";
    options.wordBreak = options.wordBreak || "normal";

    const element = createDummyElement(text, options);

    const size = {
        width: element.offsetWidth,
        height: element.offsetHeight
    };

    destroyElement(element);

    cache[cacheKey] = size;

    return size;
}
