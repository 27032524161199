//  @flow

import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { __ } from "../../../lib/translate";
import { loadScript } from "../../../lib/utils";

import SubscriptionDetails from "./SubscriptionDetails";
import FinanceContainer from "../../FinanceContainer";
import ProFormaInvoices from "./ProFormaInvoices";

const BillingPageContainer = () => {
  return (
    <Container>
      <SubscriptionDetails />
      <FinanceContainer isBusiness />
      <ProFormaInvoices />
    </Container>
  );
};

export default BillingPageContainer;

const Container = styled.div`
  margin-top: 20px;
  width: 780px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const PageHeader = styled.h2``;
