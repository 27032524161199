import React, { useCallback, useRef } from 'react';
import FileList from './FilesList';
import styled, { css, createGlobalStyle } from 'styled-components';
import ToggleArrowSvg from '../../../root/img/svg/upload-manager/toggle-arrow.svg';
import CloseSvg from '../../../root/img/svg/upload-manager/close.svg';
import { UPLOAD_TAB, UPLOAD_STATUS, FAILED_BADGE_COLOR } from './constants';
import WarningOnUploadModal from './WarningOnUploadModal';
import UploadOptionsModal from './UploadOptionsModal';
import UploadCancelModal from './UploadCancelModal';
import UploadRemoteModal from './UploadRemoteModal';
import UploadPasteModal from './UploadPasteModal';
import UploadFailedFolderItemsModal from './UploadFailedFolderItemsModal';
import UploadLockCryptoModal from './UploadLockCryptoModal';
import UploadCloseWithRetry from './UploadCloseWithRetry';
import FailedSvg from '../../../root/img/svg/upload-manager/failed-folder.svg';
import useUploadContext from './hooks/useUploadContext';
import { __ } from '../../lib/translate';
import useOutsideClicker from '../../../web-shared/hooks/useOutsideClicker';
import DropdownArrowSvg from '../../../root/img/svg/upload-manager/dropdown-arrow.svg';
import TopBarProgress from './TopBarProgress';
import { createPortal } from "react-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Popover from '../Popover';
import { useSelector } from "react-redux";
import useRightSectionSlideAnimationManager from '../ContentView/hooks/useRightSectionSlideAnimationManager';

const currentRightSectionId = "upload-section";
const otherRightSectionId = "items-info";

const UploadManagerContainer = () => {
    const {
        uploadQueue,
        failedItems,
        isToggled,
        handleToggleState,
        isOpened,
        handleOpenState,
        handleCancel,
        activeTab,
        setActiveTab,
        donePercentage,
        isMobile,
        filteredUploadQueue,
        handleClear,
        handleRetryAll,
        tabItemsLength,
        isUploadCancelModalOpen,
        handleUploadCancelModalClose,
        handleUploadCancelModalYesClick
    } = useUploadContext();

    // Do not delete - userLang is used for language change render
    const userLang = useSelector(({ user }) => user.userinfo.language);
    const tabDropdownRef = useRef(null);
    const isDlink = HFN.config.isDlink();
    const containerRef = useRef();

    const showOld = isDlink || isMobile;
    const { content } = useRightSectionSlideAnimationManager({
        containerRef,
        currentRightSectionId,
        otherRightSectionId,
        isOpened,
        isToggled,
        closeRightSection: handleToggleState
    });

    const renderTabDropdownSelect = () => {
        const items = [
            {
                text: __('upload_manager_tabs_all', 'All'),
                id: UPLOAD_TAB.ALL,
                textSelected: __('upload_manager_tabs_all_items', 'All items')
            },
            {
                text: __('upload_manager_tabs_active', 'Active'),
                id: UPLOAD_TAB.ACTIVE,
                textSelected: __('upload_manager_tabs_active_items', 'Active items')
            },
            {
                text: __('upload_manager_tabs_completed', 'Completed'),
                id: UPLOAD_TAB.COMPLETED,
                textSelected: __('upload_manager_tabs_completed_items', 'Completed items')
            },
            {
                text: __('upload_manager_tabs_failed', 'Failed'),
                id: UPLOAD_TAB.FAILED,
                textSelected: __('upload_manager_tabs_failed_items', 'Failed items')
            }
        ];
        return (

            <Popover
                trigger="click"
                render={
                    <UploadTabDropdownModal>
                        {items.map((item) => (
                            <UploadTabDropdownModalItem
                                key={item.id}
                                onClick={() => {
                                    tabDropdownRef.current.click();
                                    setActiveTab(item.id);
                                }}
                            >
                                <UploadTabDropdownModalItemText>{item.text}</UploadTabDropdownModalItemText>
                                <UploadTabDropdownModalItemCount
                                    $isFailed={item.id === UPLOAD_TAB.FAILED && tabItemsLength[item.id] > 0}
                                >
                                    {tabItemsLength[item.id]}
                                </UploadTabDropdownModalItemCount>
                            </UploadTabDropdownModalItem>
                        ))}
                    </UploadTabDropdownModal>
                }
                cssPosition="fixed"
                position="bottom"
                flyin="top">
                <UploadTabDropdownSelect>
                    <UploadTabDropdownSelectLabel ref={tabDropdownRef}>
                        {items.find((item) => item.id === activeTab).textSelected}
                        <StyledDropdownArrowSvg /> {tabItemsLength[UPLOAD_TAB.FAILED] !== 0 && <StyledWarningSvg />}
                    </UploadTabDropdownSelectLabel>
                </UploadTabDropdownSelect>
            </Popover>
        );
    };

    const renderHeaderContent = () => {
        if (!uploadQueue.length) {
            return __('Upload Manager', 'Upload Manager');
        }

        if (donePercentage.isUploading) {
            return donePercentage.pendingItems === 1
                ? __('upload_manager_uploading_single', 'Uploading 1 item')
                : __('upload_manager_uploading', 'Uploading %itemslength% items').replace(
                    '%itemslength%',
                    donePercentage.pendingItems
                );
        } else {
            return uploadQueue.length - donePercentage.failedItems === 1
                ? __('upload_manager_uploaded_single', '1 item uploaded')
                : __('upload_manager_uploaded', '%itemslength% items uploaded').replace(
                    '%itemslength%',
                    uploadQueue.length - donePercentage.failedItems
                );
        }
    };

    const renderManagerActionButton = useCallback(() => {
        const { isUploading } = donePercentage;
        let actionText = '';
        let disabled = false;
        let actionHandler = () => { };
        switch (activeTab) {
            case UPLOAD_TAB.ALL:
                if (isUploading) {
                    actionText = __('Cancel', 'Cancel');
                    actionHandler = handleCancel;
                } else {
                    actionText = __('Clear', 'Clear');
                    actionHandler = handleClear;
                }
                break;
            case UPLOAD_TAB.ACTIVE:
                actionText = __('Cancel', 'Cancel');
                actionHandler = handleCancel;
                if (!isUploading) {
                    disabled = true;
                }
                break;
            case UPLOAD_TAB.COMPLETED:
                actionText = __('Clear', 'Clear');
                actionHandler = handleClear;
                break;
            case UPLOAD_TAB.FAILED:
                actionText = __('upload_manager_failed_items_action_success', 'Retry all');
                actionHandler = handleRetryAll;
                break;
            default:
                break;
        }

        if (filteredUploadQueue.length === 0) {
            disabled = true;
        }

        return (
            <ManagerActionButton disabled={disabled} onClick={actionHandler}>
                {actionText}
            </ManagerActionButton>
        );
    }, [donePercentage.isUploading, activeTab, filteredUploadQueue]);

    const renderContent = () => {
        return (
            <>
                <WarningOnUploadModal />
                <UploadOptionsModal />
                <UploadFailedFolderItemsModal />
                <UploadLockCryptoModal />
                <UploadCloseWithRetry />
                <UploadRemoteModal />
                <UploadPasteModal />
                <TopBarProgress />
                <StyledReactTooltip className="cv-tooltip" noArrow={true} id="failed-item-error-tooltip" opacity={1} multiline />
                <ManagerContainer
                    ref={containerRef}
                    id="upload-manager-container"
                    className={(!isDlink && isMobile) || (isDlink && HFN.config.isMobile()) ? 'isMobile fixed-content' : 'fixed-content'}
                    $isMobile={isMobile}
                    $isDlink={isDlink}
                    $isClosed={!isOpened}
                    $isToggled={isToggled}
                >
                    <TopContainer $showOld={showOld} $isDlink={isDlink} onClick={isMobile ? handleToggleState : undefined}>
                        <UploadingItemsText $isDlink={isDlink} $showOld={showOld}>
                            {failedItems.length !== 0 && isToggled && <FailedSvg />}
                            {renderHeaderContent()}
                        </UploadingItemsText>
                        <IconsContainer $isDlink={isDlink}>
                            <StyledToggleArrowSvg
                                $isToggled={isToggled}
                                $isUploading={donePercentage.isUploading}
                                $showOld={showOld}
                                onClick={isMobile ? undefined : handleToggleState}
                            />
                            {(showOld || !donePercentage.isUploading) && (
                                <StyledCloseSvg $showOld={showOld} onClick={handleOpenState} />
                            )}
                        </IconsContainer>
                    </TopContainer>
                    <UploadCancelModal
                        show={isUploadCancelModalOpen}
                        handleNo={handleUploadCancelModalClose}
                        handleYes={handleUploadCancelModalYesClick}
                    />
                    <MainContainer id="upload-main-container" $isToggled={isToggled} $isMobile={isMobile} $isDlink={isDlink}>
                        <FileListTabs>
                            {renderTabDropdownSelect()}
                            {renderManagerActionButton()}
                        </FileListTabs>
                        <FileList />
                    </MainContainer>
                </ManagerContainer>
            </>
        );
    }

    if (!isMobile && !isDlink) {

        if (content) {
            return (
                <>
                    <GlobalStyle
                        $isMobile={isMobile}
                        $isDlink={isDlink}
                        $isClosed={!isOpened}
                        $isToggled={isToggled}
                        $otherRightSectionId={otherRightSectionId}
                    />
                    {createPortal(
                        renderContent(),
                        content
                    )}
                </>
            )
        }
    }

    return (
        <>{renderContent()}</>
    );
};

export default UploadManagerContainer;

const GlobalStyle = createGlobalStyle`
    ${({ $isDlink, $isMobile, $otherRightSectionId, $isClosed, $isToggled }) =>
        !$isDlink && !$isMobile &&
        css`
            html {
                --upload-content-width: ${$isClosed || $isToggled ? '0px !important' : 'var(--right-content-width, 400px) !important'};
                #content-right-section-inner.opened-${$otherRightSectionId} {
                    #upload-manager-container {
                        transition: right 0.5s ease-in-out;
                        right: ${$isClosed || $isToggled ? 'calc(0px - var(--right-content-width, 400px))' : '0'};
                    }
                }
            }
        `}
`;

const ManagerContainer = styled.div`
    background-color: #fff;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;

    ${({ $isDlink, $isMobile }) =>
        !$isDlink && !$isMobile &&
        css`
            height: 100%;
            width: width: var(--right-content-width, 400px) - var(--right-content-border-width, 1px));
            border-radius: var(--radius-rounded, 16px) 0px 0px 0px;
            border-top: 1px solid var(--divider-primary);
            border-left: ${({ $isClosed, $isToggled }) => ($isClosed || $isToggled ? 'none' : 'var(--right-content-border-width, 1px) solid var(--divider-primary)')};
            background: #fff;
        `}
    ${({ $isDlink, $isMobile }) =>
        !$isDlink && $isMobile &&
        css`
            max-height: ${({ $isClosed }) => ($isClosed ? '0!important' : '366px')};
            transition: max-height 0.5s ease-in-out;
            box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.22);
            border-radius: 4px;
        `}
    ${({ $isDlink }) =>
        $isDlink &&
        css`
          max-height: ${({ $isClosed }) => ($isClosed ? '0' : '366px')};
          transition: max-height 0.5s ease-in-out;
          box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.22);
          border-radius: 4px;
          width: 400px;
      `}
    &.isMobile {
        right: 0;
        bottom: 0;
        width: 100%;
        box-shadow: none;
        border-radius: ${({ $isToggled, $isDlink }) => ($isToggled || !$isDlink ? 0 : 4)}px;
        box-shadow: none;
    }
`;

const TopContainer = styled.div`
    background-color: ${({ $showOld }) => ($showOld ? '#494F55' : '#f0f4f5')};
    padding: 14px 16px;
    width: ${({ $isDlink, $isMobile }) => !$isDlink && !$isMobile ? "calc(var(--right-content-width, 400px) - var(--right-content-border-width, 1px))" : "100%"};
    display: flex;
    justify-content: space-between;
    height: 46px;
    align-items: center;
    box-sizing: border-box;
    ${({ $isDlink }) =>
        !$isDlink &&
        css`
          @media (max-width: 1023px) {
              width: 100%;
              background: var(--color-grey-400);
              height: 48px;
          }
        `}
`;

const UploadingItemsText = styled.p`
    color: ${({ $showOld }) => ($showOld ? '#FFF' : '#2a4346')};
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: center;
    gap: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    ${({ $isDlink }) =>
        !$isDlink &&
        css`
            @media (max-width: 1023px) {
                color: var(--text-primary);
                font-weight: 500;
            }
        `}
`;

const IconsContainer = styled.div`
    display: flex;
    align-items: center;
    ${({ $isDlink }) =>
        !$isDlink &&
        css`
            @media (max-width: 1023px) {
                & svg {
                    fill: var(--text-icons);
                }
            }
        `}
`;

const StyledToggleArrowSvg = styled(ToggleArrowSvg)`
    transition: transform 0.5s ease-in-out;
    cursor: pointer;

    ${({ $showOld }) =>
        !$showOld &&
        css`
            transform: rotate(270deg);
            fill: #8c959d;
        `}

    ${({ $showOld, $isUploading }) =>
        ($showOld || !$isUploading) &&
        css`
            margin-right: 16px;
        `}

    ${({ $isToggled, $showOld }) =>
        $isToggled &&
        $showOld &&
        css`
            transform: rotate(180deg);
        `}
`;

const StyledCloseSvg = styled(CloseSvg)`
    cursor: pointer;

    ${({ $showOld }) =>
        !$showOld &&
        css`
            fill: #8c959d;
        `}
`;

const MainContainer = styled.div`
    ${({ $isDlink, $isMobile }) =>
        !$isDlink && !$isMobile &&
        css`
          height: 100%;
      `}
    ${({ $isDlink, $isMobile }) =>
        !$isDlink && $isMobile &&
        css`
          transition: max-height 0.5s ease-in-out;
          max-height: ${({ $isToggled }) => ($isToggled ? '0!important' : '320px')};
        `}
    ${({ $isDlink }) =>
        $isDlink &&
        css`
            transition: max-height 0.5s ease-in-out;
            max-height: ${({ $isToggled }) => ($isToggled ? '0' : '320px')};
        `}
    .tabs {
        height: 39px;
        width: 280px;
        margin: 0;
    }
`;

const FileListTabs = styled.div`
    height: 40px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
`;

const ManagerActionButton = styled.span`
    font-family: Roboto;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    color: #17bed0;
    cursor: pointer;

    ${({ disabled }) =>
        disabled &&
        css`
            cursor: default;
            opacity: 0.5;
        `}
`;

const UploadTabDropdownSelect = styled.div`
    display: flex;
    position: relative;
    height: 40px;
    align-items: center;
`;

const UploadTabDropdownSelectLabel = styled.label`
    color: #000;
    font-family: Roboto;
    font-size: 13px;
    font-weight: 500;
    padding: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const StyledDropdownArrowSvg = styled(DropdownArrowSvg)`
    margin-left: 8px;
`;

const UploadTabDropdownModal = styled.div`
    width: 160px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    background: #fff;
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.15);
    top: 43px;
    left: -8px;
    /* z-index: 1; */
    box-sizing: border-box;
    padding: 2px 0;
`;

const UploadTabDropdownModalItem = styled.div`
    display: flex;
    padding: 0 16px;
    align-items: center;
    justify-content: space-between;
    height: 32px;
    cursor: pointer;

    &:hover {
        background: #f9f9f9;
    }
`;

const UploadTabDropdownModalItemText = styled.p`
    color: #000;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
`;

const UploadTabDropdownModalItemCount = styled.p`
    color: ${({ $isFailed }) => ($isFailed ? '#F73C3C' : '#A2A2AA')};
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
`;

const StyledWarningSvg = styled.div`
    top: 0px;
    right: 22px;
    width: 6px;
    height: 6px;
    background-color: #f73c3c;
    border-radius: 50%;
`;

const StyledReactTooltip = styled(ReactTooltip)`
  padding: 15px !important;
    max-width: 320px;
    & span.url {
        display: inline-block;
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
`
