import styled, { css, keyframes } from "styled-components";
import { motion } from "framer-motion";

export const Wrapper = styled.div`
    width: 425px;
    min-width: 300px;
    border-radius: var(--radius-rounded);
    background: var(--surface-primary);
    position: relative;
    box-sizing: border-box;
    padding: var(--spacing-xl) 0 var(--spacing-lg);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: 137px;

    &,
    & * {
        tap-highlight-color: transparent;
    }

    @media (max-width: 800px) {
        width: 100%;
        min-width: auto;
        height: auto;
        transition: padding .3s linear;
        padding: var(--spacing-md) 0 var(--spacing-md);
    };

    ${({ $isAvailable }) =>
        $isAvailable === false &&
        css`
            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                display: inline-block;
                width: 100%;
                height: 100%;
                background-color: #ffffff;
                border-radius: var(--radius-rounded);
                opacity: 0.5;
                z-index: 1;
            }
        `}
}
`;

export const PrimeContent = styled.div`
    display: flex;
    align-items: start;
    justify-content: start;
    gap: 16px;
    padding-left: var(--spacing-lg);
    padding-right: var(--spacing-lg);

    @media (max-width: 800px) {
        align-items: center;
        padding-left: var(--spacing-md);
        padding-right: var(--spacing-md);

        & i.fa-chevron-down {
            transition: transform 0.5s ease;
        }

        ${({ $isExpanded }) =>
            $isExpanded &&
            css`
                & i.fa-chevron-down {
                    transform: rotate(180deg);
                }
            `}
    }
`;

export const SubContent = styled.div`
    width: 100%;
    padding-left: 72px;
    box-sizing: border-box;
    margin-top: auto;
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const SubContentRow = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    flex-wrap: nowrap;
    
    & > span {
        display: flex;
        justify-content: start;
        align-items: center;
        flex-wrap: nowrap;
    }
`;

export const SubContentRowLabel = styled.div`
    color: var(--text-primary);
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    white-space: nowrap;
`;

export const SubContentRowError = styled(SubContentRowLabel)`
    color: var(--text-danger);
    font-size: 11px;
    line-height: 16px;
`;

export const SubContentRowDefault = styled(SubContentRowLabel)`
    color: var(--text-secondary);
    font-size: 12px;
    font-weight: 400;
`;

export const SubContentRowValue = styled.div`
    color: var(--text-secondary);
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    margin-right: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;

export const TextArea = styled.div`
    width: 100%;
    min-width: 0;
`;

export const ExpandButton = styled.div`
    display: none;

    @media (max-width: 800px) {
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
    }
`;

export const ServiceIcon = styled.div`
    width: 32px;
    height: 32px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ServiceName = styled.div`
    color: var(--color-base-black);
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
`;

export const UserAccount = styled.div`
    color: var(--text-secondary);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-top: 4px;
`;

export const LinkButton = styled.div`
    cursor: pointer;
    color: var(--text-link);
    font-size: ${({$fontSize}) => $fontSize ? $fontSize+'px' : '11px'};
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    & span {
        color: var(--text-primary)
    }
`;

export const ExpandedContent = styled(motion.div)`
    background: var(--button-positive-background-default);
`;

export const ExpandedContentRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: var(--spacing-sm) var(--spacing-md);

    border-right: 1px solid var(--border-secondary);
    border-bottom: 1px solid var(--border-secondary);
    border-left: 1px solid var(--border-secondary);
    background: var(--button-positive-background-default);
`;

export const ExpandedContentLabel = styled.div`
    color: var(--color-base-black);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
`;

export const ExpandedContentDefault = styled(ExpandedContentLabel)`
    color: var(--text-secondary);
`;

export const ExpandedContentError = styled(ExpandedContentLabel)`
    color: var(--text-danger);
`;

export const ExpandedContentValue = styled(ExpandedContentLabel)`
    color: var(--color-base-black);
    font-weight: 500;
`;

export const ButtonWrapper = styled.div`
    align-self: start;
    @media (max-width: 800px) {
        margin-left: inherit;
        align-self: center;
    };
`;

export const Warning = styled.div`
    border-radius: 16px 16px 0 0;
    border-bottom: 1px solid var(--border-warning);
    padding: 7px 24px 5px;
    width: 100%;
    box-sizing: border-box;
    background: var(--color-warning-200);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
`;

export const WarningText = styled.div`
    color: var(--text-secondary);
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
`;

export const ProgressContainer = styled.div`
    margin-top: 8px;
    box-sizing: border-box;
    padding-left: var(--spacing-lg);
    padding-right: var(--spacing-lg);
    
    @media (max-width: 800px) {
        margin-top: 0;
        width: 100%;
        padding-left: 0;
        padding-right: 0;
    };
`;

const progressBarAnimation = keyframes`
    0% { background-position: left bottom; }
    100% { background-position: right bottom; }
`;

export const ProgressBar = styled.div`
    width: 100%;
    height: 4px;
    border-radius: 10px;
    background: linear-gradient(
            90deg,
            #bababa 30%,
            #dbdbdb 70%,
            #bababa 100%
    ) repeat;
    background-size: 50% 100%;
    animation-name: ${progressBarAnimation};
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    position: relative;
    overflow: hidden;
`;


export const Progress = styled.div`
    display: inline-block;
    width: ${({ $progress }) => ($progress ? $progress : 0)}%;
    height: 4px;
    border-radius: 10px;
    background: linear-gradient(
            90deg,
            var(--color-primary-500) 30%,
            var(--color-primary-300) 70%,
            var(--color-primary-500) 100%
    ) repeat;
    position: absolute;
    top: 0;
    left: 0;
    transition: width 300ms ease-in-out;
    overflow: hidden;
    background-size: 50% 100%;
    animation-name: ${progressBarAnimation};
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
`;
