import React, { useEffect, useState } from "react";
import styled from "styled-components";

import * as Style from "./preferences.style";
import apiMethod from "../../api/apiMethod";
import UnlinkAppModal from "../Modals/UnlinkAppModal";

const LinkedAppsSetting = () => {
  const [linkedApps, setLinkedApps] = useState(null);
  const [unlinkApp, setUnlinkApp] = useState(null);

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = () => {
    apiMethod("app_userlist", {}, response => {
      const apps = response.applications;

      if (apps) {
        setLinkedApps(apps);
      }
    }, {
      forceFresh: true
    })
  };

  const renderTableHeader = () => (
    <thead>
      <Style.HeaderRow>
        <Style.HeaderCol style={{ width: "24%" }}> {__("App Name")}</Style.HeaderCol>
        <Style.HeaderCol style={{ width: "24%" }}> {__("Publisher")}</Style.HeaderCol>
        <Style.HeaderCol style={{ width: "24%" }}> {__("Folder")}</Style.HeaderCol>
        <Style.HeaderCol style={{ width: "24%" }}> {__("Access Type")}</Style.HeaderCol>
        <Style.HeaderCol align="right" style={{ width: "5%" }}> {__("Delete")}</Style.HeaderCol>
      </Style.HeaderRow>
    </thead>
  )

  const getFolderName = folderid => {
    let name = "";

    HFN.getFolderCallback(folderid, ret => {
      name = htmlentities(ret.name);
    });

    if (name === "/") {
      name += __("root");
    }

    return name;
  }

  const renderTableBody = () => {
    if (!linkedApps) {
      return null;
    }

    return linkedApps.map(app => (
      <tbody>
        <Style.BodyRow>
          <Style.BodyCol>
            <FlexWrap>
              {app.icon ? <img src={HFN.prepUrl(app.icon)} width="32px" height="32px"/> : null}
              <span>{app.name}</span>
            </FlexWrap>
          </Style.BodyCol>
          <Style.BodyCol>{app.publisher || "-"}</Style.BodyCol>
          <Style.BodyCol>{getFolderName(app.folderid)}</Style.BodyCol>
          <Style.BodyCol>{app.writeaccess ? __("settings_accounts_full_access", "Full Access") : __("settings_accounts_readonly", "Read-only")}</Style.BodyCol>
          <Style.BodyCol>
            <IconWrapper onClick={() => setUnlinkApp(app)}>
              <i className="fa-regular fa-xmark"/>
            </IconWrapper>
          </Style.BodyCol>
        </Style.BodyRow>
      </tbody>
    ))
  }

  return (
    <>
      <Style.Row>
        <Style.Title>{__("Linked Apps")}</Style.Title>
      </Style.Row>
      {renderTableHeader()}
      {renderTableBody()}
      <UnlinkAppModal
        opened={!!unlinkApp}
        app={unlinkApp}
        onSuccess={() => {
          setUnlinkApp(null)
        }}
      />
    </>
  )
}

export default LinkedAppsSetting;

const IconWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: var(--icon-base);
  width: 32px;
  height: 32px;
  padding: 6px;
  border-radius: 50%;
  box-sizing: border-box;
  background-color: var(--color-base-white);
  transition: all 200ms ease-in-out;

  &:hover {
    background-color: var(--color-functional-danger200);
    color: var(--color-functional-danger500);
  }
`;

const FlexWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--spacing-sm);
`;