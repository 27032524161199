// @flow

import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { rcookie, setcookie } from "@pcloud/web-utilities/dist/lib/utils";

import { __ } from "../../lib/translate";
import * as Style from "./preferences.style";
import InputCheckbox from "../InputCheckbox";

const HiddenFilesSetting = () => {
  const [showHiddenFiles, setShowHiddenFiles] = useState(rcookie('showhidden') === "1" ? true : false);

  const toggleHiddenFiles = () => {
    HFN.message(__('Setting Saved') + '.');
    setcookie('showhidden', showHiddenFiles ? 0 : 1, 365);
    setShowHiddenFiles(!showHiddenFiles);
  }

  return (
    <Style.Row>
      <div>
        <Style.Title>{__("Show system hidden files")}</Style.Title>
      </div>
      <InputCheckbox size="small" checked={showHiddenFiles} onClick={toggleHiddenFiles}/>
    </Style.Row>
  );
};

export default HiddenFilesSetting;

const Status = styled.div`
  display: inline-block;
`;
