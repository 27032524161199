import React from "react";
import styled from "styled-components";

const TrashExtend = ({ noMargin = false }) => (
    <TrashStyle $noMargin={noMargin}>
        {__("efh_extend_trash")}
        <span>
                <Link href="https://www.pcloud.com/cloud-storage-pricing-plans.html?step=purchase&product=extended-history&period=year">
                    {__("efh_extendnow")}
                </Link>
            </span>
    </TrashStyle>
);

export default TrashExtend;

const TrashStyle = styled.div`
    display: flex;
    padding: var(--spacing-md);
    justify-content: center;
    align-items: center;
    gap: 32px;
    box-sizing: border-box;
    border-radius: var(--radius-minimum);
    border: 2px solid var(--color-functional-positive200);
    background: var(--color-functional-positive100);
    margin: var(--spacing-sm) 0 var(--spacing-md) 0;

    @media (max-width: 1024px) {
        margin: ${({ $noMargin }) =>
            $noMargin
                ? "var(--spacing-sm) 0 var(--spacing-md) 0"
                : "var(--spacing-xs) var(--spacing-md) var(--spacing-sm)"};
    }

    @media (max-width: 900px) {
        flex-direction: column;
        gap: 16px;
        text-align: center;
    }

    color: var(--text-positive);
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
`;

const Link = styled.a`
    display: flex;
    height: 40px;
    box-sizing: border-box;
    padding: var(--spacing-sm) var(--spacing-lg);
    justify-content: center;
    align-items: center;
    border-radius: var(--radius-full);
    border: 1px solid var(--text-positive);
    text-decoration: none;

    color: var(--text-positive);
    text-align: center;

    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    white-space: nowrap;
`;
