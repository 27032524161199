
import React, { useEffect, useMemo, useRef } from "react";
import { createGlobalStyle } from "styled-components";

import { Textcomplete } from "@textcomplete/core";
import { TextareaEditor } from "@textcomplete/textarea";

const useMentionUsersMenu = ({
  textareaRef,
  textareaWrapperRef,
  users,
  onSelectUserForMention = () => {},
  inputFocused
}) => {

  const textComplete = useRef();

  const strategy = useMemo(() => {
    // users = users.concat(users);
    // users = users.concat(users);
    return {
      match: /\B\@([\-\+\w]*)$/,
      async search(term, callback, match) {
        const searchRegEx = new RegExp("^" + term, "i");
        const filtered = users.filter((user) => {
          const searchRes = (user.active || user.frozen) && (
            searchRegEx.test(user.email.trim()) || 
            searchRegEx.test(user.firstname.trim()) || 
            searchRegEx.test(user.lastname.trim())
          );
          return searchRes;
        });

        callback(filtered);
      },
      template(user, term) {
        return renderUserForMentionItem(user);
      },
      replace(user) {
        return `[^${user.email}] `;
      }
    }
  }, [users]);

  const handleHideDropdown = (event) => {
    if (textComplete.current && textareaWrapperRef.current) {
      const clickInsideDropdown = event.composedPath().includes(textComplete.current.dropdown.el);
      const clickInsideTextarea = event.composedPath().includes(textareaWrapperRef.current);
      if (!(clickInsideDropdown || clickInsideTextarea)) {
        textComplete.current.hide();
      }
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleHideDropdown);

    return () => {
      destroyTextComplete();
    }
  }, []);

  useEffect(() => {
    if (!textComplete.current) {
      return;
    }

    if (inputFocused) {
      setTimeout(() => textComplete.current.trigger(textComplete.current.editor.getBeforeCursor()), 0);
    }
  }, [inputFocused]);

  const destroyTextComplete = () => {
    if (textComplete.current) {
      // This command also destroys the editor object. 
      textComplete.current.off("select");
      textComplete.current.destroy();
      textComplete.current = null;
    }
  };

  useEffect(() => {
    destroyTextComplete();

    if (users.length === 0) {
      return;
    }

    // Init mention users suggestion listeners.
    const editor = new TextareaEditor(textareaRef.current);
    textComplete.current = new Textcomplete(editor, [strategy], {
      dropdown: {
        // Class attribute of the dropdown element.
        className: "dropdown-menu textcomplete-dropdown user-for-mention-dropdown",

        // The maximum number of items to be rendered.
        maxCount: 1000000,
        // Placement of the dropdown. "auto", "top" or "bottom".
        placement: "top",

        parent: document.body,

        item: {
          // Class attribute of the each dropdown item element.
          className: "textcomplete-item user-for-mention-item",
    
          // Active item's class attribute.
          activeClassName: "textcomplete-item user-for-mention-item active",
        }
      }
    });

    textComplete.current.on("select", (e) => {
      onSelectUserForMention();
    });

  }, [users]);

  const renderUserForMentionItem = (user) => {
    return `
      <div class="user-for-mention-wrapper">
        <div class="user-for-mention-avatar" style="${user.avatar ? `background: url('${HFN.prepUrl(user.avatar)}') lightgray 50% / cover no-repeat` : ""}">${!user.avatar ? `${user.firstname[0]}${user.lastname[0]}` : ""}</div>
        <div class="user-for-mention-name-and-email">
          <div class="user-for-mention-name">
            ${user.firstname} ${user.lastname}
          </div>
          <div class="user-for-mention-email">
            ${user.email}
          </div>
        </div>
      </div>
    `;
  }

  return {
    textComplete,
    UsersForMentionsStyle
  }

};

export default useMentionUsersMenu;

export const UsersForMentionsStyle = createGlobalStyle`
  .user-for-mention-dropdown {
    display: flex;
    padding: var(--spacing-sm, 8px) var(--spacing-none, 0px);
    flex-direction: column;
    align-items: flex-start;
    width: 300px;
    border-radius: var(--radius-minimum, 4px);
    border: 0.5px solid rgba(0, 0, 0, 0.25);
    background: var(--color-base-white, #FFF);
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    overflow: auto;
    max-height: 300px;

    .user-for-mention-wrapper {
      display: grid;
      padding: var(--spacing-sm, 8px) var(--spacing-md, 16px);
      align-items: center;
      gap: 8px;
      grid-template-columns: auto auto;
      justify-content: flex-start;
      cursor: pointer;
    }

    .user-for-mention-avatar {
      display: flex;
      width: 32px;
      height: 32px;
      justify-content: center;
      align-items: center;
      border-radius: var(--radius-full, 9999px);
      background: var(--Neutral-500, #C6CACE);
      text-transform: uppercase;
      flex: 1;
    }

    .user-for-mention-name-and-email {
      display: grid;
      grid-template-columns: auto;
    }

    .user-for-mention-name {
      color: var(--text-primary, #282C2F);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .user-for-mention-email {
      color: var(--text-secondary, #8C959D);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }


    .textcomplete-header, .textcomplete-footer {
      display: none;
    }
  }
`;