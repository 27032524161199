import passworddict from './passworddict.js';

const passscorelib = {
  mul_score: function(score, num) {
    score.log_remainder *= num;
    while (score.log_remainder > 1.99) {
      score.log_score++;
      score.log_remainder /= 2;
    }
  },

  is_punct: function(character) {
    if (character === undefined) {
      return false;
    }
    return "!@#$%^&*()_+[]{},.<>:;'\"`\\/~|".includes(character);
  },

  islower: function(character) {
    if (character === undefined) {
      return false;
    }
    let cascii = character.charCodeAt(0);
    if (cascii >= "a".charCodeAt(0) && cascii <= "z".charCodeAt(0)){
      return true;
    }
    return false;
  },

  isupper: function(character) {
    if (character === undefined) {
      return false;
    }
    let cascii = character.charCodeAt(0);
    if (cascii >= "A".charCodeAt(0) && cascii <= "Z".charCodeAt(0)) {
      return true;
    }
    return false;
  },

  isspace: function(character) {
    if (character === undefined) {
      return false;
    }
    if (/\s/.test(character)) {
      return true;
    }
    return false;
  },

  is_numeric: function(character) {
    if (character === undefined) {
      return false;
    }
    return /^\d+$/.test(character);
  },

  memcmp: function(str1, str2, size) {
    if (typeof str1 !== 'object') {
      str1 = str1.split('');
      for (var i = 0; i < str1.length; i++) {
        str1[i] = str1[i].charCodeAt(0);
      }
    }
    if (typeof str2 !== 'object') {
      str2 = str2.split('');
      for (var i = 0; i < str2.length; i++) {
        str2[i] = str2[i].charCodeAt(0);
      }
    }
    for(i = 0; i < size; i++) {
      if(str1[i] != str2[i]) {
        if((str1[i] >= 0 && str2[i] >= 0) || (str1[i] < 0 && str2[i] < 0))
          return str1[i] - str2[i];
        if(str1[i] < 0 && str2[i] >= 0)
          return 1;
        if(str2[i] < 0 && str1[i] >=0)
          return -1;
      }
    }
    return 0;
  },

  trailing_num_score: function(num, numlen, nstr) {
    let score = {
      log_score: 0,
      log_remainder: 1
    };
    let i, j;
    if (numlen == 1) {
      if (num <= 1) {
        return 1;
      } else {
        return 2;
      }
    } else if (numlen == 2) { // chances are too high that this might be a guessable year
      if (num == 11) {
        return 1;
      } else if (num == 69 || num % 10 == num / 10 || num % 10 + 1 == num / 10) {
        return 2;
      } else {
        return 3;
      }
    }
    else if (numlen == 4 && num >= 1900 && num <= 2030) { // probably a year
      return 4;
    }
    if (nstr[0] == '1') {
      score.log_score = 1;
    } else if (nstr[0] == '0') {
      score.log_score = 2;
    } else {
      score.log_score = 3;
    }
    i = 1;
    do {
      let skip = false;
      for (j = i; j > 0; j--) {
        if (i + j <= numlen && !this.memcmp(nstr.substring(i), nstr.substring(i - j), j)) {
          score.log_score++;
          i += j;
          skip = true;
          break;
        }
      }
      if (!skip) {
        if (nstr[i].charCodeAt(0) == nstr[i - 1].charCodeAt(0) || nstr[i].charCodeAt(0) == nstr[i - 1].charCodeAt(0) + 1 || nstr[i].charCodeAt(0) == nstr[i - 1].charCodeAt(0) - 1) {
          score.log_score++;
        } else {
          score.log_score += 4;
        }
        i++;
      }
    } while (i < numlen);
    return score.log_score;
  },

  find_in_dict: function(pwd, len) {
    let hi, lo, med, l;
    let c;
    if (len > 8) {
      len = 8;
    } else if (len <= 3) {
      return 0;
    }
    lo = 0;
    hi = passworddict.length;
    while (lo < hi) {
      med = parseInt((lo + hi) / 2);
      l = len;
      while (l && passworddict[med][l - 1] == 0) {
        l--;
      }
      c = this.memcmp(pwd, passworddict[med], l);
      if (c < 0)
        hi = med;
      else if (c > 0)
        lo = med + 1;
      else {
        while (l < 8 && l <= len && med < passworddict.length && !this.memcmp(pwd, passworddict[med + 1], l + 1)) {
          hi = len;
          med++;
          while (hi > 0 && passworddict[med][hi - 1] == 0)
            hi--;
          if (this.memcmp(pwd, passworddict[med], hi))
            break;
          else
            l = hi;
        }
        return l;
      }
    }
    return 0;
  },

  keyboard_buddies: function(ch1, ch2) {
    ch1 = String.fromCharCode(ch1);
    ch2 = String.fromCharCode(ch2);
    const kb = "qwertyuiop[]asdfghjkl;'\\zxcvbnm,./QWERTYUIOP{}ASDFGHJKL:\"|ZXCVBNM<>?~!@#$%^&*()_+";
    let f = kb.indexOf(ch1);
    return f !== -1 && (kb[f+1] == ch2 || (f > 0 && (kb[f-1] == ch2)));
  },

  score_variants: function(password, lpassword, npassword, plen) {
    let score = {
      log_score: 0,
      log_remainder: 1
    };
    let off, r, numchars, n, j;
    let d, haslow, hasup, hasnum, haspunct, hasspace, hasother;
    let ch, pch;
    off = 0;
    haslow = hasup = hasnum = haspunct = hasspace = hasother = 0;
    numchars = 0;
    while (off < plen) {
      let skip = false;
      let isother = false;
      r = plen - off;
      d = this.find_in_dict(password.slice(off), r);
      if (d) {
        this.mul_score(score, parseInt(passworddict.length / 32) * d);
        off += d;
        continue;
      }
      d = this.find_in_dict(lpassword.slice(off), r);
      if (d) {
        this.mul_score(score, parseInt(passworddict.length / 16) * d);
        off += d;
        continue;
      }
      d = this.find_in_dict(npassword.slice(off), r);
      if (d) {
        this.mul_score(score, parseInt(passworddict.length / 8) * d);
        off += d;
        continue;
      }
      if (this.islower(password[off])) {
        haslow = 1;
      } else if (this.isupper(password[off])) {
        hasup = 1;
      } else if (this.is_numeric(password[off])) {
        hasnum = 1;
      } else if (this.is_punct(password[off])) {
        haspunct = 1;
      } else if (this.isspace(password[off])) {
        hasspace = 1;
      } else {
        isother = true;
        hasother = 1;
        numchars += (new TextEncoder().encode(password[off])).length - 1;
      }
      ch = password[off];
      if (!off) {
        if (ch == 'a') {
          this.mul_score(score, 2);
        } else if (ch == 'q') {
          this.mul_score(score, 2);
        } else if (ch == 'z') {
          this.mul_score(score, 4);
        } else if (ch == '1') {
          this.mul_score(score, 2);
        } else {
          numchars++;
        }
      } else {
        for (j = off; j > 0; j--) {
          if (j <= r) {
            if (!this.memcmp(password.slice(off), password.slice(off - j), j)) {
              this.mul_score(score, 1 + j);
              off += j;
              skip = true;
              break;
            } else if (!this.memcmp(lpassword.slice(off), lpassword.slice(off - j), j)) {
              this.mul_score(score, 2 + j);
              off += j;
              skip = true;
              break;
            }
          }
        }
        if (!skip) {
          if (!isother) {
            pch = password[off - 1].charCodeAt(0);
            ch = ch.charCodeAt(0);
            if (pch + 1 == ch || pch - 1 == ch) {
              this.mul_score(score, 2);
            } else if (this.keyboard_buddies(pch, ch)) {
              this.mul_score(score, 2);
            } else if (this.keyboard_buddies(lpassword[off - 1].charCodeAt(0), lpassword[off].charCodeAt(0))) {
              this.mul_score(score, 4);
            } else {
              numchars++;
            }
          } else {
            numchars++;
          }
        }
      }
      if (!skip) {
        off++;
      }
    }
    n = 0;
    if (haslow)
      n += 26;
    if (hasup)
      n += 26;
    if (hasnum)
      n += 10;
    if (haspunct)
      n += 20;
    if (hasspace)
      n += 2;
    if (hasother)
      n += 10;

    while (numchars--) {
      this.mul_score(score, n);
    }
    return score.log_score;
  },

	password_score: function(cpassword) {
    let num;
    let lpwd = [];
    let ldpwd = [];
    let nlen;
    let ch;
    let plen = cpassword.length;
    let password = cpassword;
    let score = {
      log_score: 0,
      log_remainder: 1
    };
    // trailing ! is too common
    while (plen && password[plen - 1] == '!') {
      this.mul_score(score, 2);
      plen--;
    }
    ch = 0;
    // if punctuation is in the end, we give it a low score
    while (plen && this.is_punct(password[plen - 1])) {
      plen--;
      if (password[plen] == ch) {
        this.mul_score(score, 2);
      } else {
        this.mul_score(score, 10);
      }
    }
    if (plen && this.is_numeric(password[plen - 1])) {
      // number in the end, low score
      num = 0;
      nlen = 0;
      do {
        plen--;
        num = num * 10 + password[plen].charCodeAt(0) - '0'.charCodeAt(0);
        nlen++;
      } while (plen && this.is_numeric(password[plen - 1]));
      score.log_score += this.trailing_num_score(num, nlen, password.slice(plen));
      // check for punctuation again
      while (plen && this.is_punct(password[plen - 1])) {
        plen--;
        if (password[plen] == ch) {
          this.mul_score(score, 2);
        } else {
          this.mul_score(score, 10);
        }
      }
    }
    if (!plen) {
      return score.log_score;
    }
    for (nlen = 0; nlen < plen; nlen++) {
      if (password[nlen] !== undefined) {
        lpwd[nlen] = password[nlen].toLowerCase();
      } else {
        lpwd[nlen] = password[nlen];
      }
      if (lpwd[nlen] == '0')
        ldpwd[nlen] = 'o';
      else if (lpwd[nlen] == '1')
        ldpwd[nlen] = 'i';
      else if (lpwd[nlen] == '3')
        ldpwd[nlen] = 'e';
      else if (lpwd[nlen] == '4')
        ldpwd[nlen] = 'a';
      else if (lpwd[nlen] == '5')
        ldpwd[nlen] = 's';
      else if (lpwd[nlen] == '7')
        ldpwd[nlen] = 't';
      else if (lpwd[nlen] == '$')
        ldpwd[nlen] = 's';
      else if (lpwd[nlen] == '@')
        ldpwd[nlen] = 'a';
      else if (lpwd[nlen] == '!')
        ldpwd[nlen] = 'l';
      else
        ldpwd[nlen] = lpwd[nlen];
    }

    score.log_score += this.score_variants(password, lpwd.join(''), ldpwd.join(''), plen);

    return score.log_score;
  }
};

export default passscorelib;
