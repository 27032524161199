import React from "react";
import ActionButton from "../../../userinterface/ActionButton";
import {
    SongControlsCenterWrapper,
    SongControlsWrapper
} from "../../../userinterface/styles";
import {
    REPEAT_STATE_INACTIVE,
    REPEAT_STATE_REPEAT,
    REPEAT_STATE_REPEAT_ONE
} from "../../../constants";
import MinimizedBoxControls from "./MinimizedBoxControls";
import usePlayerContext from "../../../hooks/usePlayerContext";
import { getPlayerState } from "../../../helpers";

type Props = {
    onNextCallback?: Function<any>,
    onPrevCallback?: Function<any>
};

const SongControls = (props: Props) => {
    const { onNextCallback, onPrevCallback } = props;

    const {
        isMobile,
        onPrev,
        onNext,
        onToggleRepeat,
        onToggleShuffle,
        onTogglePlayPause,
        playerVariant,
        onPlayerClose,
        onPlayerMaximize,
        additionalClass,
        isPlaying,
        repeatState,
        isShuffle,
        queue
    } = usePlayerContext();

    const { isMiniPlayer, isMinimize, isFullPage } = playerVariant;

    const playButtonSize = isMobile
        ? 40
        : isMiniPlayer
        ? isMinimize
            ? 40
            : 30
        : 24;
    const controlDefaultColor =
        isFullPage || isMiniPlayer
            ? "svg-color-default"
            : "svg-color-default-light";
    const controlBtnSize = isMobile ? 40 : 24;
    const showQueueControlBtns = !isMinimize;
    const showMinimizedBoxControlBtns = isMinimize && !isMobile;

    return (
        <>
            <SongControlsWrapper
                className={additionalClass}
                layout={isMiniPlayer}
            >
                {showQueueControlBtns && (
                    <ActionButton
                        disabled={queue.length === 1}
                        onClick={(e) => {
                            onToggleShuffle(e);

                            if (typeof gtag === "function") {
                                gtag("event", "audio_player_click", {
                                    action: "shuffle",
                                    state: getPlayerState(playerVariant),
                                    eventValue: !isShuffle ? "on" : "off"
                                });
                            }
                        }}
                        className={
                            isShuffle ? "svg-color-prime" : controlDefaultColor
                        }
                        label={
                            <i
                                className="fa-light fa-shuffle"
                                style={{
                                    fontSize:
                                        isMobile && isMiniPlayer
                                            ? 24
                                            : isMobile || isFullPage
                                            ? 20
                                            : 16
                                }}
                            />
                        }
                        buttonWidth={controlBtnSize}
                        buttonHeight={controlBtnSize}
                        styles={{
                            justifyContent: "start"
                        }}
                    />
                )}

                <SongControlsCenterWrapper
                    className={additionalClass + " animate"}
                >
                    <ActionButton
                        disabled={queue.length === 1}
                        onClick={(e) => {
                            if (
                                onPrevCallback &&
                                typeof onPrevCallback === "function"
                            ) {
                                onPrevCallback();
                            }
                            onPrev(e);

                            if (typeof gtag === "function") {
                                gtag("event", "audio_player_click", {
                                    action: "previous",
                                    state: getPlayerState(playerVariant)
                                });
                            }
                        }}
                        className={"svg-color-default"}
                        label={
                            <i
                                className="fa-solid fa-backward-step"
                                style={{
                                    fontSize:
                                        isMiniPlayer && !isMinimize && isMobile
                                            ? 32
                                            : isFullPage
                                            ? 28
                                            : isMobile
                                            ? 20
                                            : 16
                                }}
                            />
                        }
                        buttonWidth={playButtonSize}
                        buttonHeight={playButtonSize}
                    />
                    <ActionButton
                        onClick={(e) => {
                            onTogglePlayPause(e);

                            if (typeof gtag === "function") {
                                gtag("event", "audio_player_click", {
                                    action: isPlaying ? "pause" : "play",
                                    state: getPlayerState(playerVariant)
                                });
                            }
                        }}
                        className={"svg-color-default"}
                        label={
                            isPlaying ? (
                                <i
                                    className="fa-solid fa-pause"
                                    style={{
                                        fontSize:
                                            isMiniPlayer &&
                                            !isMinimize &&
                                            isMobile
                                                ? 40
                                                : isFullPage
                                                ? 28
                                                : isMobile
                                                ? 20
                                                : 16
                                    }}
                                />
                            ) : (
                                <i
                                    className="fa-solid fa-play"
                                    style={{
                                        fontSize:
                                            isMiniPlayer &&
                                            !isMinimize &&
                                            isMobile
                                                ? 40
                                                : isFullPage
                                                ? 28
                                                : isMobile
                                                ? 20
                                                : 16
                                    }}
                                />
                            )
                        }
                        buttonWidth={playButtonSize}
                        buttonHeight={playButtonSize}
                    />
                    <ActionButton
                        disabled={queue.length === 1}
                        onClick={(e) => {
                            if (
                                onNextCallback &&
                                typeof onNextCallback === "function"
                            ) {
                                onNextCallback();
                            }
                            onNext(e);

                            if (typeof gtag === "function") {
                                gtag("event", "audio_player_click", {
                                    action: "next",
                                    state: getPlayerState(playerVariant)
                                });
                            }
                        }}
                        className={"svg-color-default"}
                        label={
                            <i
                                className="fa-solid fa-forward-step"
                                style={{
                                    fontSize:
                                        isMiniPlayer && !isMinimize && isMobile
                                            ? 32
                                            : isFullPage
                                            ? 28
                                            : isMobile
                                            ? 20
                                            : 16
                                }}
                            />
                        }
                        buttonWidth={playButtonSize}
                        buttonHeight={playButtonSize}
                    />
                </SongControlsCenterWrapper>

                {showQueueControlBtns && (
                    <ActionButton
                        onClick={(e) => {
                            onToggleRepeat(e);

                            if (typeof gtag === "function") {
                                let gtagValue;
                                if (repeatState === REPEAT_STATE_INACTIVE) {
                                    gtagValue = "on";
                                } else if (
                                    repeatState === REPEAT_STATE_REPEAT
                                ) {
                                    gtagValue = "one";
                                } else {
                                    gtagValue = "off";
                                }
                                gtag("event", "audio_player_click", {
                                    action: "repeat",
                                    state: getPlayerState(playerVariant),
                                    eventValue: gtagValue
                                });
                            }
                        }}
                        className={
                            repeatState !== REPEAT_STATE_INACTIVE
                                ? "svg-color-prime"
                                : controlDefaultColor
                        }
                        label={
                            repeatState === REPEAT_STATE_REPEAT_ONE ? (
                                <i
                                    className="fa-light fa-arrows-repeat-1"
                                    style={{
                                        fontSize:
                                            isMobile && isMiniPlayer
                                                ? 24
                                                : isMobile || isFullPage
                                                ? 20
                                                : 16
                                    }}
                                />
                            ) : (
                                <i
                                    className="fa-light fa-arrows-repeat"
                                    style={{
                                        fontSize:
                                            isMobile && isMiniPlayer
                                                ? 24
                                                : isMobile || isFullPage
                                                ? 20
                                                : 16
                                    }}
                                />
                            )
                        }
                        buttonWidth={controlBtnSize}
                        buttonHeight={controlBtnSize}
                        styles={{
                            justifyContent: "end"
                        }}
                    />
                )}

                {showMinimizedBoxControlBtns && (
                    <MinimizedBoxControls
                        onPlayerMaximize={onPlayerMaximize}
                        onPlayerClose={onPlayerClose}
                    />
                )}
            </SongControlsWrapper>
        </>
    );
};

export default SongControls;
