import React, { useState, useCallback, useEffect } from 'react';
import { MINIMIZED_HEIGHT, UPLOAD_TAB } from "../constants";
import hashManager from "../../../lib/hashManager";

const useControls = ({
  isUploading,
  cancelUpload,
  closeUpload,
  failedItems,
  handleRetryAction,
  lockCryptoAttempt,
  action,
  random,
  setActiveTab,
  handleRetryAll,
  isMobile
}) => {
  const [isToggled, setIsToggled] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const [isWarningOnUploadModalOpen, setIsWarningOnUploadModalOpen] = useState(false);
  const [isUploadCancelModalOpen, setIsUploadCancelModalOpen] = useState(false);
  const [failedFolderModalItemId, setFailedFolderModalItemId] = useState(false);
  const [isLockCryptoModalOpen, setIsLockCryptoModalOpen] = useState(false);
  const [isUploadCloseWithRetryOpen, setIsCloseWithRetryOpened] = useState(false);
  const isDlink = HFN.config.isDlink();

  useEffect(() => {
    if (lockCryptoAttempt) {
      if (isUploading) {
        setIsLockCryptoModalOpen(true);
      } else {
        handleLockCryptoYesClick();
      }
    }
  }, [lockCryptoAttempt]);

  useEffect(() => {
    if (action) {
      if (action === 'minimize' && isOpened) {
        setIsToggled(true);
      }
    }
  }, [action, random]);

  useEffect(() => {
    if (isDlink || !isMobile) {
      return;
    }
    const chatbase = document.getElementById('chatbase-bubble-button');
    const upload = document.getElementById('mobile-upload-button-container');

    if (!isMobile) {
      if (chatbase) chatbase.style.opacity = 1;
      if (upload) upload.style.opacity = 1;
      return;
    }

    if (chatbase) chatbase.style.opacity = isToggled || !isOpened ? 1 : 0;
    if (upload) upload.style.opacity = isToggled || !isOpened ? 1 : 0;

    return () => {
      if (chatbase) chatbase.style.opacity = 1;
      if (upload) upload.style.opacity = 1;
    }
  }, [isToggled, isOpened, isMobile]);

  const handleToggleState = () => {
    if (isToggled && (isDlink || HFN.config.isMobile())) {
      HFN.audioPlayer.minimize();
    }
    setIsToggled((current) => !current);
  }

  const handleOpenState = (event) => {
    event?.stopPropagation();
    if (isUploading && isOpened) {
      setIsWarningOnUploadModalOpen(true);
    } else if (!isUploading && isOpened) {
      if (failedItems.length !== 0) {
        setIsCloseWithRetryOpened(true);
      } else {
        setIsOpened(false);
        handleWarningOnUploadModalYesClick();
      }
    } else {
      setIsOpened(true);
      HFN.bottomFixedControls.calculatePageBottom.add(MINIMIZED_HEIGHT);
    }
  }

  const handleWarningOnUploadModalClose = () => setIsWarningOnUploadModalOpen(false);

  const handleWarningOnUploadModalYesClick = () => {
    setIsWarningOnUploadModalOpen(false);
    setIsCloseWithRetryOpened(false);
    setIsOpened(false);
    HFN.bottomFixedControls.calculatePageBottom.remove(MINIMIZED_HEIGHT);
    closeUpload();
  }

  const handleCancel = () => {
    if (isUploading) {
      setIsUploadCancelModalOpen(true);
    }
  }

  const handleUploadCancelModalClose = () => setIsUploadCancelModalOpen(false);

  const handleUploadCancelModalYesClick = () => {
    setIsUploadCancelModalOpen(false);
    cancelUpload();
  }

  const openFailedFolderModal = useCallback((id) => {
    setFailedFolderModalItemId(id);
  }, [failedItems])

  const handleFailedFolderModalOpenClose = () => setFailedFolderModalItemId(false);

  const handleFailedFolderModalOpenYesClick = () => {
    handleRetryAction(failedFolderModalItemId);
    handleFailedFolderModalOpenClose();
  }

  const handleLockCryptoModalClose = () => setIsLockCryptoModalOpen(false);

  const handleLockCryptoYesClick = () => {
    setIsLockCryptoModalOpen(false);
    HFN.lockCrypto();
    HFN.config.showTempCryptoPassMessage = true;
    hashManager.pushState({ page: 'cryptolocked' }, 2);
    cancelUpload();
  }


  const handleUploadCloseWithRetryClose = () => setIsCloseWithRetryOpened(false);

  const handleUploadCloseWithRetryYesClick = () => {
    handleRetryAll();
    setIsCloseWithRetryOpened(false);
  }

  return {
    isToggled,
    handleToggleState,
    isOpened,
    handleOpenState,

    isWarningOnUploadModalOpen,
    handleWarningOnUploadModalYesClick,
    handleWarningOnUploadModalClose,

    handleCancel,
    isUploadCancelModalOpen,
    handleUploadCancelModalClose,
    handleUploadCancelModalYesClick,

    openFailedFolderModal,
    failedFolderModalItemId,
    handleFailedFolderModalOpenClose,
    handleFailedFolderModalOpenYesClick,

    isLockCryptoModalOpen,
    handleLockCryptoModalClose,
    handleLockCryptoYesClick,

    isUploadCloseWithRetryOpen,
    handleUploadCloseWithRetryClose,
    handleUploadCloseWithRetryYesClick,
  };
};

export default useControls;