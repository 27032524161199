import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import useOutsideClicker from "../../../web-shared/hooks/useOutsideClicker";
import { handleDisabledInfoModals } from "./helpers";
import { __ } from "../../lib/translate"
import {
  ONBOARDING_ID_UPLOAD_BUTTON
} from "../HighlightOnboardingComponent/constants";
import HighlightOnboardingComponent from "../HighlightOnboardingComponent";
import UploadButtonContent from "../HighlightOnboardingComponent/Contents/UploadManager/UploadButtonContent";
import { ONBOARDING_ADD_ITEM } from "../../lib/state/constants/onboarding";
import { useDispatch } from "react-redux";

const UploadButton = ({ onUpload, isOnboarding, disabled }) => {
  const [isOptionsModalOpen, setIsOptionsModalOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!HFN.config.isDlink() && !isOnboarding) {
      dispatch({ type: ONBOARDING_ADD_ITEM, id: ONBOARDING_ID_UPLOAD_BUTTON });
    }
  }, []);

  const uploadModalRef = useRef(null);
  const fileInputRef = useRef(null);
  const folderInputRef = useRef(null);

  const handleFileChange = (event) => {
    onUpload({ items: event.target.files, isDrop: false });
    setIsOptionsModalOpen(false);
  };

  const handleRemoteUpload = () => {
    onUpload({ isRemoteUpload: true });
    setIsOptionsModalOpen(false);
  }

  const triggerFileInput = () => {
    fileInputRef.current.click();
    fileInputRef.current.value = '';
    setIsOptionsModalOpen(false);
  };

  const triggerFolderInput = () => {
    folderInputRef.current.click();
    folderInputRef.current.value = '';
    setIsOptionsModalOpen(false);
  };

  useOutsideClicker(uploadModalRef, () => {
    setIsOptionsModalOpen(false);
  })

  const upload = () => {
    const isMobile = HFN.config.isMobile();
    handleDisabledInfoModals(() => {
      if (isMobile || isOnboarding) {
        triggerFileInput()
      } else {
        setIsOptionsModalOpen(!isOptionsModalOpen)
      }
    })
  }

  return (
    <Container>
      <ButtonWrapper
        $isOnboarding={isOnboarding}
      >
        <HighlightOnboardingComponent
            id={ONBOARDING_ID_UPLOAD_BUTTON}
            content={[
              <UploadButtonContent />
            ]}
            temporaryHide={isOptionsModalOpen}
            notShowAfterClose={true}
            pulseAnimation={true}
            pulseCircle={false}
            showOverlay={true}
            place={"bottom-right"}
            delay={500}
        >
          <Button
            $isOnboarding={isOnboarding}
            onClick={upload}
            disabled={disabled}
          >
            {isOnboarding ? __("step_action_upload", "Upload file") : __('Upload', 'Upload')}
          </Button>
        </HighlightOnboardingComponent>
      </ButtonWrapper>
      {isOptionsModalOpen && (
        <Modal ref={uploadModalRef}>
          <ModalSelectOption onClick={triggerFileInput}>{__('Files', 'Files')}</ModalSelectOption>
          <ModalSelectOption onClick={triggerFolderInput}>{__('Folder', 'Files')}</ModalSelectOption>
          {(!HFN.config.isDlink() && !HFN.inCrypto()) &&
            <ModalSelectOption onClick={handleRemoteUpload}>{__('upload_manager_remote_upload_option', 'File from URL')}</ModalSelectOption>
          }
        </Modal>
      )}
      <input type='file' style={{display: "none"}} multiple onChange={handleFileChange} ref={fileInputRef} />
      <input type='file' style={{display: "none"}} webkitdirectory="true" onChange={handleFileChange} ref={folderInputRef} />
    </Container>
  );
};

export default UploadButton;

const Container = styled.div`
  position: relative;
`

const ButtonWrapper = styled.div`
  float: left;
  display: inline-block;
  margin-right: 15px;
  height: ${({ $isOnboarding }) => $isOnboarding ? '34' : '28'}px;
`;

const Button = styled.button`
  cursor: pointer;
  height: ${({ $isOnboarding }) => $isOnboarding ? '34' : '28'}px; 
  line-height: ${({ $isOnboarding }) => $isOnboarding ? '34' : '28'}px;
  background: ${({ $isOnboarding }) => $isOnboarding ? '#61C100' : '#17bed0'};
  color: #fff;
  font-size: 13px;
  text-align: center;
  padding: 0 20px 0 20px;
  border-radius: ${({ $isOnboarding }) => $isOnboarding ? '5' : '3'}px;
  min-width: ${({ $isOnboarding }) => $isOnboarding ? '130' : '108'}px;
  box-sizing: border-box;
  border: none;
  font-weight: ${({ $isOnboarding }) => $isOnboarding ? '600' : '400'};

  ${({ disabled, $isOnboarding }) => (disabled && $isOnboarding) && css`
    background: #999999;
    opacity: 0.5;
    pointer-events: none;
  `}
`

const Modal = styled.div`
  min-width: 160px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  background: #FFF;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.15);
  padding: 8px 0;
  position: absolute;
  top: 40px;
  right: 15px;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  z-index: 120;
`

const ModalSelectOption = styled.span`
  width: 100%;
  height: 32px;
  padding: 0 20px;
  color: #000;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  cursor: pointer;
  
  &:hover {
    background: #f9f9f9;
  }
`
