import styled from "styled-components";

export const FlexContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const Img = styled.img`
    width: ${({ width }) => width || "100%"};
    max-width: ${({ maxwidth }) => maxwidth || "100%"};
`;
