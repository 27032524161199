// @flow

import React from "react";
import styled from "styled-components";

type Props = {
  showTabs: boolean,
  type: number,
  children: any,
  onTabClick: (index: number) => void
};

class TabWrapper extends React.Component<Props> {
  static defaultProps = {
    onTabClick: () => {},
    type: 1,
    children: []
  };

  constructor(props: Props) {
    super(props);

    (this: any).renderTitles = this.renderTitles.bind(this);
  }

  handleClick(index: number, event: Event) {
    event.preventDefault();

    const { onTabClick } = this.props;

    onTabClick(index);
  }

  renderTitles(child: any, selectedTabIndex: number) {
    const { type } = this.props;
    selectedTabIndex++;
    const activeClass = type === selectedTabIndex ? "active" : "";

    return (
      <li key={selectedTabIndex}>
        <a
          href="#"
          className={activeClass}
          onClick={this.handleClick.bind(this, selectedTabIndex)}
        >
          {child.props.label}
        </a>
      </li>
    );
  }

  renderTabs() {
    return (
      <Tab className="tabs__labels">
        {this.props.children.map(this.renderTitles)}
      </Tab>
    );
  }

  renderContent() {
    return (
      <div className="tabs__content">
        {this.props.children[this.props.type - 1]}
      </div>
    );
  }

  render() {
    const { showTabs } = this.props;

    return (
      <div className="tabs__wrapper">
        {showTabs ? this.renderTabs() : null}
        {this.renderContent()}
      </div>
    );
  }
}

export default TabWrapper;

const Tab = styled.ul`
  background-color: white;
  padding: 0px 20px 30px 10px;
  margin: 0;

  li {
    display: inline-block;
    list-style-type: none;
  }

  li a {
    display: inline-block;
    min-width: 165px;
    position: relative;
    padding: 12px;
    border-bottom: 1px solid rgba(17, 17, 17, 0.1);
    color: #8b8b8b;
    text-transform: uppercase;
    font-size: 15px;
    line-height: 20px;
    cursor: pointer;
    text-decoration: none;
    text-align: center;

    @media (max-width: 800px) {
      min-width: auto;
      width: 100%;
      display: inline-block;
      box-sizing: border-box;
    }

    &.active {
      color: #2c2c2c;
    }

    &.active:after {
      content: "";
      display: block;
      height: 2px;
      width: 100%;
      background: #86c600;
      bottom: -2px;
      left: 0;
      position: absolute;
    }
  }
`;
