import React from "react";
import CryptoLockedImg from "../../../root/img/crypto/crypto-locked.svg";
import { __ } from "../../lib/translate";
import { Button, Content, Header, HeaderLabel, Wrapper } from "./styles";
import styled from "styled-components";
import { siteUrl } from "@pcloud/web-utilities/dist/config";
import Componentify from "react-componentify";
import { boldConverter } from "../../lib/componentifyConverters";

const CryptoExpired = ({ extendedHours, onShowLockPage }) => {
    const days = extendedHours <= 24 ? 1 : (extendedHours / 24).toFixed(0);
    const warningMessage = __("expired_crypto_warning").replace(
        /%d/,
        "<b>" + days + "</b>"
    );

    return (
        <Wrapper>
            <ContentCustom $maxWidth={550}>
                <Header>
                    <CryptoLockedImg />
                    <HeaderLabel>{__("Locked")}</HeaderLabel>
                </Header>
                <InfoWrapper>
                    <Title>
                        {__("pCloud Encryption Subscription has expired!")}
                    </Title>
                    <Description>
                        <Componentify text={warningMessage} converters={[boldConverter]}/>
                    </Description>
                </InfoWrapper>
            </ContentCustom>
            <ActionWrapper>
                <Button
                    onClick={() => {
                        window.location =
                            siteUrl("encrypted-cloud-storage.html");
                    }}
                >
                    {__("Buy Crypto")}
                </Button>
                <Button $secondary={true} onClick={onShowLockPage}>
                    {__("Continue")}
                </Button>
            </ActionWrapper>
        </Wrapper>
    );
};

export default CryptoExpired;

const ContentCustom = styled(Content)`
    gap: 12px;
`;

const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
`;

const ActionWrapper = styled.div`
    width: 100%;
    max-width: 550px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;

    @media (max-width: 500px) {
        flex-direction: column;
        gap: 16px;
    }
`;

const Title = styled.div`
    color: var(--text-primary);
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    text-align: center;
`;

const Description = styled.div`
    color: var(--text-primary);
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
`;
