import React from "react";
import styled from "styled-components";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { detectIsMobile } from "@pcloud/web-utilities/dist/lib/utils";

const QRCodeTooltip = () => {
    if (detectIsMobile()) {
        return null;
    }

    return (
        <ReactTooltipStyle>
            <ReactTooltip
                id="qr-code-tooltip"
                effect="solid"
                className="qr-code-tooltip"
                classNameArrow="qr-code-tooltip-arrow"
                backgroundColor="#fff"
                multiline
                arrowRadius="3"
            />
        </ReactTooltipStyle>
    );
};

export default QRCodeTooltip;

const ReactTooltipStyle = styled.div`
    .qr-code-tooltip {
        background-color: #fff;
        padding: 8px 20px 16px;
        box-shadow: 3px 12px 25px 0 rgba(0, 0, 0, 0.2);
        opacity: 1 !important;
        border-radius: 16px;

        &.place-top::after,
        &.place-bottom::after {
            width: 36px;
            height: 36px;
            margin-left: -18px;
        }

        &.place-left::after,
        &.place-right::after {
            width: 36px;
            height: 36px;
            margin-top: -18px;
        }
    }
`;
