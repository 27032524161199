import { apiMethodUrl } from "../../../api/utils";
import apiMethod from "../../../api/apiMethod";
import { v4 as uuidv4 } from 'uuid';
import { fibonacci, delay } from '../../../lib/utils';
import { MAX_FAILED_UPLOADS_RETRIES, FILE_SIZE_FOR_CHUNKS, DEFAULT_PASTE_ITEM_NAME } from "../constants";
import { uniqFilename, getIconId, shouldNotRetryForError, getFormattedPasteName } from '../helpers';
import hashManager from "../../../lib/hashManager";

const uploadFileToServer = async (file, onProgress, onUploadComplete, onUploadFailed, opts, signal, isSingleFile, onUploadAborted) => {
  const isDlink = HFN.config.isDlink();
  const uploadMethod = isDlink ? "publink/upload" : file.url ? "downloadfile" : "uploadfile";
  const uploadProgressMethod = isDlink ? "publink/uploadprogress" : "uploadprogress";
  let formData = undefined
  if (!file.url) {
    formData = new FormData();
    formData.append('file', file, file.name);
  } else {
    opts.url = file.url;
  }
  // Additional options
  if (file.lastModified) {
    opts.mtime = Math.floor(new Date(file.lastModified).getTime() / 1000);
  }

  const progresshash = uuidv4();

  const xhr = new XMLHttpRequest();

  // Abort handling
  const abortHandler = () => {
    clearInterval(progressIntervalId);
    xhr.abort();
    onUploadAborted();
  };

  // Listen to the abort signal
  if (signal) {
    signal.addEventListener('abort', abortHandler);
  }

  // Open and send request
  xhr.open('POST', apiMethodUrl(false, uploadMethod, { ...opts, progresshash, iconformat: "id" }), true);

  // Handle errors
  xhr.onerror = () => {
    clearInterval(progressIntervalId);
    onUploadFailed();

    // Clean up
    if (signal) {
      signal.removeEventListener('abort', abortHandler);
    }
  };

  xhr.send(formData);

  let progressIsRunning = false;

  // Start an interval for server-side progress checks
  const progressIntervalId = setInterval(() => {
    if (progressIsRunning) {
      return;
    }

    if (isSingleFile) {
      let params = { progresshash, auth: opts.auth };

      if (isDlink) {
        const url = window.location.href;
        const urlObj = new URL(url);
        params.code = urlObj.searchParams.get('code');
      }

      progressIsRunning = true;

      apiMethod(uploadProgressMethod, params, (res) => {
        progressIsRunning = false;

        if (res.finished) {
          clearInterval(progressIntervalId);
        }

        let fileFromUrl = file.url ? res.files[0] : undefined;
        let uploaded, total;

        if (fileFromUrl) {
          uploaded = fileFromUrl.downloaded;
          total = fileFromUrl.size;
        } else {
          uploaded = res.uploaded;
          total = res.total;
        }

        const progress = Math.round((uploaded * 100) / total);
        onProgress(progress, total);
        if (fileFromUrl && fileFromUrl.status === "error") {
          onUploadFailed(0, "link-not-accessible");
        }
      }, {
        timeout: 300000,
        errorCallback: (error) => {
          clearInterval(progressIntervalId);
        },
        onXhrError: (error) => {
          clearInterval(progressIntervalId);
        }
      })
    }
  }, 1000);

  xhr.onload = () => {
    // Clean up
    if (signal) {
      signal.removeEventListener('abort', abortHandler);
    }
    if (xhr.readyState === 4) {
      if (xhr.status === 200) {
        const response = JSON.parse(xhr.response);
        const result = parseInt(response.result);

        if (result !== 0) {
          onUploadFailed(0, result);
          clearInterval(progressIntervalId);
        } else {
          if (isDlink) {
            syncFileCache(opts.folderid, response);
            clearInterval(progressIntervalId);
          }
          const meta = response.metadata[0];
          if (file.url) {
            if (meta) {
              onUploadComplete({ name: meta.name, size: meta.size, fileIdCreated: meta.id });
              clearInterval(progressIntervalId);
            }
          } else {
            onUploadComplete({ fileIdCreated: meta.id });
            clearInterval(progressIntervalId);
          }
        }
      }
    }
  }
}

const getOrCreateFolder = async (folderName, parentFolderid = 0, encrypted, setFailedFolderError) => {
  const isDlink = HFN.config.isDlink();
  const uploadMethod = isDlink ? "publink/createfolder" : "createfolderifnotexists";
  const params = {
    folderid: parentFolderid,
    name: folderName,
    iconformat: "id"
  };

  if (isDlink) {
    const url = window.location.href;
    const urlObj = new URL(url);
    params.code = urlObj.searchParams.get('code');
  }

  if (encrypted) {
    const key = pCrypt.generatefolderkey();
    params.name = pCloudCrypto.encryptMetaName({ name: folderName, parentfolderid: parentFolderid });
    params.encrypted = encrypted;
    params.key = key;
  }

  return new Promise((resolve, reject) => {
    apiMethod(uploadMethod, params, (res) => {
      if (encrypted) {
        pCloudCrypto.saveKey('d' + res.metadata.folderid, params.key);
      }
      resolve(res.metadata.folderid);

      if (isDlink) {
        syncFolderCache(parentFolderid, res)
      }
    }, {
      errorCallback: (error) => {
        if (error.hasOwnProperty('result') && parseInt(error.result) > 0) {
          setFailedFolderError(parseInt(error.result));
        }
        reject(error);
      },
      onXhrError: (error) => {
        reject(error);
      }
    })
  })
}

const diffAppendedFileIds = [];
const syncFileCache = (folderid, res) => {
  const pageFolderId = hashManager.getState('folder') || 0;

  const newFile = res.metadata[0];

  if (!newFile.ismine && !diffAppendedFileIds.includes(newFile.fileid)) {
    HFN.diffHandler.eventHandlers.createfile({ metadata: newFile });
    diffAppendedFileIds.push(newFile.fileid)
  }

  if (parseInt(pageFolderId) !== folderid || !newFile.ismine) {
    setTimeout(() => {
      const cid = HFN.cache.cacheid('listfolder', 'list', folderid || 0, 'public');
      let object;
      if (HFN.cache.has(cid)) {
        object = HFN.cache.get(cid);

        const existFile = object.contents.filter((item) => {
          return item.fileid === newFile.fileid;
        });
        if (!existFile.length) {
          object.contents.push(newFile);
        }
      }
    }, 200);
  }
}

const syncFolderCache = (parentFolderid, res) => {
  if (!res.metadata.ismine) {
    HFN.diffHandler.eventHandlers.createfolder(res);
  }
  setTimeout(() => {
    const cid = HFN.cache.cacheid('listfolder', 'list', parentFolderid || 0, 'public');
    if (HFN.cache.has(cid)) {
      const object = HFN.cache.get(cid);
      const newFolder = res.metadata;
      const existFolder = object.contents.filter((item) => {
        return item.folderid === newFolder.folderid;
      });
      if (!existFolder.length) {
        newFolder.contents = [];
        object.contents.push(newFolder);
      }
    }
  }, 200);
}

const listFolder = async (folderid, auth) => {
  return new Promise((resolve, reject) => {
    apiMethod("listfolder", { folderid, recursive: 0, iconformat: 'id', getkey: 1, getpublicfolderlink: 1, auth, cache: true }, (res) => {
      resolve(res);
    }, {
      errorCallback: (error) => {
        reject(error);
      }, onXhrError: (error) => {
        reject(error);
      },
      forceFresh: true
    })
  })
}

const uploadFileToServerChunks = async (file, onProgress, onUploadComplete, onUploadFailed, opts, signal, isSingleFile, onUploadAborted) => {
  let start = 0;
  let retryCount = 0;

  let uploadId
  try {
    uploadId = await createUploadSessionWithRetry(MAX_FAILED_UPLOADS_RETRIES);
  } catch (errorCode) {
    onUploadFailed(undefined, errorCode, true);
    return;
  }

  const uploadChunk = async (start, chunk) => {
    const xhr = new XMLHttpRequest();
    // Abort handling

    const abortHandler = () => {
      xhr.abort();
      onUploadAborted();
      return;
    };

    if (signal) {
      signal.addEventListener('abort', abortHandler);
    }

    return new Promise((resolve, reject) => {
      xhr.open('PUT', apiMethodUrl(false, "upload_write", {
        auth: opts.auth,
        uploadid: uploadId,
        uploadoffset: start,
        uploadsize: file.size,
      }), true);

      xhr.overrideMimeType('application/json');

      xhr.onload = () => {
        if (signal) {
          signal.removeEventListener('abort', abortHandler);
        }
        if (xhr.status === 200) {
          resolve();
        } else {
          reject(new Error('Upload failed with status: ' + xhr.status));
        }
      };

      xhr.onerror = () => {
        if (signal) {
          signal.removeEventListener('abort', abortHandler);
        }
        reject(new Error('Network error'));
      };

      xhr.send(chunk);
    });
  };

  while (start < file.size) {
    const chunk = file.slice(start, start + FILE_SIZE_FOR_CHUNKS);
    try {
      await uploadChunk(start, chunk);
      start += FILE_SIZE_FOR_CHUNKS;
      retryCount = 0;
      const progress = Math.round((start * 100) / file.size);
      if (isSingleFile) {
        onProgress(progress >= 100 ? 100 : progress);
      }
    } catch (_) {
      if (retryCount < MAX_FAILED_UPLOADS_RETRIES) {
        retryCount++;
        continue;
      } else {
        onUploadFailed(undefined, undefined, true);
        return;
      }
    }
  }

  try {
    const fileIdCreated = await saveUploadSessionWithRetry(MAX_FAILED_UPLOADS_RETRIES, uploadId, file.name, opts.folderid, opts.renameifexists);
    onUploadComplete({ fileIdCreated });
  } catch (errorCode) {
    onUploadFailed(undefined, errorCode, true);
  }
};

const createUploadSessionWithRetry = (maxAttempts) => {
  let count = 1;
  return new Promise((resolve, reject) => {
    const attempt = () => {
      createUploadSession().then(resolve).catch(async (errorCode) => {
        if (maxAttempts-- > 1 && !shouldNotRetryForError(errorCode)) {
          const waitTime = fibonacci(count) * 1000;
          count++;
          await delay(waitTime);
          attempt()
        } else {
          reject(errorCode);
        }
      });
    }
    attempt();
  });
}
const createUploadSession = () => {
  return new Promise((resolve, reject) => {
    apiMethod('upload_create', {}, function (ret) {
      if (ret.uploadid) {
        resolve(ret.uploadid);
      } else {
        reject(ret.result);
      }
    }, {
      errorCallback: (error) => {
        reject(error.result);
      },
      onXhrError: (error) => {
        reject(error.result);
      }
    });
  });
}

const saveUploadSession = (uploadId, fileName, folderid, renameifexists = 0) => {
  return new Promise((resolve, reject) => {
    apiMethod('upload_save', {
      uploadid: uploadId,
      name: fileName,
      renameifexists: renameifexists,
      folderid,
    }, (res) => {
      if (res.result === 0) {
        resolve(res.metadata.id);
      } else {
        reject(res.result);
      }
    }, {
      errorCallback: (error) => {
        reject(error.result);
      },
      onXhrError: (error) => {
        reject(error.result);
      }
    })
  })
}

const saveUploadSessionWithRetry = (maxAttempts, uploadId, fileName, folderid, renameifexists) => {
  let count = 1;
  return new Promise((resolve, reject) => {
    const attempt = () => {
      saveUploadSession(uploadId, fileName, folderid, renameifexists).then(resolve).catch(async (errorCode) => {
        if (maxAttempts-- > 1 && !shouldNotRetryForError(errorCode)) {
          const waitTime = fibonacci(count) * 1000;
          count++;
          await delay(waitTime);
          attempt()
        } else {
          reject(errorCode);
        }
      });
    }
    attempt();
  });
}

const findCryptoFolder = () => {
  return new Promise((resolve) => {
    apiMethod('crypto_getroot', {}, function (ret) {
      resolve(ret.metadata);
    }, {
      errorCallback: function () {
        pCloudCrypto.createCryptoFolder(function (folder) {
          resolve(folder);
        });
      }
    });
  })
}

const cryptoUpload = (file, onProgress, onUploadComplete, onUploadFailed, opts, signal, isSingleFile, onUploadAborted) => {
  let name = file.name
  if (opts.renameifexists) {
    name = uniqFilename(name, opts.folderid, false);
  }
  const encuploadid = pCloudCryptoUpload.uploadFile({
    file: file,
    folderid: opts.folderid,
    name: name,
    callbacks: {
      onBegin: function () { },
      onProgress: function (loaded, total) {
        if (isSingleFile) {
          onProgress((loaded / total) * 100);
        }
      },
      onFinish: function (ret) {
        if (signal) {
          signal.removeEventListener('abort', abortHandler);
        }
        onUploadComplete({ fileIdCreated: ret.metadata.id });
      },
      onError: function (error) {
        if (signal) {
          signal.removeEventListener('abort', abortHandler);
          if (signal.aborted) {
            return;
          }
        }

        let errorCode = null;
        if (error === 'Crypto subscription expired.') {
          errorCode = 2124;
        }

        onUploadFailed(0, errorCode);
      }
    }
  });

  const abortHandler = () => {
    onUploadAborted();
    pCloudCryptoUpload.abort(encuploadid);
  };

  if (signal) {
    signal.addEventListener('abort', abortHandler);
  }
}

export default {
  uploadFileToServer,
  getOrCreateFolder,
  listFolder,
  uploadFileToServerChunks,
  findCryptoFolder,
  cryptoUpload,
};