import React, { useRef, useState } from "react";

import PlayerContext from "./player-context";
import usePlayerBoxControl from "../hooks/usePlayerBoxControl";
import usePlayerOverlayControls from "../hooks/usePlayerOverlayControls";
import usePlayerThumbs from "../hooks/usePlayerThumbs";
import usePlayer from "../hooks/usePlayer";
import useWindowDimensions from "../../../lib/hooks/useWindowDimensions";
import { MOBILE_WIDTH_BREAKPOINT } from "../../../config/constants";

type Props = {
    data: Array<any>,
    isPublic: boolean
};

const PlayerContextProvider = (props: Props) => {
    const { bodyWidth } = useWindowDimensions();
    const isMobile = bodyWidth < MOBILE_WIDTH_BREAKPOINT;

    const [data] = useState(props.data ?? null);
    const [isPublic] = useState(props.isPublic ?? false);

    const playerBoxRef = useRef();
    const backDropOverlayRef = useRef();
    const playerBackgroundRef = useRef();
    const headerRef = useRef();
    const boxPlayerRef = useRef();

    let objContext = {
        data,
        isPublic,
        isMobile,
        playerBoxRef,
        headerRef,
        boxPlayerRef,
        playerBackgroundRef,
        backDropOverlayRef
    };

    // Import player functionality
    const playerControls = usePlayer(props);
    objContext = Object.assign(objContext, playerControls);

    // Import box state controls functionality
    const playerBoxControl = usePlayerBoxControl({
        isPublic,
        isMobile,
        action: props.action,
        random: props.random,
        queue: playerControls.queue,
        audioRef: playerControls.audioRef,
        restoreState: props.restoreState ?? false
    });
    objContext = Object.assign(objContext, playerBoxControl);

    // Import overlay controls functionality
    const playerOverlayControls = usePlayerOverlayControls({
        playerVariant: objContext.playerVariant
    });
    objContext = Object.assign(objContext, playerOverlayControls);

    // Import Thumbs functionality
    const thumbsControls = usePlayerThumbs({
        queue: playerControls.queue,
        isPublic
    });
    objContext = Object.assign(objContext, thumbsControls);

    return (
        <PlayerContext.Provider value={objContext}>
            {props.children}
        </PlayerContext.Provider>
    );
};

export default PlayerContextProvider;
