// @flow
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Componentify from "react-componentify";

import * as Style from "./preferences.style";
import { __ } from "../../lib/translate";
import { brTagConverter } from "../../lib/componentifyConverters";
import { apiMethod } from "../../api";
import { loadScript } from "@pcloud/web-utilities/dist/lib/utils";
import Modal, { OvalModalCloseV2, OvalModalWrap } from "../Modal";
import ButtonDefault from "../ButtonDefault";

const texts = {
  facebook: __("Facebook Account"),
  google: __("Google Account"),
  apple: __("Apple Account")
}

const LoginAccountsSetting = () => {
  const [unlinkModal, setUnlinkModal] = useState(null);
  const [unlinkLoading, setUnlinkLoading] = useState(false);
  const [loginAccounts, setLoginAccounts] = useState(new Map([
    [
      "facebook", {
        id: 1,
        loading: true,
        linked: false,
        account: null
      }
    ],
    [
      "google", {
        id: 2,
        loading: true,
        linked: false,
        account: null
      }
    ],
    [
      "apple", {
        id: 3,
        loading: true,
        linked: false,
        account: null
      }
    ]
  ]))

  useEffect(() => {
    loadAppleScript();
    initialDataFetch();
  }, []);

  const loadAppleScript = () => {
    loadScript("https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js", () => {
      APPLELogin.init(true);
    });
  }

  const facebookLoginInfo = () => {
    return new Promise((resolve, reject) => {
      apiMethod("fb_login_info", {}, (response) => resolve(response), {errorCallback: (error) => resolve(error)});
    });
  }

  const googleLoginInfo = () => {
    return new Promise((resolve, reject) => {
      apiMethod("gl_login_info", {}, (response) => resolve(response), {errorCallback: (error) => resolve(error)});
    });
  }

  const appleLoginInfo = () => {
    return new Promise((resolve, reject) => {
      apiMethod("apple_login_info", {}, (response) => resolve(response), {errorCallback: (error) => resolve(error)});
    })
  }

  const initialDataFetch = () => {
    const facebookPromise = facebookLoginInfo();
    const googlePrimise = googleLoginInfo();
    const applePromise = appleLoginInfo();
    const promises = [facebookPromise, googlePrimise, applePromise];

    Promise.all(promises)
      .then((values) => {
        const facebookResponse = values[0];
        const googleResponse = values[1];
        const appleResponse = values[2];

        const newLoginAccounts = new Map([...loginAccounts]);

        if (facebookResponse.result === 0) {
          const facebookloginData = newLoginAccounts.get("facebook");
          facebookloginData.linked = true;
          facebookloginData.loading = false;
          facebookloginData.account = `${facebookResponse.firstname} ${facebookResponse.lastname}`;
          newLoginAccounts.set("facebook", facebookloginData);
        }

        if (googleResponse.result === 0) {
          const googleloginData = newLoginAccounts.get("google");
          googleloginData.linked = true;
          googleloginData.loading = false;
          googleloginData.account = `${googleResponse.firstname} ${googleResponse.lastname}`;
          newLoginAccounts.set("google", googleloginData);
        }

        if (appleResponse.result === 0 ) {
          const appleloginData = newLoginAccounts.get("apple");
          appleloginData.linked = true;
          appleloginData.loading = false;
          appleloginData.account = appleResponse.email;
          newLoginAccounts.set("apple", appleloginData);
        }

        setLoginAccounts(newLoginAccounts);
      })
  };

  const linkFacebookAccount = () => {
    FBLogin.getTokenCallback(token => {
      apiMethod('fb_login_assign', { fb_access_token: token }, ret => {
        HFN.message(__('Account linked.'));
        facebookLoginInfo().then(response => {
          const newLoginAccounts = new Map([...loginAccounts]);

          if (response.result === 0) {
            const facebookloginData = newLoginAccounts.get("facebook");
            facebookloginData.linked = true;
            facebookloginData.loading = false;
            facebookloginData.account = `${response.firstname} ${response.lastname}`;
            newLoginAccounts.set("facebook", facebookloginData);
          }

          setLoginAccounts(newLoginAccounts);
        })
      }, {
        errorCallback: function(ret) {
          HFN.message(__("error_facebook_login_could_not_be_linked"), "error");
          FBLogin.deletePermissions(token);
        }
      });
    });
  };

  const linkGoogleAccount = () => {
    GLLogin.getTokenCallback(token => {
      apiMethod('gl_login_assign', { gl_access_token: token }, ret => {
        HFN.message(__('Account linked.'));
        googleLoginInfo().then(response => {
          const newLoginAccounts = new Map([...loginAccounts]);

          if (response.result === 0) {
            const googleloginData = newLoginAccounts.get("google");
            googleloginData.linked = true;
            googleloginData.loading = false;
            googleloginData.account = `${response.firstname} ${response.lastname}`;
            newLoginAccounts.set("google", googleloginData);
          }

          setLoginAccounts(newLoginAccounts);
        })
      }, {
        errorCallback: function (ret) {
          if (ret.result === 2315) {
            HFN.message(__("error_google_login_already_linked"), "error");
          } else {
            HFN.message(__("error_google_login_could_not_be_linked"), "error");
          }
          GLLogin.deletePermissions(token);
        }
      });
    });
  };

  const linkAppleAccount = () => {
    const data = APPLELogin.signIn();

    data.then(result => {
      const token = result.authorization.id_token;

      apiMethod('apple_login_assign', { id_token: token }, ret => {
        HFN.message(__('Account linked.'));
        appleLoginInfo().then(response => {
          const newLoginAccounts = new Map([...loginAccounts]);

          if (response.result === 0) {
            const appleloginData = newLoginAccounts.get("apple");
            appleloginData.linked = true;
            appleloginData.loading = false;
            appleloginData.account = response.email;
            newLoginAccounts.set("apple", appleloginData);
          }

          setLoginAccounts(newLoginAccounts);
        })
      }, {
        errorCallback: function (ret) {
          if (ret.result === 2315) {
            HFN.message(__("error_apple_login_already_linked"), "error");
          } else {
            HFN.message(__("error_apple_login_could_not_be_linked"), "error");
          }
        }
      });
    });
  };

  const resetAccountOnUnlink = type => {
    const newLoginAccounts = new Map([...loginAccounts]);
    const loginData = newLoginAccounts.get(type);
    loginData.linked = false;
    loginData.loading = false;
    loginData.account = null;
    newLoginAccounts.set(type, loginData);
    setcookie("logininfo", "");
    setLoginAccounts(newLoginAccounts);
  }

  const unlinkFacebookAccount = () => {
    apiMethod('fb_login_unassign', {}, response => {
      if (response.result === 0) {
        setUnlinkLoading(true);
        resetAccountOnUnlink("facebook");
        HFN.message(__('Link removed.'));
        setUnlinkLoading(false);
        setUnlinkModal(null);
      }
    }, { errorCallback: () => {} });
  };

  const unlinkGoogleAccount = () => {
    apiMethod('gl_login_unassign', {}, response => {
      if (response.result === 0) {
        setUnlinkLoading(true);
        resetAccountOnUnlink("google");
        HFN.message(__('Link removed.'));
        setUnlinkLoading(false);
        setUnlinkModal(null);
      }
    }, { errorCallback: () => {} });
  };

  const unlinkAppleAccount = () => {
    apiMethod('apple_login_unassign', {}, response => {
      if (response.result === 0) {
        setUnlinkLoading(true);
        resetAccountOnUnlink("apple");
        HFN.message(__('Link removed.'));
        setUnlinkLoading(false);
        setUnlinkModal(null);
      }
    }, { errorCallback: () => {} });
  };

  const unlink = type => {
    switch (type) {
      case "facebook":
        unlinkFacebookAccount()
        break;
      case "google":
        unlinkGoogleAccount()
        break;
      case "apple":
        unlinkAppleAccount()
        break;
      default:
        break;
    }
  };

  const onLinkClick = type => {
    switch (type) {
      case "facebook":
        linkFacebookAccount()
        break;
      case "google":
        linkGoogleAccount()
        break;
      case "apple":
        linkAppleAccount()
        break;
      default:
        break;
    }
  };


  const onUnlinkClick = (type) => {
    setUnlinkModal(type)
  };

  const onModalCloseClick = () => {
    if (unlinkLoading) {
      return;
    }

    setUnlinkModal(null);
  };

  const renderTableData = () => {
    const render = [];

    loginAccounts.forEach((value, key) => {
      render.push(
        <Style.BodyRow key={`row-${key}`}>
          <Style.BodyCol key={`col-0-${key}`}>{texts[key]}</Style.BodyCol>
          <Style.BodyCol key={`col-1-${key}`}>{value.account || "-"}</Style.BodyCol>
          <Style.BodyCol key={`col-2-${key}`}>
            <Style.Button key={`but-${key}`} onClick={() => value.linked ? onUnlinkClick(key) : onLinkClick(key)}>{value.linked ? __("Unlink") : __("settings_accounts_link", "Link")}</Style.Button>
          </Style.BodyCol>
        </Style.BodyRow>
      )
    })

    return render;
  }

  const renderTableContent = () => {
    return (
      <Style.TableWrapper>
        <thead>
          <Style.HeaderRow>
            <Style.HeaderCol style={{ width: "25%" }}> {__("App Name")}</Style.HeaderCol>
            <Style.HeaderCol style={{ width: "50%" }}> {__("Account")}</Style.HeaderCol>
            <Style.HeaderCol align="right" style={{ width: "25%" }}> {__("family_member_list_col_action", "Action")}</Style.HeaderCol>
          </Style.HeaderRow>
        </thead>
        <tbody>
          {renderTableData()}
        </tbody>
      </Style.TableWrapper>
    )
  };

  const renderUnlinkModal = () => (
    <Modal opened={unlinkModal}>
      <ModalContainer>
        <OvalModalCloseV2 onClick={onModalCloseClick} />
        <Style.Container>
          <ModalTitle>{__("unlink_account_header", "Unlink Account")}</ModalTitle>
          <ModalDescription>
            <Componentify
              text={__("unlink_account_message")}
              converters={[brTagConverter]}
            />
          </ModalDescription>
          <ButtonWrapper>
            <ButtonDefault
              color="lightgray4"
              onClick={onModalCloseClick}
              disabled={unlinkLoading}
            >
              {__("Close")}
            </ButtonDefault>
            <ButtonDefault
              color="red"
              loading={unlinkLoading}
              disabled={unlinkLoading}
              disabledColor="#dddddd"
              onClick={() => unlink(unlinkModal)}
            >
              {__("Continue")}
            </ButtonDefault>
          </ButtonWrapper>
        </Style.Container>
      </ModalContainer>
    </Modal>
  )
  
  return (
    <>
      <Style.Row>
        <Style.Title>{__("login", "Login")}</Style.Title>
      </Style.Row>
      {renderTableContent()}
      {renderUnlinkModal()}
    </>
  )
};

export default LoginAccountsSetting;

const ModalContainer = styled.div`
  text-align: center;
  border-radius: var(--radius-lg);
  box-shadow: rgba(0, 0, 0, 0.25) 3px 3px 5px;
  padding: var(--spacing-xl);
  background: var(--color-base-white);
  max-width: 560px;
  width: 480px;
  min-width: 425px;
  min-height: auto;
  color: var(--color-base-black);
  box-sizing: border-box;
`;

const ModalTitle = styled.div`
  font-size: var(--font-size-18);
  font-weight: 700;
  line-height: 20px;
  margin-bottom: var(--spacing-md);
  color: var(--color-base-black);
`;

const ModalDescription = styled.div`
  font-size: var(--font-size-14);
  color: var(--color-base-black);
  padding: 0px var(--spacing-md);
  text-align: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
  margin-top: var(--spacing-xl);
  gap: var(--spacing-md);
  box-sizing: border-box;

  a {
    width: 50%;
  }
`;
