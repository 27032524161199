// @flow

import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import apiMethod from "../../api/apiMethod";
import errors from "../../lib/errors";
import { __ } from "../../lib/translate";
import { setPassword } from "../../lib/state/actions/user";
import { renderSentEmail } from "./SentEmailStep";

import { ButtonCentered } from "../../components/ButtonDefault";
import { Container, Header, Message, InputPass, ErrorMessage, Link } from "./SharedComponents";
import InputPassword from "../../components/InputPassword";

type Props = {
  token: string,
  email: string,
  onSuccess: () => void,
  setPassword: string => void
};

type State = {
  password: string,
  hasForgottenPass: boolean,
  showErrorMessage: boolean,
  errorMessage: string,
  isLoading: boolean
};

class RequirePasswordStep extends Component<Props, State> {
  static defaultProps = {
    token: "",
    email: "",
    onSuccess: () => {},
    setPassword: () => {}
  };

  constructor(props: Props) {
    super(props);

    (this: any).onSubmit = (this: any).onSubmit.bind(this);
    (this: any).onEnter = (this: any).onEnter.bind(this);
    (this: any).onPassChange = (this: any).onPassChange.bind(this);
    (this: any).onForgottenPassClick = (this: any).onForgottenPassClick.bind(this);

    this.state = {
      password: "",
      hasForgottenPass: false,
      showErrorMessage: false,
      errorMessage: "",
      isLoading: false
    };
  }

  onPassChange(e: any) {
    this.setState({ password: e.target.value, showErrorMessage: false });
  }

  onForgottenPassClick() {
    const { token, email } = this.props;

    apiMethod(
      "lostpassword",
      {
        auth: token,
        mail: email
      },
      () => {
        this.setState({ hasForgottenPass: true });
      },
      {
        errorCallback: ({ error }) => {
          HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
          throw new Error(error);
        }
      }
    );
  }

  onSubmit() {
    const { password, isLoading } = this.state;
    const { token, onSuccess, setPassword } = this.props;

    if (isLoading) {
      return;
    }

    if (!password) {
      this.setState({
        errorMessage: __("Password is required"),
        showErrorMessage: true
      });
      return;
    }

    this.setState({ isLoading: true });

    apiMethod(
      "verifypassword",
      {
        auth: token,
        password
      },
      () => {
        this.setState({ isLoading: false });
        setPassword(password);
        onSuccess();
      },
      {
        errorCallback: ({ result, error }) => {
          let errorMessage = "";
          if (errors[result]) {
            errorMessage = errors[result];
          } else {
            errorMessage = __("something_went_wrong_refresh_and_try_again");
          }

          if (result === 2272) {
            errorMessage = __("Incorrect password", "Incorrect password");
          }

          this.setState({
            errorMessage,
            showErrorMessage: true,
            isLoading: false
          });

          if (!errors[result]) {
            throw new Error(error);
          }
        }
      }
    );
  }

  onEnter(e: any) {
    if (e.keyCode === 13) {
      this.onSubmit();
    }
  }

  renderRequirePass() {
    const { password, showErrorMessage, errorMessage, isLoading } = this.state;

    return (
      <Container>
        <Header>{__("Password required")}</Header>
        <p style={{ padding: "0" }}>{__("tfa_setup_require_pass_subheading")}</p>
        <Message className="require-pass">
          <ErrorMessage show={showErrorMessage}>{errorMessage}</ErrorMessage>
          <InputPassword
            name="pass"
            type="password"
            value={password}
            placeholder={__("Password")}
            autoFocus
            onChange={this.onPassChange}
            onKeyUp={this.onEnter}
            shouldRenderRedBorder={showErrorMessage}
            revealPassword={true}
            InputComponent={InputPass}
          />
        </Message>
        <ButtonCentered
          style={{ width: "100%" }}
          disabled={isLoading || !password}
          loading={isLoading}
          onClick={this.onSubmit}
        >
          {__("Continue")}
        </ButtonCentered>
        <Link style={{ marginTop: "26px" }} onClick={this.onForgottenPassClick}>
          {__("Forgot your password?")}
        </Link>
      </Container>
    );
  }

  render() {
    const { hasForgottenPass } = this.state;
    const { email } = this.props;

    return (
      <React.Fragment>
        {hasForgottenPass ? renderSentEmail(email) : this.renderRequirePass()}
      </React.Fragment>
    );
  }
}

export default connect(
  ({ user }) => {
    const { token, userinfo: { email } = {} } = user;

    return { token, email };
  },
  dispatch =>
    bindActionCreators(
      {
        setPassword
      },
      dispatch
    )
)(RequirePasswordStep);
