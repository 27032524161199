import React, { useEffect, useState } from "react";
import usePlayerContext from "../../../hooks/usePlayerContext";
import {
    DivWrapper,
    FlexWrapper,
    ProgressBarInputWrapper,
    ProgressBarSongTimeWrapper,
    ProgressBarWrapper
} from "../../../userinterface/styles";
import { motion } from "framer-motion";
import styled from "styled-components";

const ProgressBar = ({
    showBottomTimes = false,
    showMiddleTimes = false,
    showPointer = false
}) => {
    const {
        audioRef,
        isPlaying,
        duration,
        isMobile,
        additionalClass,
        playerVariant
    } = usePlayerContext();

    const [currentProgress, setCurrentProgress] = useState(0);
    const [buffered, setBuffered] = useState(0);

    useEffect(() => {
        let interval;

        if (isPlaying) {
            interval = setInterval(() => {
                setCurrentProgress(audioRef.current?.currentTime ?? 0);
                onBufferUpdate();
            }, 200);
        } else {
            setCurrentProgress(audioRef.current?.currentTime ?? 0);
        }

        return () => clearInterval(interval);
    }, [duration, isPlaying]);

    const onBufferUpdate = () => {
        if (!audioRef.current) return;

        const audio = audioRef.current;
        const duration = audio.duration;
        if (duration > 0) {
            for (let i = 0; i < audio.buffered.length; i++) {
                if (
                    audio.buffered.start(audio.buffered.length - 1 - i) <
                    audio.currentTime
                ) {
                    const bufferedLength = audio.buffered.end(
                        audio.buffered.length - 1 - i
                    );
                    setBuffered(bufferedLength);
                    break;
                }
            }
        }
    };

    const onProgressBarUpdate = (e) => {
        if (!audioRef.current) return;
        audioRef.current.currentTime = e.currentTarget.valueAsNumber;
        setCurrentProgress(e.currentTarget.valueAsNumber);
        onBufferUpdate();
    };

    const progressBarWidth = isNaN(currentProgress / duration)
        ? 0
        : currentProgress / duration;
    const bufferedWidth = isNaN(buffered / duration) ? 0 : buffered / duration;

    const progressStyles = {
        "--progress-width":
            duration !== Infinity && duration > 0
                ? Math.min(progressBarWidth, 1)
                : 0,
        "--buffered-width":
            duration !== Infinity && duration > 0
                ? Math.min(bufferedWidth, 1)
                : 0
    };

    const getTime = (time) => {
        if (!time || isNaN(time) || time == Infinity) {
            return "00:00";
        } else {
            return HFN.formatTime(parseInt(time), false, true);
        }
    };

    return (
        <ProgressMotion>
            <DivWrapper
                className="progress-bar"
                animate={{
                    opacity: duration !== Infinity && duration > 0 ? 1 : 0
                }}
                transition={{
                    duration: 0.25,
                    delay: playerVariant.isMinimize ? 0.4 : 0
                }}
            >
                <FlexWrapper>
                    {showMiddleTimes && (
                        <ProgressBarSongTimeWrapper>
                            {getTime(currentProgress)}
                        </ProgressBarSongTimeWrapper>
                    )}
                    <ProgressBarWrapper className={additionalClass}>
                        <ProgressBarInputWrapper
                            type="range"
                            name="progress"
                            min={0}
                            step={0.01}
                            max={duration}
                            value={
                                duration !== Infinity && duration > 0
                                    ? currentProgress
                                    : 0
                            }
                            style={progressStyles}
                            onChange={onProgressBarUpdate}
                            $showPointer={showPointer}
                            className={additionalClass}
                        />
                    </ProgressBarWrapper>
                    {showMiddleTimes && (
                        <ProgressBarSongTimeWrapper>
                            {getTime(duration)}
                        </ProgressBarSongTimeWrapper>
                    )}
                </FlexWrapper>
                <FlexWrapper>
                    {showBottomTimes && (
                        <ProgressBarSongTimeWrapper
                            style={{
                                marginTop: 3
                            }}
                        >
                            {getTime(currentProgress)}
                        </ProgressBarSongTimeWrapper>
                    )}
                    {showBottomTimes && (
                        <ProgressBarSongTimeWrapper
                            style={{
                                marginTop: 3,
                                textAlign: "right"
                            }}
                        >
                            {getTime(duration)}
                        </ProgressBarSongTimeWrapper>
                    )}
                </FlexWrapper>
            </DivWrapper>
        </ProgressMotion>
    );
};

export default ProgressBar;

const ProgressMotion = styled(motion.div)`
    width: 100%;
`;
