//  @flow

import React from "react";
import { useSelector } from "react-redux";

import { __ } from "../../lib/translate";

import InviteViaEmail from "../../components/InviteViaEmail";
import { SettingsWrapper, SettingsHeader, SettingsTitle } from "./SharedStyledComponents";
import TopBar from "./TopBar";

type Props = {
  allSettingsBack: any,
  bodyHeight: number,
  parentRef: any
};

const ShareInviteEmailSetting = ({ allSettingsBack, bodyHeight, parentRef, currentSettingId }: Props) => {
  const linkData = useSelector(({ sharedLinks }) => sharedLinks.linkData);
  const { code, shortLinkUrl: shortlink } = linkData;

  return (
    <SettingsWrapper bodyHeight={bodyHeight} ref={parentRef}>
      <TopBar allSettingsBack={allSettingsBack} settingId={currentSettingId} />
      <SettingsHeader>
        <SettingsTitle margin>{__("send_link_email", "Send link via email")}</SettingsTitle>
      </SettingsHeader>
      <InviteViaEmail
        formTitle={__("share_folder_modal_send_to", "Send to")}
        apiCall="sendpublink"
        opts={{ code, shortlink }}
        shouldRenderTextarea
        type={1}
      />
    </SettingsWrapper>
  );
};

export default ShareInviteEmailSetting;
