import React from "react";
import { ContentHeader, ContentText } from "../styles";
import { __ } from "../../../../lib/translate";
import styled from "styled-components";
import { boldConverter } from "../../../../lib/componentifyConverters";
import Componentify from "react-componentify";

const UploadProgressContent = () => {
    return (
        <Wrapper>
            <ContentHeader>{__("tutorial_title_upload_progress", "Manage your uploads")}</ContentHeader>
            <ContentText>
                <Componentify
                    text={__(
                        "tutorial_message_upload_progress",
                        "Manage uploads by checking status or canceling in the Upload manager. Stay organized and informed!"
                    )}
                    converters={[boldConverter]}
                />
            </ContentText>
        </Wrapper>
    );
};

export default UploadProgressContent;

const Wrapper = styled.div`
    width: 280px;
`;
