import React, { useState } from "react";
import styled from "styled-components";
import Componentify from "react-componentify";

import FileThumb from "../../../../FileThumb";
import FileIcon from "../../../../FileIcon";
import { ICONS } from "../../../../../lib/icons";
import popOver from "../../../../../lib/popOver";
import { boldConverter } from "../../../../../lib/componentifyConverters";
import ToLocalTime from "../../../../ToLocalTime";

import type { Notification as NotificationType } from "../../../../../lib/notifications/types";

function Notification({
    style,
    rowRef,
    notification: { iconid, notification, mtime, isnew, thumb, url }
}: {
    notification: NotificationType
}) {
    const [isThumbFailed, setIsThumbFailed] = useState(false);
    return (
        <NotificationWrap
            ref={rowRef}
            style={style}
            href={url}
            onClick={() => {
                popOver.closeAll();
            }}
        >
            {isnew && <NewNotificationDot />}
            {thumb && !isThumbFailed ? (
                <FileThumb
                    type={ICONS.SVG_NOTIFICATIONS}
                    thumb={thumb}
                    onError={() => {
                        setIsThumbFailed(true);
                    }}
                />
            ) : (
                <FileIcon item={iconid} type={ICONS.SVG_NOTIFICATIONS} />
            )}
            <NotificationBody>
                <Componentify
                    text={notification}
                    converters={[boldConverter]}
                />
                <NotificationDate>
                    <ToLocalTime time={mtime} />
                </NotificationDate>
            </NotificationBody>
        </NotificationWrap>
    );
}

export default Notification;

const NotificationWrap = styled.a`
    min-height: 36px;
    font-size: 12px;
    padding: var(--spacing-sm) var(--spacing-md) 0;
    background: ${(props) => (props.isnew ? "#fff" : "#fff")};
    cursor: pointer;
    text-decoration: none;
    position: relative;
    display: flex;
    gap: var(--spacing-md);
    box-sizing: border-box;

    & > .iconwrap {
        margin: 0;

        & > img {
            border-radius: var(--radius-minimum);
        }
    }

    &:hover {
        background-color: #f3fdfe;
    }
`;

const NewNotificationDot = styled.div`
    border-radius: var(--radius-full);
    background: var(--text-danger);
    width: 6px;
    height: 6px;
    position: absolute;
    left: 5px;
    top: 21px;
`;

const NotificationBody = styled.div`
    display: inline-block;
    width: 100%;
    height: 100%;
    word-break: break-word;
    word-wrap: break-word;
    hyphens: auto;
    -ms-hyphens: auto;
    align-self: stretch;

    color: var(--text-primary);
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-bottom: 1px solid var(--divider-secondary);
    padding-bottom: var(--spacing-sm);

    & b {
        font-weight: 700;
    }
`;

const NotificationDate = styled.span`
    display: block;
    align-self: stretch;
    color: var(--text-secondary);
    font-family: Roboto, sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-top: 2px;
`;
