import React from "react";
import styled, { keyframes } from "styled-components";
import CryptoLockedImg from "../../../root/img/crypto/crypto-load.svg";
import CryptoSpinnerImg from "../../../root/img/crypto/crypto-spinner.svg";

const CryptoLoader = ({ title = "", message = "" }) => {
    return (
        <Wrapper>
            <Content>
                <Loader>
                    <CryptoLockedImg />
                    <CryptoSpinnerImg className={"spinner"} />
                </Loader>
                {title && <Title>{title}</Title>}
                {message && <Message>{message}</Message>}
            </Content>
        </Wrapper>
    );
};

export default CryptoLoader;

const Wrapper = styled.div`
    margin-top: 120px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 500px) {
        margin-top: 60px;
    }
`;

const Content = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 16px;
`;

const Title = styled.div`
    color: var(--text-primary);
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
`;

const Message = styled.div`
    color: var(--text-primary);
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
`;

// Skeleton base
const loading = keyframes`
    100% {
        transform: rotate(360deg);
    }
`;

const Loader = styled.div`
    position: relative;
    & .spinner {
        position: absolute;
        top: 0;
        left: 0;
        animation: ${loading} 1s linear infinite;
    }
`;
