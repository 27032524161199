import React, { PureComponent } from "react";
import styled from "styled-components";

import { __ } from "../../../../../lib/translate";
import HoverMenu from "../../../UI/HoverMenu";
import FileIcon from "../../../../FileIcon";

type Props = {
    shouldSerchInDocs: boolean,
    selectedItemId: number,
    onItemSelect: (number) => void
};

class SearchFilterDropdown extends PureComponent<Props, {}> {
    static defaultProps = {
        shouldSerchInDocs: false,
        selectedItemId: 0,
        onItemSelect: () => {},
        onOpen: () => {},
        onClose: () => {}
    };

    constructor(props: Props) {
        super(props);
        (this: any).onItemClick = (this: any).onItemClick.bind(this);
        (this: any).renderFilterButton = (this: any).renderFilterButton.bind(
            this
        );
    }

    getDropDownList(selectedItemId = null) {
        return [
            {
                label: ["All Files", "All Files"],
                params: [],
                iconComponent: (
                    <FileIcon
                        item={HFN.CATEGORY.UNKNOWN}
                        type={HFN.ICONS.SVG_DARKER_DROPDOWN}
                    />
                ),
                id: HFN.CATEGORY.UNKNOWN,
                onClick: () => this.onItemClick(HFN.CATEGORY.UNKNOWN),
                active: selectedItemId === HFN.CATEGORY.UNKNOWN
            },
            {
                label: ["Images", "Images"],
                params: [],
                iconComponent: (
                    <FileIcon
                        item={HFN.CATEGORY.IMAGE}
                        type={HFN.ICONS.SVG_DARKER_DROPDOWN}
                    />
                ),
                id: HFN.CATEGORY.IMAGE,
                onClick: () => this.onItemClick(HFN.CATEGORY.IMAGE),
                active: selectedItemId === HFN.CATEGORY.IMAGE
            },
            {
                label: ["Audio", "Audio"],
                params: [],
                iconComponent: (
                    <FileIcon
                        item={HFN.CATEGORY.AUDIO}
                        type={HFN.ICONS.SVG_DARKER_DROPDOWN}
                    />
                ),
                id: HFN.CATEGORY.AUDIO,
                onClick: () => this.onItemClick(HFN.CATEGORY.AUDIO),
                active: selectedItemId === HFN.CATEGORY.AUDIO
            },
            {
                label: ["Video", "Video"],
                params: [],
                iconComponent: (
                    <FileIcon
                        item={HFN.CATEGORY.VIDEO}
                        type={HFN.ICONS.SVG_DARKER_DROPDOWN}
                    />
                ),
                id: HFN.CATEGORY.VIDEO,
                onClick: () => this.onItemClick(HFN.CATEGORY.VIDEO),
                active: selectedItemId === HFN.CATEGORY.VIDEO
            },
            {
                label: ["Documents", "Documents"],
                params: [],
                iconComponent: (
                    <FileIcon
                        item={HFN.CATEGORY.DOCUMENT}
                        type={HFN.ICONS.SVG_DARKER_DROPDOWN}
                    />
                ),
                id: HFN.CATEGORY.DOCUMENT,
                onClick: () => this.onItemClick(HFN.CATEGORY.DOCUMENT),
                active: selectedItemId === HFN.CATEGORY.DOCUMENT
            },
            {
                label: ["Archives", "Archives"],
                params: [],
                iconComponent: (
                    <FileIcon
                        item={HFN.CATEGORY.ARCHIVE}
                        type={HFN.ICONS.SVG_DARKER_DROPDOWN}
                    />
                ),
                id: HFN.CATEGORY.ARCHIVE,
                onClick: () => this.onItemClick(HFN.CATEGORY.ARCHIVE),
                active: selectedItemId === HFN.CATEGORY.ARCHIVE
            }
        ];
    }

    onItemClick(id) {
        const { onItemSelect } = this.props;

        onItemSelect(id);
    }

    renderFilterButton = () => {
        const list = this.getDropDownList();
        const { selectedItemId } = this.props;
        const selectedIndex = list.findIndex((item) => {
            return selectedItemId === item.id;
        });
        return (
            <Filter>
                <FilterName>{__(...list[selectedIndex].label)}</FilterName>
            </Filter>
        );
    };

    render() {
        const { selectedItemId, onOpen, onClose } = this.props;
        const list = this.getDropDownList(selectedItemId);
        const iconSetup = {
            open: (
                <i
                    className="smallIcon fa-light fa-chevron-up"
                    style={{ color: "var(--text-secondary)" }}
                />
            ),
            close: (
                <i
                    className="smallIcon  fa-light fa-chevron-down"
                    style={{ color: "var(--text-secondary)" }}
                />
            )
        };

        return (
            <HoverMenu
                triggerOnClick={true}
                align={"end"}
                arrow={false}
                menuComponent={this.renderFilterButton()}
                menuItems={list}
                iconSetup={iconSetup}
                onOpen={onOpen}
                onClose={onClose}
            />
        );
    }
}

export default SearchFilterDropdown;

const Filter = styled.div`
    height: 24px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: var(--spacing-xs);
`;
const FilterName = styled.span`
    color: var(--text-secondary);
    text-align: right;
    font-size: var(--base-font-size);
    font-weight: 400;
    line-height: 24px;
`;
