import React from "react";
import styled from "styled-components";

const NewNotificationDot = styled.div`
    border-radius: var(--radius-full);
    background: var(--text-danger);
    width: 6px;
    height: 6px;
    position: absolute;
    right: 12px;
    top: 12px;
    border: 1px solid #f0f4f5;

    @media (max-width: 1023px) {
        border-color: #fff;
    }
`;

function NotificationsNew() {
    return <NewNotificationDot />;
}

export default NotificationsNew;
