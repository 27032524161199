// @flow

import styled, { keyframes } from "styled-components";

export const PreferencesAlign = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 0 var(--spacing-lg) 0;
  max-width: 1140px;
`;

export const PreferencesContent = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
`;

export const Headline = styled.div`
  padding: 0 7px 22px 7px;
  border-bottom: var(--border-size) solid var(--border-primary);
  font-size: 24px;
  font-weight: 600;
  line-height: 1.21;
  color: var(--color-base-black);
`;

export const Container = styled.div`
  position: relative;

  &.security-container {
    padding-bottom: 100px;
  }

  &.lang-wrapper {
    padding-bottom: 23px;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;
`;

export const Row = styled.div`
  width: 100%;
  min-height: 68px;
  padding: var(--spacing-md) var(--spacing-sm);
  box-sizing: border-box;
  display: flex;
  gap: var(--spacing-md);
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-base-white);
  border-bottom: var(--border-size) solid var(--border-secondary);
  position: relative;
  white-space: break-spaces;
`;

export const Icon = styled.div`
  width: 32px;
  height: 32px;
  margin-right: 10px;
  border-radius: 50%;
  background-color: var(--color-primary-700);
  box-sizing: border-box;
  font-size: var(--icon-base);
  font-weight: 400;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const User = styled.div`
  font-size: 15px;
  font-weight: 500;
  color: var(--color-base-black);
  overflow: hidden;

  @media (max-width: 768px) {
    font-size: var(--font-size-14);
  }
`;

export const Title = styled.div`
  width: 100%;
  font-size: 15px;
  font-weight: 500;
  color: var(--color-base-black);

  @media (max-width: 768px) {
    font-size: var(--font-size-14);
  }
`;

export const Description = styled.div`
  font-size: 15px;
  color: var(--color-base-black);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  gap: var(--spacing-xs);
  margin-top: var(--spacing-sm);

  @media (max-width: 768px) {
    font-size: var(--font-size-13);
  }
`;

export const Button = styled.div`
  font-size: var(--font-size-14);
  font-weight: normal;
  text-align: right;
  color: var(--color-primary-500);
  cursor: pointer;
  flex-shrink: 0;

  i {
    margin-left: var(--spacing-sm);
    font-size: var(--icon-base);
    position: relative;
    top: 1px;
  }

  &:disabled {
    color: var(--color-grey-600);
  }
`;

export const Link = styled.a`
  font-size: var(--font-size-14);
  font-weight: normal;
  text-align: right;
  color: var(--color-primary-500);
  text-decoration: none;
  /* width: max-content;
  flex-grow: 1;
  flex-shrink: 0; */
  flex-shrink: 0;
  cursor: pointer;

  &[disabled] {
    color: var(--color-grey-500);
    cursor: default;
  }

  &.link-with-label-modal {
    max-width: 346px;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: bottom;
    line-height: 19px;
  }
`;

export const RightButton = styled(Button)`
  width: 30%;
`;


const loaderSpin = keyframes`
  0% {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
`;

export const ArrowButton = styled(Button)`
.arrow-button-text {
  padding-right: 4px;
}`;

export const Arrow = styled.span`
  position: relative;
  top: 12px;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #746de4;
`;

export const ExpandListButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  &.disabled-btn {
    color: initial;
    cursor: default;
  }

  svg {
    transition: transform .5s;
    &.arrow-up {
      transform: rotate(180deg);
    }
  }
`;

export const LoaderWrapper = styled.div`
  animation-name: ${loaderSpin};
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  width: ${({width}) => width || "8px"};
  height: ${({height}) => height || "8px"};
  display: inline-block;
  svg {
    display: block;

    path:last-child {
      fill: #746de4;
    }
  }
`;

export const RedButton = styled(Button)`
  color: var(--color-functional-danger500);
`;

export const Separator = styled.div`
  width: 100%;
  height: 8px;
  background-color: #fafafb;
`;

export const BtnSeparator = styled.div`
  width: 4px;
  height: 4px;
  margin: 0 10px;
  background-color: #999;
  border-radius: 50%;
`;

export const TableTitle = styled.div`
  background-color: #fafafb;
  color: var(--color-base-black);
  opacity: 0.5;
  font-size: var(--font-size-13);
  font-weight: 500;
  padding: 8px 0 9px 10px;
  /* border-top: 1px solid #e1e1e1; */
`;

export const TableWrapper = styled.table`
  width: 100%;
  text-align: left;
  border-collapse: collapse;
  padding: 0 10px;
`;

export const HeaderRow = styled.tr`
  height: 32px;
  background-color: #fafafb;
`;

export const HeaderCol = styled.th`
  opacity: 0.5;
  font-size: var(--font-size-13);
  font-weight: 500;
  padding: 0 10px;
  text-align: ${({ align }) => align || "left"};

  &.mobile-only {
    display: none;
  }

  @media (max-width: 768px) {
    &.mobile-only {
      display: table-cell;
    }

    &.desktop-only {
      display: none;
    }
  }
`;

export const BodyRow = styled.tr`
  height: 54px;
`;

export const BodyCol = styled.td`
  border-bottom: var(--border-size) solid var(--border-secondary);
  padding: 0 10px;
  font-size: var(--font-size-13);
  line-height: 1.15;
  text-align: ${({ align }) => align || "left"};
  color: var(--color-base-black);

  strong {
    margin: 0px 4px;
  }

  &.end {
    text-align: end;
  }

  &.mobile-only {
    display: none;
  }

  @media (max-width: 768px) {
    &.mobile-only {
      display: table-cell;
    }

    &.desktop-only {
      display: none;
    }
  }
`;

export const DotSeparator = styled.div`
  display: inline-block;
  border-radius: 50%;
  margin: 0px 8px;
  width: 4px;
  height: 4px;
  background-color: #999;
`;

export const LinkSmall = styled(Link)`
  font-size: 13px;
`

export const IconWrapper = styled.div`
  margin-right: 20px;
`;

export const Warning = styled.div`
  color: var(--color-functional-danger500);
  font-size: var(--font-size-13);
  text-align: right;
`;