import React from "react";
import styled from "styled-components";
import reactStringReplace from "react-string-replace";
import { __ } from "../../../../lib/translate";

import SwissFlagIcon from "@pcloud/web-utilities/dist/resources/images/svg/swiss-flag.svg"

const Credits = ({ noItemsLabel }) => {
	return reactStringReplace(
		__("footer_swiss_love", "Made in %flag% with love"),
		/(%flag%)/g,
		(match, i) => (
			<SwissFlagIcon key={`${i}-swiss-flag`} />
		)
	);
};

export default Credits;
