import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { __ } from "../../lib/translate";
import { Wrapper, Content, Button } from "./styles";
import useWindowDimensions from "../../lib/hooks/useWindowDimensions";
import { MOBILE_WIDTH_BREAKPOINT } from "../../config/constants";
import CryptoImage from "../../../root/img/crypto/crypto-buy-image.svg";
import { useSelector } from "react-redux";
import { siteUrl } from "@pcloud/web-utilities/dist/config";
import { currencyMap } from "@pcloud/web-utilities/dist/payments/constants.payments";
import { currencyPrices } from "@pcloud/web-utilities/dist/plans/prices";
import { CRYPTO_PLAN_ID } from "@pcloud/web-utilities/dist/plans/constants.plans";
import hashManager from "../../lib/hashManager";

const CryptoBuy = () => {
    const { bodyWidth } = useWindowDimensions();
    const isMobile = bodyWidth < MOBILE_WIDTH_BREAKPOINT;
    const [priceText, setPriceText] = useState("4.99 EUR/mo");
    const user = useSelector(({ user }) => user.userinfo);

    useEffect(() => {
        const userCurrency = currencyMap[user.currency];
        const cryptoPrice = currencyPrices[CRYPTO_PLAN_ID][userCurrency].month;

        setPriceText(cryptoPrice + " " + user.currency + "/mo");
    }, [user]);

    const showTrial = !user.cryptoexpires;

    const renderInfoSection = () => (
        <InfoSection $isMobile={isMobile}>
            <InfoSectionTitle>
                {__(
                    "crypto_buy_title",
                    "Keep your files private with pCloud Encryption"
                )}
            </InfoSectionTitle>
            <InfoSectionDescription>
                <div>
                    {__(
                        "crypto_buy_message_1",
                        "Activate the Crypto folder - the vault for your private files."
                    )}
                </div>
                <div>
                    {__(
                        "crypto_buy_message_2",
                        "Enjoy client-side encryption for the files in the Crypto folder."
                    )}
                </div>
                <div>
                    {__(
                        "crypto_buy_message_3",
                        "Ensure zero-knowledge privacy for everything confidential."
                    )}
                </div>
            </InfoSectionDescription>
        </InfoSection>
    );

    return (
        <WrapperCustom $isMobile={isMobile}>
            <ContentCustom>
                {!isMobile && <Title>{__("pCloud Encryption")}</Title>}
                {isMobile && renderInfoSection()}
                <CryptoImageWrapper>
                    <CryptoImage />
                </CryptoImageWrapper>
                <Content $maxWidth={560}>
                    {!isMobile && renderInfoSection()}
                    <ActionWrapper>
                        <Button
                            onClick={() => {
                                window.location =
                                    siteUrl("encrypted-cloud-storage.html?period=month")
                            }}
                        >
                            {__("buy_now_for_label", "Buy now for %price%", {
                                price: priceText
                            })}
                        </Button>
                        {showTrial && (
                            <Button
                                onClick={() => {
                                    hashManager.pushState({ page: "cryptoset" }, 2);
                                }}
                                $secondary={true}
                            >
                                {__("Free Trial")}
                            </Button>
                        )}
                    </ActionWrapper>
                </Content>
            </ContentCustom>
        </WrapperCustom>
    );
};

export default CryptoBuy;

const WrapperCustom = styled(Wrapper)`
    margin-top: ${({ $isMobile }) => ($isMobile ? "0" : "44px")};
`;

const ContentCustom = styled(Content)`
    gap: 24px;
    max-width: 560px;
`;

const Title = styled.div`
    color: var(--text-primary, #282c2f);
    font-size: 32px;
    font-weight: 600;
    line-height: 44px;
    letter-spacing: 0.32px;
`;

const CryptoImageWrapper = styled.div`
    width: 100%;
    svg {
        width: 100%;
        height: auto;
    }
`;

const InfoSection = styled(Content)`
    max-width: 560px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: ${({ $isMobile }) => ($isMobile ? "left" : "center")};
    gap: 12px;
`;

const InfoSectionDescription = styled.div`
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 8px;

    & > div {
        position: relative;
        color: var(--text-primary);
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        padding-left: 20px;
        box-sizing: border-box;

        &:after {
            font-family: "Font Awesome 6 Pro";
            font-weight: 400;
            font-size: 16px;
            content: "\\f00c";
            color: var(--text-positive);
            position: absolute;
            left: 0px;
            top: 0px;
            pointer-events: none;
        }
    }
`;

const InfoSectionTitle = styled.div`
    color: var(--text-primary);
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
`;

const ActionWrapper = styled.div`
    width: 100%;
    max-width: 340px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 16px;
`;
