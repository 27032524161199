// @flow

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import * as SETTINGS from "../settingsConstants";
import AccountInfo from "../AccountInfo";
import DataRegionSettings from "../DataRegionSettings";
import * as Style from "../preferences.style";
import UserPlanInfoSetting from "../UserPlanInfoSetting";
import HiddenFilesSetting from "../HiddenFilesSetting";
import ChangeLanguage from "../ChangeLanguage";
import DeleteAccountSetting from "../DeleteAccountSetting";

const Account = ({ settings }) => {
  const isBusiness = useSelector(({ user }) => user.userinfo.business);

  const renderAccountInfo = () => <AccountInfo/>;

  const renderDataRegionSetting = () => <DataRegionSettings/>;

  const renderUserPlanInfo = () => <UserPlanInfoSetting/>;

  const renderHiddenFilesSetting = () => <HiddenFilesSetting/>;

  const renderChangeLanguageSetting = () => <ChangeLanguage/>;

  const renderDeleteAccountSetting = () => {
    if (isBusiness || !HFN.config.label.canSelfDeleteAccounts) {
      return;
    }

    return (
      <DeleteAccountSetting/>
    )
  }

  const renderFunctionsPerSetting = {
    [SETTINGS.USER_EMAIL]: renderAccountInfo,
    [SETTINGS.DATA_REGION_SETTING]: renderDataRegionSetting,
    // [SETTINGS.USER_PLAN_INFO]: renderUserPlanInfo,
    [SETTINGS.HIDDEN_FILES_SETTING]: renderHiddenFilesSetting,
    [SETTINGS.LANGUAGE_SETTING]: renderChangeLanguageSetting,
    [SETTINGS.DELETE_ACCOUNT_SETTING]: renderDeleteAccountSetting,
  };

  return (
    <Style.Container className="account-container lang-wrapper">
      {settings.map((setting) => renderFunctionsPerSetting[setting] && renderFunctionsPerSetting[setting]())}
    </Style.Container>
  );
};

export default Account;
