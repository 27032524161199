//@flow

import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { SettingsWrapper, SettingsHeader, SettingsTitle } from "./styledComponents";
import TopBar from "./TopBar";
import CalendarIcon from "../../../root/img/svg/calendar.svg";
import StatsIcon from "../../../root/img/svg/stats.svg";
import FileIcon from "../../../root/img/svg/file.svg";

import { __ } from "../../lib/translate";
import { formatDt, formatSize } from "../../lib/utils";

type Props = {
  allSettingsBack: any,
  bodyHeight: string,
  parentRef: any
};

const FileRequestStats = ({ allSettingsBack, bodyHeight, parentRef }: Props) => {
  const linkData = useSelector(({ fileRequests }) => fileRequests.linkData);
  const { created, files, maxspace, expires: expirationDate, space } = linkData;

  return (
    <SettingsWrapper bodyHeight={bodyHeight} ref={parentRef} className="share-publink">
      <TopBar allSettingsBack={allSettingsBack} />
      <SettingsHeader>
        <SettingsTitle margin>
          {__("file_request_settings_modal_stats_desc", "File request statistics")}
        </SettingsTitle>
      </SettingsHeader>
      <StatsSummaryContainer>
        <LeftWrapper>
          <StatsSummary>
            <IconWrapper>
              <CalendarIcon />
            </IconWrapper>
            <Texts>
              <StatHeading>{__("Created")}</StatHeading>
              <StatValue>{formatDt(created)}</StatValue>
            </Texts>
          </StatsSummary>
          <StatsSummary>
            <IconWrapper>
              <FileIcon />
            </IconWrapper>
            <Texts>
              <StatHeading>{__("ul_uploaded_files", "Uploaded files")}</StatHeading>
              <StatValue>{files}</StatValue>
            </Texts>
          </StatsSummary>
        </LeftWrapper>
        <RightWrapper>
          <StatsSummary>
            <IconWrapper>
              <CalendarIcon />
            </IconWrapper>
            <Texts>
              <StatHeading>{__("dl_expire", "Expiration date")}</StatHeading>
              <StatValue>{expirationDate ? formatDt(expirationDate) : __("Never")}</StatValue>
            </Texts>
          </StatsSummary>
          <StatsSummary>
            <IconWrapper>
              <StatsIcon />
            </IconWrapper>
            <Texts>
              <StatHeading>
                {__("file_request_settings_modal_used_space", "Used space")}
              </StatHeading>
              <StatValue>
                {`${formatSize(space, 0)} / ${
                  maxspace ? formatSize(maxspace, 0) : __("Unlimited")
                }`}
              </StatValue>
            </Texts>
          </StatsSummary>
        </RightWrapper>
      </StatsSummaryContainer>
    </SettingsWrapper>
  );
};

export default FileRequestStats;

const StatsSummaryContainer = styled.div`
  width: 100%;
  height: 164px;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  margin-top: 10px;
`;

const StatsSummary = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: #fff;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05);
  
  @media only screen and (max-width: 450px) {
    display: none;
  }
`;

const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  width: 50%;
`;

const RightWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  width: 50%;
`;

const Texts = styled.div`
  margin-left: 20px;
`;

const StatHeading = styled.div`
  font-size: 13px;
  height: 15px;
  line-height: 1.15;
  text-align: left;
  color: #aaaaaa;
`;

const StatValue = styled.div`
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
  text-align: left;
  color: #000000;
`;
