// @flow

import React from "react";
import styled from "styled-components";

type Props = {
  type: number,
  size?: "big" | "small",
  children: any,
  onTabClick: (index: number) => void
};

const TabsHolder = ({ type, size = "big", children, onTabClick }: Props) => {
  const handleClick = (index: number) => {
    onTabClick(index);
  };

  const renderTitles = (child: any, selectedTabIndex: number) => {
    selectedTabIndex++;
    const activeClass = type === selectedTabIndex ? "active" : "";

    return (
      <Tab
        key={selectedTabIndex}
        className={activeClass}
        size={size}
        onClick={() => handleClick(selectedTabIndex)}
      >
        {child.props.label}
      </Tab>
    );
  };

  return (
    <React.Fragment>
      <Tabs size={size} className="tabs__labels">{children.map(renderTitles)}</Tabs>
      <div className="tabs__content">{children[type - 1]}</div>
    </React.Fragment>
  );
};

export default TabsHolder;

const Tabs = styled.ul`
  background-color: #fff;
  padding: 0 20px;
  height: ${props => props.size === "big" ? "50px" : "35px"};

  margin: 0;
  border-bottom: 1px solid rgba(17, 17, 17, 0.1);
  
  @media (max-width: 700px) {
    padding: 0;
  }
`;

const Tab = styled.li`
  display: inline-block;
  position: relative;
  list-style-type: none;
  color: #8b8b8b;
  min-width: 200px;
  height: ${props => props.size === "big" ? "50px" : "35px"};
  line-height: ${props => props.size === "big" ? "50px" : "35px"};
  font-size: 13px;
  text-align: center;
  cursor: pointer;

  @media (max-width: 700px) {
    width: 50%;
    min-width: auto;
  }
  

  &.active {
    color: #2c2c2c;
    font-weight: bold;
  }

  &.active:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 100%;
    height: 3px;
    background: #17bed0;
  }
`;
