import styled, { css } from "styled-components";

export const Wrapper = styled.div`
    margin-top: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: ${({ $isMobile }) =>
        $isMobile
            ? "0 var(--spacing-md) var(--spacing-md)"
            : "var(--spacing-md) var(--spacing-md)"};
    gap: 32px;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 44px;
    width: 100%;
    max-width: ${({ $maxWidth }) => ($maxWidth ? $maxWidth + "px" : "400px")};
`;

export const Header = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
`;

export const HeaderLabel = styled.div`
    color: var(--text-primary);
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
`;

export const InputWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 4px;
`;

export const Input = styled.input`
    width: 100%;
    padding: var(--spacing-none) var(--spacing-xs) var(--spacing-none)
        var(--spacing-md);
    border-radius: 88px;
    border: 1px solid
        ${({ $isInvalid }) =>
            $isInvalid
                ? "var(--color-functional-danger500)"
                : "var(--border-primary)"};
    background: var(--surface-secondary);
    height: 40px;

    color: var(--text-primary);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    box-sizing: border-box;

    &:focus {
        outline: none;
        border: 1px solid var(--color-primary-500);
    }

    &::-moz-placeholder,
    &::-ms-input-placeholder,
    &::placeholder {
        color: var(--text-secondary);
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
    }
`;
export const Button = styled.button`
    border-radius: var(--radius-full);
    background: var(--color-success);
    display: flex;
    width: 100%;
    padding: var(--spacing-sm) var(--spacing-md);
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: none;

    color: var(--button-primary-label-default);
    text-align: center;

    font-size: 15px;
    font-weight: 500;
    line-height: 24px;

    ${({ disabled }) =>
        disabled &&
        css`
            opacity: 0.4;
            pointer-events: none;
        `}

    ${({ $outline }) =>
        $outline
            ? css`
                  background: var(--button-primary-label-default);
                  border: var(--border-size, 1px) solid var(--color-success);
                  color: var(--text-positive);
              `
            : css`
                  color: var(--button-primary-label-default);
                  border: var(--border-size, 1px) solid var(--color-success);
                  background: var(--text-positive);
              `}

    ${({ $danger, $outline }) =>
        $danger &&
        (!$outline
            ? css`
                  color: var(--button-primary-label-default);
                  border: var(--border-size) solid var(--text-danger);
                  background: var(--text-danger);
              `
            : css`
                  background: var(--button-primary-label-default);
                  border: var(--border-size) solid var(--text-danger);
                  color: var(--text-danger);
              `)}

    ${({ $black, $outline }) =>
            $black &&
        (!$outline
            ? css`
                  color: var(--button-primary-label-default);
                  border: var(--border-size) solid var(--button-secondary-label-default);
                  background: var(--button-secondary-label-default);
              `
            : css`
                  background: var(--button-primary-label-default);
                  border: var(--border-size) solid var(--button-secondary-label-default);
                  color: var(--button-secondary-label-default);
              `)}
`;
