//  @flow

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { hiDPI } from "polished";

import apiMethod from "../../api/apiMethod";
import { changePublinkApiCall } from "./utils";

import { __ } from "../../lib/translate";

import ShareDropdown from "./ShareDropdown";
import TopBar from "./TopBar";
import InviteViaEmail from "../../components/InviteViaEmail";
import { ButtonCentered } from "../../components/ButtonDefault";
import {
  SettingsWrapper,
  SettingsHeader,
  SettingsTitle,
  SettingsDescription
} from "./SharedStyledComponents";

import { defaultHeaderHeight } from "./DefaultValues";

type Props = {
  allSettingsBack: any,
  bodyHeight: number,
  parentRef: any,
  userIsPremium: boolean,
  userIsBusiness: boolean,
  setModalHeight: () => void
};

const ShareUploadSetting = ({ allSettingsBack, bodyHeight, parentRef, userIsPremium, userIsBusiness, setModalHeight, currentSettingId }: Props) => {
  const token = useSelector(({ user }) => user.token);
  const linkData = useSelector(({ sharedLinks }) => sharedLinks.linkData);
  const { linkId, uploadSettings, emailsWithUploadAccess } = linkData;
  const { isUploadDisabled } = uploadSettings;
  const [settings, setSettings] = useState({ ...uploadSettings });
  const [emails, setEmails] = useState([...emailsWithUploadAccess]);
  const [isOpenDropdown, setIsOpenDropdown] = useState(isUploadDisabled);
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (parentRef.current) {
      setModalHeight(parentRef.current.offsetHeight + defaultHeaderHeight);
    }
  }, [settings, isOpenDropdown, parentRef, setModalHeight, emails]);

  useEffect(() => {
    setEmails(emailsWithUploadAccess);
  }, [emailsWithUploadAccess, setEmails]);

  const getUploadDropDownList = () => {
    return [
      {
        id: 0,
        title: __("upload_setting_nobody", "Nobody can upload"),
        description: __(
          "upload_setting_nobody_info",
          "Anyone with access to this link will only be able to download and preview the files."
        )
      },
      {
        id: 1,
        title: __("upload_setting_anyone", "Anyone can upload"),
        description: __(
          "upload_setting_anyone_info",
          "Any pCloud user with access to this link can upload files."
        )
      },
      {
        id: 2,
        title: __("upload_setting_restricted", "Only selected users can upload"),
        description: __(
          "upload_setting_restricted_info",
          "Only the pCloud users you add can upload files from this link."
        )
      }
    ];
  };

  const getSelectedItemId = () => {
    const uploadSetiingsKeys = Object.keys(settings);
    let selItemIndex = -1;
    uploadSetiingsKeys.map((key, index) => {
      if (settings[key]) {
        selItemIndex = index;
      }
    });

    return selItemIndex !== -1 ? selItemIndex : 0;
  };

  const getUploadSettingForAnalytics = ({ isUploadDisabled, canEveryoneUpload, isRestrictedUpload }) => {
    if (isUploadDisabled) {
      return "upload disabled"
    } else if (isRestrictedUpload) {
      return "upload restricted"
    } else if (canEveryoneUpload) {
      return "upload allowed"
    }
  };

  const onChangeSelectedId = (selectedSettingId: number) => {
    const newSettings = Object.keys(settings).reduce(
      (acc, item, index) => {
        if (selectedSettingId === index) {
          acc[item] = true;
        } else {
          acc[item] = false;
        }
        return acc;
      },
      { ...settings }
    );

    setSettings(newSettings);
  };

  const onSaveClick = cb => {
    if (isLoading) {
      return;
    }

    setLoading(true);

    const params = { auth: token, linkid: linkId };
    const { isUploadDisabled, canEveryoneUpload, isRestrictedUpload } = settings;
    const callOnlyCallback = uploadSettings.isRestrictedUpload;

    if (isUploadDisabled) {
      params.disableupload = true;
    } else if (canEveryoneUpload) {
      params.enableuploadforeveryone = true;
    } else if (isRestrictedUpload) {
      params.enableuploadforchosenusers = true;
    }

    if (typeof gtag === "function") {
      gtag("event", "shared_link_settings_click", {
        action: "upload setting change",
        category: "setting",
        type: "free",
        user_premium: (userIsPremium || userIsBusiness) ? 1 : 0,
        location: "settings modal",
        value: getUploadSettingForAnalytics({ isUploadDisabled, canEveryoneUpload, isRestrictedUpload }),
      })
    }

    if (callOnlyCallback && cb && typeof cb === "function") {
      cb();
      setLoading(false);
      return;
    }

    changePublinkApiCall(
      params,
      () => {
        setLoading(false);
        dispatch({ type: "SET_UPLOAD_SETTINGS", settings });

        if (cb && typeof cb === "function") {
          cb();
        } else {
          HFN.message(__("shared_links_settings_updated", "Settings updated."), "success");
          dispatch({ type: "SET_EMAILS_WITH_UPLOAD_ACCESS", emails: [] });
        }
      },
      ({ error }) => {
        setLoading(false);
        HFN.message(error, "error");
        throw new Error(error);
      }
    );
  };

  const onSuccess = resList => {
    var successEmails = resList.map(item => ({
      email: item.email,
      receiverid: item.receiverid
    }));
    emails.push(...successEmails);
    dispatch({ type: "SET_EMAILS_WITH_UPLOAD_ACCESS", emails: [...emails] });
    HFN.message(
      __("invite_flow_success_message", "An email with the link has been sent to these users."),
      "success"
    );
  };

  const onDeleteEmailsWithAccess = id => {
    apiMethod(
      "publink/removeaccess",
      {
        auth: token,
        linkid: linkId,
        receiverid: id
      },
      () => {
        HFN.message(
          __("shared_link_user_removed", "The user can no longer upload files to this folder."),
          "success"
        );
        removeEmailFromList(id);
      },
      {
        forceFresh: true,
        cacheTime: 0,
        errorCallback: ({ error }) => {
          throw new Error(error);
        }
      }
    );
  };

  const removeEmailFromList = id => {
    const filteredEmails = emails.filter(({ receiverid }) => receiverid !== id) || [];
    dispatch({ type: "SET_EMAILS_WITH_UPLOAD_ACCESS", emails: filteredEmails });
  };

  const renderSaveButton = () => {
    const canSave = JSON.stringify(uploadSettings) !== JSON.stringify(settings);

    return (
      <ButtonWrapper>
        <ButtonCentered
          disabled={isLoading || !canSave}
          loading={isLoading}
          onClick={onSaveClick}
          style={{
            minWidth: "120px",
            borderRadius: "3.1px",
            marginTop: "27px"
          }}
        >
          {__("Save")}
        </ButtonCentered>
      </ButtonWrapper>
    );
  };

  const renderDropdown = () => {
    return (
      <ShareDropdown
        list={getUploadDropDownList()}
        selectedItemId={getSelectedItemId()}
        onItemDropdownClick={onChangeSelectedId}
        isOpen={isOpenDropdown}
        setIsOpen={setIsOpenDropdown}
      />
    );
  };

  const renderInviteForm = () => {
    return (
      <InviteViaEmail
        formTitle={__("add_people", "Add people")}
        buttonText={__("Save")}
        apiCall="publink/addaccess"
        opts={{ linkid: linkId }}
        onSuccess={onSuccess}
        onError={() => {}}
        beforeSubmit={onSaveClick}
        type={2}
      />
    );
  };

  const renderUserWithAccess = ({ email, receiverid }: { email: string, receiverid: number }) => {
    return (
      <WrapperUser key={receiverid}>
        <Email>{email}</Email>
        <DeleteIcon onClick={() => onDeleteEmailsWithAccess(receiverid)} />
      </WrapperUser>
    );
  };

  const renderUsersWithAccess = () => {
    if (emails.length === 0) {
      return null;
    }

    return (
      <WrapperUsers>
        <TitleUsers>{__("People who already can upload")}</TitleUsers>
        <EmailsWithAccess>{emails.map(renderUserWithAccess)}</EmailsWithAccess>
      </WrapperUsers>
    );
  };

  const renderFooter = () => {
    const { isRestrictedUpload } = settings;

    if (!isOpenDropdown) {
      if (isRestrictedUpload) {
        return (
          <React.Fragment>
            {renderInviteForm()}
            {renderUsersWithAccess()}
          </React.Fragment>
        );
      } else {
        return renderSaveButton();
      }
    } else {
      return null;
    }
  };

  return (
    <UploadWrapper bodyHeight={bodyHeight} ref={parentRef}>
      <TopBar allSettingsBack={allSettingsBack} settingId={currentSettingId}/>
      <SettingsHeader>
        <SettingsTitle>{__("link_settings_upload", "Upload")}</SettingsTitle>
        <SettingsDescription>
          {__(
            "link_settings_upload_info",
            "Allow users to upload files to your account from this link. The uploaded files will become available for preview and download."
          )}
        </SettingsDescription>
      </SettingsHeader>
      <DropDownWrapper>{renderDropdown()}</DropDownWrapper>
      {renderFooter()}
    </UploadWrapper>
  );
};

export default ShareUploadSetting;

const DropDownWrapper = styled.div`
  margin-top: 27px;
  margin-bottom: 20px;
`;

const UploadWrapper = styled(SettingsWrapper)`
  justify-content: flex-start;
  
  @media only screen and (max-width: 800px) {
    padding-bottom: 20px;
  }
`;

const WrapperUsers = styled.div`
  margin-top: 20px;
  width: 496px;
  border-top: 1px solid #f2f2f2;
  padding: 15px 0 0 0;
  text-align: left;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

const TitleUsers = styled.div`
  padding-bottom: 15px;
  font-size: 13px;
  font-weight: 500;
`;

const EmailsWithAccess = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-flow: wrap;
  overflow-y: scroll;
  height: 60px;
  & ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  @media only screen and (max-width: 800px) {
    height: 75px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
  }
}
`;

const WrapperUser = styled.div`
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 1px 0 0 rgba(0, 0, 0, 0.01);
  border: solid 1px #e1e1e1;
  padding: 6px 8px 6px 10px;
  border-radius: 13px;
  height: 26px;
  background-color: #ffffff;
  box-sizing: border-box;
  align-items: center;
  margin: 0 6px 6px 0;
`;

const Email = styled.div`
  font-size: 13px;
`;

const DeleteIcon = styled.div`
  display: inline-block;
  margin-left: 6px;
  width: 9px;
  height: 9px;
  cursor: pointer;
  opacity: 0.3;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 9px 9px;

  background-image: url(${require("../../../root/img/share-icons/cancel.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/share-icons/cancel@2x.png")});
  }
`;

const ButtonWrapper = styled.div`
  a[disabled] {
    background-color: #dddddd;
    color: #a2a2a2;
    opacity: 1;
  }
`;
