import React from "react";
import { __ } from "../../../../../../lib/translate";
import { SERVICES_INFO } from "../../../../configurations";
import styled, { css } from "styled-components";
import {
    ExpandButton,
    LinkButton,
    PrimeContent,
    ServiceIcon,
    ServiceName,
    TextArea,
    UserAccount,
    Warning,
    WarningText,
    Wrapper
} from "../styles";
import useThirdPartyControls from "../../../../hooks/useThirdPartyControls";
import useWindowDimensions from "../../../../../../lib/hooks/useWindowDimensions";
import { BACKUP_MOBILE_BREAKPOINT } from "../../../../constants";
import StatusIndicator from "./StatusIndicator";
import InitButton from "./InitButton";
import DesktopSubLayout from "./DesktopSubLayout";
import MobileSubLayout from "./MobileSubLayout";

const CardControlledBackup = (props) => {
    const {
        LAST_DATE_FORMATTED,
        NEXT_DATE_FORMATTED,
        PROGRESS_PERCENT,
        type,
        status,
        sourceid,
        title,
        isOverquota,
        isExpanded,
        is_running,
        is_paused,
        exit_code,
        passedNextExecutionDate,
        backup_metadata,
        disabledButton,
        accountInfo,
        errorState,
        triggerClick,
        triggerExpand,
        onButtonClick,
        unlinkAccount
    } = useThirdPartyControls(props);

    const { bodyWidth } = useWindowDimensions();
    const isMobileView = bodyWidth < BACKUP_MOBILE_BREAKPOINT;

    return (
        <CustomWrapper
            $isMobileView={isMobileView}
            $isExpanded={isExpanded}
            $isClickable={!!backup_metadata}
            $isAvailable={status !== "unavailable"}
            onClick={(e) => {
                if (isMobileView) {
                    triggerExpand(e);
                    return;
                }
                if (!!backup_metadata) {
                    triggerClick(e, backup_metadata.folderid);
                }
            }}
        >
            <PrimeContent $isExpanded={isExpanded}>
                <ServiceIcon>
                    {SERVICES_INFO[sourceid]?.icon}
                    <StatusIndicator status={status} />
                </ServiceIcon>
                <TextArea>
                    <ServiceName>
                        {title ?? SERVICES_INFO[sourceid]?.name}
                    </ServiceName>
                    <UserAccount>
                        {accountInfo ||
                            __("backup_no_account", "No account connected")}
                    </UserAccount>
                    {!isMobileView && accountInfo && !is_running && !is_paused && (
                        <LinkButton onClick={unlinkAccount}>
                            {__("Disconnect")}
                        </LinkButton>
                    )}
                </TextArea>
                <ExpandButton onClick={triggerExpand}>
                    <i className={"fa-regular fa-chevron-down baseIcon"} />
                </ExpandButton>
                {!isMobileView && (
                    <InitButton
                        disabledButton={disabledButton}
                        type={type}
                        status={status}
                        passedNextExecutionDate={passedNextExecutionDate}
                        onButtonClick={onButtonClick}
                        isOverquota={isOverquota}
                    />
                )}
            </PrimeContent>
            {!isMobileView && (
                <DesktopSubLayout
                    backup_metadata={backup_metadata}
                    exit_code={exit_code}
                    errorState={errorState}
                    sourceid={sourceid}
                    type={type}
                    status={status}
                    accountInfo={accountInfo}
                    NEXT_DATE_FORMATTED={NEXT_DATE_FORMATTED}
                    LAST_DATE_FORMATTED={LAST_DATE_FORMATTED}
                    PROGRESS_PERCENT={PROGRESS_PERCENT}
                    triggerClick={triggerClick}
                />
            )}
            {isMobileView && (
                <MobileSubLayout
                    backup_metadata={backup_metadata}
                    exit_code={exit_code}
                    is_running={is_running}
                    is_paused={is_paused}
                    errorState={errorState}
                    type={type}
                    status={status}
                    sourceid={sourceid}
                    accountInfo={accountInfo}
                    passedNextExecutionDate={passedNextExecutionDate}
                    NEXT_DATE_FORMATTED={NEXT_DATE_FORMATTED}
                    LAST_DATE_FORMATTED={LAST_DATE_FORMATTED}
                    PROGRESS_PERCENT={PROGRESS_PERCENT}
                    triggerClick={triggerClick}
                    onButtonClick={onButtonClick}
                    unlinkAccount={unlinkAccount}
                    disabledButton={disabledButton}
                    isOverquota={isOverquota}
                    isExpanded={isExpanded}
                />
            )}
            {status === "unavailable" && (
                <Warning>
                    <WarningText>
                        {__(
                            "backup_warning_unavailable",
                            "Option temporarily unavailable"
                        )}
                    </WarningText>
                </Warning>
            )}
        </CustomWrapper>
    );
};

export default CardControlledBackup;

const CustomWrapper = styled(Wrapper)`
    height: 185px;

    @media (max-width: 800px) {
        height: auto;
    }

    ${({ $isClickable, $isMobileView }) =>
            $isClickable && !$isMobileView &&
            css`
                cursor: pointer;

                &:hover {
                    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.12);
                }
            `}
`;