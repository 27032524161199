//@flow

import React from "react";
import styled, { keyframes } from "styled-components";
import { hiDPI } from "polished";
import { Tooltip as ReactTooltip } from "react-tooltip";

import { ColorType } from "../../../web-shared/dataTypes";
import { __ } from "../../lib/translate";
import { ButtonWithIcon } from "../../../web-shared/components/ButtonDefault";
import { Error, cardIcons } from "./SharedStyledComponents";

export type PrefilledCreditCardFormProps = {
  paymentLoading: boolean,
  userPaymentSource: Object,
  errorMessage: string,
  handleCardChange: () => void,
  handleSubmit: (event: SyntheticInputEvent<HTMLInputElement>) => void,
  color?: ColorType,
  price?: number,
  currency? : string
};

const PrefilledCreditCardForm = ({
  paymentLoading,
  userPaymentSource,
  errorMessage,
  handleCardChange,
  handleSubmit,
  color,
  price,
  currency
}: PrefilledCreditCardFormProps) => {
  const { last4, brand, expmonth, expyear, name } = userPaymentSource;
  let brandLowerCase = brand ? brand.toLowerCase() : null;
  let buttonText = __("checkout_right_btn");

  if (price && currency) {
    buttonText = `${__("pay_button", "Pay")} ${price} ${currency}`;
  }

  return (
    <CardFormWrapper>
      {errorMessage ? <Error className="payment-error-message">{errorMessage}</Error> : null}
      <CardNumberWrapper>
        <InputLabel>{__("card_number", "Card number")}</InputLabel>
        <ChangeCard disabled={paymentLoading} onClick={handleCardChange}>{__("change_card", "Change card?")}</ChangeCard>
        <InputField
          placeholder="XXXX XXXX XXXX XXXX"
          type="text"
          name="cardnumber"
          value={"**** **** **** " + last4}
          autocomplete="cc-number"
          disabled
        />
        {brandLowerCase && cardIcons[brandLowerCase] ? cardIcons[brandLowerCase] : cardIcons["default"]}
      </CardNumberWrapper>
      <ExpDateWrapper>
        <InputLabel>{__("expiration_date_short", "Exp. date")}</InputLabel>
        <InputField
          placeholder={__("MM", "MM") + " / " + __("YY", "YY")}
          type="text"
          name="cc-exp"
          maxLength="5"
          value={expmonth + "/" + expyear}
          autocomplete="cc-exp"
          disabled
        />
      </ExpDateWrapper>
      <CVCWrapper>
        <TextWrapper>
          <InputLabel>{__("CVC", "CVC")}</InputLabel>
          <InfoIcon
            data-tooltip-content={__(
              "find_card_cvc",
              "This is the secret code located on the back of your credit card."
            )}
          >
            i
          </InfoIcon>
          <ReactTooltip className="tooltip" multiline />
        </TextWrapper>
        <InputField
          placeholder="XXX"
          type="text"
          maxLength="3"
          value={brandLowerCase === "amex" ? "****" : "***"}
          name="cvc"
          autocomplete="cc-csc"
          disabled
        />
      </CVCWrapper>
      <CardHolderWrapper>
        <InputLabel>{__("card_holder_name", "Cardholder name")}</InputLabel>
        <InputField
          placeholder={__("card_holder_name", "Cardholder name")}
          type="text"
          value={name}
          name="ccname"
          maxLength="30"
          autocomplete="cc-name"
          disabled
        />
      </CardHolderWrapper>
      <ButtonWrapper>
        <ButtonWithIcon
          size="big"
          color={color}
          onClick={handleSubmit}
          disabled={false}
          height={42}
          loading={paymentLoading}
          text={buttonText}
          icon="secure"
        />
      </ButtonWrapper>
      <SecureText>
        <span>{__("secure_payment")}</span>
      </SecureText>
    </CardFormWrapper>
  );
};

export default PrefilledCreditCardForm;

const CardFormWrapper = styled.form`
  text-align: left;
`;

const InputWrapper = styled.div`
  margin-top: 17px;
`;

const InputLabel = styled.label`
  display: inline-block;
  margin-bottom: 5px;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  color: #999999;
`;

const InputField = styled.input`
  width: 100%;
  height: 40px;
  padding: 15px;
  box-sizing: border-box;
  border: 1px solid #c7c7c7;
  background-color: #f1f1f1;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #999999;
`;

const CardNumberWrapper = styled(InputWrapper)`
  position: relative;
`;

const ExpDateWrapper = styled(InputWrapper)`
  display: inline-block;
  width: 48%;

  input {
    text-align: center;
  }
`;

const CVCWrapper = styled(InputWrapper)`
  display: flex;
  width: 48%;
  float: right;
  flex-direction: column;

  .tooltip {
    padding: 15px 20px;
    max-width: 200px;
    border-radius: 6px;
    font-size: 12px;
    line-height: 1.35;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    text-align: center;
  }

  input {
    text-align: center;
  }
`;

const InfoIcon = styled.div`
  margin-left: auto;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: #cccccc;
  color: #ffffff;
  font-size: 11px;
  font-weight: bold;
  line-height: 16px;
  text-align: center;
  cursor: pointer;
  transition: background 0.2s ease-in-out;

  &:hover {
    background-color: #aaaaaa;
  }
`;

const CardHolderWrapper = styled(InputWrapper)`
  clear: both;

  input {
    text-transform: uppercase;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 30px;
`;

const ChangeCard = styled.div`
  float: right;
  font-size: 13px;
  color: #000000;
  font-family: "Roboto", sans-serif;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  cursor: ${props => (props.disabled ? "default" : "pointer")};
  text-decoration: underline;
`;

const SecureText = styled.div`
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: center;
  color: #999999;
  margin-top: 18px;
`;

const SecureIcon = styled.div`
  display: inline-block;
  width: 21px;
  height: 21px;
  background-repeat: no-repeat;
  background-size: 21px 21px;
  background-position: center;
  background-image: url(${require("../../../root/img/secure.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/secure@2x.png")});
  }
  position: relative;
  top: 5px;
  margin-right: 10px;
`;

const TextWrapper = styled.div`
  display: flex;
`;
