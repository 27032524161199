// @flow

import React, { PureComponent } from "react";
import styled from "styled-components";
import { Tooltip as ReactTooltip } from "react-tooltip";

import { __ } from "../../lib/translate";
import Button from "../../components/ButtonDefault";
import { Header, Title, Description, Warning, Message } from "./CommonComponents";
import CustomLinkSettings from "./CustomLinkSettings";
import EditBrandingModal from "./EditBrandingModal";
import Tab from "./Tab";
import RemoveCustomBranding from "./RemoveCustomBranding";
import StopCustomLink from "./StopCustomLink";
import type { PubLink } from "../../types/publiclinks";
import { URLSITE } from "@pcloud/web-utilities/dist/config";

type Props = {
  isPremium: boolean,
  isActiveGL: boolean,
  customLinks: Array<Object>,
  updateGeneralBrandingStatus: () => void
};

type State = {
  switchingOffCL: boolean,
  openBrandingModal: boolean,
  brandingType?: "custom" | "default",
  customLinks: Array<Object>,
  shouldResizeTab: boolean,
  removingCustomBranding: boolean,
  linkid?: string,
  link?: PubLink | null,
  offsetX?: string,
  offsetY?: string
};

class SettingsContainer extends PureComponent<Props, State> {
  static defaultProps = {};

  constructor(props: Props) {
    super(props);
    const { customLinks } = this.props;

    this.state = {
      switchingOffCL: false,
      customLinks: customLinks,
      shouldResizeTab: false,
      removingCustomBranding: false,
      openBrandingModal: false
    };

    (this: any).resetResize = this.resetResize.bind(this);
    (this: any).deactivateCustomLinks = this.deactivateCustomLinks.bind(this);
    (this: any).closeModal = this.closeModal.bind(this);
    //(this: any).stopCustomLink = this.stopCustomLink.bind(this);
    (this: any).onClickCreateGeneralBranding = this.onClickCreateGeneralBranding.bind(this);
    (this: any).onClickEditCustomBranding = this.onClickEditCustomBranding.bind(this);
    (this: any).onCloseBrandingModal = this.onCloseBrandingModal.bind(this);
    (this: any).onDisableSuccess = this.onDisableSuccess.bind(this);
    (this: any).renderDeleteCustomLinksButton = this.renderDeleteCustomLinksButton.bind(this);
    (this: any).renderGeneralBrandingButton = this.renderGeneralBrandingButton.bind(this);
    (this: any).initCustomLinkStop = this.initCustomLinkStop.bind(this);
    (this: any).updateCustomLinksOnRemove = this.updateCustomLinksOnRemove.bind(this);
  }

  resetResize() {
    this.setState({ shouldResizeTab: false });
  }

  deactivateCustomLinks() {
    const { customLinks } = this.state;

    if (!customLinks.length) {
      return;
    }

    this.setState({
      switchingOffCL: true
    });
  }

  closeModal() {
    this.setState({
      switchingOffCL: false,
      removingCustomBranding: false
    });
  }

  onCloseBrandingModal() {
    this.setState({ openBrandingModal: false, link: {} });
  }

  onClickCreateGeneralBranding(event) {
    const { isPremium } = this.props;

    if (!isPremium) {
      return;
    }

    this.setState({
      brandingType: "default",
      openBrandingModal: true,
      link: null,
      offsetX: event.clientX,
      offsetY: event.clientY
    });
  }

  onClickEditCustomBranding({ event, link }) {
    this.setState({
      brandingType: "custom",
      link,
      openBrandingModal: true,
      offsetX: event.clientX,
      offsetY: event.clientY
    });
  }

  initCustomLinkStop(id: string) {
    this.setState({ linkid: id, removingCustomBranding: true });
  }

  updateCustomLinksOnRemove(linkId: string) {
    this.setState(prevState => {
      return {
        customLinks: prevState.customLinks.filter(link => link.linkid !== linkId),
        shouldResizeTab: true,
        removingCustomBranding: false
      };
    });
  }

  onDisableSuccess() {
    this.setState({
      customLinks: [],
      shouldResizeTab: true,
      switchingOffCL: false
    });
  }

  renderDeleteCustomLinksButton() {
    const { customLinks } = this.state;

    return customLinks.length ? (
      <Button
        color="redCaution"
        disabled={!customLinks.length}
        onClick={this.deactivateCustomLinks}
        borderRadius={3}
        inline
      >
        {__("Delete")}
      </Button>
    ) : null;
  }

  renderGeneralBrandingButton() {
    const { isActiveGL, isPremium } = this.props;

    if (isActiveGL) {
      return (
        <Button
          color="cyan"
          onClick={this.onClickCreateGeneralBranding}
          disabled={!isPremium}
          borderRadius={3}
          inline
        >
          {__("Edit")}
        </Button>
      );
    } else {
      return (
        <Button
          color="green"
          onClick={this.onClickCreateGeneralBranding}
          disabled={!isPremium}
          borderRadius={3}
          inline
        >
          {__("add_short")}
        </Button>
      );
    }
  }

  render() {
    let modalProps = {};
    const {
      switchingOffCL,
      customLinks,
      shouldResizeTab,
      removingCustomBranding,
      link,
      linkid,
      offsetX,
      offsetY,
      brandingType,
      openBrandingModal
    } = this.state;

    const { isPremium, updateGeneralBrandingStatus, isActiveGL } = this.props;

    modalProps.onClose = this.closeModal;

    return (
      <React.Fragment>
        <RemoveCustomBranding
          isOpen={switchingOffCL}
          customLinks={customLinks}
          onDisableSuccess={this.onDisableSuccess}
          onClose={this.closeModal}
        />
        <StopCustomLink
          isOpen={removingCustomBranding}
          linkid={linkid}
          updateCustomLinksOnRemove={this.updateCustomLinksOnRemove}
          onClose={this.closeModal}
        />
        <Header>
          {!isPremium && customLinks.length === 1 ? (
            <Warning
              dangerouslySetInnerHTML={{
                __html: __("custom_branding_warning", "", {
                  attr: `href=${URLSITE}cloud-storage-pricing-plans.html`
                })
              }}
            />
          ) : (
            <Description>{__("branding-intro-description-premium")}</Description>
          )}
        </Header>
        <TabsContainer>
          {/* {!isPremium ? (
            <ReactTooltip id="disabled-tooltip" className="tooltip-wrapper">
              {__("general_branding_upgrade_tooltip")}
            </ReactTooltip>
          ) : null} */}
          <Tab
            title={__("general_branding")}
            isActive
            renderButton={this.renderGeneralBrandingButton}
            shouldToggle={false}
          >
            {isActiveGL ? (
              <Message>{__("branding_general_activated")}</Message>
            ) : (
              <Message>{__("branding_general_info")}</Message>
            )}
          </Tab>
          <Tab
            title={__("custom_branding") + " (" + customLinks.length + ")"}
            isActive
            renderButton={this.renderDeleteCustomLinksButton}
            shouldResizeTab={shouldResizeTab}
            resetResize={this.resetResize}
            shouldToggle={false}
          >
            <CustomLinkSettings
              isPremium={isPremium}
              customLinks={customLinks}
              // stopCustomLink={this.stopCustomLink}
              updateCustomLinksOnRemove={this.updateCustomLinksOnRemove}
              initCustomLinkStop={this.initCustomLinkStop}
              onEditCustomBranding={this.onClickEditCustomBranding}
            />
          </Tab>
        </TabsContainer>
        <EditBrandingModal
          onClose={this.onCloseBrandingModal}
          opened={openBrandingModal}
          offsetX={offsetX}
          offsetY={offsetY}
          brandingType={brandingType}
          isActiveGL={isActiveGL}
          isActiveCL={brandingType === "custom"}
          link={link}
          isPremium={isPremium}
          updateGeneralBrandingStatus={updateGeneralBrandingStatus}
          updateCustomBrandingStatus={this.updateCustomLinksOnRemove}
        />
      </React.Fragment>
    );
  }
}

export default SettingsContainer;

const TabsContainer = styled.div`
  margin: 34px auto;
  max-width: 780px;
  width: 100%;

  .tooltip-wrapper {
    opacity: 1;
    padding: 12px;
    border-radius: 4px;
    box-shadow: 0px 1px 2.9px 0.1px;
  }
`;
