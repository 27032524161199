// @flow

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import * as SETTINGS from "../settingsConstants";
import * as Style from "../preferences.style";
import NotificationSetting from "../NotificationSetting";

const Notifications = ({ settings }) => {
  const renderNotifications = () => <NotificationSetting/>;

  const renderFunctionsPerSetting = {
    [SETTINGS.NOTIFICATION_PREFERENCES_SETTING]: renderNotifications
  };

  return (
    <Style.Container className="account-container lang-wrapper">
      {settings.map((setting) => renderFunctionsPerSetting[setting] && renderFunctionsPerSetting[setting]())}
    </Style.Container>
  );
};

export default Notifications;
