import React, { useEffect, useState } from "react";
import CryptoLockedImg from "../../../root/img/crypto/crypto-locked.svg";
import { __ } from "../../lib/translate";
import { Tooltip as ReactTooltip } from "react-tooltip";
import {
    Button,
    InputWrapper,
    Content,
    Header,
    HeaderLabel,
    Wrapper
} from "./styles";
import styled from "styled-components";
import PasswordInput from "./parts/PasswordInput";
import CryptoExpired from "./CryptoExpired";
import CryptoLoader from "./CryptoLoader";
import { useSelector } from "react-redux";
import AlertCryptoV1 from "./parts/AlertCryptoV1";
import hashManager from "../../lib/hashManager";

const CryptoLock = () => {
    const [showLoader, setShowLoader] = useState(false);
    const [showLockPage, setShowLockPage] = useState(false);
    const [hint, setHint] = useState("");
    const [passwordValue, setPasswordValue] = useState("");
    const user = useSelector(({ user }) => user.userinfo);

    const disabledSubmitBtn = passwordValue.length <= 3;

    useEffect(() => {
        pCloudCrypto.getHint(
            function(responseHint) {
                if (responseHint) {
                    setHint(responseHint);
                }
            },
            function() {}
        );
    }, [user]);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (disabledSubmitBtn) {
            return;
        }

        setShowLoader(true);

        pCloudCrypto.unlockCrypto(passwordValue, function(ok) {
            if (ok) {
                HFN.message(__("Crypto Unlocked", "Crypto Unlocked"));

                if (HFN.data.afterCryptoUnlock && HFN.data.afterCryptoUnlock.page !== 'cryptolocked') {
                    hashManager.pushState(HFN.data.afterCryptoUnlock, 2);
                    delete HFN.data.afterCryptoUnlock;
                    return;
                }

                const state = { page: "filemanager", crypto: 1 };
                if (pCloudCrypto.redirectfolder)
                    state.folder = pCloudCrypto.redirectfolder;
                hashManager.pushState(state, 2);
            } else {
                setShowLoader(false);
                setPasswordValue("");
                HFN.message(
                    __("wrong_password", "Wrong Crypto Pass."),
                    "error"
                );
            }
        });
    };

    if (!showLockPage) {
        const hoursLeft = Math.ceil(
            (new Date(user.cryptoexpires).getTime() / 1000 -
                Math.round(new Date().getTime() / 1000)) /
                3600
        );
        const extendedHours = hoursLeft + 720; // Plus 30 days

        if (hoursLeft <= 0 && extendedHours > 0) {
            return (
                <CryptoExpired
                    onShowLockPage={() => {
                        setShowLockPage(true);
                    }}
                    extendedHours={extendedHours}
                />
            );
        }
    }

    if (showLoader) {
        return (
            <CryptoLoader
                title={__("Unlocking")}
                message={
                    isCryptoLoginSlow()
                        ? __("This could take a minute. Please wait!")
                        : null
                }
            />
        );
    }

    return (
        <>
            <AlertCryptoV1 />
            <Wrapper>
                <Content>
                    <Header>
                        <CryptoLockedImg />
                        <HeaderLabel>{__("Locked")}</HeaderLabel>
                    </Header>
                    <Main onSubmit={handleSubmit} autoComplete="off">
                        <InputWrapper>
                            <PasswordInput
                                value={passwordValue}
                                onChange={(e) => {
                                    setPasswordValue(e.target.value);
                                }}
                                autoFocus={true}
                                className={"cryptopass"}
                                placeholder={__(
                                    "crypto_password_label",
                                    "Crypto Password"
                                )}
                            />
                            {hint && (
                                <>
                                    <HintWrapper>
                                        <HintLabel data-tooltip-id="crypto-hint">
                                            {__("Hint")}
                                        </HintLabel>
                                    </HintWrapper>

                                    <ReactTooltip
                                        id="crypto-hint"
                                        className="cv-tooltip visible-on-mobile"
                                        noArrow
                                        offset={12}
                                        place="top"
                                    >
                                        {hint}
                                    </ReactTooltip>
                                </>
                            )}
                        </InputWrapper>
                        <Button
                            type={"submit"}
                            disabled={disabledSubmitBtn || showLoader}
                        >
                            {__("Unlock Crypto Folder")}
                        </Button>
                    </Main>
                </Content>
            </Wrapper>
        </>
    );
};

export default CryptoLock;

const HintWrapper = styled.div`
    display: flex;
    justify-content: end;
    align-items: center;
`;
const HintLabel = styled.div`
    display: inline-block;
    margin: var(--spacing-none) 16px;
    color: var(--text-primary);
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
    cursor: pointer;
    border-bottom: 1px solid var(--text-primary);
`;

const Main = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    width: 100%;
`;