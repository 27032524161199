// @flow

import React, { useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { __ } from "../../lib/translate";
import apiMethod from "../../api/apiMethod";
import EmailVerifyModal from "../../containers/EmailVerifyModal";
import * as Style from "./preferences.style";
import UnverifiedEmailIcon from "../../../web-shared/images/svg/pcloud-pass/not_verified_email.svg";
import { emailInitials } from "../../../web-shared/utils";
import UserPlanInfoSetting from "./UserPlanInfoSetting";
import ResponsiveEmail from "../../containers/ResponsiveEmail";

const AccountInfo = () => {
  const [openModal, setOpenModal] = useState(false);
  const isVerified = useSelector(({ user }) => user.userinfo.emailverified);
  const email = useSelector(({ user }) => user.userinfo.email);

  const onVerifyClick = () => {
    apiMethod(
      "sendverificationemail",
      {},
      () => {
        setOpenModal(true);
      },
      {
        errorCallback: ({ error }) => {
          HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
          throw new Error(error);
        }
      }
    );
  };

  const onChangeEmailClick = () => {
    apiMethod("sendchangemail", {}, ret => {
      HFN.message(__("change_email_after_click", "We've sent you an email to <b>%email%</b>. Check your inbox and follow the instructions from the email. If you don't receive an email within few minutes, check your Junk/Spam folder.", {
        email: email
      }));
    }, {
      errorCallback: function(ret) {
        if (ret.result === 2255) {
          ret.error = __("change_email_limit", "You've already requested email change just recently. Pleasy, try again later.");
        }

        HFN.message(ret.error, "error");
      },
      type: 'POST'
    });
  }

  return (
    <UserInfoWrapper>
      <Style.Row>
        <Style.FlexContainer>
          <Style.Icon style={{ flexShrink: "0" }}>{email ? emailInitials(email) : " - "}</Style.Icon>
          <Style.User>
            <ResponsiveEmail email={email}/>
          </Style.User>
        </Style.FlexContainer>

        {!isVerified ? (
          <ButtonWrapper>        
            <Style.RedButton onClick={onVerifyClick}>
              {__("setting_verify_account_button", "Verify account")}
            </Style.RedButton>
            <UnverifiedEmailIcon
              width="16px"
              height="16px"
              style={{ marginLeft: "10px" }}
              data-tip
              data-tooltip-id="unverified-email-icon"
            />
            <ReactTooltip effect="solid" id="unverified-email-icon" place="left" className="cv-tooltip" noArrow={true}>
              {__("settings_not_verifed_email_message", "Your email address is not verified. At the moment, you don't have full access to pCloud.")}
            </ReactTooltip>
          </ButtonWrapper>
        ) : (
          <Style.Button onClick={onChangeEmailClick}>
            {__("change_email_btn", "Change Email")}
          </Style.Button>
        )}
        <EmailVerifyModal email={email} isModalOpen={openModal} onModalClose={() => setOpenModal()} />
      </Style.Row>
      <IntendedRow>
        <UserPlanInfoSetting/>
      </IntendedRow>
    </UserInfoWrapper>
  )
};

export default AccountInfo;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
  flex-shrink: 0;
`

const UserInfoWrapper = styled.div`
  &>${Style.Row}:first-of-type {
    border-bottom: none;
  }
`;

const IntendedRow = styled.div`
  padding-left: 42px;
  box-sizing: border-box;
  border-bottom: var(--border-size) solid var(--border-secondary);

  ${Style.Row} {
    padding: var(--spacing-sm);
    min-height: 48px;

    &>div {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    &:first-of-type {
      border-top: var(--border-size) solid var(--border-secondary);
    }

    &:last-of-type {
      border-bottom: none;
    }
  }

  ${Style.Title} {
    font-size: var(--font-size-14);
    font-weight: 400;
    text-transform: capitalize;

    @media (max-width: 768px) {
      font-size: var(--font-size-13)
    }
  }

  ${Style.Description} {
    margin-top: 0;
    text-transform: capitalize;
    flex-shrink: 0;
  }
`;
