// @flow

import React, { useMemo, useEffect, useRef, useState, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Tooltip } from "react-tooltip";

import { Menu, MenuItem } from "@szhsin/react-menu";
import { CATEGORY } from "@pcloud/web-utilities/dist/config/constants";
import { galleryHeaderConfig } from "./config/HeaderConfig";
import { ButtonWithIcon } from "../../components/ButtonDefault";

import Navigation from "./Navigation";

import ImgIcon from "../../../root/img/svg/gallery/photo.svg";
import VideoIcon from "../../../root/img/svg/gallery/slideshow.svg";
import DownloadIcon from "../../../root/img/svg/gallery/download.svg";
import LinkIcon from "../../../root/img/svg/gallery/link.svg";
import MoreOptionsIcon from "../../../root/img/svg/gallery/more_vert.svg";
import CloseIcon from "../../../root/img/svg/gallery/close.svg";
import ArrowIconBack from "../../../root/img/svg/gallery/arrow_back.svg";
import CloudUpload from "../../../root/img/svg/gallery/cloud_upload.svg";
// import NavigationLeftIcon from "../../../root/img/svg/gallery/chevron_left.svg";
// import NavigationRightIcon from "../../../root/img/svg/gallery/chevron_right.svg";

// import ItemInfoModal from "../../components/Modals/ItemInfoModal";

import { actionsGalleryToIcon, actionsGalleryToName, actionsGallery as actions } from "./constants";
import { MenuWrapper, Icon } from "./common.style";
import { strFitWidthPx } from "./utils";
import { onMoreOptionsClick } from "./services/onMoreOptionsClick";
import { copyTextToClipboard } from "../../lib/utils";
import { __ } from "../../lib/translate";
import { detectIsMobile } from "../../../web-shared/utils";

import useDownloadMedia from "./hooks/useDownloadMedia";

type Props = {
  // data: object,
  shownItemData: {
    place: number,
    metaData: Object,
    autoPlay: boolean
  },
  total: number,
  contentDataGalleryIds: array,
  opts: {
    code: string,
    linkpassword: string,
    candownload: boolean
  },
  onClose: () => void,
  onGoToNextMedia: () => void,
  onGoToPrevMedia: () => void,
  slideshowOn: boolean,
  modalContainer: any,
  showErrorMediaPreview: { show: boolean, options: { showDownloadButton: boolean } }
};

const GalleryHeader = React.forwardRef(
  (
    {
      shownItemData,
      opts = {},
      total,
      contentDataGalleryIds,
      onClose = () => {},
      onGoToNextMedia,
      onGoToPrevMedia,
      slideshowOn = false,
      modalContainer,
      showErrorMediaPreview = { show: false, options: { showDownloadButton: true } }
    }: Props,
    ref
  ) => {
    const SPACING = 122;

    const { name = "", revisionid = "", candelete = false, canmodify = false, ismine = false, category } =
      shownItemData.metaData || {};
    const isMobile = useMemo(() => !!detectIsMobile(), []);
    const isLogged = useSelector(({ user }) => user.logged);

    const config = useMemo(() => {
      const mediaType =
        shownItemData && shownItemData.metaData && shownItemData.metaData.category === HFN.CATEGORY.VIDEO ? "video" : "image";
      const deviceType = isMobile ? "mobile" : "desktop";
      const slideshowState = slideshowOn ? "slideshowOn" : "slideshowOff";
      return galleryHeaderConfig[deviceType][slideshowState][mediaType];
    }, [slideshowOn, isMobile, shownItemData.metaData]);

    const downloadOnMobileDLinkEl = useRef(null);
    const titleRef = useRef(null);
    const [titleWidth, setTitleWidth] = useState(0);

    const { downloadFile, canDownloadMobile, canDownload } = useDownloadMedia({
      downloadOnMobileDLinkEl,
      downloadItem: shownItemData,
      opts,
      onClose
    });

    const updateTitleWidth = () => {
      if (titleRef?.current) {
        const titleDivWidth = Math.round(titleRef?.current.getBoundingClientRect().width) - SPACING;
        setTitleWidth(titleDivWidth);
      }
    };

    useEffect(() => {
      window.addEventListener("resize", updateTitleWidth);
      return () => {
        window.removeEventListener("resize", updateTitleWidth);
      };
    }, []);

    useEffect(() => {
      if (shownItemData && shownItemData.place !== -1 && titleWidth === 0) {
        updateTitleWidth();
      }
    }, [shownItemData]);

    const saveToPcloudClick = e => {
      e.preventDefault();

      HFN.dLink._actionCopyToCloud(shownItemData, modalContainer);
    };

    const onShareIconClick = () => {

      if (typeof gtag === "function") {
        gtag("event", "media_preview_click", {
          action: "share",
          category: shownItemData && shownItemData.metaData && shownItemData.metaData.category === HFN.CATEGORY.VIDEO ? "video" : "image"
        });
      }

      HFN.getOrCreatePublink(
        shownItemData.metaData, // data,
        linkData => {
          const { link } = linkData;
          const message = __("preview_toast_msg", "Share link copied to the clipboard.");
          const buttonText = __("preview_toast_msg_link", "SETTINGS");

          copyTextToClipboard(link, "");

          HFN.message(message, "custom", true, true, false, true, {}, false, "light", buttonText, () => {
            // open link setting
            HFN.sharePublink(linkData, {}, modalContainer);
          });
        },
        {
          async: false,
          errorCallback(ret) {
            if (HFN.ERROR_MESSAGE[ret.result]) {
              HFN.message(__(HFN.ERROR_MESSAGE[ret.result]), "error");
            } else {
              HFN.message(ret.error, "error");
            }
          }
        }
      );
    };

    const onPrevClick = () => {
      // Get prev media.
      onGoToPrevMedia();
    };

    const onNextClick = () => {
      // Get next media.
      onGoToNextMedia();
    };

    const onMenuChange = ({ open }) => {
      if (open) {
        // On opened menu
        if (typeof gtag === "function") {
          gtag("event", "media_preview_view", {
            action: "options",
            category: shownItemData && shownItemData.metaData && shownItemData.metaData.category === HFN.CATEGORY.VIDEO ? "video" : "image"
          });
        }
      }
    };

    const renderMenuItem = (actionGroup, index, data) =>
      actionGroup.map((action, index) => (
        <MenuItem
          key={index}
          onClick={() => {
            onMoreOptionsClick(action, data, onClose, modalContainer, shownItemData && shownItemData.metaData && shownItemData.metaData.category === HFN.CATEGORY.VIDEO ? "video" : "image");
          }}
        >
          <Icon>{actionsGalleryToIcon[action]}</Icon>
          {__(actionsGalleryToName[action])}
        </MenuItem>
      ));

    const renderDownloadButton = () => {
      if (canDownloadMobile) {
        // Dlink on mobile with allowed "Download".
        return (
          <ActionIconWrapper
            ref={downloadOnMobileDLinkEl}
            style={{ display: showErrorMediaPreview.show ? "none" : "inherit" }}
          >
            <DownloadIcon width="24px" height="24px" />
          </ActionIconWrapper>
        );
      }

      if (canDownload) {
        return (
          <ActionIconWrapper
            style={{ display: showErrorMediaPreview.show ? "none" : "inherit" }}
            onClick={downloadFile}
          >
            <DownloadIcon width="24px" height="24px" />
          </ActionIconWrapper>
        );
      }
    };

    const renderUploadButton = () => {
      if (HFN.config.isDlink() && !isLogged) {
        if (HFN.config.isPublinkMobile()) {
          return (
            <ActionIconWrapper>
              <CloudUpload width="24px" height="24px" onClick={saveToPcloudClick} />
            </ActionIconWrapper>
          );
        }
        return (
          <ButtonWithIconExtended
            color="cyan"
            style={{ padding: "16px" }}
            onClick={saveToPcloudClick}
            minWidth="auto"
            icon="upload"
            text={__("save_to_pcloud_account", "Save to FREE pCloud account")}
            disabled={false}
            height={32}
          />
        );
      }
    };

    const renderActions = () => {
      if (!opts.code) {
        if (!revisionid && (ismine || (candelete && canmodify))) {
          return (
            <>
              {config?.options?.share && !(showErrorMediaPreview.show && !showErrorMediaPreview.options.showDownloadButton) && (
                <ActionIconWrapper onClick={onShareIconClick}>
                  <LinkIcon width="24px" height="24px" />
                </ActionIconWrapper>
              )}
              {config?.options?.moreOption && (
                <MenuWrapperStyled>
                  <Menu
                    align="end"
                    position={({ x, y, width }) => ({
                      left: x + width,
                      top: y
                    })}
                    transition
                    menuButton={
                      <ActionIconWrapper>
                        <MoreOptionsIcon width="24px" height="24px" />
                      </ActionIconWrapper>
                    }
                    onMenuChange={onMenuChange}
                    instanceRef={ref}
                  >
                    {!contentDataGalleryIds[shownItemData.place]?.missing &&
                      actions[shownItemData.metaData.category === HFN.CATEGORY.VIDEO ? "video" : "image"].map((actionGroup, index) => (
                        <>
                          {renderMenuItem(actionGroup, index, shownItemData.metaData)}
                          {index < actions.length - 1 && <Divider />}
                        </>
                      ))}
                  </Menu>
                </MenuWrapperStyled>
              )}
            </>
          );
        }
      }
    };

    return (
      <HaderWrapper
        className={`preview-header ${isMobile ? "mobile-view" : ""} ${slideshowOn ? "playing-slideshow" : ""}`}
        cols={config?.showNavigation ? 3 : 2}
      >
        {shownItemData && shownItemData.place !== -1 && config?.mediaTitle && (
          <TitleWrapper ref={titleRef}>
            {config?.mediaIcon ? (
              category === CATEGORY.IMAGE ? (
                <ImgIcon width="20px" height="20px" />
              ) : (
                <VideoIcon width="20px" height="20px" />
              )
            ) : null}
            {config?.arrowBackButton && (
              <div className="gallery-preview-close back-button" onClick={onClose}>
                <ArrowIconBack width="9px" height="16px" />
              </div>
            )}
            <Title>{strFitWidthPx(name, titleWidth)}</Title>
          </TitleWrapper>
        )}

        {shownItemData && shownItemData.place !== -1 && config?.showNavigation && (
          <Navigation
            currentIndex={shownItemData.place}
            total={total}
            hideArrows={config?.navigationArrows}
            handlePrevClick={onPrevClick}
            handleNextClick={onNextClick}
          />
        )}

        <OptionsWrapper className={!shownItemData || shownItemData.place === -1 ? "hidden-options-wrapper" : ""}  menuActive={!contentDataGalleryIds[shownItemData.place]?.missing}>
          <div data-tooltip-id="more-options" style={{display: showErrorMediaPreview.show && !showErrorMediaPreview.options.showDownloadButton ? "none" : "block"}} data-tooltip-hidden={!contentDataGalleryIds[shownItemData.place]?.missing}>
            <div className="gallery-preview-options">
              {renderUploadButton()}
              {config?.options?.download ? renderDownloadButton() : null}
              {renderActions()}
            </div>
            
            <Tooltip id="more-options" className="tooltip gallery-tooltip" effect="solid" place="bottom" multiline>
              {__("missing_file", "No actions are allowed on this file. The file has been deleted or moved.")}
            </Tooltip>
          </div>

          {config?.closeButton && (
            <div className="gallery-preview-close" onClick={onClose}>
              <CloseIcon width="24px" height="24px" />
            </div>
          )}
        </OptionsWrapper>
      </HaderWrapper>
    );
  }
);

export default GalleryHeader;

const HaderWrapper = styled.div`
  box-sizing: border-box;
  ${({ cols }) =>
    cols == 3
      ? `
      display: grid;
      grid-template-columns: minmax(0, 1fr) max-content minmax(0, 1fr);
      column-gap: 8px;
    `
      : `
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-shrink: 0;
      flex-grow: 1;
      gap: 8px;
    `}
  height: 64px;
  padding: 0 24px;
  color: #fff;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;

  &.playing-slideshow {
    position: absolute;
    width: 100%;
    left: 0;
    z-index: 1;
  }

  @media (max-width: 768px) {
    padding: 0 16px;
    height: 56px;
  }

  .gallery-preview-close {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  gap: 8px;
  align-items: center;
  overflow: hidden;
  padding-right: 32px;

  & > svg {
    flex-shrink: 0;
  }

  & > .gallery-preview-close.back-button {
    padding-right: 8px;
  }
`;

const Title = styled.div`
  line-height: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
`;

const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;

  &.hidden-options-wrapper {
    display: none;
  }

  .gallery-preview-options {
    display: flex;
    justify-content: space-between;
    gap: 16px;
  }

  ${({ menuActive }) => {
    if (!menuActive) {
      return `
        .gallery-preview-options {
          cursor: default;
          pointer-events: none;

          svg {
            opacity: 0.64;
            pointer-events: none;
          }
        }
      `;
    }
  }};
`;

const MenuWrapperStyled = styled(MenuWrapper)`
  display: flex;
  justify-content: flex-end;
  position: relative;

  .szh-menu-container {
    position: absolute;
    right: 0;
    top: 39px;
    z-index: 2;
  }

  .szh-menu {
    position: relative !important;

    max-height: calc(100vh - 64px - 6px);
    max-height: calc(100dvh - 64px - 6px);

    @media (max-width: 768px) {
      max-height: calc(100vh - 56px - 6px);
      max-height: calc(100dvh - 56px - 6px);
    }
    overflow: auto;
  }
`;

const Divider = styled.li`
  background: #eff0f1;
  height: 1px;
  width: 100%;
`;

const ActionIconWrapper = styled.span`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const ButtonWithIconExtended = styled(ButtonWithIcon)`
  display: flex;

  & > span {
    display: flex;
    gap: 8px;
    flex-direction: row-reverse;
    align-items: center;
    text-wrap: nowrap;
  }
`;
