import React, { useRef } from "react";
import styled from "styled-components";
import { Input } from "../styles";

const RegularInput = ({
    placeholder = "",
    className = "",
    value = "",
    autoFocus = false,
    invalid = false,
    onChange = () => {},
    ...rest
}) => {
    const inputRef = useRef(null);

    return (
        <Wrapper>
            <Input
                ref={inputRef}
                value={value}
                onChange={onChange}
                className={className}
                autoFocus={autoFocus}
                autoComplete="off"
                placeholder={placeholder}
                $isInvalid={!!invalid}
                type={"text"}
                {...rest}
            />
            {!!invalid && <Error>{invalid}</Error>}
        </Wrapper>
    );
};

export default RegularInput;

const Wrapper = styled.div`
    width: 100%;
    position: relative;

    & input {
        padding-right: 40px;
    }
`;

const Error = styled.div`
    color: var(--text-danger);
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
    margin: 2px 16px 0;
`;
