import { formatDt } from "../../../lib";
import { useEffect, useState } from "react";
import { __ } from "../../../lib/translate";
import hashManager from "../../../lib/hashManager";

const useThirdPartyControls = ({
    sourceid,
    title = null,
    modified = null,
    backup_metadata = null,
    next_execution = null,
    backuped_albums = 0,
    total_albums = 0,
    is_aborted,
    is_running,
    is_paused,
    is_stopped,
    is_inactive,
    unavailable,
    srcobj,
    exit_code,
    finished,
    isOverquota = false,
    type,
    is_allowed_to_backup,
    changeBackupStatus = () => {},
    stopBackup = () => {},
    isBackupStateLoading
}) => {
    const NEXT_DATE = next_execution ? new Date(next_execution) : null;
    const NEXT_DATE_UNIX_STAMP = NEXT_DATE && NEXT_DATE.getTime();
    const CURRENT_DATE = new Date().getTime();
    const NEXT_DATE_FORMATTED = next_execution ? formatDt(NEXT_DATE) : null;
    const LAST_DATE_FORMATTED = finished ? formatDt(new Date(finished)) : null;
    const PROGRESS_PERCENT =
        total_albums > 0
            ? Math.min(100, (backuped_albums / total_albums) * 100)
            : 0;

    const [isExpanded, setIsExpanded] = useState(false);
    const [accountInfo, setAccountInfo] = useState(false);
    const [status, setStatus] = useState("inactive");
    const [isDisconnecting, setisDisconnecting] = useState(false);

    const passedNextExecutionDate =
        next_execution && NEXT_DATE_UNIX_STAMP < CURRENT_DATE;
    const disabledButton =
        isBackupStateLoading ||
        !is_allowed_to_backup ||
        status === "running" ||
        isOverquota ||
        (type === "onetime" &&
            status !== "inactive" &&
            !passedNextExecutionDate);
    const errorState = !!(status !== "unavailable" && is_aborted && exit_code);

    useEffect(() => {
        let newStatus = "inactive";

        if (
            is_running ||
            (!is_running &&
                !is_aborted &&
                !is_stopped &&
                !finished &&
                !is_inactive &&
                !is_paused)
        ) {
            newStatus = "running";
        } else if (is_paused) {
            newStatus = "paused";
        } else if (is_aborted) {
            newStatus = "aborted";
        } else if (is_stopped) {
            newStatus = "stopped";
        } else if (finished) {
            newStatus = "finished";
        } else if (is_inactive) {
            newStatus = "inactive";
        }

        if (unavailable) {
            newStatus = "unavailable";
        }

        setStatus(newStatus);
    }, [
        is_running,
        is_aborted,
        is_stopped,
        finished,
        is_inactive,
        unavailable,
        is_paused
    ]);

    useEffect(() => {
        if (!["unavailable", "inactive"].includes(status) && !isDisconnecting) {
            getBackupAccountInfo(
                (userinfo) => {
                    setAccountInfo(userinfo);
                },
                () => {}
            );
        }
        setisDisconnecting(false);
    }, [getBackupAccountInfo, status]);

    const unlinkAccount = (e) => {
        e.stopPropagation();

        srcobj.unlinkAccount(() => {
            setAccountInfo(false);
            setisDisconnecting(true);
            if (finished) {
                stopBackup({
                    backupID: sourceid,
                    successCallback: () => {
                        HFN.message(
                            __(
                                "backup_stopped_disconnected",
                                "Backup has been stopped and your account is now disconnected"
                            )
                        );
                    }
                });
            } else {
                HFN.message(
                    __(
                        "backup_disconnected",
                        "Your account is now disconnected"
                    )
                );
            }
        });
    };

    const getBackupAccountInfo = (successCallback, errorCallback) => {
        srcobj.getUserIdentity(
            (userinfo) => {
                successCallback(userinfo);
            },
            () => {
                errorCallback();
            }
        );
    };

    const onButtonClick = (e) => {
        e.stopPropagation();
        if (disabledButton) {
            return;
        }
        changeBackupStatus({ backupID: sourceid });
    };

    const triggerClick = (e, folderid) => {
        if (e.target.nodeName === 'INPUT') {
            return;
        }

        hashManager.pushState(
            {
                page: "filemanager",
                folder: folderid
            },
            2
        );
    };

    const triggerExpand = (e) => {
        e.stopPropagation();
        if (e.target.nodeName === 'INPUT') {
            return;
        }

        setIsExpanded(!isExpanded);
    };

    return {
        LAST_DATE_FORMATTED,
        NEXT_DATE_FORMATTED,
        PROGRESS_PERCENT,
        type,
        status,
        sourceid,
        title,
        isOverquota,
        isExpanded,
        is_running,
        is_paused,
        exit_code,
        passedNextExecutionDate,
        backup_metadata,
        disabledButton,
        accountInfo,
        errorState,
        triggerClick,
        triggerExpand,
        onButtonClick,
        unlinkAccount,
    };
};

export default useThirdPartyControls;
