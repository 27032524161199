import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import Card from "./Card";
import { __ } from "../../../../lib/translate";
import DesktopEmptyContainer from "./components/EmptyContainers/DesktopEmptyContainer";
import MobileEmptyContainer from "./components/EmptyContainers/MobileEmptyContainer";
import { AnimatePresence, motion } from "framer-motion";

const BackupCategory = ({
    activeCategory,
    category,
    changeBackupStatus = () => {},
    stopBackup = () => {},
    isOverquota = false,
    isBackupStateLoading = false
}) => {
    const [canExpand, setCanExpand] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        let tmpCanExpand = ["desktop", "mobile"].includes(category.id) &&
            category.hasOwnProperty("items") &&
            category.items.length === 0 &&
            localStorage.getItem("backupsLayersSeen")
        if (
            (
                canExpand &&
                category.hasOwnProperty("items") &&
                category.items.length > 0
            ) ||
            category.id === activeCategory
        ) {
            tmpCanExpand = false;
        }

        setCanExpand(tmpCanExpand);
        setIsExpanded(!tmpCanExpand);
    }, [activeCategory]);

    return (
        <Wrapper>
            <Title
                className={canExpand ? "clickable" : null}
                onClick={canExpand ? () => setIsExpanded(!isExpanded) : null}
            >
                {__(category.title)}
                {canExpand && (
                    <ExpandableButton $isExpanded={isExpanded}>
                        <i className={"fa-regular fa-chevron-down baseIcon"} />
                    </ExpandableButton>
                )}
            </Title>
            {category.id === "one-time" && (
                <SubTitle>
                    {__(
                        "onetime_backups_subtext",
                        "Import your files from other services to pCloud. You can make one-time backups once every 30 days."
                    )}
                </SubTitle>
            )}
            {category.id === "ongoing" && (
                <SubTitle>
                    {__(
                        "thirdparty_backups_ongoing_descr",
                        "Create an automatic, ongoing backup from other services to pCloud."
                    )}
                </SubTitle>
            )}
            {category.hasOwnProperty("items") && category.items.length > 0 && (
                <ItemsContainer>
                    {category.items.map((item, index) => (
                        <Card
                            {...item}
                            key={index}
                            category={category.id}
                            changeBackupStatus={changeBackupStatus}
                            stopBackup={stopBackup}
                            isOverquota={isOverquota}
                            isBackupStateLoading={isBackupStateLoading}
                        />
                    ))}
                </ItemsContainer>
            )}
            <AnimatePresence exitBeforeEnter initial={false}>
                {isExpanded && (
                    <motion.div
                        initial={{ height: 0, opacity: 0, y: -40 }}
                        animate={{
                            height: "auto",
                            opacity: 1,
                            y: 0
                        }}
                        exit={{ height: 0, opacity: 0, y: -40 }}
                        transition={{ duration: 0.3 }}
                    >
                        {category.hasOwnProperty("items") &&
                            category.items.length === 0 &&
                            category.id === "mobile" && (
                                <MobileEmptyContainer />
                            )}
                        {category.hasOwnProperty("items") &&
                            category.items.length === 0 &&
                            category.id === "desktop" && (
                                <DesktopEmptyContainer />
                            )}
                    </motion.div>
                )}
            </AnimatePresence>
        </Wrapper>
    );
};

export default BackupCategory;

const Wrapper = styled.div`
    width: 100%;
    margin-bottom: 32px;
`;

const Title = styled.div`
    color: var(--text-primary, #282c2f);
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 40px;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 8px;

    &.clickable {
        cursor: pointer;
    }
`;

const ExpandableButton = styled.div`
    height: 25px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    & i.fa-chevron-down {
        transition: transform 0.5s ease;
    }

    @media (max-width: 800px) {
        height: 40px;
        width: 40px;
    }

    ${({ $isExpanded }) =>
        $isExpanded &&
        css`
            & i.fa-chevron-down {
                transform: rotate(180deg);
            }
        `}
`;

const SubTitle = styled.div`
    margin-top: 8px;
    color: var(--text-secondary);
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
`;

const ItemsContainer = styled(motion.div)`
    margin-top: 8px;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-flow: row wrap;
    gap: 24px;
`;
