import React from "react";
import {ContentHeader, ContentText} from "./styles";
import { __ } from "../../../lib/translate";
import styled from "styled-components";
import {boldConverter} from "../../../lib/componentifyConverters";
import Componentify from "react-componentify";

const FileViewOptionContent = () => {
    return (
        <Wrapper>
            <ContentHeader>{__("tutorial_title_file_view_option", "Change your view")}</ContentHeader>
            <ContentText>
                <Componentify
                    text={__("tutorial_message_file_view_option", "To change your view, select from four options: List, Large List, Grid, or Aspect Ratio Grid. Choose the one that suits you best and enjoy your cloud storage experience!")}
                    converters={[boldConverter]}
                />
            </ContentText>
        </Wrapper>
    );
}

export default FileViewOptionContent;

const Wrapper = styled.div`
    width: 280px;
`;