import React, { useEffect, useState } from "react";
import { apiMethod } from "../../../api";
import BackupAccountUI from "./BackupAccountUI";

const InstagramLink  = () => {
  const [account, setAccount] = useState(null);
  const [unlinkModal, setUnlinkModal] = useState(null);
  const [unlinkLoading, setUnlinkLoading] = useState(false);

  useEffect(() => {
    getToken().then(getIdentity);
  }, [])

  const getToken = () => {
    return new Promise((resolve, reject) => {
      apiMethod("social_assign", {
        type: "ig"
      }, response => {
        if (response.access_token) {
          Instagram.token = response.access_token;
          resolve(response);
        }
      }, {
        errorCallback: (error) => { console.error(error) }
      })
    })

  }

  const getIdentity = (response) => {
    Instagram.apiCallGet('me?fields=username', {}, ret => {
      setAccount(ret.username);
    }, {
      onMissingToken: () => {
        HFN.apiMethod('social_assign', { type: 'ig', dismiss: 'true' }, ret => {});
      }
    });
  }

  const onLink = () => {
    Instagram.getTokenCallback((token, expire) => {
      HFN.message('Token saved.');
      getIdentity();
    });
  };

  const onUnlink = () => {
    apiMethod('social_assign', { type: 'ig', dismiss: 'true' }, ret => {
      HFN.message(__('Account unlinked.'));
      Instagram.token = null;
      setAccount(null);
    })
  }

  return (
    <BackupAccountUI
      type="ig"
      app={__("Instagram Account")}
      account={account}
      linked={!!account}
      onLink={onLink}
      onUnlink={onUnlink}
    />
  )
}

export default InstagramLink ;