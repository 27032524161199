import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { setGridHeaderHeight } from "../../../../lib/state/actions/content";
import { useDispatch } from "react-redux";

const RewindExtend = () => {
    const contentRef = useRef(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!contentRef.current) {
            return;
        }
        const parent = contentRef.current;

        const handleDynamicWidth = () => {
            const height = parent.offsetHeight + 10;

            if (RewindExtendHeight !== height) {
                RewindExtendHeight = height;
                dispatch(setGridHeaderHeight(height));
            }
        };

        handleDynamicWidth();
        const observer = new ResizeObserver(handleDynamicWidth);
        observer.observe(parent);

        return () => {
            observer.unobserve(parent);
        };
    }, [contentRef.current]);

    return (
        <RewindExtendStyle ref={contentRef}>
            {__(
                "rewind_extend",
                "Want to extend Rewind? Get up to one year Extended File History as an add-on."
            )}
            <span>
                <Link href="https://www.pcloud.com/cloud-storage-pricing-plans.html?step=purchase&product=extended-history&period=year">
                    {__("efh_extendnow")}
                </Link>
            </span>
        </RewindExtendStyle>
    );
};

export default RewindExtend;

export let RewindExtendHeight = 100;

const RewindExtendStyle = styled.div`
    display: flex;
    padding: var(--spacing-md);
    justify-content: center;
    align-items: center;
    gap: 32px;
    box-sizing: border-box;
    border-radius: var(--radius-minimum);
    border: 2px solid var(--color-functional-positive200);
    background: var(--color-functional-positive100);
    margin-bottom: var(--spacing-md);
    margin-top: var(--spacing-sm);
    margin: var(--spacing-sm) 0 var(--spacing-md) 0;

    @media (max-width: 1024px) {
        margin: var(--spacing-xs) var(--spacing-md) var(--spacing-sm);
    }

    @media (max-width: 900px) {
        flex-direction: column;
        gap: 16px;
        text-align: center;
    }

    color: var(--text-positive);
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
`;

const Link = styled.a`
    display: flex;
    height: 40px;
    box-sizing: border-box;
    padding: var(--spacing-sm) var(--spacing-lg);
    justify-content: center;
    align-items: center;
    border-radius: var(--radius-full);
    border: 1px solid var(--text-positive);
    text-decoration: none;

    color: var(--text-positive);
    text-align: center;

    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    white-space: nowrap;
`;
