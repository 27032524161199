import React from "react";
import { detectIsMobile } from "../../lib";
import { flyIn, DescriptionWrapper, FontAwesomeIconArrow } from "./styles";
import styled, { css } from "styled-components";
import { hiDPI } from "polished";
import { sendAnalyticsEvent } from "../../lib/sendAnalyticsEvent";

const PromoCardTemplate = ({
    isInMenu,
    swipeable,
    toggled,
    isSingleCard,
    promoCardData,
    isSmall,
    key,
    setIsHovered
}) => {
    const isMobileDevice = detectIsMobile();

    if (!promoCardData) {
        return null;
    }

    let cardData = {
        title: promoCardData.title,
        titleonhover: promoCardData.titleonhover,
        description: promoCardData.description,
        bgcolor: promoCardData.desktopbgcolor,
        bghovercolor: promoCardData.desktopbghovercolor,
        image1x: promoCardData.desktopimage1x,
        image2x: promoCardData.desktopimage2x,
        titlevalign: promoCardData.desktoptitlevalign,
        titlehalign: promoCardData.desktoptitlehalign,
        titlecolor: promoCardData.desktoptitlecolor,
        titlehovercolor: promoCardData.desktoptitlehovercolor,
        titlefontsize: promoCardData.desktoptitlefontsize
    };

    if (isSmall) {
        if (toggled) {
            cardData = {
                bgcolor: promoCardData.leftnavbgcolor,
                image1x: promoCardData.leftnavcolimage1x,
                image2x: promoCardData.leftnavcolimage2x
            };
        } else {
            cardData = {
                title: promoCardData.title,
                bgcolor: promoCardData.leftnavbgcolor,
                image1x: promoCardData.leftnavimage1x,
                image2x: promoCardData.leftnavimage2x,
                titlevalign: promoCardData.leftnavtitlevalign,
                titlehalign: promoCardData.leftnavtitlehalign,
                titlecolor: promoCardData.leftnavtitlecolor,
                titlefontsize: promoCardData.leftnavtitlefontsize
            };
        }
    } else if (isMobileDevice) {
        cardData = {
            title: promoCardData.title,
            bgcolor: promoCardData.mobilebgcolor,
            image1x: promoCardData.mobileimage1x,
            image2x: promoCardData.mobileimage2x,
            titlevalign: promoCardData.mobiletitlevalign,
            titlehalign: promoCardData.mobiletitlehalign,
            titlecolor: promoCardData.mobiletitlecolor,
            titlefontsize: promoCardData.mobiletitlefontsize
        };
    }

    return (
        <PromoCard
            onClick={() => {
                sendAnalyticsEvent({
                    category: "webcard",
                    action: "click",
                    label: promoCardData.label,
                    os: 4
                  }, {
                    channelid: promoCardData.channelid
                  }
                );

                window.location.href = promoCardData.url;
            }}
            key={key}
            $promoCardData={cardData}
            $isInMenu={isInMenu}
            $swipeable={swipeable}
            $toggled={toggled}
            $isSingleCard={isSingleCard}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            $isMobileDevice={isMobileDevice}
        >
            <FlipCard>
                <FlipCardFront>
                    {cardData.title && (
                        <PromoName className="title">
                            {cardData.title}
                        </PromoName>
                    )}
                    {isInMenu && (
                        <DescriptionWrapper
                            className="hover-arrow"
                            $isInMenu={swipeable}
                        >
                            {!isSmall && (
                                <FontAwesomeIconArrow
                                    $isInMenu={isInMenu}
                                    className="icon fa-solid fa-circle-arrow-right"
                                />
                            )}
                        </DescriptionWrapper>
                    )}
                </FlipCardFront>
                <FlipCardBack>
                    {cardData.titleonhover && (
                        <PromoName className="title">
                            {cardData.titleonhover}
                        </PromoName>
                    )}
                    <DescriptionWrapper
                        className="hover-arrow"
                        $isInMenu={swipeable}
                    >
                        {!isSmall && (
                            <FontAwesomeIconArrow
                                $isInMenu={isInMenu}
                                className="icon fa-solid fa-circle-arrow-right"
                            />
                        )}
                    </DescriptionWrapper>
                    {cardData.description && (
                        <PromoDescription
                            className="description"
                            $promoCardData={promoCardData}
                        >
                            {cardData.description}
                        </PromoDescription>
                    )}
                </FlipCardBack>
            </FlipCard>
        </PromoCard>
    );
};

export default PromoCardTemplate;

const FlipCard = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.8s;
    transform-style: preserve-3d;
`;
const FlipCardInner = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    padding: 16px;
    box-sizing: border-box;
`;
const FlipCardFront = styled(FlipCardInner)``;
const FlipCardBack = styled(FlipCardInner)`
    transform: rotateY(180deg);
`;

const PromoCard = styled.div`
    position: relative;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: ${({ $toggled, $isInMenu }) =>
        !$isInMenu
            ? "15%"
            : $toggled
            ? "calc(100% - 16px)"
            : "calc(100% - 48px)"};
    height: ${({ $toggled, $isInMenu }) =>
        !$isInMenu ? 120 : $toggled ? 40 : 100}px;
    margin: 0 ${({ $toggled, $isInMenu, $swipeable }) =>
        !$swipeable && !$isInMenu ? 0 : !$isInMenu ? 16 : $toggled ? 8 : 24}px;
    cursor: pointer;
    background-size: cover;
    overflow: hidden;
    box-sizing: border-box;
    perspective: 1000px;

    ${({ $isInMenu }) =>
        !$isInMenu ?
                css`
                    min-width: 200px;
                    max-width: 240px;

                    @media only screen and (max-width: 1400px) {
                        width: 20%;
                    }

                    @media only screen and (max-width: 1150px) {
                        width: 30%;
                    }

                    @media only screen and (max-width: 700px) {
                        width: 40%;
                    }

                    @media only screen and (max-width: 460px) {
                        max-width: unset;
                        width: 100%;
                        ${({ $isSingleCard }) =>
                                $isSingleCard &&
                                css`
                                    margin: 0 auto;
                                `}
                    }

                    ${({ $isMobileDevice }) =>
                            $isMobileDevice
                                    ? css`
                                        @media only screen and (max-width: 460px) {
                                            width: calc(100% - 32px);
                                        }
                                    `
                                    : css`
                                        &:hover ${FlipCard} {
                                            transform: rotateY(180deg);
                                        }

                                        & .hover-arrow {
                                            opacity: 1;
                                            transform: scale(1);
                                            ${({ $isInMenu }) =>
                                                    $isInMenu &&
                                                    css`
                                                        animation: ${flyIn} 0.2s ease-out forwards;
                                                    `}
                                        }
                                    `}
                ` : css`
                    ${({ $isMobileDevice }) =>
                            !$isMobileDevice && css`
                                &:hover .hover-arrow {
                                    opacity: 1;
                                    transform: scale(1);
                                    ${({ $isInMenu }) =>
                                            $isInMenu &&
                                            css`
                                                animation: ${flyIn} 0.2s ease-out forwards;
                                            `}
                                }
                            `}
                `}
    & ${FlipCardFront} {
        background-color: #${({ $promoCardData }) => $promoCardData.bgcolor};

        background-repeat: no-repeat;
        background-size: auto 100%;
        background-position: bottom 0 left 0;
        background-image: url(${({ $promoCardData }) =>
            $promoCardData.image1x});

        ${hiDPI(1.5)} {
            background-image: url(${({ $promoCardData }) =>
                $promoCardData.image2x});
        }

        display: flex;
        align-items: ${({ $promoCardData }) =>
            getValignPosition($promoCardData.titlevalign)};
        justify-content: ${({ $promoCardData }) =>
            getAlignPosition($promoCardData.titlehalign)};

        & .title {
            color: #${({ $promoCardData }) => $promoCardData.titlecolor};
            font-size: ${({ $promoCardData }) =>
                $promoCardData.titlefontsize}px;
        }
    }

    & ${FlipCardBack} {
        background-color: #${({ $promoCardData }) =>
            $promoCardData.bghovercolor};
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        gap: 8px;

        & .title {
            color: #${({ $promoCardData }) => $promoCardData.titlehovercolor};
            font-size: 16px;
        }
        & .fa-circle-arrow-right {
            color: #${({ $promoCardData }) => $promoCardData.titlehovercolor};
        }

        & .description {
            color: #${({ $promoCardData }) => $promoCardData.titlehovercolor};
            font-size: 12px;
            font-weight: 400;
            line-height: 14px;
        }
    }
`;

const PromoName = styled.div``;

const PromoDescription = styled.div``;

const getAlignPosition = (position) => {
    switch (position) {
        case "left":
            return "start";
        case "right":
            return "end";
        default:
            return "center";
    }
};
const getValignPosition = (position) => {
    switch (position) {
        case "top":
            return "start";
        case "bottom":
            return "end";
        default:
            return "center";
    }
};
