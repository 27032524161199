import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    padding: 16px 0 18px 0;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
`;

export const Title = styled.div`
    color: var(--color-base-black);
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
`;

export const SubTitle = styled.div`
    color: var(--text-secondary);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
`;

export const AppContainer = styled.div`
    display: flex;
    padding-top: 16px;
    align-items: flex-start;
    gap: 16px;
`;
