// @flow

import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { hiDPI } from "polished";

import { __ } from "../../lib/translate";
import { copyTextToClipboard } from "../../lib/utils";
import { LinkWrapper, LinkInput, CopyLink } from "./SharedStyledComponents";

type Props = {
  onEmailIconClick: () => void
};

const ShareSettingsModalHeader = (props: Props) => {
  const linkData = useSelector(({ sharedLinks }) => sharedLinks.linkData);
  const { linkUrl, shortLinkUrl, linkName } = linkData;

  const onEmailIconClick = () => {
    if (typeof gtag === "function") {
      gtag("event", "shared_link_settings_click", {
        action: "initiate send email",
        category: "share",
        type: "email",
        location: "settings modal"
      })
    }

    props.onEmailIconClick("share")
  }

  const copyLink = useCallback(() => {
    if (typeof gtag === "function") {
      gtag("event", "shared_link_settings_click", {
        action: "copy link",
        category: "share",
        type: "copy",
        location: "settings modal"
      })
    }

    copyTextToClipboard(linkUrl, __("Link copied."));
  }, [linkUrl]);

  const onSocialClick = type => {
    const shareurl = shortLinkUrl ? shortLinkUrl : linkUrl;

    if (typeof gtag === "function") {
      gtag("event", "shared_link_settings_click", {
        action: type === "fb" ? "initiate facebook share" : "initiate twitter share",
        category: "share",
        type: "social share",
        location: "settings modal"
      })
    }

    if (type === "fb") {
      window.open(
        "http://facebook.com/sharer.php?u=" + encodeURIComponent(shareurl),
        "facebook-share-dialog",
        "width=626,height=436"
      );
    } else if (type === "tw") {
      window.open(
        "https://twitter.com/intent/tweet?url=" + encodeURIComponent(shareurl) + "&text=pCloud",
        "twitter-share",
        "width=550,height=450"
      );
    }
  };

  return (
    <HeaderWrapper>
      <LinkName>{linkName}</LinkName>
      <Section>
        <LinkWrapper>
          <LinkInput defaultValue={linkUrl} disabled />
          <CopyLink onClick={copyLink}>{__("shared_links_settings_copy_link")}</CopyLink>
        </LinkWrapper>
        <ShareButtons>
          <EmailShare onClick={() => onEmailIconClick()} />
        </ShareButtons>
      </Section>
    </HeaderWrapper>
  );
};

export default ShareSettingsModalHeader;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 26px 20px 20px 20px;
  box-sizing: border-box;
`;

const Section = styled.div`
  display: flex;
  margin-top: 25px;
`;

const LinkName = styled.div`
  width: 90%;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  color: #000000;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
`;

const ShareButtons = styled.div`
  flex-shrink: 0;
  height: 36px;
`;

const EmailShare = styled.div`
  display: inline-block;
  margin-left: 6px;
  width: 36px;
  height: 36px;
  border-radius: 3.1px;
  cursor: pointer;

  background-color: #17bed0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 18px 14px;

  background-image: url(${require("../../../root/img/share-icons/mail.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/share-icons/mail@2x.png")});
  }
`;

const TwitterShare = styled(EmailShare)`
  background-color: #1ca0f1;
  background-size: 19px 16px;

  background-image: url(${require("../../../root/img/share-icons/tw.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/share-icons/tw@2x.png")});
  }
`;

const FacebookShare = styled(EmailShare)`
  background-color: #1776f1;
  background-size: 10px 19px;

  background-image: url(${require("../../../root/img/share-icons/fb.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/share-icons/fb@2x.png")});
  }
`;
